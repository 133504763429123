((define) => {
    const device = {}
    const userAgent = window.navigator.userAgent
    const phoneModelRules = {
        "meizu": /meizu/i,
        'iphone': /\biPhone\b|\biPod\b/i,
        "xiaomi": /^(?!.*\bx11\b).*xiaomi.*$|POCOPHONE F1|MI 8|Redmi|MI\s|M2001J2G|M2001J2I|M1805E10A|M2004J11G|M1902F1G|M2002J9G|M2004J19G|M2003J6A1G/i,
        "pixel": /; \bPixel\b/i, 
        "oneplus": /ONEPLUS/i,
        "oppo": /oppo/i,
        "vivo": /vivo/i,
        "huawei": /huawei|HONOR/i, 
        "samsung": /\bSamsung\b|SM-|GT-|SCH-|SGH-|SPH-|SHW-/,  
    }
    const test = (reg) => {
        return reg.test(userAgent)
    }
    device.isiPhone = () => {
        return test(/iphone/i)
    }
    device.isiPad = () => {
        return test(/ipad/i)
    }
    device.isiPod = () => {
        return test(/ipod/i)
    }
    device.isWindows = () => {
        return test(/windows/i)
    }
    device.isAndroid = () => {
        return test(/android/i)
    }
    device.isAndroidPhone = () => {
        return device.isAndroid() && test(/mobile/i)
    }
    device.isAndroidTablet = () => {
        return device.isAndroid() && !test(/mobile/i)
    }

    device.isMobile = () => {
        return device.isiPhone() || device.isiPod() || device.isAndroidPhone()
    }
    device.isTablet = () => {
        return device.isiPad() || device.isAndroidTablet()
    }
    device.isDesktop = () => {
        return !device.isMobile() && !device.isTablet()
    }

    device.getPhoneModel = () => {
        if (!device.isMobile()) return 'unkown'
        for (const key in phoneModelRules) {
            const reg=phoneModelRules[key]
             if(reg.test(userAgent)) return key 
        } 
        return 'unkown'
    }


    if (define && typeof define === 'function' && typeof define.amd === 'object' && define.amd) {
        define(function () {
            return device
        });
    } else if (typeof module !== 'undefined' && module.exports) {
        module.exports = device
    } else {
        window.device = device
    }
})()