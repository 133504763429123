import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
// import { Checkbox } from "@abb/abb-common-ux-react"
import { Button, TabControl, TabItem } from "@abb/abb-common-ux-react"
import { CascadeType, CommonFormEdit } from "components/LoongInCommon"
import SidePanel from "components/SidePanel"
import { CommonRule } from "components/LoongInCommon"
import { CommonTree } from "components/LoongInCommon"
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { getDeviceTypes , addAssociatedDeviceType } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { getMaterial } from "slices/materialSlice"
export const DeviceTypeFormEdit = forwardRef(({ id, onSubmit, onCancel }, ref) => {
  //引用基础窗体控件
  const treeRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const material = useSelector(getMaterial)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [isSumbiting, setIsSumbiting] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [tree, setTree] = useState(null)
  const [checkItems, setCheckItems] = useState([])

  const resetForm = () => {}
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    resetForm,
    getDeviceTypeTree,
  }))
  useEffect(() => {
    getDeviceTypeTree()
  }, [showSidePanel])
  const getDeviceTypeTree = async () => {
    try {
      const response = await axios.post(getDeviceTypes(), {})
      if (response.status === 200) {
        if (response.data) {
          setTree(response.data)
          expandAll()
        }
      } else {
        console.log("error to get tree grant")
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  const expandAll = () => {
    if (treeRef && treeRef.current) {
      treeRef.current.expandAll()
    } else {
      const intev = setInterval(() => {
        if (treeRef && treeRef.current) {
          clearInterval(intev)
          treeRef.current.expandAll()
        }
      }, 100)
    }
  }
  const cancel = () => {
    setShowSidePanel(false)
  }
  const saveForm = () => {
    onSubmitThis()
    setShowSidePanel(false)
  }
  const onCheckChange = (newCheckItems) => {
    console.log(newCheckItems)
    let newTree = newCheckItems
    setCheckItems(newTree)
  }
  //提交成功后触发
  const onSubmitThis = async (item) => {
    try {
      const response = await axios.post(addAssociatedDeviceType(), {
        slaverId: id,
        masterId:[...checkItems],
      })
      if (response.status === 200) {
        console.log("update sucess")
      } else {
        console.log("error to get tree grant")
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
    onSubmit && onSubmit(item)
  }
  //返回页面
  return (
    <SidePanel show={showSidePanel} onClickBackdrop={() => cancel()}>
      <div className="side-panel-header">
        <div className="side-panel-title">关联设备类型</div>
      </div>
      <div className="side-panel-body">
        <TabControl type="panel">
          <TabItem title="关联设备类型">
            <div style={{ height: clientHeight - 280 + "px" }}>
              <CommonTree cascade={CascadeType.None} ref={treeRef} items={tree} checkItems={checkItems} onCheckChange={onCheckChange} />
            </div>
          </TabItem>
        </TabControl>
        <div className="form-group-button" style={{ marginTop: "5px" }}>
          <Button
            style={{ marginRight: "5px" }}
            text={t("b_save")}
            type="primary-blue"
            sizeClass="small"
            onClick={saveForm}
            isLoading={isSumbiting}
          />
          <Button text={t("b_cancel")} type="normal" sizeClass="small" onClick={cancel} />
        </div>
      </div>
    </SidePanel>
  )
})
