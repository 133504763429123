import React, { useState, useEffect, Children, cloneElement } from "react"

const prefixCls = "ivu-row"
export const Row = ({
  children,
  type, // 无效，强制都是：flex
  align, //flex 布局下的垂直对齐方式，可选值为 top、middle、bottom	String
  justify, //布局下的水平排列方式，可选值为 start、end、center、space-around、space-between
  gutter, //栅格间距，单位 px，左右平分 类型：Number 默认：0
  wrap, //是否自动换行	类型：Boolean	默认：true
  className, //自定义的class名称
}) => {
  const [classes, setClasses] = useState({})
  const [styles, setStyles] = useState({})

  useEffect(() => {
    let classList = [
      // todo 4.5.0 已无效，强制 flex
      `${prefixCls}`,
      !!type ? `${prefixCls}-${type}` : "",
      !!align && type
        ? `${prefixCls}-${type}-${align}`
        : "",
      !!justify && type
        ? `${prefixCls}-${type}-${justify}`
        : "",
      !!align ? `${prefixCls}-${align}` : "",
      !!justify ? `${prefixCls}-${justify}` : "",
      !!className ? `${className}` : "",
      !wrap ? `${prefixCls}-no-wrap` : "",
    ]
    let newclasses = ""
    classList.forEach((c) => {
      if (c) newclasses += c + " "
    })
    setClasses(newclasses)
  }, [type, align, justify, className])

  useEffect(() => {
    let newstyle = {}
    if (gutter !== 0) {
      newstyle = {
        marginLeft: gutter / -2 + "px",
        marginRight: gutter / -2 + "px",
      }
    }
    setStyles(newstyle)
  }, [gutter])

  const childrenWithProps = Children.map(children, (child) => {
    return cloneElement(child, {
      gutter: gutter
    })
  })
  return (
    <div className={classes} style={styles}>
      {childrenWithProps}
    </div>
  )
}
