import React, { useState, useRef } from "react"
import { WithTooltip, Tooltip } from "@abb/abb-common-ux-react"
import { getRolePage, deleteRole, lockUser } from "common/endpoints"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import { t } from "i18next"

export default () => {
  const [deletedItems, setDeletedItems] = useState([])
  const refIndex = useRef(null)
  //列定义
  const columns = [
    {
      title: "role.name",
      key: "name",
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>该角色已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
    {
      title: "role.isDefault",
      key: "isDefault",
      width: 150,
      render: (item, index) => {
        if (item.isDefault) return <span className="abb-tag abb-tag-red">{t("t_yes")}</span>
        else return <span className="abb-tag abb-tag-green">{t("t_no")}</span>
      },
    },
  ]
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => grantAuth(item),
        title: t("t_grant_auth"),
        color: "blue",
      },
    ]
  }
  //打开权限树
  const grantAuth = (item) => {
    refIndex.current.showAndSwitchTab(1, item)
  }
  //删除时将数据放入删除列表
  const onDeleteData = (item) => {
    setDeletedItems([...deletedItems, item.id])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getRolePage}
        deleteData={deleteRole}
        FormEdit={FormEdit}
        otherMenu={otherMenu}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        refreshAfterDelete={false}
        enableSorting={true}
      />
    </>
  )
}
