import React, { useState, useEffect } from "react";
import { Dropdown, DropdownOption, Spinner, Button, Icon } from "@abb/abb-common-ux-react";
import { useHistory, useParams } from "react-router-dom";



export default function ClientSelector({ clients,  isLoading }) {
  const history = useHistory();
  const urlParams = useParams();
  const [activeClientId, setActiveClientId] = useState(null)


  useEffect(() => {
    setActiveClientId(urlParams.id)
  }, [urlParams.id]);


  const onSelectClient = (e) => {
    if (!e[0]) {
      return
    } else {
      history.push(`/maindata/clients/${e[0].value}`)
    }
  }
  const clientOptionTemplate = (client) => (
    <div style={{ display: "flex" }} >
      <span style={{ flexGrow: 1 }}>{client.name}</span>
      {client.inactive && <Icon className="text-light-gray" name="abb/warning-circle-1" />}
    </div>
  );
  const clientSelectedTemplate = (client) => (
    <div style={{ display: "flex", alignItems: 'center' }} >
      {client.inactive && <Icon className="text-orange" name="abb/warning-circle-1" style={{ marginRight: 4 }} />}
      <span style={{ flexGrow: 1 }}>{client.name}</span>
    </div>
  );
  console.log(clients)

  return (
    <>
      <div className="admin-main-content-header" >
        <div  style={{ display: 'flex', padding: 8, alignItems: 'center', flexGrow: 1 }}>
          <span>客户</span>
          <Dropdown 
            value={[{ value: activeClientId }]}
            onChange={onSelectClient}
            searchable
            createOptionText="添加客户"
            style={{ width: 240, marginLeft: 16, marginRight: 16 }}>
            {clients.map(c => (
              <DropdownOption key={c.id} value={c.id} label={c.name}
                selectedItemTemplate={clientSelectedTemplate(c)}
                menuItemTemplate={clientOptionTemplate(c)}
              />))}
          </Dropdown>
          {isLoading && <Spinner />}
        </div>
        
      </div>

    </>
  )
}
