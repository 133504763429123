import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { CommonConfirm, CommonIndex } from 'components/LoongInCommon'
import { useSelector } from 'react-redux'
import {  Button } from '@abb/abb-common-ux-react'
import { forwardRef } from 'react'
import './organiza.scss'
import { deleteLifecycle, generateLifecycle, getLifecyclePage } from 'common/apis/lifecycle'
import { getDevice } from 'slices/deviceSlice'
import { t } from 'i18next'
import axios from 'axios'
import moment from 'moment'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { LifeCycleFormEdit } from './LifeCycleFormEdit'
export const LifeCycle = forwardRef(({ onEdit }, ref) => {
  const [searchData, setSearchData] = useState(null)
  const [showGenerate, setShowGenerate] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const refIndex = useRef(null)
  const device = useSelector(getDevice)
  const lifeCycleType = useDataDictionary('LifeCycleType')
  useEffect(() => {
    if (device) {
      setSearchData({ deviceId: device.id })
    }
  }, [device])

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const onWindowResize = () => {}
  
  //列定义
  const columns = [
    {
      title: '事件类型',
      key: 'type',
      render: (item) => {
        return lifeCycleType.details.find((d) => d.code === item.type)?.displayText
      },
    },
    {
      title: '事件标题',
      key: 'title',
    },
    {
      title: '计划执行时间',
      key: 'planDate',
      render: (item) => {
        return moment(item.planDate).format('yyyy-MM')
      },
    },
    {
      title: '执行时间',
      key: 'date',
      render: (item) => {
        return item.date?moment(item.date).format('yyyy-MM-DD'):'未执行'
      },
    },
  ]

  const refresh = () => {
    refIndex.current.query()
  }
  const actionRender = (item, index) => {
    return (
      <>
      <Button
        sizeClass='small'
        icon='abb/edit'
        shape='pill'
        onClick={() => {
          refIndex.current.edit(item)
        }}
      />
      <Button
        sizeClass='small'
        icon='abb/trash'
        shape='pill'
        onClick={() => {
          refIndex.current.setDeleteItem(item)
        }}
      />
      </>
    )
  }
  const onGenerating = () => {
    setIsGenerating(true)
    axios.post(generateLifecycle(device.id), {}).then((r) => {
      setIsGenerating(false)
      refIndex.current.query()
      setShowGenerate(false)
    })
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    refresh,
  }))
  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>生命周期事件</div>
          <div className='widget-tools device-profile-paginator show-md show-lg' style={{ paddingTop: 10 }}>
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/generator'
              onClick={() => setShowGenerate(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/plus'
              onClick={() => refIndex.current.add()}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>
          <div className='widget-tools device-profile-paginator show-xl'>
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/generator'
              onClick={() => setShowGenerate(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/plus'
              onClick={() => refIndex.current.add()}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>
        </div>
        <div className='widget-body device-profile-details'>
          <CommonIndex
            className={'admin-main-content3 with-padding'}
            ref={refIndex}
            columns={columns}
            getList={getLifecyclePage}
            searchData={searchData}
            deleteData={deleteLifecycle}
            FormEdit={LifeCycleFormEdit}
            canDelete
            canEdit
            pageSize={7}
            pageInQuery={false}
            canSearch={false}
            refreshAfterDelete={true}
            tableWidth={'100%'}
            tableHeight={'100%'}
            enableSorting={false}
            refreshWhenOpen={false}
            canSelection={false} //是否开启选择功能
            canBatchDel={false}
            actionRender={actionRender}
            searchClassName='searching-form-simple' //查询样式
            toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
          />
          <CommonConfirm
            title={'生成生命周期事件'}
            content={'确认要设备的生成生命周期事件吗？'}
            isVisible={showGenerate}
            onConfirm={onGenerating}
            confirmText={t('b_confirm')}
            onCancel={() => setShowGenerate(false)}
            isLoading={isGenerating}
          />
        </div>
      </div>
    </div>
  )
})
