import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Dialog } from "@abb/abb-common-ux-react"
import { getUnAddUsers, getUserPage } from "common/endpoints"
import { CommonIndex } from "components/LoongInCommon"
import { t } from "i18next"
import axios from "axios"
import { getClientHeight } from "slices/appSlice"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"

import { addDevice, getInstrumentPage, getStationDevices } from "common/apis/maindata"
import { id } from "date-fns/locale"
import { addTestInstrument, addTestModal, getTestModalPage } from "common/apis/ezreport"
import { findTree } from "common/utils"
import moment from "moment"
import { useDataDictionary } from 'hooks/useDataDictionary'
import { fetchGetOrganizationAsync, getOrganization } from "slices/organizationSlice"

export const SearchInstrument = forwardRef(({itemId, onRefresh }, ref) => {
  const refIndex = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [checkItems, setCheckItems] = useState([])
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])

  const dt = useDataDictionary('InstrStatus')
  const organization = useSelector(getOrganization)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //列定义
  const changeColumns = () => {
    setColumns([
      {
        title: 'instrument.assetCode',
        key: 'assetCode',
      },
      {
        title: 'instrument.name',
        key: 'name',
      },
      {
        title: 'instrument.modal',
        key: 'modal',
        width: '150px',
      },
      {
        title: '所在服务中心',
        key: 'unitId',
        render: (item, index) => {
          const manager = findTree(organization, (d) => d.key == item.unitId)
          return manager?.title
        },
      },
      {
        title: 'instrument.status',
        key: 'status',
        render: (item, index) => {
          const category = dt?.details.find((d) => d.code == item.status)
          return category?.displayText
        },
      },
      {
        title: 'instrument.lastCheck',
        key: 'lastCheck',
        render: (item) => {
          return moment(item.lastCheck).format('yyyy-MM-DD')
        },
      },
      {
        title: '到期日期',
        key: 'purchaseDate',
        render: (item) => {
          return item.validity>=9999?'一次性':moment(item.lastCheck).add(item.validity, 'M').format('yyyy-MM-DD')
        },
      },
    ])
  }
  useEffect(() => {
    if (!organization) {
      dispatch(fetchGetOrganizationAsync())
    }
  }, [organization])

  useEffect(() => {
    changeColumns()
  }, [dt, organization])
  const addModals = async (items, callback) => {
    try {
      axios
        .post(addTestInstrument(), {
          instrumentId: items,
          itemId: itemId,
        })
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            dispatch(showNotification(NotificationType.Success, t("t_successToAdd")))
            if (callback) callback()
          } else {
            dispatch(showNotification(NotificationType.Warn, t("t_failToAdd")))
          }
        })
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t("t_failToAdd")))
    } finally {
    }
  }
  const onConfirm = () => {
    if (!checkItems || checkItems.length <= 0) {
      dispatch(showNotification(NotificationType.Alarm, t("t_plsSelectUser")))
      return
    }
    addModals(
      checkItems.map((item) => {
        return item.id
      }),
      () => {
        setIsVisible(false)
        onRefresh && onRefresh()
      }
    )
  }
  
  const showDialog = () => {
    setCheckItems([])
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    // setIsVisible,
    // setSearchData,
    showDialog,
  }))
  const onSelectionChange = (items) => {
    // console.log(items)
    setCheckItems(items)
  }
  //返回页面
  return (
    <Dialog
      style={{ width: "960px" }}
      showCloseButton={true}
      closeOnLostFocus
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title="添加试验仪器"
      standardButtonsOnBottom={[
        {
          text: t("b_confirm"),
          type: "primary-blue",
          handler: onConfirm,
        },
        {
          text: t("b_cancel"),
          type: "normal",
          handler: () => setIsVisible(false)
        },
      ]}
      onClose={() => setIsVisible(false)}
    >
      <div style={{ height: "420px" }}>
        <CommonIndex
          tableWidth={"100%"}
          ref={refIndex}
          columns={columns}
          getList={getInstrumentPage}
          enableSorting={false}
          pageSize={5}
          tableHeight={280}
          className="admin-main-content2 with-padding"
          searchClassName="searching-form2"
          canSelection={true} //是否开启选择功能
          multipleChoice={true}
          onSelectionChange={onSelectionChange} //选择改变时触发
        />
      </div>
    </Dialog>
  )
})
