import React, { useRef, useImperativeHandle, forwardRef, useState } from 'react'
import { CommonForm } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Button, LoadingIndicator, Spinner, TabControl, TabItem } from '@abb/abb-common-ux-react'
import SidePanel from 'components/SidePanel'
import axios from 'axios'
import { getUsers } from 'slices/userSlice'
import { useEffect } from 'react'
import { getInstrumentPage } from 'common/apis/maindata'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { importTestData } from 'common/apis/singlereports'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'

export const ImportTestResultFormEdit = forwardRef(({ id, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [isSumbiting, setIsSumbiting] = useState(false)
  const [instruments, setInstruments] = useState([])
  const [fileName, setFileName] = useState(null)
  const [file, setFile] = useState(null)
  const [reportId, setReportId] = useState(null)
  const users = useSelector(getUsers)
  const dispatch = useDispatch()

  const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
    // Do something with the files
    if (!acceptedFiles[0]) {
      return
    }
    setFile(acceptedFiles[0])
    setFileName(acceptedFiles[0].name)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ accept: '.xlsx', onDrop })

  //初始化数据
  const initData = {}
  //表单数据项
  const formItem = [
    // {
    //   type: 'dropdown',
    //   label: '试验人员',
    //   fieldName: 'testPersonl',
    //   options: users
    //     ? users.map((c) => {
    //         return { label: c.name, value: c.id }
    //       })
    //     : [],
    // },
    // {
    //   type: 'date',
    //   label: '试验日期',
    //   fieldName: 'testDate',
    //   dateFormat: 'yyyy-MM-dd HH:mm',
    //   showTimeSelect: true,
    // },
    // {
    //   type: 'text',
    //   dataType: 'number',
    //   label: '相对湿度',
    //   fieldName: 'humidity',
    //   maxLength: 128,
    // },
    // {
    //   type: 'text',
    //   dataType: 'number',
    //   label: '温度',
    //   fieldName: 'temperature',
    //   maxLength: 128,
    // },
    {
      type: 'dropdown',
      label: '试验结论',
      fieldName: 'code',
      options: [
        { label: '合格', value: '1' },
        { label: '不合格', value: '2' },
      ],
    },
    {
      type: 'text',
      dataType: 'text',
      label: '不合格原因',
      fieldName: 'reason',
      maxLength: 128,
    },
    // {
    //   type: 'dropdown',
    //   label: '试验仪器',
    //   fieldName: 'tool',
    //   options: instruments
    //     ? instruments.map((c) => {
    //         return { label: c.name, value: c.id }
    //       })
    //     : [],
    // },
    {
      type: 'div',
      label: '试验数据文件',
      fieldName: 'file',
      maxLength: 256,
      render: (item, callback) => {
        console.log(item)
        return (
          <div style={{ paddingTop: '12px' }}>
            <div {...getRootProps({ className: 'file-picker' })} title='上传文件'>
              <input {...getInputProps({ multiple: false })} />
              {fileName ?? '上传文件'}
              {isSumbiting && (
                <div className='central-position'>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        )
      },
    },
    // {
    //   type: 'text',
    //   dataType: 'textarea',
    //   label: '备注',
    //   fieldName: 'remark',
    //   maxLength: 128,
    // },
  ]

  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
  }

  useEffect(() => {
    axios.post(getInstrumentPage(), { filter: null, pageIndex: 1, pageSize: 1000 }).then((r) => {
      console.log(r)
      setInstruments(r.data.items)
    })
  }, [])
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      reportId: inputdata.reportId,
      testItemId: inputdata.testItemId,
      // testPersonl: inputdata.testPersonl,
      // testDate: inputdata.testDate,
      // humidity: inputdata.humidity,
      // temperature: inputdata.temperature,
      // tool: inputdata.tool,
      code: inputdata.code,
      reason: inputdata.reason,
      // remark: inputdata.remark,
    }
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showPanel,
  }))
  //显示边栏

  const saveForm = async () => {
    setIsSumbiting(true)
    await onSubmitThis()
    setIsSumbiting(false)
    resetForm()
    setShowSidePanel(false)
  }

  const showPanel = (reportId) => {
    setReportId(reportId)
    setShowSidePanel(true)
  }

  const resetForm = () => {
    setFile(null)
    setFileName(null)
  }

  const onSubmitThis = async () => {
    if (!file) {
      dispatch(showNotification(NotificationType.Alarm, t('t_formError')))
      return
    }
    let input = {}
    formEditRef.current.validate((valid, formData) => {
      if (valid) {
        //做其他校验，返回错误信息则返回
        input = formData
      } else {
        dispatch(showNotification(NotificationType.Alarm, t('t_formError')))
        return
      }
    })
    try {
      const form = new FormData()
      form.append('file', file)
      const response = await axios.post(importTestData(), form, {
        params: { ...input, reportId: reportId, testItemId: '3a0b8b95-840e-b254-f517-f155adcc111c' },
      })
      if (response.status === 200 || response.status === 204) {
        dispatch(showNotification(NotificationType.Success, '导入成功'))
        console.log('update sucess')
      } else {
        dispatch(showNotification(NotificationType.Alarm, '导入失败，当前报告不包含机械特性试验'))
        console.log('error to get tree grant')
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, e?.error.message ?? '导入失败，当前报告不包含机械特性试验'))
      console.log(e)
    } finally {
    }
    onSubmit && onSubmit()
  }
  const cancel = () => {
    setShowSidePanel(false)
    onCancel && onCancel()
  }

  //返回页面
  return (
    <SidePanel show={showSidePanel} size={400}>
      <div className='side-panel-header'>
        <div className='side-panel-title'>导入机械特性试验数据</div>
      </div>
      <div className='side-panel-body'>
        {!isSumbiting && (
          <>
            <TabControl type='panel'>
              <TabItem title='导入机械特性试验数据'>
                <div style={{ height: clientHeight - 280 + 'px' }}>
                  <CommonForm initData={initData} ref={formEditRef} rules={rules} formItem={formItem}></CommonForm>
                </div>
              </TabItem>
            </TabControl>
            <div className='form-group-button' style={{ marginTop: '5px' }}>
              <Button
                style={{ marginRight: '5px' }}
                text={t('b_save')}
                type='primary-blue'
                sizeClass='small'
                onClick={saveForm}
                isLoading={isSumbiting}
              />
              <Button text={t('b_cancel')} type='normal' sizeClass='small' onClick={cancel} />
            </div>
          </>
        )}
        {isSumbiting && (
          <div style={{ flex: 1 }}>
            <div className='central-position'>
              <LoadingIndicator sizeClass='small' color='blue' type='radial' />
            </div>
          </div>
        )}
      </div>
    </SidePanel>
  )
})
