import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Dialog } from "@abb/abb-common-ux-react"
import { getUnAddUsers, getUserPage } from "common/endpoints"
import { CommonIndex } from "components/LoongInCommon"
import { t } from "i18next"
import axios from "axios"
import { addUserToOrganization } from "common/endpoints"
import { getClientHeight } from "slices/appSlice"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"

import "./organiza.scss"
import { removeTeamMember, setTeamMember } from "common/apis/maindata"

export const SearchUser = forwardRef(({ onRefresh }, ref) => {
  const refIndex = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [checkItems, setCheckItems] = useState([])
  const dispatch = useDispatch()
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //列定义
  const columns = [
    {
      title: "user.userName",
      key: "userName",
    },
    {
      title: "user.name",
      key: "name",
    },
    {
      title: "user.email",
      key: "email",
    },
  ]
  const addUsers = async (items, callback) => {
    try {
      if (searchData.removeOld) {
        axios.post(removeTeamMember(), searchData.old).then((response) => {
          if (response.status === 200 || response.status === 204) {
            axios
              .post(setTeamMember(), {
                memberId: items,
                projectId: searchData.projectId,
                role: searchData.role,
              })
              .then((response) => {
                if (response.status === 200 || response.status === 204) {
                  dispatch(showNotification(NotificationType.Success, t("t_successToAdd")))
                  if (callback) callback()
                } else {
                  dispatch(showNotification(NotificationType.Warn, t("t_failToAdd")))
                }
              })
          } else {
            dispatch(showNotification(NotificationType.Warn, t("t_failToAdd")))
          }
        })
      } else {
        axios
          .post(setTeamMember(), {
            memberId: items,
            projectId: searchData.projectId,
            role: searchData.role,
          })
          .then((response) => {
            if (response.status === 200 || response.status === 204) {
              dispatch(showNotification(NotificationType.Success, t("t_successToAdd")))
              if (callback) callback()
            } else {
              dispatch(showNotification(NotificationType.Warn, t("t_failToAdd")))
            }
          })
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t("t_failToAdd")))
    } finally {
    }
  }
  const onConfirm = () => {
    if (!checkItems || checkItems.length <= 0) {
      dispatch(showNotification(NotificationType.Alarm, t("t_plsSelectUser")))
      return
    }
    addUsers(
      checkItems.map((item) => {
        return item.id
      }),
      () => {
        setIsVisible(false)
        onRefresh && onRefresh()
      }
    )
  }
  const onCancel = () => {
    setIsVisible(false)
  }
  const showDialog = (projectId, role, multipleChoice, removeOld, old) => {
    setCheckItems([])
    setSearchData({
      projectId,
      role,
      multipleChoice,
      removeOld,
      old,
    })
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    // setIsVisible,
    // setSearchData,
    showDialog,
  }))
  const onSelectionChange = (items) => {
    // console.log(items)
    setCheckItems(items)
  }
  //返回页面
  return (
    <Dialog
      style={{ width: "960px" }}
      showCloseButton={true}
      closeOnLostFocus
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title={t("b_addUser")}
      standardButtonsOnBottom={[
        {
          text: t("b_confirm"),
          type: "primary-blue",
          handler: onConfirm,
        },
        {
          text: t("b_cancel"),
          type: "normal",
          handler: onCancel,
        },
      ]}
      onClose={() => setIsVisible(false)}
    >
      <div style={{ height: "420px" }}>
        <CommonIndex
          tableWidth={"100%"}
          ref={refIndex}
          pageSize={6}
          columns={columns}
          getList={getUserPage}
          searchData={searchData}
          enableSorting={false}
          tableHeight={300}
          
          pageInQuery={false}
          className="admin-main-content2 with-padding"
          searchClassName="searching-form2"
          canSelection={true} //是否开启选择功能
          multipleChoice={searchData?.multipleChoice}
          onSelectionChange={onSelectionChange} //选择改变时触发
        />
      </div>
    </Dialog>
  )
})
