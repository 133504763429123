import React, { useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { updateSettings } from 'common/endpoints'
import { t } from 'i18next'
import { getClientHeight, getClientWidth } from 'slices/appSlice'
import { useSelector } from 'react-redux'
// import axios from "axios"
import { useEffect } from 'react'
import { ErrorBoundary } from 'common/ErrorBoundary'
import TestModal from './TestModal'
import { InstrumentList } from './InstrumentList'
// import { Row, Col } from "components/Grid"

export const Details = forwardRef(({ id, item, onSubmit, event }, ref) => {
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //获得客户区宽度
  const clientWidth = useSelector(getClientWidth)

  // 返回页面
  return (
    <div
      className='admin-main-content disable-background no-margin'
      style={{
        width: clientWidth - 480 > 300 ? clientWidth - 480 + 'px' : '100%',
      }}
    >
      
        <ErrorBoundary>
        <div className='grid root-grid' data-cy='module-device-dashboard'>
          <div className='row abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
              <div className='row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-7x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms'>
                {item && item.type==='testItem' && ( <TestModal item={item} />)}
                </div>
              </div>

              <div className='row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-5x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'><InstrumentList item={item}/></div>
              </div>
            </div>
          </div>
          
        </div>
        {/* <DataFormEdit ref={formDataRef} formData={DataFormData} id={device.id} onSubmit={onSubmit} /> */}
      </ErrorBoundary>
      
    </div>
  )
})
