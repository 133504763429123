import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { createMaterialCatalog, updateMaterialCatalog } from "common/apis/maindata"
import { t } from "i18next"
import {  getClientHeight,} from "slices/appSlice"
import { useSelector } from "react-redux"

export const MaterialCatalogFormEdit = forwardRef(
  ({ id, formData, onSubmit, onCancel,event }, ref) => {
    //引用基础窗体控件
    const formEditRef = useRef(null)
    //获取客户区高度
    const clientHeight = useSelector(getClientHeight)
    //初始化数据
    const initData = {
      parentId: "",
      parentName: "",
      name: "",
    }
    //表单数据项
    const formItem = [
      {
        type: "text",
        dataType: "text",
        disabled: true,
        label: t("materialCatalog.parentName"),
        fieldName: "parentName",
        visible: !formData || !!formData.parentName,
        maxLength: 128,
      },
      {
        type: "text",
        dataType: "text",
        label: t("materialCatalog.name"),
        fieldName: "name",
        maxLength: 128,
      },
    ]

    //表单的校验规则
    const rules = {
      name: [CommonRule.Require],
    }
    
    //获取更新时的参数
    const getUpdateInput = (inputdata) => {
      return {
        id: inputdata.id,
        name: inputdata.name,
      }
    }
    //获取添加时的参数
    const getCreateInput = (inputdata) => {
      return {
        parentId: inputdata.parentId,
        parentName: inputdata.parentName,
        name: inputdata.name,
      }
    }
    //传递引用方法
    useImperativeHandle(ref, () => ({
      setShowSidePanel,
      resetForm,
    }))
    //显示边栏
    const setShowSidePanel = (visible) => {
      formEditRef.current.setShowSidePanel(visible)
    }
    const resetForm = () => {
      return formEditRef.current.resetForm()
    }
    //返回页面
    return (
      <CommonFormEdit
        ref={formEditRef}
        title={t("materialCatalog.formTitle")}
        formItem={formItem}
        initData={initData}
        rules={rules}
        createData={createMaterialCatalog}
        updateData={updateMaterialCatalog}
        getUpdateInput={getUpdateInput}
        getCreateInput={getCreateInput}
        id={id}
        formData={formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
        height={clientHeight - 280}
        event={event}
      />
    )
  }
)
