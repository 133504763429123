export const terms = "/file/terms-of-use.pdf"
export const privacy = "/file/privacy-statement.pdf"

//登录
export const login = () => `/api/app/account/login`
export const resetPassword = () => `/api/account/reset-password`
export const sendPasswordResetCode = () => `/api/account/send-password-reset-code`
export const sendTowFactorCode = (tokenProvider) => `/api/app/account/send-two-factor-code?tokenProvider=${tokenProvider}`
export const loginByTowFactorCode = () => `/api/app/account/login-by-two-factor-code`
export const getTokenProviders = () => `/api/app/account/get-token-providers`
export const logout = () => `/api/app/account/logout`
export const changePassword = () => `api/account/my-profile/change-password`
export const myProfile = () => `api/account/my-profile`
//同步
export const sync = () => `api/Standalone`

//用户
export const getUserPage = () => `/api/Users/page`
export const exportUser = (userId) => `/api/Users/export`
export const createUser = () => `/api/Users/create`
export const updateUser = () => `/api/Users/update`
export const deleteUser = (userId) => `/api/Users/delete`
export const getUserRole = () => `/api/Users/role`
export const lockUser = () => `/api/Users/lock`

//角色
export const getAllRole = () => `/api/Roles/all`
export const getRolePage = () => `/api/Roles/page`
export const createRole = () => `/api/Roles/create`
export const updateRole = () => `/api/Roles/update`
export const deleteRole = () => `/api/Roles/delete`
export const treeGrant = () => `/api/Permissions/tree`
export const updateGrant = () => `/api/Permissions/update`

//OrganizationUnits/tree
export const getOrganization = () => `/api/OrganizationUnits/tree`
export const createOrganization = () => `/api/OrganizationUnits/create`
export const updateOrganization = () => `/api/OrganizationUnits/update`
export const deleteOrganization = () => `/api/OrganizationUnits/delete`
export const getOrganizationUsers = () => `/api/OrganizationUnits/getUsers`
export const deleteUserFromOrganization = () => `/api/OrganizationUnits/removeUserFromOrganizationUnit`
export const getUnAddUsers = () => `/api/OrganizationUnits/getUnAddUsers`
export const addUserToOrganization = () => `/api/OrganizationUnits/addUserToOrganizationUnit`

//租户
export const findTenant = () => `/api/Tenants/find`
export const getTenantPage = () => `/api/Tenants/page`
export const createTenant = () => `/api/Tenants/create`
export const updateTenant = () => `/api/Tenants/update`
export const deleteTenant = () => `/api/Tenants/delete`
export const getConnectionString = () => `/api/Tenants/getConnectionString`
export const updateConnectionString = () => `/api/Tenants/updateConnectionString`

//配置
export const getAllSettings = () => `/api/Settings/all`
export const updateSettings = () => `/api/Settings/update`

//字典
export const getDataDictionaryAll = () => `/api/DataDictionary/all`
export const getDataDictionaryPage = () => `/api/DataDictionary/page`
export const createDataDictionary = () => `/api/DataDictionary/create`
export const deleteDataDictionary = () => `/api/DataDictionary/deleteDataDictionaryType`
export const updateDataDictionary = () => `/api/DataDictionary/update`

//字典明细
export const getDataDictionaryPageDetail = () => `/api/DataDictionary/pageDetail`
export const createDataDictionaryDetail = () => `/api/DataDictionary/createDetail`
export const deleteDataDictionaryDetail = () => `/api/DataDictionary/delete`
export const updateDataDictionaryDetail = () => `/api/DataDictionary/updateDetail`
export const updateDataDictionaryStatus = () => `/api/DataDictionary/status`

//审计日志
export const getAuditLogs = () => `/api/AuditLogs/page`

//以下是旧的
// User
export const getUserInfo = () => `/api/user`
export const setTimezone = (timezone) => `/api/user/timezone/${timezone}`
export const getLanguages = () => `/api/admin/languages`
export const acceptTerms = () => `/api/user/accept/policy`

// Common
export const getPathByNodeId = (nodeType, id) =>
  `/api/plant/navigation/${nodeType}/${id}`

// Plant & Substation
export const getPlantList = (pageIndex, pageSize, keyword) =>
  `/api/plant?activeOnly=true&pageIndex=${pageIndex}&pageSize=${pageSize}&keyword=${keyword}`
export const getPlantById = (id) => `/api/plant/${id}`
export const getPlantSubstation = (plantId) =>
  `/api/plant/${plantId}/substations?activeOnly=true&pageSize=100`
export const getPlantSubstations = (id) => `/api/plant/substations/${id}`
export const getPlantDeviceConnection = (id) => `/api/plant/${id}/connection`
export const getSubstationDeviceConnection = (id) =>
  `/api/plant/substations/${id}/connection`
export const getPlantGatewayConnectionDetails = (id) =>
  `/api/plant/${id}/gatewayOverview`
export const getSubstationGatewayConnectionDetails = (id) =>
  `/api/plant/substations/${id}/gatewayOverview`
export const getPlantDeviceOverview = (id) => `/api/plant/${id}/overview`
export const getSubstationDeviceOverview = (id) =>
  `/api/plant/substations/${id}/overview`

export const getPlantDeviceList = ({
  plantId,
  online,
  status,
  keyword,
  sn,
  substation,
  model,
  manufactory,
  orderBy,
  order,
  pageSize,
  pageIndex,
}) =>
  `/api/plant/${plantId}/equipments` +
  `?onlineStatus=${online ?? -1}&status=${status ?? ""}&keyword=${
    keyword ?? ""
  }&sn=${sn ?? ""}` +
  `&subNameKeyword=${substation ?? ""}&model=${model ?? ""}&manufactory=${
    manufactory ?? ""
  }` +
  `&orderBy=${orderBy ?? "name"}&order=${order ?? "asc"}` +
  `&pageIndex=${pageIndex ?? 0}&pageSize=${pageSize ?? 10}`
export const getSubstationDeviceList = ({
  substationId,
  online,
  status,
  keyword,
  sn,
  substation,
  model,
  manufactory,
  orderBy,
  order,
  pageSize,
  pageIndex,
}) =>
  `/api/plant/substations/${substationId}/equipments` +
  `?onlineStatus=${online ?? -1}&status=${status ?? ""}&keyword=${
    keyword ?? ""
  }&sn=${sn ?? ""}` +
  `&subNameKeyword=${substation ?? ""}&model=${model ?? ""}&manufactory=${
    manufactory ?? ""
  }` +
  `&orderBy=${orderBy ?? "name"}&order=${order ?? "asc"}` +
  `&pageIndex=${pageIndex ?? 0}&pageSize=${pageSize ?? 10}`
export const toggleFavoriteDevice = (deviceId) =>
  `/api/plant/equipments/${deviceId}/bookmark`

export const getPlantEvents = ({
  id,
  startTime,
  endTime,
  pageIndex,
  pageSize,
  eventLevels,
}) =>
  `/api/plant/${id}/events?endTime=${endTime}${
    startTime ? "&startTime=" + startTime : ""
  }` +
  `${
    eventLevels ? "&eventLevels=" + eventLevels : ""
  }&pageIndex=${pageIndex}&pageSize=${pageSize}`
export const exportPlantEvents = ({ id, startTime, endTime, eventLevels }) =>
  `/api/plant/${id}/events/download?endTime=${endTime}${
    startTime ? "&startTime=" + startTime : ""
  }${eventLevels ? "&eventLevels=" + eventLevels : ""}`

export const getSubstationEvents = ({
  id,
  startTime,
  endTime,
  pageIndex,
  pageSize,
  eventLevels,
}) =>
  `/api/plant/substation/${id}/events?endTime=${endTime}${
    startTime ? "&startTime=" + startTime : ""
  }` +
  `${
    eventLevels ? "&eventLevels=" + eventLevels : ""
  }&pageIndex=${pageIndex}&pageSize=${pageSize}`
export const exportSubstationEvents = ({
  id,
  startTime,
  endTime,
  eventLevels,
}) =>
  `/api/plant/substation/${id}/events/download?endTime=${endTime}${
    startTime ? "&startTime=" + startTime : ""
  }${eventLevels ? "&eventLevels=" + eventLevels : ""}`
export const getDeviceStatusByIdList = () => `/api/equipments/statuses`

export const getPlantUserUserList = (id) =>
  `/api/plant/userPermission/query?NodeId=${id}&NodeLevel=Plant&PageIndex=0&PageSize=999`
export const getSubsationUserUserList = (id) =>
  `/api/plant/userPermission/query?NodeId=${id}&NodeLevel=Substation&PageIndex=0&PageSize=999`
export const getUserListByNode = (nodeLevel, nodeId) =>
  `/api/plant/userPermission/query?NodeId=${nodeId}&NodeLevel=${nodeLevel}&PageIndex=0&PageSize=999`

export const addNodeUser = () => `/api/plant/userPermission`
export const removeNodeUser = (id) => `/api/plant/userPermission/${id}`
export const updateUserRole = () => "/api/plant/userPermission/setadministrator"
export const updateMapLocation = () => "/api/plant/node/location"
export const sendSMSSubscribeCode = () => "/api/notification/securityCode/send"
export const subscribeSmsNotification = () => "/api/notification/subscribe/sms"
export const getSmsSubscription = (type, id) =>
  `/api/notification/subscribe/sms/query?NodeLevel=${type}&NodeIds=${id}&PageIndex=0&PageSize=99`
export const UnsubscribeSmsNotification = (id) =>
  `/api/notification/subscribe/sms/${id}`

// Device
export const getDeviceProfile = (id) => `/api/equipments/info/${id}`
export const updateDeviceProfile = () => "/api/equipments/baseInformation"
export const getDeviceModel = (id) => `/api/equipments/model/${id}`
export const createDeviceModel = () => `/api/admin/model`
export const updateDeviceModel = () => `/api/admin/model`
export const deleteDeviceModel = (id) => `/api/admin/model/${id}`
export const getDeviceHealthIndex = (id) =>
  `/api/equipments/${id}/healthIndex?isNew=1`
export const getDeviceHealthIndexHistory = (id) =>
  `/api/equipments/${id}/healthScoreHistory`
export const queryVariableHealthScoreHistory = (id) =>
  `/api/equipments/${id}/QueryVariableHealthScoreHistory`
export const getDeviceModelManual = (id) =>
  `/api/equipments/dataManual/${id}?pageSize=100`

export const getDeviceRemainELife = (id) =>
  `/api/equipments/${id}/remainElectricalLife`
export const getDeviceOnlineStatus = (id) =>
  `/api/equipments/${id}/isConnection`
export const getDeviceRunningStatus = (id) =>
  `/api/equipments/${id}/statusForSingleLine`
export const getDeviceManual = ({ id, pageIndex, pageSize }) =>
  `/api/equipments/manual/${id}?pageIndex=${pageIndex}&&pageSize=${pageSize}`
export const getDeviceMaintainReports = ({ id, pageIndex, pageSize }) =>
  `/api/equipments/maintenanceReport/${id}?pageIndex=${pageIndex}&&pageSize=${pageSize}`

export const getLatestValue = (equipmentId, variables) =>
  `/api/equipments/${equipmentId}/currentVariableValues?` +
  variables.map((v) => `variableNames=${v}`).join("&") // cb/swg id
export const getMeasurementHistory = () => `/api/equipments/temperature/curve` // cb/swg
export const getSamplesByVariableName = (
  cbId,
  name,
  pageIndex,
  pageSize,
  startTime
) =>
  `/api/equipments/sampling/${cbId}/query?sampleType=${name}&pageIndex=${pageIndex}&pageSize=${pageSize}${
    startTime ? "&startTime=" + startTime : ""
  }` //cb id
export const getSample = (cbId, sampleId) =>
  `/api/equipments/sampling/${cbId}/querySamplingData?id=${sampleId}` //cb id

export const getLatestValues = (id, variable, from, to, count) =>
  `/api/equipments/${id}/VariableMeasurementData?variableName=${variable}&startDate=${from}&endDate=${to}&count=${count}`

export const getLatestInterLockStatus = (id) =>
  `/api/equipments/${id}/interlockingStatus`
export const getInterlockHistory = (id, endTime, pageIndex, pageSize) =>
  `/api/equipments/interlockingStatus/history?equipmentId=${id}&pageIndex=${pageIndex}&endTime=${endTime}&pageSize=${pageSize}`

export const getDeviceEvents = ({
  id,
  startTime,
  endTime,
  pageIndex,
  pageSize,
  eventLevels,
}) =>
  `/api/equipments/${id}/events?endTime=${endTime}${
    startTime ? "&startTime=" + startTime : ""
  }` +
  `${
    eventLevels ? "&eventLevels=" + eventLevels : ""
  }&pageIndex=${pageIndex}&pageSize=${pageSize}`
export const exportDeviceEvents = ({ id, startTime, endTime, eventLevels }) =>
  `/api/equipments/${id}/events/download?endTime=${endTime}${
    startTime ? "&startTime=" + startTime : ""
  }${eventLevels ? "&eventLevels=" + eventLevels : ""}`

export const getDeviceUnnormalPeriod = (id, from, to, variables) =>
  `/api/equipments/${id}/alarmOrWarning/timeline?StartDate=${from}&EndDate=${to}&` +
  variables.map((v) => `VariableNames=${v}`).join("&")

export const getDeviceComponentSnapshots = (id) =>
  `/api/equipments/${id}/QueryImageRecognizeAttachmentsAsync`
export const getDeviceComponentVideos = (id) =>
  `/api/equipments/${id}/QueryVideoRecognizeAttachments`
export const getDeviceComponentVideoEvents = (id) =>
  `/api/equipments/${id}/QueryVideoRecognizeEvents`
// GIS
export const getDeviceGasChamberInfo = (equipmentId) =>
  `/api/equipments/gas/${equipmentId}/info`
export const getDeviceChamberMaintenance = (equipmentId, variableName) =>
  `/api/equipments/${equipmentId}/GasPressureMaintainPrediction?variableName=${variableName}`
export const get3PSLatestStatus = (deviceId, busbarId) =>
  `/api/equipments/${deviceId}/3ps/${busbarId}/status`
export const get3PSHistory = (deviceId, busbarId, from, to, type) =>
  `/api/equipments/${deviceId}/3ps/history?BusbarId=${busbarId}&StartDate=${from}&EndDate=${to}&OperationType=${type}`
// Common
export const updateEventAck = () => `/api/events2/acknowleadge`
export const updateEventAckByActions = () =>
  `/api/events2/acknowleadgeByActions`
export const getEventDetailsById = (id) => `/api/events2/${id}`
export const moduleSettings = (module, key) =>
  `/api/configuration/custom/${module}/${key}`
export const updateModuleSettings = () => `/api/configuration/custom`

export const getReports = () => "/api/report/list"

//     +------------------------------+
//     |                              |
//     |   >>>  Administration  <<<   |
//     |                              |
//     +------------------------------+

// Clients
export const getAllCountries = () => `/api/admin/clients/countries`
export const getAllClients = () => `/api/admin/clients`
export const getClientTree = (id) => `/api/admin/clients/${id}`
export const createClient = () => `/api/admin/clients`
export const updateClient = (id) => `/api/admin/clients/${id}`
export const deleteClient = (id) =>
  `/api/admin/clients/${id}?id=${id}&treeLevel=1`
export const bindDeviceModel = () => `/api/equipments/bindEquipmentModel`

export const createClientTreeNode = () => `/api/admin/clients`
export const updateClientTreeNode = (clientId) =>
  `/api/admin/clients/${clientId}`
export const deleteClientTreeNode = (clientId, nodeId, nodeLevel) =>
  `/api/admin/clients/${clientId}?id=${nodeId}&treeLevel=${nodeLevel}`

// Gateway
export const getGatewayList = (keyword, status, index) =>
  `/api/admin/gateways?keyword=${keyword}&status=${status}&index=${index}&pageSize=20`
export const getGateway = (id) => `/api/admin/gateways/${id}`
export const bindGateway = () => `/api/admin/gateways/approve`

// User management
export const getUserList = ({ keyword, pageIndex, pageSize }) =>
  `/api/identity/users?keyword=${keyword ?? ""}&pageIndex=${
    pageIndex ?? 0
  }&pageSize=${pageSize ?? 10}`
export const getUserById = (userId) => `/api/user/${userId}`
// export const createUser = () => `/api/user`
// export const updateUser = () => `/api/user`
// export const deleteUser = (userId) => `/api/user?userId=${userId}`
export const updateUserAdminRole = () => `/api/user/role/update`

// Device model
export const getDeviceModelList = () =>
  `/api/equipments/model/queryEquipmentModels?pageSize=999`
export const getDeviceModelById = (id) => `/api/equipments/model/${id}`
export const duplicateDeviceModel = (id) => `/api/equipments/model/copy`

export const getAllInfoModels = () => `/api/admin/informationmodels`
export const bindInfoModel = () =>
  `/api/admin/model/bindEquipmentInformationModel`
export const unbindInfoModel = () =>
  `/api/admin/model/unBindEquipmentInformationModel`

export const getAllInfoModelVariables = (id) =>
  `/api/admin/informationModel/variables?EquipmentInformationModelId=${id}&PageIndex=0&PageSize=99999`
export const getDeviceModelSpec = (id) =>
  `/api/admin/model/variableThreshold/query?EquipmentModelId=${id}&PageIndex=0&PageSize=99999`

export const uploadFiles = () => `/api/resource/upload`

export const updateDeviceModelAlgorithms = (id) => `/api/admin/${id}/algorithm`

export const getDeviceModelAlgorithms = (id) => `/api/admin/${id}/algorithm`
export const addDeviceModelManual = () => `/api/admin/model/dataManual`
export const removeDeviceModelManual = (id) =>
  `/api/admin/model/dataManual/${id}`

// Alogorithm
export const getAllAlgorithmTemplates = () =>
  "/api/admin/algorithms/query?PageIndex=0&PageSize=999"
export const getAlgorithmTemplates = () => "/api/admin/algorithms/templates"
export const createAlgorithmSetting = () => `/api/admin/algorithms/false`
export const updateAlgorithmSetting = () => "/api/admin/algorithms"
export const deleteAlgorithmSetting = (algorithmId) =>
  `/api/admin/algorithms/${algorithmId}`

// Log
export const getLog = ({
  traceId,
  identity,
  requestIp,
  message,
  level,
  endTime,
  hostName,
  applicationName,
  logger,
  pageIndex,
  pageSize,
}) =>
  `/api/admin/log/system?traceId=${traceId ?? ""}&identity=${
    identity ?? ""
  }&requestId=${requestIp ?? ""}` +
  `&message=${message ?? ""}&level=${level ?? ""}&endTime=${endTime ?? ""}` +
  `&hostName=${hostName ?? ""}&applicationName=${
    applicationName ?? ""
  }&logger=${logger ?? ""}
&pageIndex=${pageIndex ?? 0}&pageSize=${pageSize ?? 10}`

export const getSmsLog = ({
  from,
  to,
  receiver,
  content,
  pageIndex,
  pageSize,
}) =>
  `/api/admin/message/query?MessageType=2&pageIndex=${pageIndex}&pageSize=${pageSize}&to=${to}&content=${content}&Receiver=${receiver}`
export const resendSms = (id) => `/api/admin/message/sms/resend/${id}`
// Resource

export const getDirectory = () => `/api/resource/folder/tree`
export const createDirectory = () => `/api/resource/folder`
export const renameDirectory = () => `/api/resource/folder/rename`
export const deleteDirectory = (directoryId) =>
  `/api/resource/folder/${directoryId}`

export const getDirectoryFiles = (directoryId) =>
  `/api/resource/query?FolderId=${directoryId}&PageIndex=0&PageSize=99`

export const uploadloadDirectoryFile = (directoryId) =>
  `/api/resource/upload?folderId=${directoryId}`
export const deleteDirectoryFile = (fileIdList) =>
  `/api/resource?` + fileIdList.map((id) => `fileKeys=${id}`).join("&")
export const replaceDirectoryFile = (fileId) =>
  `/api/resource/replace/${fileId}`
export const moveDirectoryFile = () => `/api/resource/move`
export const renameDirectoryFile = () => `/api/resource/rename`
export const getResourceUrl = (key) => `/api/resource/url/${key}`

// 重命名文件
// ​/api​/resource​/rename
// {
//   "fileKey": "4851b2b99a204b5ca6220d206c78dc37",
//   "newName": "t.png"
// }

//resetSettings
export const resetSetting = (equipmentId) =>
  `/api/equipments/${equipmentId}/resetMechanismPGIS`

//video identify
export const queryVideoCalibration = (equipmentId) =>
  `/api/equipments/${equipmentId}/QueryVideoCalibration`
export const queryVideoRecognizeAttachments = (equipmentId) =>
  `/api/equipments/${equipmentId}/QueryVideoRecognizeAttachments`
export const queryVideoCalibrationVerify = (equipmentId) =>
  `/api/equipments/${equipmentId}/QueryVideoCalibrationVerify`
export const getDefaultVideoCalibration = (equipmentId) =>
  `/api/equipments/${equipmentId}/DefaultVideoCalibration`
export const setDefaultVideoCalibration = (equipmentId, videoCalibrationId) =>
  `/api/equipments/${equipmentId}/SetDefaultVideoCalibration?videoCalibrationId=${videoCalibrationId}`
export const triggerVideoCalibrationVerify = (equipmentId) =>
  `/api/equipments/${equipmentId}/TriggerVideoCalibrationVerify`
export const triggerVideoCalibrationEvent = (equipmentId) =>
  `/api/equipments/${equipmentId}/TriggerVideoCalibrationEvent`
export const passVideoCalibrationVerify = (equipmentId) =>
  `/api/equipments/${equipmentId}/passVideoCalibrationVerify`

//ioT
export const queryIotInfo = () => "/api/admin/queryIotInfo"
export const exportIotInfo = () => "/api/admin/exportIotInfo"

export const getCMESSubstaions = (id) => `/api/plant/${id}/CMESSubstaions`
export const getCMESEvents = (id) => `/api/plant/${id}/CMESEvents`

export const setAlgorithmParameter = (equipmentId, paraName) =>
  `/api/equipments/${equipmentId}/setAlgorithmParameter/${paraName}`
