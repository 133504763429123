import React, { useState, useEffect, useRef, forwardRef } from "react"
import { useSelector } from "react-redux"
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react"
import { SlidingCard } from "components/SlidingCard"
import { nanoid } from "nanoid"
import { t } from "i18next"
import { CommonIndex } from "components/LoongInCommon"
import { getContacts, removeContact } from "common/apis/maindata"
import { getProject } from "slices/ezProjectSlice"
import { useImperativeHandle } from "react"
import { getUsers } from "slices/userSlice"
import axios from "axios"
import { ContactFormEdit } from "./ContactFormEdit"

export const ContactList = forwardRef(({ onRefresh, onEdit }, ref) => {
  const refTeamIndex = useRef(null)
  const [teamSearchData, setTeamSearchData] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])

  const project = useSelector(getProject)
  const columns = [
    {
      title: "姓名",
      key: "name",
    },
    {
      title: "联系方式",
      key: "phone",
    },
    {
      title: "邮件",
      key: "email",
    },
  ]
  useEffect(() => {
    if (project) {
      setTeamSearchData({ projectId: project.id })
    }
  }, [project])

  useEffect(() => {
    window.addEventListener("resize", onWindowResize)
    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [])

  const onWindowResize = () => {}
  const getTeamDeleteInput = (item) => {
    return { id: item.id, projectId: project.id }
  }

  useImperativeHandle(ref, () => ({
    query, //刷新数据
  }))
  const query = () => {
    refTeamIndex.current.query()
  }
  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  const onAddBefore = () => {
    return {
      projectId: project.id,
    }
  }
  const teamActionRender = (item, index) => {
    return (
      <>
        <Button
          key={nanoid()}
          sizeClass="small"
          icon="abb/edit"
          type="discreet-black"
          shape="pill"
          onClick={() => {
            refTeamIndex.current.showAndSwitchTab(0, item)
          }}
        />
        <Button
          key={nanoid()}
          sizeClass="small"
          type="discreet-black"
          icon="abb/trash"
          shape="pill"
          onClick={() => {
            refTeamIndex.current.setDeleteItem(item)
          }}
        />
      </>
    )
  }
  return (
    <SlidingCard>
      <CommonIndex
        otherButton={[
          <Button
            key={nanoid()}
            sizeClass="small"
            shape="pill"
            type="discreet-black"
            icon="abb/plus"
            onClick={
              () => refTeamIndex.current.add()
            }
            style={{ opacity: 1, marginRight: 8 }}
          />,
        ]}
        title="客户联系人"
        key={nanoid()}
        className={"admin-main-content3 with-padding"}
        ref={refTeamIndex}
        columns={columns}
        getList={getContacts}
        searchData={teamSearchData}
        deleteData={removeContact}
        getDeleteInput={getTeamDeleteInput}
        FormEdit={ContactFormEdit}
        onAddBefore={onAddBefore}
        canEdit
        canDelete
        pageSize={4}
        pageInQuery={false}
        canSearch={false}
        refreshAfterDelete={true}
        tableWidth={"100%"}
        tableHeight={"100%"}
        enableSorting={false}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        actionRender={teamActionRender}
        onDeleteData={onDeleteData}
        searchClassName="searching-form-simple" //查询样式
        toolClassName="searching-form-simple searching-form-right-tool text-right" //工具栏样式
      />
    </SlidingCard>
  )
})
