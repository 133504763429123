import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, setUserTimezone } from "slices/userProfileSlice";
import { Button, LoadingIndicator } from "@abb/abb-common-ux-react";
import axios from 'axios';
import SidePanel from "components/SidePanel";
import { listedUtc } from "common/timezone"
import Clock from 'components/Widget/Clock'
import { setTimezone } from "common/endpoints";
import { ResponseResult } from "common/request";
import { showNotification, NotificationType } from "components/Notification/notificationSlice";

export default function TimezonePanel({ isShow, onClose }) {
  const dispatch = useDispatch();
  const userProfile = useSelector(getUserProfile);
  const [nextTimezone, setNextTimezone] = useState(null);
  const [isUpdatingUserTimezone, setIsUpdatingUserTimezone] = useState(false)
  const onSetTimezone = (tz) => {
    dispatch(setUserTimezone({
      ...userProfile,
      timeZone: tz
    }))
    updateTimezoneSetting(tz);
  }
  const updateTimezoneSetting = async (tz) => {
    setIsUpdatingUserTimezone(true)
    try {
      const response = await axios.post(setTimezone(tz), null);
      if (response.status === 200) {
        if (response.data.result === ResponseResult.Ok) {
          dispatch(showNotification(NotificationType.Success, `时区设为 ${tz}`));
          window.location.reload()
        }
        else {
          dispatch(showNotification(NotificationType.Warn, response.data.message));
        }
      }
      else {
        dispatch(showNotification(NotificationType.Warn, `时区更新失败`));
      }
    }
    catch (e) {
      dispatch(showNotification(NotificationType.Alarm, `时区更新失败`));
    }
    finally {
      setIsUpdatingUserTimezone(false)
      onClose();
    }
  }

  const convertTypeByUserTimezone = (tz) => {
    return tz === userProfile?.timeZone ? 'primary-blue' : "discreet-black"
  }

  return (
    <SidePanel show={isShow} onClickBackdrop={onClose}>
      <div className="time-zone">
        <div style={{ height: 300 }}><Clock timezone={nextTimezone ? nextTimezone : userProfile?.timeZone} /></div>
        <div style={{ flexShrink: 1, overflow: 'auto', position: 'relative' }}>
          {isUpdatingUserTimezone &&
            <LoadingIndicator style={{ position: 'absolute', top: 0, left: 0 }} sizeClass="small" color="blue" />}
          <div style={{ display: 'flex', padding: 16, justifyContent: 'space-between' }}>
            {listedUtc &&
              Array.apply(null, { length: 3 }).map((a, b) => (
                <div key={b}>
                  {listedUtc.map((tz, index) => (!(listedUtc.length / 3 * b <= index && index < listedUtc.length / 3 * (b + 1)) ? null :
                    <div key={index} onMouseEnter={() => setNextTimezone(tz)} onMouseLeave={() => setNextTimezone(null)} >
                      <Button
                        text={`GMT ${tz}`}
                        sizeClass="small"
                        type={convertTypeByUserTimezone(tz)}
                        style={{ width: '100%' }}
                        onClick={() => isUpdatingUserTimezone ? null : onSetTimezone(tz)} />
                    </div>))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </SidePanel>
  )
}
