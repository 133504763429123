import React from "react"
import { LoadingIndicator, Dialog } from "@abb/abb-common-ux-react"
import { t } from "i18next"

export const CommonConfirm = ({
  children, //子内容，如果有则content失效。
  title, //标题
  content, //内容
  isVisible, //是否可见
  isLoading, //是否显示加载中
  onConfirm, //确认事件
  confirmText, //确认按钮标题
  onCancel, //取消事件
  cancelText, //取消按钮标题
  showCloseButton, //显示关闭按钮
  closeOnEscape, //按Esc关闭
}) => {
  return (
    <Dialog
      showCloseButton={showCloseButton || false}
      closeOnLostFocus
      closeOnEscape={closeOnEscape || false}
      dimBackground={true}
      isOpen={isVisible}
      title={title}
      standardButtonsOnBottom={
        isLoading
          ? []
          : [
              {
                text: confirmText || t("b_confirm"),
                type: "primary-blue",
                handler:
                  onConfirm ||
                  (() => {
                    console.log("you click confirm")
                  }),
              },
              {
                text: cancelText || t("b_cancel"),
                type: "normal",
                handler: onCancel || (() => {}),
              },
            ]
      }
    >
      {!isLoading && (children || <div>{content}</div>)}
      {isLoading && (
        <div style={{ height: 80, position: "relative" }}>
          <div className="central-position">
            <LoadingIndicator sizeClass="small" color="blue" type="radial" />{" "}
          </div>
        </div>
      )}
    </Dialog>
  )
}
