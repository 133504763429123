import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { createContact, updateContact } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { createRange, getDeviceTypes, updateRange } from 'common/apis/maintenanceRange'
import DeviceInfo from 'modules/MainData/Device/DeviceInfo'

export const RangeFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [deviceTypes, setDeviceTypes] = useState([])
  const [formItem, setFormItem] = useState([])
  const [currentInnerData, setCurrentInnerData] = useState(null)
  const fetchDeviceTypes = () => {
    const projectId = formData.projectId
    axios.post(getDeviceTypes(), { id: projectId }).then((response) => {
      setDeviceTypes(response.data)
    })
  }

  //初始化数据
  const initData = {
    id: '',
    name: '',
    projectId: '',
    deviceTypes: [],
    deviceType: '',
    devices: [],
  }

  //表单数据项
  const changeFromItem = () => {
    let items = [
      {
        type: 'text',
        dataType: 'text',
        label: '维保范围',
        fieldName: 'name',
        maxLength: 256,
      },
      {
        type: 'dropdown',
        dataType: 'text',
        label: '设备类型',
        fieldName: 'deviceType',
        maxLength: 30,
        options: deviceTypes.map((i) => {
          return { label: i.name, value: i.id }
        }),
        disabled:formData?.id && id,
      },
    ]

    if (currentInnerData && currentInnerData.deviceType) {
      let type = deviceTypes.filter((e) => e.id == currentInnerData.deviceType)[0]
      items.push({
        type: 'dropdown',
        dataType: 'text',
        label: '设备类型范围',
        fieldName: 'deviceTypes',
        multiselect: true,
        maxLength: 30,
        options: type.deviceTypes.map((i) => {
          return { label: i.name, value: i.id }
        }),
        disabled:formData?.id && id,
      })

      items.push({
        type: 'dropdown',
        dataType: 'text',
        label: '包含设备',
        fieldName: 'devices',
        multiselect: true,
        maxLength: 30,
        options: type.devices.map((i) => {
          return { label: i.value, value: i.id }
        }),
      })
    }
    console.log('test')
    console.log(items)
    setFormItem(items)
  }

  useEffect(() => {
    if (formData) {
      fetchDeviceTypes()
      setCurrentInnerData(formData)
    }
  }, [formData])
  useEffect(() => {
    if (deviceTypes) {
      if(currentInnerData?.id && id && deviceTypes && !currentInnerData.deviceType){
        const type=deviceTypes.find(t=>currentInnerData.devices.every(d=>!!t.devices.find(f=>f.id===d)))
        console.log(currentInnerData.devices);
        console.log(deviceTypes);
        if(type){
          console.log(type);
          setCurrentInnerData({...currentInnerData,deviceType:type.id})
        }
      }
      changeFromItem()
    }
  }, [deviceTypes, currentInnerData])
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      deviceType: [CommonRule.Require],
      deviceTypes: [CommonRule.Require],
      devices: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  useEffect(() => {
    console.log(formItem)
  }, [formItem])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,    
      name: inputdata.name,
      devices: inputdata.devices,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      projectId: inputdata.projectId,
      name: inputdata.name,
      deviceTypes: inputdata.deviceTypes,
      devices: inputdata.devices,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='项目范围信息'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createRange}
      updateData={updateRange}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      onChange={(newValue, oldValue, field) => {
        console.log(field,oldValue,newValue)
        if (field == 'deviceType' && newValue!=oldValue) setCurrentInnerData(newValue)
      }}
      id={id}
      formData={currentInnerData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
