import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';

export default function NavDropdown({ className, navButton, children, disabled }) {
  const [open, setOpen] = useState(false)
  return (
    <div className={`header-nav-item${!disabled && open ? ' open' : ''}${className ? ' ' + className : ''}`}>
      <div className="nav-button" onClick={disabled ? undefined : (() => setOpen(true))} data-cy="header-nav-dropdown">
        {navButton}</div>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <div className="header-nav-dropdown" onClick={() => setOpen(false)}>
          {children}
        </div>
      </OutsideClickHandler>
    </div>
  )
}
