import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { SlidingCard } from 'components/SlidingCard'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import { CommonIndex } from 'components/LoongInCommon'
import { getTeamMembers, removeTeamMember } from 'common/apis/maindata'
import { getProject } from 'slices/ezProjectSlice'
import { useImperativeHandle } from 'react'
import { getUsers } from 'slices/userSlice'
import axios from 'axios'
import { getTestInstruments, removeTestInstrument } from 'common/apis/ezreport'
import { SearchInstrument } from './SearchInstrument'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { fetchGetOrganizationAsync, getOrganization } from 'slices/organizationSlice'
import { findTree } from 'common/utils'
import moment from 'moment'

export const InstrumentList = forwardRef(({ item, onRefresh, onEdit }, ref) => {
  const refTeamIndex = useRef(null)
  const [teamSearchData, setTeamSearchData] = useState(null)
  const devSearchRef = useRef(null)
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])

  const dt = useDataDictionary('InstrStatus')
  const organization = useSelector(getOrganization)

  useEffect(() => {
    if (!organization) {
      dispatch(fetchGetOrganizationAsync())
    }
  }, [organization])

  useEffect(() => {
    changeColumns()
  }, [dt, organization])
  const changeColumns = () => {
    setColumns([
      {
        title: 'instrument.assetCode',
        key: 'assetCode',
      },
      {
        title: 'instrument.name',
        key: 'name',
      },
      {
        title: 'instrument.modal',
        key: 'modal',
        width: '150px',
      },
      {
        title: '所在服务中心',
        key: 'unitId',
        render: (item, index) => {
          const manager = findTree(organization, (d) => d.key == item.unitId)
          return manager?.title
        },
      },
      {
        title: 'instrument.status',
        key: 'status',
        render: (item, index) => {
          const category = dt?.details.find((d) => d.code == item.status)
          return category?.displayText
        },
      },
      {
        title: 'instrument.lastCheck',
        key: 'lastCheck',
        render: (item) => {
          return moment(item.lastCheck).format('yyyy-MM-DD')
        },
      },
      {
        title: '到期日期',
        key: 'purchaseDate',
        render: (item) => {
          return item.validity>=9999?'一次性':moment(item.lastCheck).add(item.validity, 'M').format('yyyy-MM-DD')
        },
      },
    ])
  }
  useEffect(() => {
    if (item && item.type === 'testItem') {
      setTeamSearchData({ testItemId: item.key })
    } else {
      setTeamSearchData({})
    }
  }, [item])

  const getTeamDeleteInput = (i) => {
    return { instrumentId: i.id, itemId: item.key }
  }
  const teamActionRender = (item, index) => {
    return (
      <Button
        key={nanoid()}
        sizeClass='small'
        icon='abb/trash'
        type='discreet-black'
        shape='pill'
        onClick={() => {
          refTeamIndex.current.setDeleteItem(item)
        }}
      />
    )
  }
  useImperativeHandle(ref, () => ({
    query, //刷新数据
  }))
  const query = () => {
    refTeamIndex.current.query()
  }
  if (!item || item.type !== 'testItem') {
    return <></>
  }
  return (
    <>
      <SlidingCard>
        <CommonIndex
          otherButton={[
            <Button
              key={nanoid()}
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/plus'
              onClick={() => devSearchRef.current.showDialog()}
              style={{ opacity: 1, marginRight: 8 }}
            />,
          ]}
          title='试验仪器'
          key={nanoid()}
          className={'admin-main-content3 with-padding'}
          ref={refTeamIndex}
          columns={columns}
          getList={getTestInstruments}
          searchData={teamSearchData}
          deleteData={removeTestInstrument}
          getDeleteInput={getTeamDeleteInput}
          canDelete
          pageSize={5}
          pageInQuery={false}
          canSearch={false}
          refreshAfterDelete={true}
          tableWidth={'100%'}
          tableHeight={'100%'}
          enableSorting={false}
          refreshWhenOpen={false}
          canSelection={false} //是否开启选择功能
          canBatchDel={false}
          actionRender={teamActionRender}
          searchClassName='searching-form-simple' //查询样式
          toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
        />
      </SlidingCard>
      <SearchInstrument
        itemId={item?.key}
        ref={devSearchRef}
        onRefresh={() => {
          refTeamIndex.current.query()
        }}
      />
    </>
  )
})
