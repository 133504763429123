import React, { useState, useEffect, useRef } from 'react'
import { useImperativeHandle } from 'react'
import { Button, Dialog, LoadingIndicator } from '@abb/abb-common-ux-react'
import { t } from 'i18next'
import { forwardRef } from 'react'
import '../../../../styles/singleReport.scss'
import axios from 'axios'
import { getMaintenanceRangePreview } from 'common/apis/maintenanceRange'
import { tr } from 'date-fns/locale'
import { nanoid } from 'nanoid'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { chunk } from 'lodash'
import { LineChart } from 'recharts'
import { SingleReport } from 'modules/EzReport/components/SingleReport'
export const Preview = forwardRef(({}, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [report, setReport] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [project, setProject] = useState(null)
  const types = useDataDictionary('DeviceType')
  let index = 0
  const fetchPreviewInfo = (rangeId) => {
    setIsLoading(true)
    axios.post(getMaintenanceRangePreview(), { id: rangeId }).then((response) => {
      console.log(response.data)
      setReport(response.data)
      setIsLoading(false)
    })
  }

  const showDialog = (project, rangeId) => {
    setProject(project)
    fetchPreviewInfo(rangeId)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))

  return (
    <>
      <Dialog
        style={{ width: '1160px', height: '700px' }}
        showCloseButton={true}
        closeOnLostFocus
        closeOnEscape={false}
        dimBackground={true}
        isOpen={isVisible}
        title={'单体报告预览'}
        onClose={() => setIsVisible(false)}
      >
        {isLoading && (
          <div style={{ width: '100%', height: '100%', display: 'flex', backgroundColor: '#fff' }}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                zIndex: 9999,
              }}
            >
              <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
            </div>
          </div>
        )}
        {
          report && <SingleReport report={report}></SingleReport>
        }
      </Dialog>
    </>
  )
})
