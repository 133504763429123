import React, { useState,} from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import { Button } from '@abb/abb-common-ux-react'
import './dropdown-button.scss'


const DropdownButton = ({ disabled, children, forcePopupLeft }) => {
  const VerticalPosition = { Top: 'top', Bottom: 'bottom' }
  const HorizontalPosition = { Left: 'left', Right: 'right' }

  const [menuPositionVertical, setMenuPositionVertical] = useState()
  const [menuPositionHorizontal, setMenuPositionHorizontal] = useState()
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const openDropdown = (e) => {
    e.stopPropagation()
    const _position = e.clientX + 432 >= window.innerWidth ? HorizontalPosition.Right : HorizontalPosition.Left
    setMenuPositionHorizontal(!!forcePopupLeft ? HorizontalPosition.Right : _position)
    // console.log(e.clientY,":",window.innerHeight)
    setMenuPositionVertical(e.clientY + 150 >= window.innerHeight ? VerticalPosition.Bottom: VerticalPosition.Top)
    setIsShowDropdown(true);
  }

  const closeDropdown = () => {
    setIsShowDropdown(false);
  }
  const outsideClick = () => {
    if(!isShowDropdown) return
    closeDropdown()
  }

  if (disabled) {
    return (
      <div className="dropdown-button">
        <Button sizeClass="small" icon='abb/menu-narrow' shape="pill" disabled />
      </div>
    )
  }
  return (
    <OutsideClickHandler onOutsideClick={outsideClick}>
      <div className={isShowDropdown ? "dropdown-button open" : "dropdown-button"}>
        <Button sizeClass="small" icon='abb/menu-narrow' shape="pill" onClick={e => openDropdown(e)} />
        {isShowDropdown && <div className={`dropdown-list position-${menuPositionHorizontal} position-${menuPositionVertical}`}>
          <div>
            {children}
          </div>
        </div>}
      </div>
    </OutsideClickHandler>
  )
}

const DropdownButtonOption = ({ onClick, disabled, children }) => {
  const handleClick = (handler, e) => {
    if (handler && typeof (handler) === 'function') {
      handler()
    } else {
      e.stopPropagation()
    }
  }
  return (
    <div
      className={`dropdown-list-item${disabled ? ' disabled' : ''}`}
      onClick={(e) => disabled ? e.stopPropagation() : handleClick(onClick, e)}>
      {children}</div>)
}


export {
  DropdownButton,
  DropdownButtonOption
}
