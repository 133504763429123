import React, { useState } from 'react'

import { Redirect } from 'react-router-dom'
import { Row, Col } from 'components/Grid'
import { Button, LoadingIndicator } from '@abb/abb-common-ux-react'
import { sync } from 'common/endpoints'
import axios from 'axios'
import { tr } from 'date-fns/locale'

export default function Sync() {
  const [isLoading, setIsLoading] = useState(false)
  const [isFinished, setIsFinished] = useState(false)
  const [message, setMessage] = useState(null)
  const [isSuccess, setIsSuccess] = useState(true)
  const syncFun = async () => {
    setIsLoading(true)
    setIsFinished(false)
    setMessage('正在同步数据，请稍后。。。')
    const resp = await axios
      .post(sync())
      .then((resp) => {
        setMessage('同步数据成功。')
        setIsSuccess(true)
      })
      .catch((e) => {
        setIsSuccess(false)
        setMessage('同步数据失败')
      })
    setIsLoading(false)
    setIsFinished(true)
  }
  return (
    <div className='admin-main-content with-padding'>
      {(isLoading || isFinished) && <LoadingIndicator text={message} color={isSuccess?'blue':'red'} determinate={isFinished} value={100} type='bar' />}
      <div className='central-position'>
        <Button onClick={syncFun} isLoading={isLoading} text='同步数据'></Button>
      </div>
    </div>
  )
}
