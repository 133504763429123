import React, { useState, useEffect, useRef } from "react"
import { Route, useRouteMatch } from "react-router-dom"
import { CommonList } from "components/LoongInCommon"
import { MainIndex } from "./MainIndex"

export default () => {
  const { path } = useRouteMatch()
  const [activeItem, setActiveItem] = useState([])
  const refList = useRef(null)
  const list = [
    {'key':'PowerLoadLevel','title':'电力负荷级别'},
    {'key':'MainSwitchFunction','title':'主开关功能'},
    {'key':'LineImportance','title':'线路重要程度'},
  ]
  

  useEffect(() => {
    refList.current.setCurrentItem(list[0])
  }, [])
  const onItemChange = (item) => {
    console.log(item);
    setActiveItem(item)
  }
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        padding: 8,
        boxSizing: "border-box",
      }}
      data-cy="admin-device-model"
    >
      <Route path={[`${path}/:id`, path]}>
        <CommonList style={{margin:0}}
          ref={refList}
          data={list}
          keyName="key"
          filterText="title"
          displayName="title"
          onItemChange={onItemChange}
        />
        <MainIndex catalog={activeItem.key} title={activeItem.title}/>
      </Route>
    </div>
  )
}
