import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDeviceTypeMetaDatas, getDeviceDatas } from 'slices/deviceSlice'
import { Table, Tooltip, WithTooltip, Button, ToggleSwitch } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import { fetchGetApplicationsAsync, getApplications } from 'slices/maindataSlice'
import { getCustomer } from 'slices/ezProjectSlice'
import axios from 'axios'
import { getCustomerApplications, setCustomerApplication } from 'common/apis/maindata'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'

export default function Applications({ onEdit }) {
  const applications = useSelector(getApplications)
  const customer = useSelector(getCustomer)
  const [roles, setRoles] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!applications) {
      dispatch(fetchGetApplicationsAsync())
    }
  }, [applications])
  useEffect(() => {
    if (customer) {
      getUserRoleAsync()
    }
  }, [customer])
  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const onWindowResize = () => {
    setPageIndex(0)
  }

  const getUserRoleAsync = async () => {
    try {
      const response = await axios.post(getCustomerApplications(customer.id), {})
      if (response.status === 200) {
        if (response.data) {
          setRoles(response.data)
        }
      } else {
        console.log('error to get user roles')
      }
    } catch (e) {
      console.log('exception to get user roles')
      console.log(e)
    } finally {
    }
  }

  const changeStatus = (item, newStatus) => {
    axios
      .post(setCustomerApplication(), {
        applicationId: item.id,
        customerId: customer.id,
        status: newStatus,
      })
      .then((response) => {
        console.log(response)
        if (response.status == 200 || response.status == 204) {
          getUserRoleAsync()
        } else {
          dispatch(showNotification(NotificationType.Success, t('t_failToUpdate')))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(showNotification(NotificationType.Alarm, t('t_failToUpdate')))
      })
      .finally(() => {})
  }
  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>应用列表</div>
          <div className='widget-tools device-profile-paginator show-md show-lg' style={{ paddingTop: 10 }}></div>
        </div>
        <div className='widget-body device-profile-details'>
          <div className={`layout column-1 offset-0`}>
            <Table sizeClass='medium' borderType='discreet' key={nanoid()}>
              <tbody>
                {applications?.map((item) => (
                  <tr key={nanoid()}>
                    <td style={{ width: '50%' }} title={item.name}>
                      {item.name}
                    </td>
                    <td>
                      <ToggleSwitch
                        label={roles.find((c) => c.id == item.id) != null ? '已采购' : '未采购'}
                        showIcons={true}
                        value={roles.find((c) => c.id == item.id) != null}
                        onChange={(newState) => {
                          changeStatus(item, newState)
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}
