import { getDataDictionary } from "common/utils"
import { useSelector } from "react-redux"
import { getDictionary } from "slices/dataDictionarySlice"

export const useDataDictionaryOptions = (key) => {
    const dictionary = useSelector(getDictionary)
    const detail = getDataDictionary(dictionary, key)
    return detail?.details?.map((c) => {
      return { label: c.displayText, value: c.code }
    })??[]
  }