import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    clientHeight: 0,
    clientWidth: 0,
    initialized: false,
  },
  reducers: {
    setClientHeight(state, action) {
      state.clientHeight = action.payload
    },
    setClientWidth(state, action) {
      state.clientWidth = action.payload
    },

    setInitialized(state, action) {
      state.initialized = true
    },
    setUnInitialized(state, action) {
      state.initialized = false
    },
  },
})

export const { setClientHeight, setClientWidth, setInitialized, setUnInitialized } = appSlice.actions
export const getClientHeight = (state) => state.app.clientHeight
export const getClientWidth = (state) => state.app.clientWidth
export const getInitialized = (state) => state.app.initialized

export default appSlice.reducer
