import React, { useState } from "react"
import { Button } from "@abb/abb-common-ux-react"
import { useTranslation } from "react-i18next"
import { CommonFormItem } from ".."

export const SearchPanel = ({ children, onQuery, className, searchItem, initData, onChange }) => {
  const { t } = useTranslation()
  const [innerData, setInnerData] = useState(initData || {})
  const changeData = (fieldName, value, preVal) => {
    // console.log("changeData", fieldName, value, preVal)
    let oldInnerData = innerData
    let newInnerData = { ...innerData }
    newInnerData[fieldName] = value
    if (value === undefined || value === "") delete newInnerData[fieldName]
    newInnerData.__fieldName = fieldName
    setInnerData(newInnerData)
    onChange && onChange(newInnerData, oldInnerData)
  }
  return (
    <div className={className != undefined ? className : "searching-form"} style={{ zIndex: 9, flexGrow: 1, flexWrap: "wrap" }}>
      {searchItem &&
        searchItem.map((item, index) => {
          return (
            <CommonFormItem
              key={index}
              type={item.type}
              dataType={item.dataType}
              label={item.label}
              additionalLabel={item.additionalLabel}
              showClearIcon={item.showClearIcon}
              fieldName={item.fieldName}
              disabled={item.disabled}
              options={item.options}
              style={item.style}
              innerStyle={item.innerStyle}
              placeholder={item.placeholder}
              maxLength={item.maxLength}
              maxDate={item.maxDate}
              minDate={item.minDate}
              dateFormat={item.dateFormat}
              searchable={item.searchable}
              multiselect={item.multiselect}
              showYearPicker={item.showYearPicker}
              showMonthYearPicker={item.showMonthYearPicker}
              showYearDropdown={item.showYearDropdown}
              showTimeSelect={item.showTimeSelect}
              visible={item.visible}
              onKeyUp={item.onKeyUp}
              onEnter={item.onEnter}
              onChange={(fieldName, val, preVal) => {
                changeData(fieldName, val, preVal)
              }}
              value={innerData ? innerData[item.fieldName] : ""}
              render={item.render}
            >
              {item.children}
            </CommonFormItem>
          )
        })}
      {children}
      <div className="form-group form-group-button">
        <Button text={t("b_query")} type="primary-blue" sizeClass="small" icon="abb/search" onClick={() => onQuery()} />
      </div>
    </div>
  )
}

// export default { SearchPanel }
