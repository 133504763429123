import React, { useEffect, useState } from 'react'
import { Button, Collapsible, CollapsibleContainer, Input } from '@abb/abb-common-ux-react'
import './resource.scss'
import { useDispatch } from 'react-redux'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import { ResourceDirectoryFiles } from './ResourceDirectoryFiles'
import axios from 'axios'
import { useMountedState } from 'common/useMountedState'
import { ResponseResult } from 'common/request'
import { getDirectory, createDirectory, renameDirectory, deleteDirectory } from 'common/endpoints'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import SidePanel from 'components/SidePanel'

export default function Resource() {
  const isMounted = useMountedState()
  const dispatch = useDispatch();
  const [directory, setDirectory] = useState([])
  const [activeDir, setActiveDir] = useState(null)
  const [expanded, setExpanded] = useState([])
  const [isCreatingDirectory, setIsCreatingDirectory] = useState(false)
  const [isRenamingDirectory, setIsRenamingDirectory] = useState(false)
  const [isDeletingDirectory, setIsDeletingDirectory] = useState(false)
  const [deleteConfirmId, setDeleteConfirmId] = useState(null)
  const [renameDirId, setRenameDirId] = useState(null)
  const [creatingParentId, setCreatingParentId] = useState(null)
  const [inputName, setInputName] = useState('')
  const [errorName, setErrorName] = useState(null)

  const getData = () => {
    axios.get(getDirectory()).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        const dir = [e.data.data];
        setDirectory(dir)
        setActiveDir(dir[0].id)
        setExpanded(dir[0].id)
      } else {
        dispatch(showNotification(NotificationType.Warn), '文件夹读取失败')
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
    }).then(e => {
      if (!isMounted()) {
        return
      }
    })
  }

  const onDeleteDirectory = (id) => {
    setIsDeletingDirectory(true)
    axios.delete(deleteDirectory(id)).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        const mapDirectory = (dir, id) => {
          return dir.filter(d => d.id !== id).map(d => ({
            ...d,
            children: mapDirectory(d.children, id)
          }))
        }
        const nextDir = mapDirectory(directory, id)
        setDirectory(nextDir)
        setActiveDir(null)
        setDeleteConfirmId(null)
        dispatch(showNotification(NotificationType.Success, '删除成功'))
      } else {
        dispatch(showNotification(NotificationType.Warn, e.data.message))
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
      dispatch(showNotification(NotificationType.Alarm, '删除失败'))
    }).then(e => {
      if (!isMounted()) {
        return
      }
      setIsDeletingDirectory(false)
    })

  }

  const onRenameDirectory = () => {
    if (inputName.trim() === '') {
      setErrorName('请填写目录名称')
      return;
    }
    setIsRenamingDirectory(true)
    axios.put(renameDirectory(), {
      id: renameDirId,
      newName: inputName
    }).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        const mapDirectory = (dir, id, name) => {
          return dir.map(d => ({
            ...d,
            name: d.id === id ? name : d.name,
            children: mapDirectory(d.children, id, name)
          }))
        }
        const nextDir = mapDirectory(directory, renameDirId, inputName)
        setDirectory(nextDir)
        setRenameDirId(null)
        dispatch(showNotification(NotificationType.Success, '操作成功'))
      } else {
        dispatch(showNotification(NotificationType.Warn, e.data.message))
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }

    }).then(e => {
      if (!isMounted()) {
        return
      }
      setIsRenamingDirectory(false)
    })
  }

  const onCreateDirectory = () => {
    if (inputName.trim() === '') {
      setErrorName('请填写目录名称')
      return;
    }
    setIsCreatingDirectory(true)
    axios.post(createDirectory(), {
      folderName: inputName,
      parentId: creatingParentId
    }).then(e => {
      console.log(e)
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        const mapDirectory = (dir, parentId, newId, name) => {
          return dir.map(d => ({
            ...d,
            children:
              d.id === parentId ? [...d.children, {
                id: newId,
                name: name,
                children: []
              }] :
                mapDirectory(d.children, parentId, newId, name)
          }))
        }
        const newDirId = e.data.data.id// nanoid() // USE RETURN ID!!
        const nextDir = mapDirectory(directory, creatingParentId, newDirId, inputName)
        setDirectory(nextDir)
        setExpanded(Array.from(new Set([...expanded, creatingParentId])))
        setActiveDir(newDirId)
        setCreatingParentId(null)
      } else {
        dispatch(showNotification(NotificationType.Warn, e.data.message))
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
    }).then(e => {
      if (!isMounted()) {
        return
      }
      setIsCreatingDirectory(false)
    })

  }

  useEffect(() => {
    getData()
  }, [])

  const renderDir = (id, name, children) => (
    <Collapsible key={id} itemId={id} title={
      <div className="resource-dir-header">
        <div className="resource-dir-name">{name}</div>
        {id === activeDir &&
          <div className="resource-dir-menu">
            <DropdownButton forcePopupLeft >
              {id !== '0' && <DropdownButtonOption onClick={() => {
                setRenameDirId(id);
                setInputName(name)
                }} >重命名</DropdownButtonOption>}
              <DropdownButtonOption onClick={() => {
                setCreatingParentId(id);
                setInputName('')
                }} >添加子目录</DropdownButtonOption>
              {id !== directory[0].id && <DropdownButtonOption onClick={() => onDeleteDirectory(id)} >删除</DropdownButtonOption>}
            </DropdownButton>
          </div>}
      </div>
    } className={id === activeDir ? 'active' : ''}>
      {children ? children.map(c => renderDir(c.id, c.name, c.children)) : null}
    </Collapsible >)

  return (
    <>
      <div className="admin-main-content" style={{ flexDirection: 'row' }} >
        <div className="resource-directory">
          <CollapsibleContainer className="collapsible-directory"
            monochrome
            singleOpenMode={false}
            expandOnTitleClick={false}
            expandedCollapsible={expanded}
            onExpanding={expanded !== undefined ? (items) => setExpanded(Array.from(new Set([...expanded, ...items]))) : undefined}
            onCollapsing={expanded !== undefined ? (item) => setExpanded(expanded.filter((i) => i !== item)) : undefined}
            onItemClick={e => setActiveDir(e.props.itemId)}>
            {directory?.map(d => renderDir(d.id, d.name, d.children))}
          </CollapsibleContainer>

        </div>
        {!activeDir && !!directory &&
          <div className="resource-directory-main" >
            <div className="central-position text-gray">请从左侧列表选择目录</div>
          </div>}
        {activeDir && <ResourceDirectoryFiles directoryId={activeDir} directory={directory} />}
      </div>

      <SidePanel show={renameDirId !== null} onClickBackdrop={() => setRenameDirId(null)}>
        <div className='side-panel-header'>
          <div className='side-panel-title'>重命名</div>
        </div>
        <div className='side-panel-body'>
          <div className='form'>
            <div className='form-group'>
              <Input
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                clearOnEscape={false}
                label='目录名称'
                additionalLabel='必填'
                // disabled={isAddingUsername}
                value={inputName}
                onValueChange={e => {
                  setInputName(e);
                  setErrorName(null)
                }}
                validationResult={errorName}
              // ref={inputUsernameControl}
              />
            </div>
          </div>
          <div className='form-group-button'>
            <div className="button-group-left">
              <Button text='确定' type='primary-blue' sizeClass='small' onClick={onRenameDirectory} isLoading={isRenamingDirectory} />
              <Button text='取消' type='normal' sizeClass='small' onClick={() => setRenameDirId(null)} style={{ marginLeft: 8 }} />
            </div>
          </div>
        </div>
      </SidePanel>

      <SidePanel show={creatingParentId !== null} onClickBackdrop={() => setCreatingParentId(null)}>
        <div className='side-panel-header'>
          <div className='side-panel-title'>添加目录</div>
        </div>
        <div className='side-panel-body'>
          <div className='form'>
            <div className='form-group'>
              <Input
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                clearOnEscape={false}
                label='目录名称'
                additionalLabel='必填'
                // disabled={isAddingUsername}
                value={inputName}
                onValueChange={e => {
                  setInputName(e);
                  setErrorName(null)
                }}
                validationResult={errorName}
              // ref={inputUsernameControl}
              />
            </div>
          </div>
          <div className='form-group-button'>
            <div className="button-group-left">
              <Button text='添加' type='primary-blue' sizeClass='small' onClick={onCreateDirectory} isLoading={isCreatingDirectory} />
              <Button text='取消' type='normal' sizeClass='small' onClick={() => setCreatingParentId(null)} style={{ marginLeft: 8 }} />
            </div>
          </div>
        </div>
      </SidePanel>
    </>
  )
}
