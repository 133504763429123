import React, { useState, useEffect, useRef, forwardRef } from "react"
import { useSelector } from "react-redux"
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react"
import { SlidingCard } from "components/SlidingCard"
import { nanoid } from "nanoid"
import { t } from "i18next"
import { CommonIndex } from "components/LoongInCommon"
import { deleteCustomerRight, getContacts, getCustomerRights, removeContact } from "common/apis/maindata"
import { getCustomer, getProject } from "slices/ezProjectSlice"
import { useImperativeHandle } from "react"
import { getUsers } from "slices/userSlice"
import axios from "axios"
import { RightFormEdit } from "./RightFormEdit"
import moment from "moment"

export const RightList = forwardRef(({ onRefresh, onEdit }, ref) => {
  const refTeamIndex = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])

  const customer = useSelector(getCustomer)
  const columns = [
    {
      title: "权益",
      key: "title",
    },
    {
      title: "开始日期",
      key: "beginDate",
      render: (item) => {
        return moment(item.beginDate).format("yyyy-MM-DD");
      },
    },
    {
      title: "结束日期",
      key: "endDate",
      render: (item) => {
        return moment(item.endDate).format("yyyy-MM-DD");
      },
    },
  ]
  useEffect(() => {
    if (customer) {
      setSearchData({ customerId: customer.id,includeAll:true })
    }
  }, [customer])

  useEffect(() => {
    window.addEventListener("resize", onWindowResize)
    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [])

  const onWindowResize = () => {}
  const getTeamDeleteInput = (item) => {
    return { id: item.id }
  }

  useImperativeHandle(ref, () => ({
    query, //刷新数据
  }))
  const query = () => {
    refTeamIndex.current.query()
  }
  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  const onAddBefore = () => {
    return {
      customerId: customer.id,
      customerName: customer.name,
    };
  };
  const teamActionRender = (item, index) => {
    return (
      <>
        <Button
          key={nanoid()}
          sizeClass="small"
          icon="abb/edit"
          type="discreet-black"
          shape="pill"
          onClick={() => {
            refTeamIndex.current.showAndSwitchTab(0,item)
          }}
        />
        <Button
          key={nanoid()}
          sizeClass="small"
          type="discreet-black"
          icon="abb/trash"
          shape="pill"
          onClick={() => {
            refTeamIndex.current.setDeleteItem(item)
          }}
        />
      </>
    )
  }
  return (
    <SlidingCard>
      <CommonIndex
        otherButton={[
          <Button
            key={nanoid()}
            sizeClass="small"
            shape="pill"
            type="discreet-black"
            icon="abb/plus"
            onClick={() => refTeamIndex.current.add()}
            style={{ opacity: 1, marginRight: 8 }}
          />,
        ]}
        title="客户权益"
        key={nanoid()}
        className={"admin-main-content3 with-padding"}
        ref={refTeamIndex}
        columns={columns}
        getList={getCustomerRights}
        searchData={searchData}
        deleteData={deleteCustomerRight}
        getDeleteInput={getTeamDeleteInput}
        FormEdit={RightFormEdit}
        onAddBefore={onAddBefore}
        canEdit
        canDelete
        pageSize={4}
        pageInQuery={false}
        canSearch={false}
        refreshAfterDelete={true}
        tableWidth={"100%"}
        tableHeight={"100%"}
        enableSorting={false}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        actionRender={teamActionRender}
        onDeleteData={onDeleteData}
        searchClassName="searching-form-simple" //查询样式
        toolClassName="searching-form-simple searching-form-right-tool text-right" //工具栏样式
      />
    </SlidingCard>
  )
})
