import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { createMaterial, updateMaterial } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { getCustomerNames } from 'slices/maindataSlice'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { useDataDictionaryOptions } from 'hooks/useDataDictionaryOptions'

export const MaterialFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const levels = useDataDictionary('materialLevel')

  // const [formItem, setFormItem] = useState([]);

  //初始化数据
  const initData = {
    code: '',
    name: '',
    modal: '',
    provider: '',
    warranty: '',
    description: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('materialCatalog.name'),
      fieldName: 'materialCatalogName',
      visible: !formData || !!formData.materialCatalogName,
      disabled: true,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('material.code'),
      fieldName: 'code',
      maxLength: 30,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('material.purchaseCode'),
      fieldName: 'purchaseCode',
      maxLength: 30,
    },
    {
      type: 'dropdown',
      dataType: 'text',
      label: t('material.level'),
      fieldName: 'level',
      options: useDataDictionaryOptions('materialLevel'),
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('material.name'),
      fieldName: 'name',
      maxLength: 40,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('material.enName'),
      fieldName: 'enName',
      maxLength: 40,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('material.modal'),
      fieldName: 'modal',
      maxLength: 20,
    },
    {
      type: 'text',
      dataType: 'number',
      label: "推荐库存系数",
      fieldName: 'storeFactor',
      maxLength: 20,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('material.provider'),
      fieldName: 'provider',
      maxLength: 20,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('material.warranty'),
      fieldName: 'warranty',
      maxLength: 20,
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: t('material.description'),
      fieldName: 'description',
      maxLength: 1000,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      code: [CommonRule.Require],
      purchaseCode: [CommonRule.Require],
      level: [CommonRule.Require],
      enName: [CommonRule.Require],
      modal: [CommonRule.Require],
      storeFactor: [CommonRule.Require],
      warranty: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      code: inputdata.code,
      purchaseCode: inputdata.purchaseCode,
      level: inputdata.level,
      enName: inputdata.enName,
      name: inputdata.name,
      modal: inputdata.modal,
      extraProperties: {
        StoreFactor: inputdata.storeFactor,
      },
      provider: inputdata.provider,
      warranty: inputdata.warranty,
      description: inputdata.description,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      catalogId: inputdata.materialCatalogId,
      code: inputdata.code,
      purchaseCode: inputdata.purchaseCode,
      level: inputdata.level,
      extraProperties: {
        StoreFactor: inputdata.storeFactor,
      },
      enName: inputdata.enName,
      name: inputdata.name,
      modal: inputdata.modal,
      provider: inputdata.provider,
      warranty: inputdata.warranty,
      description: inputdata.description,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('material.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createMaterial}
      updateData={updateMaterial}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={{ ...formData, storeFactor: formData?.extraProperties?.StoreFactor }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
