import { createSlice } from "@reduxjs/toolkit";


const envSlice = createSlice({
  name: "env",
  initialState: {
    initialized: false,
    version: '',
    solution: '',
    authType: '',
    apiBase: '',
    azureADAuthority: '',
    azureADClientId: '',
    azureADRedirectUri: '',
    azureADScopes: '',
    id4Authority: '',
    id4ClientId: '',
    id4ClientSecret: '',
    id4Scope: '',
    id4SigninCallback: '',
    baiduMapKey: '',
    fileResource: '',
    enableSMS: '',
  },
  reducers: {
    setEnv(state, action) {
      state.initialized = true;
      const _env = process.env;
      state.version = action.payload.version;
      state.solution = action.payload.solution;
      state.authType = action.payload.authType; // "AAD" or not

      state.apiBase = action.payload.apiBase === undefined ?
        _env.REACT_APP_API_BASE : action.payload.apiBase;

      state.azureADAuthority = action.payload.azureADAuthority === undefined ?
        _env.REACT_APP_AZURE_AD_AUTHORITY : action.payload.azureADAuthority;
      state.azureADClientId = action.payload.azureADClientId === undefined ?
        _env.REACT_APP_AZURE_AD_CLIENT_ID : action.payload.azureADClientId;
      state.azureADRedirectUri = action.payload.azureADRedirectUri === undefined ?
        _env.REACT_APP_AZURE_AD_REDIRECT_URI : action.payload.azureADRedirectUri;
      state.azureADScopes = action.payload.azureADScopes === undefined ?
        _env.REACT_APP_AZURE_AD_SCOPES : action.payload.azureADScopes;

      state.id4Authority = action.payload.id4Authority;
      state.id4ClientId = action.payload.id4ClientId;
      state.id4ClientSecret = action.payload.id4ClientSecret;
      state.id4Scope = action.payload.id4Scope;
      state.id4SigninCallback = action.payload.id4SigninCallback;

      state.baiduMapKey = action.payload.baiduMapKey === undefined ?
        _env.REACT_APP_BAIDU_MAP_KEY : action.payload.baiduMapKey;
      state.fileResource = action.payload.fileResource === undefined ?
        _env.REACT_APP_FILE_RESOURCE : action.payload.fileResource;


      state.enableSMS = action.payload.smsEnable === 'true'
    },
  },
})

export const { setEnv } = envSlice.actions;
export const getEnv = (state) => state.env;
export default envSlice.reducer;
