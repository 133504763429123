import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getDevice as apiGetDevice,getStation as apiGetStation, getDeviceTypeMetaDatas as apiGetDeviceTypeMetaDatas,getDeviceDatas as apiGetDeviceDatas,getDeviceType as apiGetDeviceType } from "common/apis/maindata";
import { tr } from "date-fns/locale";

export const fetchDeviceAsync = createAsyncThunk("device/fetchDeviceAsync", async (request) =>
  axios
    .post(apiGetDevice(request.id))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
export const fetchParentAsync = createAsyncThunk("device/fetchParentAsync", async (request) =>
  axios
    .post(apiGetDevice(request.id))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
export const fetchStationAsync = createAsyncThunk("device/fetchStationAsync", async (request) =>
  axios
    .post(apiGetStation(request.id))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
export const fetchDeviceTypeAsync = createAsyncThunk("device/fetchDeviceTypeAsync", async (id) =>
  axios
    .post(apiGetDeviceType(id))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
export const fetchDeviceMetaDataAsync = createAsyncThunk("device/fetchDeviceMetaDataAsync", async (typeId) =>
  axios
    .post(apiGetDeviceTypeMetaDatas(typeId))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);

export const fetchDeviceDataAsync = createAsyncThunk("device/fetchDeviceDataAsync", async (request) =>
  axios
    .post(apiGetDeviceDatas(request.id))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
const deviceSlice = createSlice({
  name: "device",
  initialState: {
    isLoadingDevice: false,
    isLoadingParent: false,
    isLoadingStation: false,
    isLoadingDeviceType: false,
    isLoadingMetaData: false,
    isLoadingData: false,
    type:null,
    device: null,
    parent: null,
    station:null,
    metaData: null,
    data: [],
  },
  reducers: {
    setParent(state, action) {
      state.parent = action.payload;
    },
    setDevice(state, action) {
      state.device = action.payload;
    },
    setStation(state, action) {
      state.station = action.payload;
    },
    setDeviceType(state, action) {
      state.type = action.payload;
    },
    setDeviceTypeMetaDatas(state, action) {
      state.metaData = action.payload;
    },
    setDeviceDatas(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [fetchDeviceAsync.pending]: (state, action) => {
      state.isLoadingDevice=true
      state.device = null;
    },
    [fetchDeviceAsync.fulfilled]: (state, action) => {
      state.device = action.payload;
      state.isLoadingDevice=false
    },
    [fetchParentAsync.pending]: (state, action) => {
      state.isLoadingParent=true
      state.parent = null;
    },
    [fetchParentAsync.fulfilled]: (state, action) => {
      state.parent = action.payload;
      state.isLoadingParent=false
    },
    [fetchStationAsync.pending]: (state, action) => {
      state.isLoadingStation=true
      state.station = null;
    },
    [fetchStationAsync.fulfilled]: (state, action) => {
      state.station = action.payload;
      state.isLoadingStation=false
    },
    [fetchDeviceTypeAsync.pending]: (state, action) => {
      state.isLoadingDeviceType=true
      state.type = null;
    },
    [fetchDeviceTypeAsync.fulfilled]: (state, action) => {
      state.type = action.payload;
      state.isLoadingDeviceType=false
    },
    [fetchDeviceMetaDataAsync.pending]: (state, action) => {
      state.isLoadingMetaData=true
      state.metaData = null;
    },
    [fetchDeviceMetaDataAsync.fulfilled]: (state, action) => {
      state.metaData = action.payload;
      state.isLoadingMetaData=false
    },
    [fetchDeviceDataAsync.pending]: (state, action) => {
      state.isLoadingData=true
      state.data = null;
    },
    [fetchDeviceDataAsync.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoadingData=false
    },
  },
});

export const { setDevice,setParent,setStation, setDeviceType,setDeviceTypeMetaDatas,setDeviceDatas } = deviceSlice.actions;
export const getDevice = (state) => state.device.device;
export const getParent = (state) => state.device.parent;
export const getStation = (state) => state.device.station;
export const getDeviceType = (state) => state.device.type;
export const getDeviceTypeMetaDatas = (state) => state.device.metaData;
export const getDeviceDatas = (state) => state.device.data;
export const getIsLoadingDevice = (state) => state.device.isLoadingDevice;
export const getIsLoadingParent = (state) => state.device.isLoadingParent;
export const getIsLoadingStation = (state) => state.device.isLoadingStation;
export const getIsLoadingDeviceType = (state) => state.device.isLoadingDeviceType;
export const getIsLoadingDeviceTypeMetaDatas = (state) => state.device.isLoadingMetaData;
export const getIsLoadingDeviceDatas = (state) => state.device.isLoadingData;
export default deviceSlice.reducer;
