import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { getDevices, getStationDevices, updateDeviceData } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { getDeviceTypeMetaDatas, getDeviceDatas, getDevice } from 'slices/deviceSlice'
import { useDataDictionaryOptions } from 'hooks/useDataDictionaryOptions'
import { addLifecycle, updateLifecycle } from 'common/apis/lifecycle'
export const LifeCycleFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [formItem, setFormItem] = useState([])
  const device = useSelector(getDevice)
  const [devices, setDevices] = useState(null)
  useEffect(() => {
    if (device) {
      axios.post(getStationDevices(), { stationId: device.stationId }).then((r) => {
        setDevices(r.data.items)
      })
    }
  }, [device])
  useEffect(() => {
    if (id || devices) {
      changeFormItem()
    }
  }, [id, devices])
  //初始化数据
  const initData = {
    devices: [device.id],
  }
  const options = useDataDictionaryOptions('LifeCycleType')
  const changeFormItem = () =>
    setFormItem([
      {
        type: 'dropdown',
        dataType: 'text',
        label: '事件类型',
        fieldName: 'type',
        options: options,
      },
      {
        type: 'text',
        dataType: 'text',
        label: '事件标题',
        fieldName: 'title',
        maxLength: 10,
      },
      {
        type: 'dropdown',
        dataType: 'text',
        label: '相关设备',
        fieldName: 'devices',
        multiselect: true,
        maxLength: 30,
        options: devices.map((i) => {
          return { label: i.displayName, value: i.id }
        }),
        visible: !id || !formData,
      },
      {
        type: 'date',
        label: '计划执行日期',
        fieldName: 'planDate',
        dateFormat: 'yyyy-MM-dd',
        showMonthYearPicker: true,
        showTwoColumnMonthYearPicker: true,
        showClearIcon: true,
      },
      {
        type: 'date',
        label: '执行日期',
        fieldName: 'date',
        dateFormat: 'yyyy-MM-dd',
        showClearIcon: true,
      },
      {
        type: 'dropdown',
        dataType: 'text',
        label: '事件状态',
        fieldName: 'status',
        options: [
          { label: '未执行', value: -1 },
          { label: '已执行', value: 1 },
        ],
      },
      {
        type: 'text',
        dataType: 'textarea',
        label: '事件内容',
        fieldName: 'content',
        maxLength: 1000,
      },
    ])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    console.log(inputdata)
    return {
      id: inputdata.id,
      type: inputdata.type,
      title: inputdata.title,
      planDate: inputdata.planDate,
      date: inputdata.date,
      status: inputdata.status,
      content: inputdata.content,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      type: inputdata.type,
      title: inputdata.title,
      planDate: inputdata.planDate,
      customerId: device.customerId,
      stationId: device.stationId,
      devices: inputdata.devices,
      date: inputdata.date,
      status: inputdata.status,
      content: inputdata.content,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={'生命周期事件'}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={addLifecycle}
      updateData={() => updateLifecycle(id)}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
