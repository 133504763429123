import { viewFile } from 'common/apis/maindata'
import React from 'react'
import { useSelector } from 'react-redux'
import { getEnv } from 'slices/envSlice'
import nofile from './nofile.gif'

export  const Image=({ id ,style}) =>{
    const env = useSelector(getEnv)

    const path =id? `${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${viewFile()}?id=${id}`:nofile
    return <img alt='图片' src={path} style={style}/>
}
