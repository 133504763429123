import Mock from "mockjs";
import { resultPageSuccess } from "../utils.js";
 
const clients=[
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "1539b693-a1b1-4ce2-88a9-124b39cdcb4f",
      "parentId": null,
      "name": "ABB HUB",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "bccaf135-8618-41d9-bf0b-c815efd0ebb9",
      "parentId": null,
      "name": "AT测试",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "ad5369dd-52ea-479e-af01-99b3e058c3cb",
      "parentId": null,
      "name": "BENI",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "36a7c231-2de9-4558-8b09-c5a959acdaa5",
      "parentId": null,
      "name": "Digital Developers",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "656f7e62-b6c3-4b95-9cc0-0b89017894a3",
      "parentId": null,
      "name": "Duke test",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "61904a0f-454d-431f-b224-21c39f0b90b1",
      "parentId": null,
      "name": "Gavin",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "6b599902-910c-451c-9473-b42053efec1b",
      "parentId": null,
      "name": "ReportTest1111",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "1377c63c-66e3-43f2-a825-77ab261c69a2",
      "parentId": null,
      "name": "RobinTest",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "3ab75b56-7e6b-4ccd-a388-02872a5239af",
      "parentId": null,
      "name": "SgisDT",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "519b0cf5-e56f-40e3-a0b7-8e6db491a84d",
      "parentId": null,
      "name": "test",
      "inactive": false,
      "treeLevel": 1
    },
    {
      "countryId": "8c41794f99954cc19db1f89cecf002b7",
      "country": "中国",
      "countryCode": null,
      "contactName": null,
      "contactPhone": null,
      "contactEmail": null,
      "id": "7af0a4be-d9bb-4517-b7a0-410e6db3b174",
      "parentId": null,
      "name": "zilong",
      "inactive": false,
      "treeLevel": 1
    }
  ]
  const client_1={
    "displayName": "Digital Developers",
    "id": "36a7c231-2de9-4558-8b09-c5a959acdaa5",
    "treeLevel": 1,
    "inActive": false,
    "extensionData": null,
    "equipmentModelId": null,
    "gatewayId": null,
    "gatewayName": null,
    "sn": null,
    "treeNodes": [
      {
        "displayName": "全数据站",
        "id": "4fbd310c-cb69-4e2f-be31-c94f0bc27173",
        "treeLevel": 2,
        "inActive": false,
        "extensionData": {
          "address": "请勿删除（自动化测试）",
          "logo": "443351e784014c4ab364d3bf5b05c64d"
        },
        "equipmentModelId": null,
        "gatewayId": null,
        "gatewayName": null,
        "sn": null,
        "treeNodes": [
          {
            "displayName": "PAIS",
            "id": "b5486c89-613a-46fa-b712-6ac5bcf37216",
            "treeLevel": 3,
            "inActive": false,
            "extensionData": {
              "latitude": 24.643618,
              "longitude": 118.224846,
              "dynamicTemperatureCalculatePeriod": 15
            },
            "equipmentModelId": null,
            "gatewayId": null,
            "gatewayName": null,
            "sn": null,
            "treeNodes": [
              {
                "displayName": "UG500_Gateway75364",
                "id": "b0941506-9c01-4b6e-9d6f-a7c7bb95e70b",
                "treeLevel": 6,
                "inActive": false,
                "extensionData": {
                  "UpdateInterval": 0,
                  "NoDataInterval": 15,
                  "iotInfo": {
                    "id": 4,
                    "equipmentId": "b0941506-9c01-4b6e-9d6f-a7c7bb95e70b",
                    "msisdn": null,
                    "iccid": null,
                    "imsi": null,
                    "imei": null,
                    "mobile": null,
                    "meals": "15G/36个月",
                    "flow": "15728640",
                    "usedFlow": "401551",
                    "surplusFlow": "15327089",
                    "activationDate": "2021-04-29 00:00:00.0",
                    "testEndDate": "2021-04-30",
                    "silenceendDate": "2021-04-30",
                    "billingCycle": "36",
                    "billingDate": "2021-04-29",
                    "billingendDate": "2022-09-10",
                    "payType": "1",
                    "cardStatus": "00",
                    "macStatus": "0",
                    "gprsStatus": "00",
                    "ip": "10.55.225.182",
                    "apn": "",
                    "rat": "6",
                    "lastTime": null,
                    "updateTime": "2023-01-29T00:00:03"
                  }
                },
                "equipmentModelId": null,
                "gatewayId": null,
                "gatewayName": "UG500_Gateway75364",
                "sn": "000000/002/75364",
                "treeNodes": [
                  {
                    "displayName": "UG500_753641",
                    "id": "2942fef3-0888-4dbc-99a2-f73d3ccf49e1",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 2339,
                    "gatewayId": "b0941506-9c01-4b6e-9d6f-a7c7bb95e70b",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  },
                  {
                    "displayName": "UG500_753642",
                    "id": "1ec6550d-e004-4156-bcd1-584b8d39c714",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 188,
                    "gatewayId": "b0941506-9c01-4b6e-9d6f-a7c7bb95e70b",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  },
                  {
                    "displayName": "UG500753643",
                    "id": "7c804f08-e581-406a-93d2-e3fa798c5aaa",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 2396,
                    "gatewayId": "b0941506-9c01-4b6e-9d6f-a7c7bb95e70b",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  }
                ]
              }
            ]
          },
          {
            "displayName": "Safe Digital v2",
            "id": "c545376b-385d-4468-8ed5-f2feff5492ed",
            "treeLevel": 3,
            "inActive": false,
            "extensionData": {
              "latitude": 24.643303,
              "longitude": 118.179591,
              "dynamicTemperatureCalculatePeriod": 15
            },
            "equipmentModelId": null,
            "gatewayId": null,
            "gatewayName": null,
            "sn": null,
            "treeNodes": [
              {
                "displayName": "Safe_Digital_Gateway38821",
                "id": "cb905d47-41ea-47c7-843c-de229c0571c3",
                "treeLevel": 6,
                "inActive": false,
                "extensionData": {
                  "UpdateInterval": 1,
                  "NoDataInterval": 0,
                  "iotInfo": {
                    "id": 6,
                    "equipmentId": "cb905d47-41ea-47c7-843c-de229c0571c3",
                    "msisdn": "1410336278672",
                    "iccid": "8986111824203285157",
                    "imsi": "460113327590744",
                    "imei": "",
                    "mobile": null,
                    "meals": "15G/36个月",
                    "flow": "15728640",
                    "usedFlow": "0",
                    "surplusFlow": "15728640",
                    "activationDate": "2021-04-29 00:00:00.0",
                    "testEndDate": "2021-04-30",
                    "silenceendDate": "2021-04-30",
                    "billingCycle": "36",
                    "billingDate": "2021-04-29",
                    "billingendDate": "2024-03-31",
                    "payType": "1",
                    "cardStatus": "00",
                    "macStatus": "0",
                    "gprsStatus": "00",
                    "ip": "10.21.253.240",
                    "apn": "",
                    "rat": "6",
                    "lastTime": null,
                    "updateTime": "2023-01-29T00:00:10"
                  }
                },
                "equipmentModelId": null,
                "gatewayId": null,
                "gatewayName": "Safe_Digital_Gateway38821",
                "sn": "00000/002/38821",
                "treeNodes": [
                  {
                    "displayName": "Safe_Digital_388211",
                    "id": "e1069a93-ad20-4886-ac7f-c6396ff0b2c6",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 118,
                    "gatewayId": "cb905d47-41ea-47c7-843c-de229c0571c3",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  },
                  {
                    "displayName": "Safe_Digital_388212",
                    "id": "f98b86ed-aa37-41ed-8bf0-b16f688dcc4c",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 120,
                    "gatewayId": "cb905d47-41ea-47c7-843c-de229c0571c3",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  },
                  {
                    "displayName": "Safe_Digital_388213",
                    "id": "6f02f6ac-c28f-4eec-b30f-b85afb07d182",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 118,
                    "gatewayId": "cb905d47-41ea-47c7-843c-de229c0571c3",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  }
                ]
              }
            ]
          },
          {
            "displayName": "ZX Digital",
            "id": "a7b483a4-e5b1-4467-b669-664d6a4545b7",
            "treeLevel": 3,
            "inActive": false,
            "extensionData": {
              "latitude": 24.643618,
              "longitude": 118.224846,
              "dynamicTemperatureCalculatePeriod": 15
            },
            "equipmentModelId": null,
            "gatewayId": null,
            "gatewayName": null,
            "sn": null,
            "treeNodes": [
              {
                "displayName": "ZX_Digital_Gateway20422",
                "id": "d3b1ec94-69a2-4610-a9c3-0f40cc85819b",
                "treeLevel": 6,
                "inActive": false,
                "extensionData": {
                  "UpdateInterval": 1,
                  "NoDataInterval": 0,
                  "iotInfo": {
                    "id": 11,
                    "equipmentId": "d3b1ec94-69a2-4610-a9c3-0f40cc85819b",
                    "msisdn": "11",
                    "iccid": "21",
                    "imsi": "string",
                    "imei": "string",
                    "mobile": null,
                    "meals": null,
                    "flow": null,
                    "usedFlow": null,
                    "surplusFlow": null,
                    "activationDate": null,
                    "testEndDate": null,
                    "silenceendDate": null,
                    "billingCycle": null,
                    "billingDate": null,
                    "billingendDate": null,
                    "payType": null,
                    "cardStatus": null,
                    "macStatus": null,
                    "gprsStatus": null,
                    "ip": null,
                    "apn": null,
                    "rat": null,
                    "lastTime": null,
                    "updateTime": "2023-01-29T00:00:17"
                  }
                },
                "equipmentModelId": null,
                "gatewayId": null,
                "gatewayName": "ZX_Digital_Gateway20422",
                "sn": "00000/002/95532",
                "treeNodes": [
                  {
                    "displayName": "ZX_Digital_955321",
                    "id": "dc88bfc1-68b2-496a-abac-7cde3f01e90f",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 2146,
                    "gatewayId": "d3b1ec94-69a2-4610-a9c3-0f40cc85819b",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  },
                  {
                    "displayName": "ZX_Digital_955322",
                    "id": "218fc97c-a06c-407d-8cfd-eb6b3d50ab3c",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 2146,
                    "gatewayId": "d3b1ec94-69a2-4610-a9c3-0f40cc85819b",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  },
                  {
                    "displayName": "ZX_Digital_955323",
                    "id": "27c9997d-b852-4f37-9e5b-1125c62d3a39",
                    "treeLevel": 5,
                    "inActive": false,
                    "extensionData": null,
                    "equipmentModelId": 119,
                    "gatewayId": "d3b1ec94-69a2-4610-a9c3-0f40cc85819b",
                    "gatewayName": null,
                    "sn": null,
                    "treeNodes": []
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
export default [
  {
    url: /\/Clients\/all/,
    method: "get",
    response: (options) => {
      
      return clients;
    },
  },
  {
    url: /\/Clients\/id/,
    method: "get",
    response: (options) => {
      return client_1
    },
  },
  
];
