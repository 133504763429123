import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import { CommonConfirm } from "components/CommonConfirm"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'

import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { deleteCustomerUser, getCustomerUserPage, lockCustomerUser } from 'common/apis/maindata'
import { getCustomer } from 'slices/ezProjectSlice'
export default function Users({ device }) {
  const userProfile = useSelector(getUserProfile)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const dispatch = useDispatch()
  const customer = useSelector(getCustomer)

  const refIndex = useRef(null)

  //列定义
  const columns = [
    {
      title: 'user.userName',
      key: 'userName',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.userName}</del>
              <Tooltip>
                <div>用户已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.userName
      },
    },
    {
      title: 'user.name',
      key: 'name',
    },
    {
      title: 'user.email',
      key: 'email',
    },
    {
      title: 'user.phoneNumber',
      key: 'phoneNumber',
      width: 80,
    },
    {
      title: 'user.isActive',
      key: 'isActive',
      width: 60,
      render: (item, index) => {
        if (item.isActive) return <span className='abb-tag abb-tag-green'>{t('t_yes')}</span>
        else return <span className='abb-tag abb-tag-red'>{t('t_no')}</span>
      },
    },
  ]
  //其他菜单
  const otherMenu = (item, index) => {
    return [
      {
        disabled: userProfile.userName === item.userName,
        onClick: () => changeActive(item),
        color: item.isActive ? 'red' : 'blue',
        title: item.isActive ? t('b_disable') : t('b_enable'),
      },
    ]
  }
  //修改用户状态
  const changeActive = (item) => {
    setIsOperating(true)
    const payload = {
      userId: item.id,
      locked: !item.isActive,
    }
    axios
      .post(lockCustomerUser(customer.id), payload)
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          refIndex.current.query()
          dispatch(showNotification(NotificationType.Success, t('t_successToSet'))) //`设置成功`
        } else {
          dispatch(showNotification(NotificationType.Warn, response.data.message))
        }
      })
      .catch((e) => {
        dispatch(showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : `用户信息更新失败`))
      })
      .finally(() => {
        setIsOperating(false)
      })
  }
  //删除时放入删除项
  const onDeleteData = (item) => {
    setDeletedItems([...deletedItems, item.id])
  }

  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>客户账号列表</div>
        </div>
        <div className='widget-body device-profile-details'>
        <CommonIndex
        ref={refIndex}
        tableWidth={'100%'}
        tableHeight={'100%'}
        columns={columns}
        pageSize={5}
        getList={()=>getCustomerUserPage(customer.id)}
        deleteData={()=>deleteCustomerUser(customer.id)}
        FormEdit={FormEdit}
        otherMenu={otherMenu}
        canAdd
        canEdit
        canDelete
        canSearch={false}
        onDeleteData={onDeleteData}
        refreshAfterDelete={false}
        enableSorting={true}
      />
        </div>
      </div>
    </div>
  )
}
