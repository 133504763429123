import React from "react"
import { Button } from "@abb/abb-common-ux-react"
import { useTranslation } from "react-i18next"

export const ToolPanel = ({ children, options, className }) => {
  const { t } = useTranslation()
  options = options || {}
  // const { add, del, output, inport } = options
  //ABB Button type: 'normal' | 'primary-blue' | 'primary-black' | 'primary-red' | 'ghost' | 'discreet-blue' | 'discreet-black';
  // 结构：{text:'',type:'',icon:'',disabled:false,onClick:()=>{}}
  const doNothing = (name) => {
    console.log("you click " + name)
  }

  const renderOptionButton = () => {
    const x = []
    let i = 0
    for (const key in options) {
      i++
      x.push(
        <React.Fragment key={i}>
          <Button
            text={options[key].text}
            type={options[key].type || "normal"}
            sizeClass="small"
            icon={options[key].icon}
            disabled={options[key].disabled}
            isLoading={options[key].isLoading}
            onClick={() => {
              if (options[key].onClick) {
                options[key].onClick()
              } else {
                doNothing(key)
              }
            }}
          />{" "}
        </React.Fragment>
      )
    }
    return x
  }
  return (
    <div className={className != undefined ? className : "searching-form searching-form-right-tool text-right"}>
      <div className="form-group form-group-button">
        {renderOptionButton()}
        {children}
      </div>
    </div>
  )
}

// export default { ToolPanel }
