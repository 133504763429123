import React, { useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { useSelector } from "react-redux"
import { createTestItem, updateTestItem } from "common/apis/ezreport"

export const TestItemFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //初始化数据
  const initData = {
    name: "",
    testType: "",
    catalogId: "",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: "试验项名称",
      fieldName: "name",
      maxLength: 128,
    },
    {
      type: "dropdown",
      label: "维保类别",
      fieldName: "testType",
      options: [
        {
          value: "1",
          label: "维保类别Ⅱ",
        },
        {
          value: "2",
          label: "维保类别Ⅰ",
        },
        {
          value: "3",
          label: "维保类别Ⅲ",
        },
      ],
    },
    {
      type: "checkbox",
      dataType: "text",
      label: "系统试验",
      fieldName: "systemTest",
      maxLength: 128,
    },
  ]

  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
    testType: [CommonRule.Require],
    
  }

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      testType: inputdata.testType,
      projectType:'5',
      systemTest:inputdata.systemTest,
      catalogId: inputdata.catalogId,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      testType: inputdata.testType,
      projectType:'5',
      catalogId: inputdata.catalogId,
      systemTest:inputdata.systemTest,
    }
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title="试验项信息"
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createTestItem}
      updateData={updateTestItem}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
