import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Button, Checkbox } from "@abb/abb-common-ux-react"
import { CommonFormEdit, CommonTree } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { addStation, createMaterial, getStationTree, updateMaterial } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { getCustomerNames } from "slices/maindataSlice"
import SidePanel from "components/SidePanel"
import { NotificationType, showNotification } from "components/Notification/notificationSlice"

export const StationFormEdit = forwardRef(({ id, customerId, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [showSidePanel, setShowSidePanel] = useState(false)
  const [tree, setTree] = useState(null)
  const [selected, setSelected] = useState([])
  const [isSumbiting, setIsSumbiting] = useState(false)
  const dispatch = useDispatch()

  // const [formItem, setFormItem] = useState([]);
  //调用接口获取权限树
  const getTreeGrant = async (cid) => {
    try {
      const response = await axios.post(getStationTree(cid), {})
      if (response.status === 200) {
        if (response.data) {
          setTree(response.data)
        }
      } else {
        console.log("error to get tree grant")
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    if (customerId) {
      getTreeGrant(customerId)
    }
  }, [customerId])
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
  }))
  const onCheckChange = (newCheckItems) => {
    setSelected(newCheckItems)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    setShowSidePanel(true)
  }
  const saveForm = async () => {
    setIsSumbiting(true)
    try {
      const response = await axios.post(addStation(), {
        projectId: id,
        stationId: selected.filter((i) => i != "0"),
      })
      dispatch(showNotification(NotificationType.Success, t("t_successToAdd")))
      setShowSidePanel(false)
      onSubmit && onSubmit()
    } catch (e) {
      console.log(e)
      dispatch(showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : t("t_failToAdd")))
    } finally {
      setIsSumbiting(false)
    }
  }

  const cancel = () => {
    setShowSidePanel(false)
    onCancel && onCancel()
  }
  const bottomButtons = (
    <div className="form-group-button" style={{ display: "flex", marginTop: "5px" }}>
      <Button
        style={{ marginRight: "5px" }}
        icon="abb/save"
        text={t("b_save")}
        type="primary-blue"
        sizeClass="small"
        onClick={saveForm}
        isLoading={isSumbiting}
      />

      <Button icon="abb/log-out" text={t("b_cancel")} type="normal" sizeClass="small" onClick={cancel} />
    </div>
  )
  //返回页面
  return (
    <SidePanel show={showSidePanel} size={400} onClickBackdrop={() => cancel()}>
      <div className="side-panel-header">
        <div className="side-panel-title">{t("t_titleAddData")}</div>
      </div>
      <div className="side-panel-body">
        <div style={{ height: clientHeight - 280 + "px" }}>
          <CommonTree ref={formEditRef} items={tree} checkItems={selected} onCheckChange={onCheckChange} />
        </div>
        {bottomButtons}
      </div>
    </SidePanel>
  )
})
