import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Dialog } from '@abb/abb-common-ux-react'
import '../../../../styles/totalReport.scss'

import { TotalReport } from '../components/TotalReport'
export const ShowTotalReport = forwardRef(({}, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [report, setReport] = useState(null)

  const showDialog = (report) => {
    setReport(report)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))

  return (
    <Dialog
      style={{ width: '1160px', height: '700px' }}
      showCloseButton={true}
      closeOnLostFocus={false}
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title={report?.name}
      onClose={() => setIsVisible(false)}
    >
      <TotalReport report={report} readonly={true} />
    </Dialog>
  )
})
