import { createSlice } from "@reduxjs/toolkit";
const localeSlice = createSlice({
  name: "locale",
  initialState: {
    lang: 'zh'
  },
  reducers: {
    setLanguage(state, action) {
      state.lang = action.payload;
    },
  },
});

export const { setLanguage } = localeSlice.actions;
export const getLanguage = state => state.locale.lang
export default localeSlice.reducer;
