import { useParam } from 'hooks/useParam'
import React, { useEffect } from 'react'
import { TotalReport } from './components/TotalReport'
import { getReportView } from 'common/apis/ezreport'
import axios from 'axios'
import { useState } from 'react'
import { Button } from '@abb/abb-common-ux-react'
import { useSelector } from 'react-redux'
import { getUserProfile } from 'slices/userProfileSlice'
import { getPermissions } from 'slices/abpApplicationConfigurationSlice'
import { CommonPrompt, CommonPromptButtons } from 'components/LoongInCommon/CommonPrompt'
import { CommonConfirm } from 'components/LoongInCommon'
import { t } from 'i18next'
import { getEnv } from 'slices/envSlice'
import { downloadFile } from 'common/apis/maindata'
import { useRef } from 'react'
import { approveTotalReport, generateTotalReport, updateTotalReport } from 'common/apis/totalreports'
export default function TotalReportView({ detail, onRefresh }) {
  const id = useParam('id')
  const env = useSelector(getEnv)
  const [isLoading, setIsLoading] = useState(false)
  const reportView = useRef(null)

  const userProfile = useSelector(getUserProfile)
  const permissions = useSelector(getPermissions)
  const [totalReportApprovl, setTotalReportApprovl] = useState(null)
  const [totalReportGenerate, setTotalReportGenerate] = useState(null)
  const [totalReportBack, setTotalReportBack] = useState(null)
  const [isTotalReportLoading, setIsTotalReportLoading] = useState(false)
  const [isTotalGenerating, setIsTotalGenerating] = useState(false)
  const [isTotalBacking, setIsTotalBacking] = useState(false)
  const onTotalReportApprovl = (value, result) => {
    if (result === CommonPromptButtons.Yes || result === CommonPromptButtons.No) {
      setIsTotalReportLoading(true)
      axios.post(approveTotalReport(totalReportApprovl.id), { status: result === CommonPromptButtons.Yes ? 2 : -1, suggestion: value }).then(() => {
        setIsTotalReportLoading(false)
        setTotalReportApprovl(null)
        setTimeout(() => onRefresh && onRefresh(), 500)
      })
    } else {
      setTotalReportApprovl(null)
    }
  }

  const onTotalReportBack = (value, result) => {
    if (result === CommonPromptButtons.OK) {
      setIsTotalBacking(true)
      axios.post(approveTotalReport(totalReportBack.id), { suggestion: value, status: -2 }).then((r) => {
        setIsTotalBacking(false)
        setTotalReportBack(null)
        setTimeout(() => onRefresh && onRefresh(), 500)
      })
    } else {
      setTotalReportBack(null)
    }
  }

  const onTotalReportGenerate = () => {
    setIsTotalGenerating(true)
    axios.post(generateTotalReport(totalReportGenerate.id), {}).then(() => {
      setIsTotalGenerating(false)
      setTotalReportGenerate(null)
      setTimeout(() => onRefresh && onRefresh(), 500)
    })
  }

  const save = (subbmit) => {
    const report = reportView.current.getReport()
    setIsLoading(true)
    axios.post(updateTotalReport(report?.id), { ...report, submit: subbmit }).then((r) => {
      setIsLoading(false)
      onRefresh && onRefresh()
    })
  }
  const hasPermission = (permission) => {
    return !permissions || !permission || (permission in permissions && permissions[permission])
  }
  return (
    <div style={{ width: '100%', overflow: 'visible', display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
      {detail && detail.type === 'TotalReport' && (
        <>
          <TotalReport
            ref={reportView}
            report={detail}
            readonly={detail.status <= 0 && detail.manager === userProfile.id && hasPermission('EzReport.TotalReport.Update') ? false : true}
          />
          <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: 8 }}>
            {detail.status <= 0 && detail.manager === userProfile.id  && hasPermission('EzReport.TotalReport.Update') && (
              <>
                <Button
                  text='提交'
                  isLoading={isLoading}
                  onClick={() => {
                    save(true)
                  }}
                  sizeClass='small'
                  type='primary-blue'
                  style={{ margin: 8 }}
                />
                <Button
                  text='保存'
                  isLoading={isLoading}
                  onClick={() => {
                    save(false)
                  }}
                  sizeClass='small'
                  type='primary-black'
                  style={{ margin: 8 }}
                />
              </>
            )}
            {detail.status > 0 && detail.manager === userProfile.id  && hasPermission('EzReport.TotalReport.Update') && (
              <Button
                text='退回'
                onClick={() => {
                  setTotalReportBack(detail)
                }}
                sizeClass='small'
                type='primary-red'
                style={{ margin: 8 }}
              />
            )}
            {detail.status === 1 && detail.inScope && hasPermission('EzReport.TotalReport.Approve') && (
              <Button
                text='审核'
                onClick={() => {
                  setTotalReportApprovl(detail)
                }}
                sizeClass='small'
                type='primary-blue'
                style={{ margin: 8 }}
              />
            )}
            {detail.status === 2 && detail.manager === userProfile.id && hasPermission('EzReport.TotalReport.Generate') && (
              <Button
                text='生成'
                onClick={() => {
                  setTotalReportGenerate(detail)
                }}
                sizeClass='small'
                type='primary-blue'
                style={{ margin: 8 }}
              />
            )}
            {detail.status === 4 && detail.file && (
              <Button
                text='下载'
                onClick={() => {
                  window.open(`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadFile()}?id=${detail.file}`, '_blank')
                }}
                sizeClass='small'
                type='primary-black'
                style={{ margin: 8 }}
              />
            )}
          </div>
        </>
      )}
      <CommonPrompt
        title={'审核总体报告'}
        content={'请输入您的审核意见'}
        isVisible={totalReportApprovl != null}
        yesText={'批准'}
        noText={'拒绝'}
        buttons={CommonPromptButtons.Yes | CommonPromptButtons.No | CommonPromptButtons.Cancel}
        onClose={onTotalReportApprovl}
        onCancel={() => setTotalReportApprovl(null)}
        isLoading={isTotalReportLoading}
      />
      <CommonConfirm
        title={'生成报告'}
        content={'是否确定要生成总体报告？'}
        isVisible={totalReportGenerate != null}
        onConfirm={onTotalReportGenerate}
        confirmText={t('b_confirm')}
        onCancel={() => setTotalReportGenerate(null)}
        isLoading={isTotalGenerating}
      />
      <CommonPrompt
        title={'退回报告'}
        content={'请输入您的退回意见'}
        isVisible={totalReportBack != null}
        onClose={onTotalReportBack}
        confirmText={t('b_confirm')}
        buttons={CommonPromptButtons.OK | CommonPromptButtons.Cancel}
        isLoading={isTotalBacking}
      />
    </div>
  )
}
