import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { createDevice, updateDevice } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { fetchDeviceTypesAsync, getDeviceTypes } from 'slices/maindataSlice'
import { fetchFactoriesAsync, getFactories } from 'slices/factorySlice'
import { findTree, treeMap } from 'common/utils'
export const DeviceFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const deviceTypes = useSelector(getDeviceTypes)
  const factories = useSelector(getFactories)

  // const [formItem, setFormItem] = useState([]);
  useEffect(() => {
    if (!deviceTypes) {
      dispatch(fetchDeviceTypesAsync())
    }
  }, [deviceTypes])
  useEffect(() => {
    if (!factories) {
      dispatch(fetchFactoriesAsync())
    }
  }, [factories])

  //初始化数据
  const initData = {
    id: '',
    typeId: '',
    stationId: '',
    stationName: '',
    customerId: '',
    parentId: '',
    parentName: '',
    displayName: '',
    modal: '',
    sn: '',
    orderNumber: '',
    factoryId: '',
    productDate: '',
    pannelNo: '',
    pannelName: '',
    busNo: '',
    description: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: '变电站',
      fieldName: 'stationName',
      visible: !formData || !!formData.stationId,
      disabled: true,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '上级设备',
      fieldName: 'parentName',
      visible: !formData || !!formData.parentId,
      disabled: true,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('device.sn'),
      fieldName: 'sn',
      maxLength: 100,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('device.pannelNo'),
      fieldName: 'pannelNo',
      maxLength: 100,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('device.pannelName'),
      fieldName: 'pannelName',
      maxLength: 100,
    },

    {
      type: 'text',
      dataType: 'text',
      label: t('device.displayName'),
      fieldName: 'displayName',
      maxLength: 100,
    },
    // {
    //   type: "text",
    //   dataType: "text",
    //   label: t("device.modal"),
    //   fieldName: "modal",
    //   maxLength: 100,
    // },
    {
      type: 'dropdown',
      label: t('device.type'),
      fieldName: 'typeId',
      options: deviceTypes
        ? treeMap(deviceTypes, (c) => {
            return { disabled: c.children && c.children.length > 0, label: c.title, value: c.key }
          })
        : [],
      disabled: id,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('device.orderNumber'),
      fieldName: 'orderNumber',
      maxLength: 100,
    },
    {
      type: 'dropdown',
      label: t('device.factory'),
      fieldName: 'factoryId',
      options:
        factories?.map((c) => {
          return { label: c.name, value: c.id }
        }) ?? [],
    },
    {
      type: 'date',
      label: t('device.productDate'),
      fieldName: 'productDate',
      dateFormat: 'yyyy-MM-dd',
      showMonthYearPicker: true,
      showTwoColumnMonthYearPicker: true,
      showClearIcon: true,
    },

    {
      type: 'text',
      dataType: 'text',
      label: t('device.busNo'),
      fieldName: 'busNo',
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: t('device.description'),
      fieldName: 'description',
      maxLength: 1000,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      typeId: [CommonRule.Require],
      busNo: [CommonRule.Require],
      displayName: [CommonRule.Require],
      modal: [CommonRule.Require],
      pannelNo: [CommonRule.Require],
      pannelName: [CommonRule.Require],
      orderNumber: [CommonRule.Require],
      sn: [CommonRule.Require],
      productDate: [CommonRule.Require],
      factoryId: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])

  const onChange = (data, preData) => {
    if (!data.displayName || data.displayName === preData.pannelName) {
      data.displayName = data.pannelName
    }
  }
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    console.log(inputdata)
    return {
      id: inputdata.key,
      customerId: inputdata.customerId,
      displayName: inputdata.displayName,
      modal: inputdata.modal,
      sn: inputdata.sn,
      orderNumber: inputdata.orderNumber,
      factoryId: inputdata.factoryId,
      productDate: inputdata.productDate,
      pannelNo: inputdata.pannelNo,
      pannelName: inputdata.pannelName,
      busNo: inputdata.busNo,
      description: inputdata.description,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      typeId: inputdata.typeId,
      stationId: inputdata.stationId,
      customerId: inputdata.customerId,
      parentId: inputdata.parentId,
      displayName: inputdata.displayName,
      modal: inputdata.modal,
      sn: inputdata.sn,
      orderNumber: inputdata.orderNumber,
      factoryId: inputdata.factoryId,
      productDate: inputdata.productDate,
      pannelNo: inputdata.pannelNo,
      pannelName: inputdata.pannelName,
      busNo: inputdata.busNo,
      description: inputdata.description,
    }
  }

  const onChangeBefore = (data) => {
    data.modal = findTree(deviceTypes, (d) => d.key == data.typeId)?.title
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('device.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createDevice}
      updateData={updateDevice}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      onChangeBefore={onChangeBefore}
      onChange={onChange}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
