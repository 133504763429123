import React, { useState, useEffect, useRef } from "react"
// import { WithTooltip, Tooltip, Icon, Button } from "@abb/abb-common-ux-react"
import { Button } from "@abb/abb-common-ux-react"
// import { getUserProfile } from "slices/userProfileSlice"
import { getOrganizationUsers, deleteUserFromOrganization, deleteOrganization } from "common/endpoints"
// import { useSelector } from "react-redux"
import axios from "axios"
import { CommonConfirm, CommonIndex } from "components/LoongInCommon"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"
import { FormEdit } from "./FormEdit"
import { LeftPanel } from "./LeftPanel"
import { SearchUser } from "./SearchUser"

import moment from "moment"

import { useDispatch } from "react-redux"
import { t } from "i18next"

export default () => {
  // const [isOperating, setIsOperating] = useState(false)
  const [formData, setFormData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState("")
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const formOrganRef = useRef(null)
  const refIndex = useRef(null)
  const organRef = useRef(null)
  const searchUserRef = useRef(null)
  const [formEvent, setFormEvent] = useState(null)

  const [searchData, setSearchData] = useState(null)
  //列定义
  const columns = [
    {
      title: "user.userName",
      key: "userName",
    },
    // {
    //   title: "user.name",
    //   key: "name",
    // },
    {
      title: "user.email",
      key: "email",
    },
    // {
    //   title: "user.phoneNumber",
    //   key: "phoneNumber",
    //   width: 120,
    // },
    // {
    //   title: "user.isActive",
    //   key: "isActive",
    //   width: 120,
    //   render: (item, index) => {
    //     if (item.isActive)
    //       return <span className="abb-tag abb-tag-green">{t("t_yes")}</span>
    //     else return <span className="abb-tag abb-tag-red">{t("t_no")}</span>
    //   },
    // },
    // {
    //   title: "user.creationTime",
    //   key: "creationTime",
    //   width: 145,
    //   render: (item, index) => {
    //     return (
    //       <span>{moment(item.creationTime).format("YYYY-MM-DD HH:mm")}</span>
    //     )
    //   },
    // },
  ]

  const toolChildren = (
    <>
      <Button
        text={t("b_addOrgRoot")}
        type="normal"
        sizeClass="small"
        icon={"abb/plus"}
        // isLoading={isOperating}
        onClick={() => {
          setFormData({
            displayName: "",
          })
          setShowOrganSidePanel(true)
        }}
      />
      <span> </span>
      <Button
        text={t("b_addUser")}
        type="primary-blue"
        sizeClass="small"
        icon={"abb/plus"}
        disabled={!activeItem}
        // isLoading={isOperating}
        onClick={() => {
          if (activeItem) searchUserRef.current.showDialog(activeItem.key)
        }}
      />
    </>
  )
  const setShowOrganSidePanel = (visible) => {
    setFormEvent({
      name: "setShowSidePanel",
      params: [visible],
    })
    // formOrganRef.current.setShowSidePanel(visible)
  }
  const onSubmit = (d) => {
    if (activDataIndex) {
      setActivDataIndex("")
      setFormData(null)
      organRef.current.getOrganizationTree()
    } else {
      organRef.current.getOrganizationTree()
    }
  }
  const leftPanel = (
    <LeftPanel
      ref={organRef}
      onActive={(item) => {
        setActiveItem(item)
        setSearchData({ organizationUnitId: item.key })
        // refIndex.current.query()
      }}
      onAdd={(item) => {
        setFormData({
          parentId: item.key,
          parentDisplayName: item.title,
          displayName: "",
        })
        setActivDataIndex("")
        setShowOrganSidePanel(true)
      }}
      onEdit={(item) => {
        setFormData({
          id: item.key,
          parentDisplayName: "",
          displayName: item.title,
        })
        setActivDataIndex(item.key)
        setShowOrganSidePanel(true)
      }}
      onDel={(item) => {
        setDeleteId(item.key)
      }}
    ></LeftPanel>
  )
  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const response = await axios.post(deleteOrganization(), { id: deleteId })
      if (response.status === 200 || response.status === 204) {
        setActivDataIndex("")
        setFormData(null)
        setDeleteId(null)
        organRef.current.getOrganizationTree()
        dispatch(showNotification(NotificationType.Success, t("t_hasDelete")))
      } else {
        dispatch(showNotification(NotificationType.Warn, t("t_deleteFail")))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t("t_deleteFail")))
    } finally {
      setIsDeleting(false)
    }
  }
  const getDeleteInput = (item) => {
    return { userId: item.id, organizationUnitId: activeItem.key }
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getOrganizationUsers}
        searchData={searchData}
        deleteData={deleteUserFromOrganization}
        getDeleteInput={getDeleteInput}
        toolChildren={toolChildren}
        canDelete
        refreshAfterDelete={true}
        enableSorting={false}
        leftPanelWidth={400}
        leftPanel={leftPanel}
        refreshWhenOpen={false}
        canSelection={true} //是否开启选择功能
        canBatchDel={true}
        toolWidth={350}
      />
      <FormEdit
        ref={formOrganRef}
        formData={formData}
        onCancel={() => {
          setActivDataIndex("")
          setFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
        event={formEvent}
      />
      <CommonConfirm
        title={t("t_titleDeleteData")}
        content={t("t_confirmDeleteData")}
        isVisible={deleteId != null}
        onConfirm={onDeleteConfirm}
        confirmText={t("b_confirm")}
        onCancel={() => setDeleteId(null)}
        isLoading={isDeleting}
      />
      <SearchUser ref={searchUserRef} onRefresh={() => setTimeout(() => refIndex.current.query(), 500)} />
    </>
  )
}
