import React, { useRef, useImperativeHandle, forwardRef, useState } from 'react'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { changePassword, createOrganization, login, updateOrganization } from 'common/endpoints'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { useSelector } from 'react-redux'

export const ChangePassword = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  
  //初始化数据
  const initData = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'password',
      label: '当前密码',
      fieldName: 'currentPassword',
      maxLength: 128,
    },
    {
      type: 'text',
      dataType: 'password',
      label: '新密码',
      fieldName: 'newPassword',
      maxLength: 128,
    },
    {
      type: 'text',
      dataType: 'password',
      label: '重复输入新密码',
      fieldName: 'confirmPassword',
      maxLength: 128,
    },
  ]

  //表单的校验规则
  const rules = {
    currentPassword: [CommonRule.Require, CommonRule.Password],
    newPassword: [CommonRule.Require, CommonRule.Password],
    confirmPassword: [CommonRule.Require, CommonRule.Password],
  }

  const otherValidate = (formData) => {
    if (formData.newPassword && formData.newPassword != formData.confirmPassword) {
      return t('user.twicePwdNotSame')
    }
    return ''
  }

  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      currentPassword: inputdata.currentPassword,
      newPassword: inputdata.newPassword,
    }
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      panelTitle='修改密码'
      title='修改密码'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={changePassword}
      getCreateInput={getCreateInput}
      id={id}
      type='dialog'
      formData={formData}
      otherValidate={otherValidate}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={260}
      event={event}
    />
  )
})
