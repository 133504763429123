import React, { useState, useRef } from "react"
import { WithTooltip, Tooltip } from "@abb/abb-common-ux-react"
import { getApplicationgroupPage, deleteApplicationgroup } from "common/apis/maindata"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
export default function Applicationgroups() {
    const [deletedItems, setDeletedItems] = useState([])
    const refIndex = useRef(null)
    const columns = [{
        title: "applicationgroup.name",
        key: "name",
        //通过渲染的方式将内容渲染出来
        render: (item, index) => {
            const isDeleted = deletedItems.indexOf(item.id) >= 0
            if (isDeleted)
                return (
                    <WithTooltip>
                        <del>{item.name}</del>
                        <Tooltip>
                            <div>应用分组已被删除</div>
                        </Tooltip>
                    </WithTooltip>
                )
            else return item.name
        },
    },    {
        title: "applicationgroup.order",
        key: "order",
      },
      {
        title: "applicationgroup.description",
        key: "description",
      },
  ]
    const onDeleteData = (deleteId) => {
        setDeletedItems([...deletedItems, deleteId])
    }
    return (
        <>
            <CommonIndex
                ref={refIndex}
                columns={columns}
                getList={getApplicationgroupPage}
                deleteData={deleteApplicationgroup}
                FormEdit={FormEdit}
                canAdd
                canEdit
                canDelete
                onDeleteData={onDeleteData}
                enableSorting={true}
            />
        </>)
}