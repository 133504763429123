import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Checkbox } from "@abb/abb-common-ux-react";
import { CommonFormEdit } from "components/LoongInCommon";
import { CommonRule } from "components/LoongInCommon";
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createMaterialMetaData, updateMaterialMetaData } from "common/apis/maindata";
import { t } from "i18next";
import { getClientHeight } from "slices/appSlice";
import { getCustomerNames } from "slices/maindataSlice";

export const MetaDataFormEdit = forwardRef(
  ({ id, formData, onSubmit, onCancel,event }, ref) => {
    //引用基础窗体控件
    const formEditRef = useRef(null);
    //获取客户区高度
    const clientHeight = useSelector(getClientHeight);
    const [roles, setRoles] = useState([]);
    const allRoles = useSelector(getAllRoles);
    const [rules, setRules] = useState({});
    // const [formItem, setFormItem] = useState([]);

    //初始化数据
    const initData = {
      name: "",
      code: "",
      dataType: 1,
      materialCatalogId: "",
      materialCatalogName: "",
      description:"",
    };
     //表单数据项
    const formItem=[
        {
          type: "text",
          dataType: "text",
          label: t("materialCatalog.name"),
          fieldName: "materialCatalogName",
          visible: !formData || !!formData.materialCatalogName,
          disabled: true,
        },
        {
          type: "text",
          dataType: "text",
          label: t("metaData.code"),
          fieldName: "code",
          maxLength: 10,
        },
        {
          type: "text",
          dataType: "text",
          label: t("metaData.name"),
          fieldName: "name",
          maxLength: 20,
        },
        {
          type: "dropdown",
          label: t("metaData.dataType"),
          fieldName: "dataType",
          options: [
            { label: "短文本", value: "1" },
            { label: "长文本", value: "2" },
            { label: "数值", value: "3" },
            { label: "是否", value: "4" },
            { label: "枚举", value: "5" },
            { label: "日期", value: "6" },
          ],
          maxLength: 256,
        },
        {
          type: "text",
          dataType: "textarea",
          label: t("metaData.description"),
          fieldName: "description",
          maxLength: 1000,
        },
    ];
    //表单的校验规则
    useEffect(() => {
      const newrules = {
        name: [CommonRule.Require],
        code: [CommonRule.Require],
        dataType: [CommonRule.Require],
      };
      setRules(newrules);
    }, [id]);
    //获取更新时的参数
    const getUpdateInput = (inputdata) => {
      return {
        id: inputdata.id,
        code: inputdata.code,
        name: inputdata.name,
        description: inputdata.description,
        dataType: inputdata.dataType
      };
    };
    //获取添加时的参数
    const getCreateInput = (inputdata) => {
      return {
        catalogId: inputdata.materialCatalogId,
        code: inputdata.code,
        name: inputdata.name,
        description: inputdata.description,
        dataType: inputdata.dataType
      };
    };

    const resetForm = () => {
      return formEditRef.current.resetForm();
    };
    //传递引用方法
    useImperativeHandle(ref, () => ({
      setShowSidePanel,
      showAndSwitchTab,
      resetForm,
    }));
    //显示边栏
    const setShowSidePanel = (visible) => {
      formEditRef.current.setShowSidePanel(visible);
    };
    //显示边栏并切换tab
    const showAndSwitchTab = (tabIndex) => {
      formEditRef.current.showAndSwitchTab(tabIndex);
    };
    //返回页面
    return (
      <CommonFormEdit
        ref={formEditRef}
        title={t("metaData.formTitle")}
        formItem={formItem}
        initData={initData}
        rules={rules}
        createData={createMaterialMetaData}
        updateData={updateMaterialMetaData}
        getUpdateInput={getUpdateInput}
        getCreateInput={getCreateInput}
        id={id}
        formData={formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
        height={clientHeight - 280}
        event={event}
      >
        {/* 其他tab采用div在此添加 */}
      </CommonFormEdit>
    );
  }
);
