import React, { useState, useRef, forwardRef } from 'react'
import { getDataDictionaryPageDetail, deleteDataDictionaryDetail } from 'common/endpoints'
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { getClientWidth } from 'slices/appSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { deleteRight, getRightPage } from 'common/apis/maindata'
export const MainIndex = forwardRef(({ groupId, typeDisplayText }, ref) => {
  const refIndex = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const clientWidth = useSelector(getClientWidth)
  const dispatch = useDispatch()
  //设置查询字段dataDictionaryId
  useEffect(() => {
    if (groupId)
      setSearchData({
        groupId,
      })
    else if (refIndex && refIndex.current) refIndex.current.setList([])
  }, [groupId])
  //点击添加按钮时将dataDictionaryId注入到表单中
  const onAddBefore = () => {
    return {
      groupId,
      typeDisplayText,
    }
  }
  const getDeleteInput = (item) => {
    return {
      groupId: item.groupId,
      id: item.id,
    }
  }
  //列定义
  const columns = [
    {
      title: '权益标题',
      key: 'title',
    },
    {
      title: '权益内容',
      key: 'content',
    },
  ]

  //返回页面
  return (
    <CommonIndex
      className='admin-main-content with-padding no-margin'
      ref={refIndex}
      columns={columns}
      searchData={searchData}
      getList={getRightPage}
      deleteData={deleteRight}
      FormEdit={FormEdit}
      tableWidth={clientWidth - 425}
      canAdd={!!groupId}
      canEdit
      canDelete
      enableSorting={false}
      onAddBefore={onAddBefore}
      getDeleteInput={getDeleteInput}
    />
  )
})
