import React, { useState, useEffect } from 'react'
import "./singleline.scss"
import { SwitchTypes, DeviceTypes, StatusVariables, DeviceModels } from "./Const"
import { getLatestValue } from 'common/endpoints'
import { DropdownButtonOption, DropdownButton } from 'components/DropdownButton';
import { Checkbox, Dropdown, DropdownOption, Input, Icon, WithMessageDialog, MessageDialog, Gauge, LoadingIndicator } from '@abb/abb-common-ux-react'
import axios from 'axios';
import { useMountedState } from 'common/useMountedState'
import { useHistory } from "react-router-dom";



export default function Basic({ device, configMode, showConfig, applySetting, changeProperty, remove, insertRight, equipments, isBinded, showScore }) {

    const [equipment, setEquipment] = useState([])
    const [deviceModel, setDeviceModel] = useState([])
    const [deviceStatus, setDeviceStatus] = useState({})
    const isMounted = useMountedState()
    const options = device?.displayOptions
    const history = useHistory();

    useEffect(() => {

        if (configMode) {

            let dm = DeviceModels.find(m => m.value === device?.deviceModel)
            if (dm) {
                dm = { ...dm, key: dm.value }
                dm && setDeviceModel([dm])
            }
        }

    }, [device,])

    useEffect(() => {

        if (equipments) {
            let seq = equipments?.find(m => m.id === device?.equipmentId)
            if (seq) {
                let eq = { key: seq.id, value: seq.id, label: seq.name }
                setEquipment([eq])
            }
        }

    }, [equipments])



    const unbindDevice = () => {
        if (device.equipmentId) {
            changeProperty(device, [{ key: "equipmentId", val: '' }, { key: "title", val: '' }])
            setEquipment([])
        }
    }
    const bindDevice = (val) => {
        setEquipment(val);
        const eqId = val[0].value; 
        const eq = equipments?.find(m => m.id == eqId)
        if (eq) {
            const props=[{ key: "equipmentId", val: eqId === '0' ? '' : eqId } ];
            if(eq.parentName){
                props.push({ key: "title", val: eq.parentName })
                props.push({ key: "titleBottom", val: eq.name })
            }else{
                props.push({ key: "title", val: eq.name })
            } 
            
            changeProperty(device, props)

        }
    }

    if (!device) return (<></>)
    const boxName = device?.type?.replace('full', 'Full')
    const Box = React.lazy(() => import("./box/" + boxName))
    return (
        <>
            <div className={(configMode && showConfig) ? 'single-line-basic config-mode' : 'single-line-basic'} onClick={() => !configMode && device.equipmentId && history.push(`/device/${device.equipmentId}`)} >

                <div className='single-line-title'>

                    {(!configMode || !showConfig) && <div className='text' title={device.title}>{device?.title}</div>}
                    {(configMode && showConfig) &&
                        <>
                            <div className='single-line-title-input'>
                                <Dropdown value={equipment}
                                    onChange={(val) => bindDevice(val)}
                                    style={{ width: 170, }}>

                                    {equipments && equipments.map(m => (
                                        <DropdownOption key={m.id} value={m.id} label={m.name} disabled={isBinded(m.id)}
                                            menuItemTemplate={
                                                <div style={{ textAlign: "start" }}>
                                                    {m.child && <span>&nbsp;&nbsp;&nbsp;&nbsp;|---</span>}
                                                    {m.name}
                                                </div>} />
                                    ))}

                                </Dropdown>
                                <DropdownButton>
                                    {DeviceTypes.map((item) =>
                                        <DropdownButtonOption key={item.value} onClick={() => insertRight(device, item)} ><Icon name="abb/plus" /> {item.label}</DropdownButtonOption>
                                    )}

                                    <DropdownButtonOption key="__del" onClick={() => remove(device)}><Icon name="abb/close" /> 删除</DropdownButtonOption>

                                    {(device.equipmentId) &&
                                        <DropdownButtonOption key="__unbind" onClick={unbindDevice} ><Icon name="abb/lock-open" /> 解绑设备</DropdownButtonOption>}
                                </DropdownButton>
                            </div>
                            <div className='single-line-title-input'>
                                <Input placeholder="input title" style={{ width: 200, }} value={device.title} onValueChange={(val) => changeProperty(device, [{ key: "title", val: val }])}  ></Input>

                            </div>
                        </>
                    }
                </div>
                <div className='single-line-content'>
                    <React.Suspense fallback={<LoadingIndicator type="bar" sizeClass="small" determinate={false} color='blue' />}>
                        <Box device={device} deviceStatus={deviceStatus} applySetting={applySetting} configMode={configMode} showConfig={showConfig} />

                    </React.Suspense>
                </div>
                <div className='single-line-title'>

                    {(!configMode || !showConfig) && <div className='text' title={device.titleBottom}>{device.titleBottom}</div>}
                    {(configMode && showConfig) &&
                        <>
                            <div className='single-line-title-input'>
                                <Input placeholder="title" value={device.titleBottom} onValueChange={(val) => changeProperty(device, [{ key: "titleBottom", val: val }])} style={{ width: "100%" }}></Input>

                            </div>


                        </>
                    }
                </div>
            </div>


        </>
    )
}

