import { configureStore } from "@reduxjs/toolkit";

import notificationSlice from "components/Notification/notificationSlice";
import localeSlice from "components/Locale/localeSlice";
const modulesFiles = require.context('./slices', true, /.js$/)
const slices = modulesFiles.keys().reduce((slices, modulePath) => {
  const moduleName = modulePath.replace(/^.\/(.*)Slice\.js/,'$1')
  const value = modulesFiles(modulePath)
  slices[moduleName] = value.default
  return slices
}, {})
export default configureStore({
  reducer: {
    notifications: notificationSlice,
    locale: localeSlice,
    ...slices,
  },
});
