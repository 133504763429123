import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Checkbox } from "@abb/abb-common-ux-react";
import { CommonFormEdit } from "components/LoongInCommon";
import { CommonRule } from "components/LoongInCommon";
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createInstrument, updateInstrument } from "common/apis/maindata";
import { t } from "i18next";
import { getClientHeight } from "slices/appSlice";
import { getDictionary } from "slices/dataDictionarySlice";
import { getDataDictionaryDetail } from "common/dataDictionary";
import { getUsers } from "slices/userSlice";
import { fetchGetOrganizationAsync, getOrganization } from "slices/organizationSlice";
import { treeMap } from "common/utils";

export const FormEdit = forwardRef(
  ({ id, formData, onSubmit, onCancel,event }, ref) => {
    //引用基础窗体控件
    const formEditRef = useRef(null);
    //获取客户区高度
    const clientHeight = useSelector(getClientHeight);
    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);
    const allRoles = useSelector(getAllRoles);
    const [rules, setRules] = useState({});
    const dictionary = useSelector(getDictionary);
    const dt = getDataDictionaryDetail(dictionary, "InstrStatus");
    const users = useSelector(getUsers);
    const [formItem, setFormItem] = useState([]);
    //初始化数据
    const organization = useSelector(getOrganization)

    useEffect(() => {
      if (!organization) {
        dispatch(fetchGetOrganizationAsync())
      }
    }, [organization])
      const initData = {
      name: "",
      assetCode: "",
      modal: "",
      purchaseDate: "",
      factory: "",
      description: "",
      validity: 0,
      unitId:'',
      lastCheck: "",
      status: "",
      manager: "",
    };
    //表单数据项
    const changeFormItem = () => {
      setFormItem([
        {
          type: "text",
          dataType: "text",
          label: t("instrument.name"),
          fieldName: "name",
          maxLength: 100,
        },
        {
          type: "text",
          dataType: "text",
          label: t("instrument.assetCode"),
          fieldName: "assetCode",
          maxLength: 50,
        },
        {
          type: "text",
          dataType: "text",
          label: t("instrument.modal"),
          fieldName: "modal",
          maxLength: 60,
        },
        {
          type: "dropdown",
          label: '所在服务中心',
          fieldName: "unitId",
          searchable:true,
          options: organization
            ? treeMap(organization, (c) => {
                return { label: c.title, value: c.key }
              })
            : [],
            searchable:true,
          },{
          type: "date",
          label: t("instrument.purchaseDate"),
          fieldName: "purchaseDate",
          dateFormat: "yyyy-MM-dd",
          showClearIcon: true,
        },
        {
          type: "text",
          dataType: "text",
          label: t("instrument.factory"),
          fieldName: "factory",
          maxLength: 256,
        },
        {
          type: "text",
          dataType: "text",
          label: t("instrument.validity"),
          fieldName: "validity",
          maxLength: 256,
        },
        {
          type: "date",
          label: t("instrument.lastCheck"),
          fieldName: "lastCheck",
          dateFormat: "yyyy-MM-dd",
          showClearIcon: true,
        },
        {
          type: "dropdown",
          label: t("instrument.status"),
          fieldName: "status",
          options: dt
            ? dt.details.map((c) => {
                return { label: c.displayText, value: c.code };
              })
            : [],
          },
        {
          type: "dropdown",
          label: t("instrument.manager"),
          fieldName: "manager",
          searchable:true,
          options: users
            ? users.map((c) => {
                return { label: c.name, value: c.id };
              })
            : [],
        },
        {
          type: "text",
          dataType: "textarea",
          label: t("instrument.description"),
          fieldName: "description",
          maxLength: 256,
        },
      ]);
    };
    useEffect(() => {
      changeFormItem();
    }, [dictionary, users]);

    //表单的校验规则
    useEffect(() => {
      const newrules = {
        name: [CommonRule.Require],
        assetCode: [CommonRule.Require],
        modal: [CommonRule.Require],
        purchaseDate: [CommonRule.Require],
        factory: [CommonRule.Require],
        validity: [CommonRule.Require],
        unitId: [CommonRule.Require],
        lastCheck: [CommonRule.Require],
        status: [CommonRule.Require],
        manager: [CommonRule.Require],
      };
      setRules(newrules);
    }, [id]);
    //获取更新时的参数
    const getUpdateInput = (inputdata) => {
      return {
        id: inputdata.id,
        name: inputdata.name,
        assetCode: inputdata.assetCode,
        modal: inputdata.modal,
        purchaseDate: inputdata.purchaseDate,
        factory: inputdata.factory,
        description: inputdata.description,
        validity: inputdata.validity,
        unitId:inputdata.unitId,
        lastCheck: inputdata.lastCheck,
        status: inputdata.status,
        manager: inputdata.manager,
      };
    };
    //获取添加时的参数
    const getCreateInput = (inputdata) => {
      return {
        name: inputdata.name,
        assetCode: inputdata.assetCode,
        modal: inputdata.modal,
        purchaseDate: inputdata.purchaseDate,
        factory: inputdata.factory,
        unitId:inputdata.unitId,
        description: inputdata.description,
        validity: inputdata.validity,
        lastCheck: inputdata.lastCheck,
        status: inputdata.status,
        manager: inputdata.manager,
      };
    };

    const resetForm = () => {
      return formEditRef.current.resetForm();
    };
    //传递引用方法
    useImperativeHandle(ref, () => ({
      setShowSidePanel,
      showAndSwitchTab,
      resetForm,
    }));
    //显示边栏
    const setShowSidePanel = (visible) => {
      formEditRef.current.setShowSidePanel(visible);
    };
    //显示边栏并切换tab
    const showAndSwitchTab = (tabIndex) => {
      formEditRef.current.showAndSwitchTab(tabIndex);
    };
    //返回页面
    return (
      <CommonFormEdit
        ref={formEditRef}
        title={t("instrument.formTitle")}
        formItem={formItem}
        initData={initData}
        rules={rules}
        createData={createInstrument}
        updateData={updateInstrument}
        getUpdateInput={getUpdateInput}
        getCreateInput={getCreateInput}
        id={id}
        formData={formData}
        onSubmit={onSubmit}
        onCancel={onCancel}
        height={clientHeight - 280}
        event={event}
      >
        {/* 其他tab采用div在此添加 */}
      </CommonFormEdit>
    );
  }
);
