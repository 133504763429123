import React, { useState, useRef } from "react"
import { getAuditLogs } from "common/endpoints"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import { t } from "i18next"
import moment from "moment/moment"

export default () => {
  const refIndex = useRef(null)
  const [searchData, setSearchData] = useState({})
  //列定义
  const columns = [
    {
      title: "auditLogs.url",
      key: "url",
      render: (item) => {
        return (
          <div>
            <span className={"abb-tag " + (item.httpStatusCode == 200 || item.httpStatusCode == 204 ? "abb-tag-green" : "abb-tag-red")}>
              {item.httpStatusCode}
            </span>
            <span className="abb-tag abb-tag-blue">{item.httpMethod}</span>
            <span>{item.url}</span>
          </div>
        )
      },
    },
    {
      title: "auditLogs.userName",
      key: "userName",
    },
    {
      title: "auditLogs.executionTime",
      key: "executionTime",
      render: (item) => {
        return moment(item.executionTime).format("yyyy-MM-DD HH:mm:ss")
      },
    },
    {
      title: "auditLogs.executionDuration",
      key: "executionDuration",
    },
    {
      title: "auditLogs.exceptions",
      key: "exceptions",
      wrap: true,
    },
  ]
  const onSearchDataChange = (newData, oldData) => {
    setSearchData(newData)
  }
  const optionChoice = [
    { value: "true", label: t("t_yes") },
    { value: "false", label: t("t_no") },
  ]
  const searchItem = [
    {
      type: "text",
      dataType: "text",
      label: t("auditLogs.userName"),
      fieldName: "userName",
      showClearIcon: true,
    },
    {
      type: "date",
      label: t("t_dateBeg"),
      fieldName: "startTime",
      maxDate: searchData.endTime,
      dateFormat: "yyyy-MM-dd",
      showClearIcon: true,
    },
    {
      type: "date",
      label: t("t_dateEnd"),
      fieldName: "endTime",
      minDate: searchData.startTime,
      dateFormat: "yyyy-MM-dd",
      showClearIcon: true,
    },
    {
      type: "dropdown",
      label: t("auditLogs.hasException"),
      fieldName: "hasException",
      showClearIcon: true,
      options: optionChoice,
    },
  ]
  const getGetListInput = (params) => {
    let x = { ...params }
    if (x.endTime) x.endTime = moment(x.endTime).add(1, "days").format("YYYY-MM-DD")
    return x
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        searchItem={searchItem}
        searchData={searchData}
        onSearchDataChange={onSearchDataChange}
        getList={getAuditLogs}
        getGetListInput={getGetListInput}
        FormEdit={FormEdit}
        enableSorting={false}
        enableQueryOnSearchDataChange={false}
      />
    </>
  )
}
