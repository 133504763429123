import React, { useState, useEffect, useRef } from "react"
// import { WithTooltip, Tooltip, Icon, Button } from "@abb/abb-common-ux-react"
import { Button } from "@abb/abb-common-ux-react"
// import { getUserProfile } from "slices/userProfileSlice"
import {
  
  deleteMaterialCatalog,
  deleteMaterialMetaData,
  getMaterialMetaDatas
  
} from "common/apis/maindata"
// import { useSelector } from "react-redux"
import axios from "axios"
import { CommonConfirm } from "components/LoongInCommon"
import {
  showNotification,
  NotificationType,
} from "components/Notification/notificationSlice"
import { CommonIndex } from "components/LoongInCommon"
import { MaterialCatalogFormEdit } from "./MaterialCatalogFormEdit"
import { MetaDataFormEdit } from "./MetaDataFormEdit"
import { LeftPanel } from "./LeftPanel"

import moment from "moment"

import { useDispatch } from "react-redux"
import { t } from "i18next"

export default function MaterialCatalogs() {
  // const [isOperating, setIsOperating] = useState(false)
  const [MaterialCatalogFormData, setMaterialCatalogFormData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState("")
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const formDevTypRef = useRef(null)
  const refIndex = useRef(null)
  const devTypRef = useRef(null)

  const [searchData, setSearchData] = useState(null)
  const options=[
    { label: "短文本", value: "1" },
    { label: "长文本", value: "2" },
    { label: "数值", value: "3" },
    { label: "是否", value: "4" },
    { label: "枚举", value: "5" },
    { label: "日期", value: "6" },
  ]
  //列定义
  const columns = [
    {
      title: "metaData.code",
      key: "code",
    },
    {
      title: "metaData.name",
      key: "name",
    },
    {
      title: "metaData.dataType",
      key: "dataType",
      render:(item,index)=>{
        return options.find(o=>o.value==item.dataType)?.label
    }
  },
    {
      title: "metaData.attributes",
      key: "attributes",
    },
  ]
  const onAddBefore = () => {
    console.log(activeItem)
    return {
      materialCatalogId:activeItem.key,
      materialCatalogName:activeItem.title,
    }
  }
  const toolChildren = (
    <>
      <Button
        text={t("b_addMaterialCatalogRoot")}
        type="normal"
        sizeClass="small"
        icon={"abb/plus"}
        // isLoading={isOperating}
        onClick={() => {
          setMaterialCatalogFormData({
            name: "",
          })
          setShowOrganSidePanel(true)
        }}
      />
      
    </>
  )
  const setShowOrganSidePanel = (visible) => {
    formDevTypRef.current.setShowSidePanel(visible)
  }
  const onSubmit = (d) => {
    if (activDataIndex) {
      setActivDataIndex("")
      setMaterialCatalogFormData(null)
      devTypRef.current.getMaterialCatalogsTree()
    } else {
      devTypRef.current.getMaterialCatalogsTree()
    }
  }
  const leftPanel = (
    <LeftPanel
      ref={devTypRef}
      onActive={(item) => {
        setActiveItem(item)
        setSearchData({ catalogId: item.key })
        // refIndex.current.query()
      }}
      onAdd={(item) => {
        setMaterialCatalogFormData({
          parentId: item.key,
          parentName: item.title,
          name: "",
        })
        setActivDataIndex("")
        setShowOrganSidePanel(true)
      }}
      onEdit={(item) => {
        setMaterialCatalogFormData({
          id: item.key,
          parentName: "",
          name: item.title,
        })
        setActivDataIndex(item.key)
        setShowOrganSidePanel(true)
      }}
      onDel={(item) => {
        setDeleteId(item.key)
      }}
    ></LeftPanel>
  )
  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const response = await axios.post(deleteMaterialCatalog(), { id: deleteId })
      if (response.status === 200 || response.status === 204) {
        setActivDataIndex("")
        setMaterialCatalogFormData(null)
        setDeleteId(null)
        devTypRef.current.getMaterialCatalogsTree()
        dispatch(showNotification(NotificationType.Success, t("t_hasDelete")))
      } else {
        dispatch(showNotification(NotificationType.Warn, t("t_deleteFail")))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t("t_deleteFail")))
    } finally {
      setIsDeleting(false)
    }
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getMaterialMetaDatas}
        searchData={searchData}
        deleteData={deleteMaterialMetaData}
        toolChildren={toolChildren}
        FormEdit={MetaDataFormEdit}
        canAdd
        canEdit
        canDelete
        tools={{add:{text:t('b_addMetaData'),disabled:!activeItem}}}
        onAddBefore={onAddBefore}
        refreshAfterDelete={true}
        enableSorting={false}
        leftPanelWidth={400}
        leftPanel={leftPanel}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        searchClassName="searching-form-simple" //查询样式
        toolClassName="searching-form-simple searching-form-right-tool text-right" //工具栏样式
      />
      <MaterialCatalogFormEdit
        ref={formDevTypRef}
        formData={MaterialCatalogFormData}
        onCancel={() => {
          setActivDataIndex("")
          setMaterialCatalogFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
      />
      <CommonConfirm
        title={t("t_titleDeleteData")}
        content={t("t_confirmDeleteData")}
        isVisible={deleteId != null}
        onConfirm={onDeleteConfirm}
        confirmText={t("b_confirm")}
        onCancel={() => setDeleteId(null)}
        isLoading={isDeleting}
      />
      
    </>
  )
}
