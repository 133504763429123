import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"

import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import {Button} from '@abb/abb-common-ux-react'
import { t } from "i18next"
import { getClientWidth ,getClientHeight} from "slices/appSlice"
import SidePanel from "components/SidePanel"
import SingleLine from "components/SingleLine/SingleLine"

export const StationSld = forwardRef(({  id, substation,onSeved }, ref) => {
  
     
    const clientHeight = useSelector(getClientHeight)
    const clientWidth = useSelector(getClientWidth)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    useImperativeHandle(ref, () => ({
        setShowSidePanel,
        
      }))
      //显示边栏
      const setShowSidePanel = (visible) => {
        setShow(visible)
      }
  
    return (
        <SidePanel show={show} size={clientWidth-200} onClickBackdrop={()=>setShow(false)} >
            <div className="side-panel-header" style={{paddingRight:16}}> 
                <div className="side-panel-title">  {substation?.name +" - "}  { t("station.sld") }</div>
                <Button icon="abb/close"   type="normal" sizeClass="small" onClick={()=>setShow(false)} />
            </div>
            <div className="side-panel-body">
                <SingleLine configMode={true} height={clientHeight-230} substation={substation} onSeved={onSeved}/>
            </div>
        </SidePanel>
    )
})
