import axios from 'axios'
import { addDocuments, getDocuments } from 'common/apis/maindata'
import { DocumentList } from 'components/DocumentList'
import React, { useState, useEffect } from 'react'

export default function DeviceDoc({ device }) {
  const [directoryTree, setDirectoryTree] = useState([])
  const [basePath, setBasePath] = useState(null)
  const initDirectories = () => {
    const tree = [
      {
        title: device.displayName,
        key: device.id,
        path: '/',
        children: [
          {
            title: '检验报告',
            key: '检验报告',
            path: `/检验报告`,
            children: [{ title: '出厂报告', key: '出厂报告', path: `/检验报告/出厂报告`, children: [] }],
          },
          { title: '说明书', key: '说明书', path: `/说明书`, children: [] },
          { title: '图纸', key: '图纸', path: `/图纸`, children: [] },
        ],
      },
    ]
    setDirectoryTree(tree)
  }

  const onUploaded = (files) => {
    console.log(files)
    axios.post(addDocuments(device.id), { deviceId: device.id, documentIds: files.map((f) => f.id) }).then((r) => {
      query()
    })
  }
  function query() {
    axios.get(getDocuments(device.id)).then((response) => {
      const data = response.data
      setBasePath(data.path)
    })
  }
  useEffect(() => {
    query()
    initDirectories()
  }, [device])

  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>设备相关文档</div>
        </div>
        <div className='widget-body device-profile-details'>
          <DocumentList
            basePath={basePath}
            customerId={device.customerId}
            directory={directoryTree}
            onUploaded={onUploaded}
            onCreatedDirectory={({ action, item, data }) => {
              console.log(data);
              onUploaded([data])
            }}
            onDeleted={() => {
              query()
            }}
          ></DocumentList>
        </div>
      </div>
    </div>
  )
}
