import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getCustomer } from "slices/ezProjectSlice"
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react"
import { nanoid } from "nanoid"
import { t } from "i18next"
import moment from "moment"
import { getDictionary } from "slices/dataDictionarySlice"
import { getUsers } from "slices/userSlice"
import { getDataDictionaryDetail } from "common/dataDictionary"
import { getOrganization } from "slices/organizationSlice"
import { findTree } from "common/utils"
import { SlidingCard } from "components/SlidingCard"

export default function CustomerInfo({ onEdit }) {
  const customer = useSelector(getCustomer)
  const dictionary = useSelector(getDictionary)
  const organization = useSelector(getOrganization)
  const custCate = getDataDictionaryDetail(dictionary, "custCate")
  const srvArea = getDataDictionaryDetail(dictionary, "srvArea")
  const users = useSelector(getUsers)
  const [pageIndex, setPageIndex] = useState(0)
  const [profiles, setProfiles] = useState(null)
  useEffect(() => {
    if (customer) {
      setProfiles([
        [
          { title: t("customer.code"), value: customer?.code },
          { title: t("customer.name"), value: customer?.name },
          { title: t("customer.category"), value: custCate?.details?.find((d) => d.code == customer?.category)?.displayText },
          { title: t("customer.manager"), value: users?.find((d) => d.id == customer?.manager)?.name },
          { title: t("customer.serviceUnitId"), value: findTree(organization, (d) => d.key == customer.serviceUnitId)?.title },
          { title: t("customer.contact"), value: customer?.contact },
          { title: t("customer.phone"), value: customer?.phone },
          { title: t("customer.email"), value: customer?.email },
          { title: t("customer.area"), value: srvArea?.details?.find((d) => d.code == customer?.area)?.displayText },
          { title: t("customer.address"), value: customer?.address },
        ],
      ])
    }
  }, [customer])

  useEffect(() => {
    window.addEventListener("resize", onWindowResize)
    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [])
  const onWindowResize = () => {
    setPageIndex(0)
  }
  return (
    profiles && (
      <SlidingCard>
        {profiles.map((list) =>
          list.length === 0 ? null : (
            <Table title="客户信息" sizeClass="medium" borderType="discreet" key={nanoid()}>
              <tbody>
                {list.map((item) => (
                  <tr key={nanoid()}>
                    <td title={item.title}>{item.title}</td>
                    <td>
                      {item.value && (
                        <WithTooltip>
                          <span>{item.value}</span>
                          <Tooltip>
                            <div>
                              {item.title}：{item.value}
                            </div>
                          </Tooltip>
                        </WithTooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        )}
      </SlidingCard>
    )
  )
}
