//Customers Common Interface
import React from "react"
import { t } from "i18next"
import moment from "moment"

export const getCustomerNameList = () => `/api/MainData/Customers/names`
export const getApbApplicationConfiguration=()=>`/api/abp/application-configuration`
//数据状态
export const optionChoice = [
  { value: "1", label: t("b_enable") },
  { value: "0", label: t("b_disable") },
]
export const getStatus = (value) => {
  if (value === 1 || value === "1") {
    return <span className="abb-tag abb-tag-green">{t("b_enable")}</span>
  } else {
    return <span className="abb-tag abb-tag-red">{t("b_disable")}</span>
  }
}
export const getYear = (value) => {
  return value ? moment(value).format("yyyy") : ""
}
export const getMonth = (value) => {
  return value ? moment(value).format("yyyy-MM") : ""
}
export const getDate = (value) => {
  return value ? moment(value).format("yyyy-MM-DD") : ""
}
export const getDateTime = (value) => {
  return value ? moment(value).format("yyyy-MM-DD HH:mm:ss") : ""
}