export const EventType = {
  MonitoringEvent: 0,
  GatewayConnect: 1,
  GatewayDisconnect: 2,
  SystemEvent: 3,
  GatewayUpdated: 4,
  SwitchImage: 5,
  Diagnostics: 6,
  Recommandation: 7
}
export const CriticalLevel = {
  Alarm: 0,
  Warning: 1,
  Normal: 2
}
export const OpenOrClose = {
  Open: 4,
  Close: 5
}
export const StatusColor = {
  Yellow: 'rgb(255, 216, 0)',
  Orange: 'rgb(255, 115, 0)',
  OrangeLight: 'rgb(255, 159, 140)',
  Red: 'rgb(240, 48, 64)',
  RedLight: 'rgb(255, 153, 160)',
  Magenta: 'rgb(203, 43, 213)',
  Green: 'rgb(12, 169, 25)',
  Gray: 'rgb(186, 186, 186)',
  GrayLight: 'rgb(219, 219, 219)',
  Blue: 'rgb(51, 102, 255)',
  Purple40: 'rgb(230, 112, 230)',
  Purple50: 'rgb(204, 80, 204)',
  Purple60: 'rgb(161, 63, 161)',
  Teal40: 'rgb(90, 191, 174)',
  Teal50: 'rgb(61, 161, 144)',
  Teal60: 'rgb(59, 120, 110)',
  PrimaryBlue10: 'rgb(224, 240, 255)',
  PrimaryBlue20: 'rgb(197, 224, 250)',
  PrimaryBlue30: 'rgb(158, 191, 247)',
  PrimaryBlue40: 'rgb(119, 163, 252)',
  PrimaryBlue50: 'rgb(76, 133, 255)',
  PrimaryBlue60: 'rgb(51, 102, 255)',
  PrimaryBlue70: 'rgb(42, 53, 255)',
  PrimaryBlue80: 'rgb(44, 32, 212)',
  PrimaryBlue90: 'rgb(22, 12, 156)',
  PrimaryBlue100: 'rgb(8, 2, 97)',
}

export const Theme = {
  Light: 'light',
  Dark: 'dark'
}

export const Language = {
  zhCN: 'zh_cn',
  enUS: 'en_us'
}

export const DiagnosisType = {
  Temperature: "Temperature",
  TemperatureBusbar: "TemperatureBusbar",
  TemperatureContact: "TemperatureContact",
  TemperatureUpContact: "TemperatureUpContact",
  TemperatureDownContact: "TemperatureDownContact",
  TemperatureCable: "TemperatureCable",
  Pressure: "Pressure",
  LinearTravel: 'LinearTravel',
  Coil: 'Coil',
  OpenLinearTravel: "OpenLinearTravel",
  CloseLinearTravel: "CloseLinearTravel",
  CloseCoil: "CloseCoil",
  OpenCoil: "OpenCoil",
  Motor: "Motor",
  GasChamberCB: "GasChamberCB",
  GasChamberBusbar: "GasChamberBusbar",
  GasChamberFront: "GasChamberFront",
  GasChamberRear: "GasChamberRear",
  GasChamberSGIS:'GasChamberSGIS',

  TemperatureGISCable1: "TemperatureGISCable1",
  TemperatureGISCable2: "TemperatureGISCable2",
  TemperatureGISCable3: "TemperatureGISCable3",
  TemperatureGISCable4: "TemperatureGISCable4",
  TemperatureGISBusbar: "TemperatureGISBusbar",
  PosSwitchSingle: "PosSwitchSingle",
  PosSwitchFront: "PosSwitchFront",
  PosSwitchRear3: "PosSwitchRear3",
  PosSwitchRear2: "PosSwitchRear2",

  EarthingSwitch: "EarthingSwitch",
  Truck: "Truck",
  PD: "PD",

}

export const DeviceFunction = {
  Temperature: 'Temperature',
  TemperatureSGIS: 'TemperatureSGIS',
  TemperatureGIS: 'TemperatureGIS',
  Travel: 'Travel',
  Coil: 'Coil',
  Motor: 'Motor',
  PositionSwitch: 'PositionSwitch',
  EarthingSwicth: 'EarthingSwicth',
  Truck: 'Truck',
  Gas: 'Gas',
  Interlock: 'Interlock',
  ImageIndentification: 'ImageIndentification',
  VideoIndentification: 'VideoIndentification',
  PD: "PD",
}

export const GatewayStatus = {
  TobeApprove: -1,
  New: 0,
  Approved: 1,
  Modified: 2
}


export const variablesUpContact = ["UP_CONTACT_A_TEMP", "UP_CONTACT_B_TEMP", "UP_CONTACT_C_TEMP"]
export const variablesDownContact = ["DOWN_CONTACT_A_TEMP", "DOWN_CONTACT_B_TEMP", "DOWN_CONTACT_C_TEMP"];
export const variablesBusbar = ["BUSBAR_A_TEMP", "BUSBAR_B_TEMP", "BUSBAR_C_TEMP"]
export const variablesCable = ["CABLE_A_TEMP", "CABLE_B_TEMP", "CABLE_C_TEMP"]
export const variablesOpenTravel = ['OpenLinearTravel']
export const variablesCloseTravel = ['CloseLinearTravel']
export const variablesOpenCoil = ['OpenCoil']
export const variablesCloseCoil = ['CloseCoil']
export const variablesMotor = ['Motor']
export const variables3PSFront = [
  "Disconnector_Switch_Close_Peak_Current_BB1",
  "Disconnector_Switch_Close_Operation_Time_BB1",
  "Disconnector_Switch_Open_Peak_Current_BB1",
  "Disconnector_Switch_Open_Operation_Time_BB1",
  "Earthing_Switch_Close_Peak_Current_BB1",
  "Earthing_Switch_Close_Operation_Time_BB1",
  "Earthing_Switch_Open_Peak_Current_BB1",
  "Earthing_Switch_Open_Operation_Time_BB1",
]
export const variables3PSRear = [
  "Disconnector_Switch_Close_Peak_Current_BB2",
  "Disconnector_Switch_Close_Operation_Time_BB2",
  "Disconnector_Switch_Open_Peak_Current_BB2",
  "Disconnector_Switch_Open_Operation_Time_BB2",
  "Earthing_Switch_Close_Peak_Current_BB2",
  "Earthing_Switch_Close_Operation_Time_BB2",
  "Earthing_Switch_Open_Peak_Current_BB2",
  "Earthing_Switch_Open_Operation_Time_BB2",
]

export const varGisCable01 = ['CABLE_A_01_TEMP', 'CABLE_B_01_TEMP', 'CABLE_C_01_TEMP']
export const varGisCable02 = ['CABLE_A_02_TEMP', 'CABLE_B_02_TEMP', 'CABLE_C_02_TEMP']
export const varGisCable03 = ['CABLE_A_03_TEMP', 'CABLE_B_03_TEMP', 'CABLE_C_03_TEMP']
export const varGisCable04 = ['CABLE_A_04_TEMP', 'CABLE_B_04_TEMP', 'CABLE_C_04_TEMP']

export const DeviceSubscoreGroup = {
  UpContact: variablesUpContact,
  DownContact: variablesDownContact,
  Contact: [...variablesUpContact, ...variablesDownContact],
  Busbar: variablesBusbar,
  Cable: variablesCable,
  GisCable01: varGisCable01,
  GisCable02: varGisCable02,
  GisCable03: varGisCable03,
  GisCable04: varGisCable04,
  Temperature: [...variablesUpContact, ...variablesDownContact, ...variablesBusbar, ...variablesCable],
  TemperatureGIS: [...varGisCable01, ...varGisCable02, ...varGisCable03, ...varGisCable04, ...variablesBusbar],
  OpenTravel: variablesOpenTravel,
  CloseTravel: variablesCloseTravel,
  Travel: [...variablesOpenTravel, ...variablesCloseTravel],
  OpenCoil: variablesOpenCoil,
  CloseCoil: variablesCloseCoil,
  Coil: [...variablesOpenCoil, ...variablesCloseCoil],
  Motor: variablesMotor,
  Gas: ['Gas_Pressure_p_20', 'Gas_Pressure_p_20_CB', 'Gas_Pressure_p_20_BB1', 'Gas_Pressure_p_20_BB2'],
  ThreePosSwitch: [...variables3PSFront, ...variables3PSRear],
  ThreePosSwitchFront: variables3PSFront,
  ThreePosSwitchRear: variables3PSRear,
  Truck: [],
  TruckRollIn: ['MT_ROLL_IN_TIME', 'MT_ROLL_IN_PEAK_CURRENT'],
  TruckRollOut: ['MT_ROLL_OUT_TIME', 'MT_ROLL_OUT_PEAK_CURRENT'],
  EarthingSwitch: [],
  EarthingSwitchClose: ['ES_CLOSE_TIME', 'ES_CLOSE_PEAK_CURRENT'],
  EarthingSwitchOpen: ['ES_OPEN_TIME', 'ES_OPEN_PEAK_CURRENT'],
  PD:['UHF_PD_Radiation_Amplitude','UHF_PD_Radiation_Frequencyin','PD_Indicator','PD_Health_Score']

}
