import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { LoadingIndicator } from '@abb/abb-common-ux-react'

import { useSelector, useDispatch } from 'react-redux'
import { t } from 'i18next'
import { ErrorBoundary } from 'common/ErrorBoundary'

import './dashboard.scss'
import { fetchCustomerAsync, getCustomer, getIsLoadingCustomer } from 'slices/ezProjectSlice'
import CustomerInfo from './CustomerInfo'
import Applications from './Applications'
import { RightList } from './RightList'
import Users from './Users'

export default function Customer() {
  const urlParams = useParams()
  const [DataFormData, setDataFormData] = useState(null)
  const formDataRef = useRef(null)
  const dispatch = useDispatch()
  const device = useSelector(getCustomer)

  const isLoadingDevice = useSelector(getIsLoadingCustomer)
  const id = urlParams.id
  useEffect(() => {
    if (urlParams.id) {
      console.log(urlParams.id)
      dispatch(fetchCustomerAsync(urlParams.id))
    }
  }, [urlParams.id])

  useEffect(() => {
    console.log(device)
  }, [device])
  const onSubmit = (d) => {
    dispatch(fetchCustomerAsync({ id }))
  }
  if (isLoadingDevice) {
    return (
      <div className='central-position'>
        <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
      </div>
    )
  } else if (device) {
    return (
      <ErrorBoundary>
        <div className='grid root-grid' data-cy='module-device-dashboard'>
          <div className='row abb-col-lg-16 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
            <div className="row  abb-col-lg-10 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms">
                  <CustomerInfo />
                </div>
              </div>
              <div className="row  abb-col-lg-14 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms">
                  <RightList />
                </div>
              </div>
              <div className='row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-6x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'>
                  <Users/>
                </div>
              </div>
            </div>
          </div>
          <div className='row  abb-col-lg-8 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
              <div className='tile height-12x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'>
                <Applications />
              </div>
            </div>
          </div>
        </div>
        {/* <DataFormEdit ref={formDataRef} formData={DataFormData} id={device.id} onSubmit={onSubmit} /> */}
      </ErrorBoundary>
    )
  }
  return (
    <div className='grid root-grid'>
      <p>您没有访问此页面的权限</p>
    </div>
  )
  //返回页面
}
