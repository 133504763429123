import React, { useState, useEffect, useRef } from 'react'
import { useImperativeHandle } from 'react'
import { Button, Dialog } from '@abb/abb-common-ux-react'
import { t } from 'i18next'
import { forwardRef } from 'react'
import '../../../../styles/singleReport.scss'
import axios from 'axios'
import { getMaintenanceRangePreview } from 'common/apis/maintenanceRange'
import { tr } from 'date-fns/locale'
import { nanoid } from 'nanoid'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { chunk } from 'lodash'
export const Preview = forwardRef(({}, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [range, setRange] = useState(null)
  const [project, setProject] = useState(null)
  const types = useDataDictionary('DeviceType')
  let index = 0
  const fetchPreviewInfo = (rangeId) => {
    axios.post(getMaintenanceRangePreview(), { id: rangeId }).then((response) => {
      setRange(response.data)
    })
  }

  const showDialog = (project, rangeId) => {
    setProject(project)
    fetchPreviewInfo(rangeId)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))

  const renderCheckItem = (item) => {
    return item.points.map((v, i, a) => {
      if (i == 0) {
        return (
          <tr key={nanoid()}>
            <td>{++index}</td>
            <td rowSpan={item.points.length}>{item.name}</td>
            <td>{v.name}</td>
            <td className='noborder-right center'>
              <input type='checkbox' checked={true} disabled={true} />
            </td>
            <td className='center'>
              <input type='checkbox' disabled={true} />
            </td>
            <td className='noborder-right center'>
              <input type='checkbox' disabled={true} />
            </td>
            <td className='center'>
              <input type='checkbox' disabled={true} />
            </td>
            <td></td>
          </tr>
        )
      } else {
        return (
          <tr key={nanoid()}>
            <td>{++index}</td>
            <td>{v.name}</td>
            <td className='noborder-right center'>
              <input type='checkbox' checked={true} disabled={true} />
            </td>
            <td className='center'>
              <input type='checkbox' disabled={true} />
            </td>
            <td className='noborder-right center'>
              <input type='checkbox' disabled={true} />
            </td>
            <td className='center'>
              <input type='checkbox' disabled={true} />
            </td>
            <td></td>
          </tr>
        )
      }
    })
  }

  const renderTestItem = (item) => {
    var modal = JSON.parse(item.modalString)
    return (
      <table key={nanoid()} className='center '>
        {renderTestModal(modal.content)}
      </table>
    )
  }

  const renderTestModal = (modal) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderTestModal(m.componments)}
            </td>
          )
        }
        if (m.type === 'input' || m.type === 'image') {
          return 'N/A'
        }
        if (m.type === 'option') {
          return <input type='checkbox' disabled={true} />
        }
        if (m.type === 'editonly') {
          return ''
        }
        return <>{renderTestModal(m.componments)}</>
      })
    )
  }

  const renderDevice = (device, name) => {
    if (!device) {
      return null
    }
    return (
      <li className='canBeDeleted'>
        <h4>{name}检修保养</h4>
        <ul data-order='1'>
          {device.checkItems && device.checkItems.length > 0 && (
            <li className='canBeDeleted'>
              <h5>{name}检查及保养</h5>
              <table id='switchCheckTable'>
                <thead>
                  <tr className='border-top-solid border-right-dotted'>
                    <td rowSpan='2'>序号</td>
                    <td rowSpan='2'>检查项目</td>
                    <td rowSpan='2'>检查内容</td>
                    <td colSpan='2'>检查结果</td>
                    <td colSpan='2'>缺陷处理</td>
                    <td rowSpan='2'>缺陷处理原因</td>
                  </tr>
                  <tr>
                    <td>合格</td>
                    <td>不合格</td>
                    <td>已处理</td>
                    <td>未处理</td>
                  </tr>
                </thead>
                <tbody key={nanoid()}>{device.checkItems.map((i) => renderCheckItem(i))}</tbody>
              </table>
            </li>
          )}
          {device.testItems && device.testItems.length > 0 && (
            <li className='canBeDeleted'>
              <h5>{name}试验</h5>
              <ul data-order='2'>
                {device.testItems.map((test) => (
                  <li key={nanoid()} id='expCircuitvoltage' className='li_exp'>
                    <h6>{test.name}</h6>
                    {test.modals.map((modal) => renderTestItem(modal))}
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      </li>
    )
  }

  return (
    <>
      <Dialog
        style={{ width: '1160px', height: '700px' }}
        showCloseButton={true}
        closeOnLostFocus
        closeOnEscape={false}
        dimBackground={true}
        isOpen={isVisible}
        title={'单体报告预览'}
        onClose={() => setIsVisible(false)}
      >
        <div className='single-report'>
          <div className='cover'>
            <h2 id='r_projectName'>{project?.name}</h2>
            <h2 id='reportName'>
              {range?.deviceInfo?.cabinet?.modal?.value ?? range?.deviceInfo?.breaker?.modal?.value ?? range?.deviceInfo?.protection?.modal?.value}
              维护保养单体报告
            </h2>
            <h2 id='cabinetNumber'>HA01开关柜</h2>
            <img src='/templates/SingleReportCover.png' />
          </div>
          <ul className='content' data-order='0'>
            <li id='deviceinfo'>
              <h4>1. 设备信息</h4>
              <table id='deviceTitle' className='noborder-bottom'>
                <tbody>
                  {range &&
                    range.deviceInfo &&
                    types.details.map((type, index, arr) => {
                      const device = range.deviceInfo[type.code]
                      if (!device) {
                        return null
                      }
                      return (
                        <>
                          <tr className={index == 0 ? 'border-top-solid' : ''}>
                            <td>
                              {type.displayText}
                              {device.modal.title}：
                            </td>
                            <td id='cabinetModel'>{device.modal.value ?? 'NA'}</td>
                            <td>
                              {type.displayText}
                              {device.sn.title}：
                            </td>
                            <td id='cabinetServISNumber'>{device.sn.value ?? 'NA'}</td>
                            <td>{device.productDate.title}：</td>
                            <td id='cabinetNumber2'>{device.productDate.value ?? 'NA'}</td>
                          </tr>
                          {chunk(
                          Object.entries(device).filter((d) => !(d[0] === 'modal' || d[0] === 'sn' || d[0] === 'productDate')),
                          3
                        ).map((t) => (
                          <tr key={nanoid()} className={index >= arr.length - 1 ? 'border-bottom-solid' : ''}>
                            {t.map((d) => {
                              const kv = d[1]
                              return (
                                <React.Fragment key={nanoid()}>
                                  <td key={nanoid()}>{kv.title}</td>
                                  <td key={nanoid()} data-field='cabinetRatedVoltage' id='cabinetRatedVoltage'>
                                    {kv.value ?? 'NA'}
                                  </td>
                                </React.Fragment>
                              )
                            })}
                          </tr>
                        ))}
                        </>
                      )
                    })}
                </tbody>
              </table>
            </li>
            {range &&
              range.maintenanceInfo &&
              types.details.map((type) => {
                const device = range.maintenanceInfo[type.code]
                if (!device) {
                  return null
                }
                return renderDevice(device, type.displayText)
              })}

            <li id='problemlist'>
              <h4>问题汇总</h4>
              <ul data-order='1'>
                <li>
                  <h5>检查项缺陷汇总</h5>
                  <table id='checkSummary'>
                    <thead>
                      <tr className='border-top-solid  border-right-dotted'>
                        <th>问题图片</th>
                        <th>问题等级</th>
                        <th>处理结果</th>
                        <th>问题描述/处理措施</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </li>
                <li>
                  <h5>试验项缺陷汇总</h5>
                  <table id='expSummary'>
                    <thead>
                      <tr className='border-top-solid  border-right-dotted'>
                        <th>试验项目</th>
                        <th>缺陷等级</th>
                        <th>处理结果</th>
                        <th>缺陷描述</th>
                        <th>处理措施</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </table>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Dialog>
    </>
  )
})
