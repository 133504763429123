import { createSlice } from "@reduxjs/toolkit";
import { Theme } from 'common/enums'


const themeSlice = createSlice({
  name: "theme",
  initialState: {
    // name: process.env.NODE_ENV === "development" ? Theme.Dark : Theme.Light
    name: Theme.Light
  },
  reducers: {
    toggleTheme(state, action) {
      // state.name === ''
      state.name = action.payload
    },
  },
})

export const { toggleTheme } = themeSlice.actions;
export const getTheme = (state) => state.theme.name;
export default themeSlice.reducer;
