import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { getInstrumentPage, deleteInstrument } from 'common/apis/maindata'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'
import { getUsers } from 'slices/userSlice'

import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { fetchGetOrganizationAsync, getOrganization } from 'slices/organizationSlice'
import { findTree } from 'common/utils'
import { ImportFormEdit } from './ImportFormEdit'
import { useDataDictionary } from 'hooks/useDataDictionary'

export default function Instruments() {
  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])
  const users = useSelector(getUsers)
  const refIndex = useRef(null)
  const refImport = useRef(null)
  const dt = useDataDictionary( 'InstrStatus')
  const organization = useSelector(getOrganization)

  useEffect(() => {
    if (!organization) {
      dispatch(fetchGetOrganizationAsync())
    }
  }, [organization])

  useEffect(() => {
    changeColumns()
  }, [dt, users,organization])
  //列定义
  const changeColumns = () => {
    setColumns([
      {
        title: 'instrument.assetCode',
        key: 'assetCode',
        render: (item, index) => {
          const isDeleted = deletedItems.indexOf(item.id) >= 0
          if (isDeleted)
            return (
              <WithTooltip>
                <del>{item.assetCode}</del>
                <Tooltip>
                  <div>仪器已被删除</div>
                </Tooltip>
              </WithTooltip>
            )
          else return item.assetCode
        },
      },
      {
        title: 'instrument.name',
        key: 'name',
      },
      {
        title: 'instrument.modal',
        key: 'modal',
        width:'150px'
      },
      {
        title: 'instrument.factory',
        key: 'factory',
      },
      {
        title: '所在服务中心',
        key: 'unitId',
        render: (item, index) => {
          const manager = findTree(organization, (d) => d.key == item.unitId)
          return manager?.title
        },
      },
      {
        title: 'instrument.manager',
        key: 'manager',
        render: (item, index) => {
          const category = users.find((d) => d.id == item.manager)
          return category?.name
        },
      },
      {
        title: 'instrument.status',
        key: 'status',
        render: (item, index) => {
          const category = dt?.details.find((d) => d.code == item.status)
          return category?.displayText
        },
      },
      {
        title: 'instrument.lastCheck',
        key: 'lastCheck',
        render: (item) => {
          return moment(item.lastCheck).format('yyyy-MM-DD')
        },
      },
      {
        title: '到期日期',
        key: 'purchaseDate',
        render: (item) => {
          return item.validity>=9999?'一次性':moment(item.lastCheck).add(item.validity, 'M').format('yyyy-MM-DD')
        },
      },
    ])
  }

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getInstrumentPage}
        deleteData={deleteInstrument}
        FormEdit={FormEdit}
        canAdd
        canEdit
        onImport={() => refImport.current.showPanel()}
        canDelete
        canInport
        onDeleteData={onDeleteData}
        enableSorting={true}
      />
      <ImportFormEdit
        ref={refImport}
        onSubmit={() => {
          refIndex.current.query()
        }}
      />
    </>
  )
}
