import React, { useState, useEffect, useRef, forwardRef } from 'react'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { myProfile } from 'common/endpoints'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { ImageSelector } from 'components/ImageSelector'

export const ChangeProfile = forwardRef(({ onSubmit, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [rules, setRules] = useState({})
  const [formData, setFormData] = useState(null)
  const [currAvatarImg, setAvatarCurrImg] = useState(formData?.extraProperties?.Avatar)
  const [currSignImg, setCurrSignImg] = useState(formData?.extraProperties?.SignImage)

  //初始化数据
  const initData = {
    confirmPassword: '',
    email: '',
    name: '',
    password: '',
    phoneNumber: '',
    avatar: '',
    introduction: '',
    roleNames: [],
    userName: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('user.name'),
      fieldName: 'name',
      maxLength: 64,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('user.email'),
      fieldName: 'email',
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('user.phoneNumber'),
      fieldName: 'phoneNumber',
      maxLength: 16,
    },

    {
      type: 'div',
      label: '签名',
      fieldName: 'signImage',
      maxLength: 256,
      render: (item, callback) => {
        return (
          <div style={{ paddingTop: '12px' }}>
            <ImageSelector
              value={item}
              path={'/_/User/Avatars/'}
              onValueChanged={(d) => {
                setCurrSignImg(d[0].id)
              }}
            />
          </div>
        )
      },
    },
    {
      type: 'div',
      label: '头像',
      fieldName: 'avatar',
      maxLength: 256,
      render: (item, callback) => {
        return (
          <div style={{ paddingTop: '12px' }}>
            <ImageSelector
              value={item}
              path={'/_/User/Avatars/'}
              onValueChanged={(d) => {
                setAvatarCurrImg(d[0].id)
              }}
            />
          </div>
        )
      },
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: '简介',
      fieldName: 'introduction',
      maxLength: 500,
    },
  ]

  const onCancel = () => {
    setFormData(null)
  }
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      email: [CommonRule.Require, CommonRule.Email],
      name: [CommonRule.Require],
      phoneNumber: [CommonRule.Mobile],
      userName: [CommonRule.Require],
    }

    setRules(newrules)
  }, [])
  //表单的其他校验
  const otherValidate = (formData) => {
    if (formData.password && formData.password != formData.confirmPassword) {
      return t('user.twicePwdNotSame')
    }
    return ''
  }
  //获取用户对应的角色
  const getUserRoleAsync = async (id) => {
    try {
      const response = await axios.get(myProfile())
      if (response.status === 200) {
        if (response.data) {
          setFormData(response.data)
        }
      } else {
        console.log('error to get user roles')
      }
    } catch (e) {
      console.log('exception to get user roles')
      console.log(e)
    } finally {
    }
  }
  //根据id获取用户对应拥有角色
  useEffect(() => {
    if (event?.name === 'setShowSidePanel') {
      getUserRoleAsync()
    }
  }, [event])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      userName: inputdata.userName,
      name: inputdata.name,
      surname: inputdata.name,
      email: inputdata.email,
      extraProperties: {
        Avatar: currAvatarImg,
        SignImage: currSignImg,
        Introduction: inputdata.introduction,
      },
      phoneNumber: inputdata.phoneNumber,
      concurrencyStamp: inputdata.concurrencyStamp,
    }
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('user.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      otherValidate={otherValidate}
      isPost={false}
      updateData={myProfile}
      getUpdateInput={getUpdateInput}
      id={'curr'}
      formData={{
        ...formData,
        avatar: formData?.extraProperties.Avatar,
        signImage: formData?.extraProperties.SignImage,
        introduction: formData?.extraProperties.Introduction,
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    ></CommonFormEdit>
  )
})
