import React, { useState, useRef, useEffect } from 'react'
import { Button, WithTooltip, Tooltip, Icon } from '@abb/abb-common-ux-react'
import { CommonFormItem, CommonList } from 'components/LoongInCommon'
import { CommonForm, CommonRule } from 'components/LoongInCommon'
import './bootstrap.css'
import './login.css'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { findTenant, getTokenProviders, login, loginByTowFactorCode, sendPasswordResetCode, sendTowFactorCode } from 'common/endpoints'

import { changeLanguage } from 'i18n'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { fetchAbpApplicationConfigurationAsync } from 'slices/abpApplicationConfigurationSlice'
import { hashCode } from 'common/utils'
import { CommonPrompt, CommonPromptButtons } from 'components/LoongInCommon/CommonPrompt'
export default function MfaLogin() {
  const { i18n, t } = useTranslation()
  const loginFormRef = useRef(null)
  const forgetFormRef = useRef(null)
  const dispatch = useDispatch()
  const location = window.location.toString()
  const [isSumbiting, setIsSumbiting] = useState(false)
  const [showCodePannel, setShowCodePannel] = useState(false)
  const [tokenProvider, setTokenProvider] = useState(false)
  const currentUser = useCurrentUser()
  const [tokenProviders, setTokenProviders] = useState([])
  const forgetInitData = {
    code: '',
    tokenProvider: '',
    // rememberMe: true,
  }

  const forgetRules = {
    code: [
      {
        ...CommonRule.Require,
        message: t('login.rule.plsInputEmail'),
      },
    ],
  }

  useEffect(() => {
    fetchTokenProviders()
  }, [])

  const fetchTokenProviders = () => {
    axios
      .post(getTokenProviders(), {})
      .then((response) => {
        setTokenProviders(response.data)
      })
      .catch((err) => {
        console.log('login err')
        console.log(err)
        dispatch(showNotification(NotificationType.Alarm, err?.error?.message ?? t('login.msg.userOrPwdError')))
      })
      .finally(() => {})
  }

  const sendCodeSubmit = (item) => {
    axios
      .post(sendTowFactorCode(item.key), {})
      .then((response) => {
        setTokenProvider(item)
        setShowCodePannel(true)
      })
      .catch((err) => {
        console.log('login err')
        console.log(err)
        dispatch(showNotification(NotificationType.Alarm, err?.error?.message ?? t('login.msg.userOrPwdError')))
      })
      .finally(() => {
        setIsSumbiting(false)
      })
  }
  const forgetSubmit = (payload, config) => {
    axios
      .post(loginByTowFactorCode(), { ...payload, tokenProvider: tokenProvider.key }, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.id && response.data.result.succeeded) {
            currentUser.setUser(response.data)
            dispatch(fetchAbpApplicationConfigurationAsync())
            window.location.reload()
          } else {
            currentUser.setUser(null)
            dispatch(showNotification(NotificationType.Warn, t('login.msg.userOrPwdError')))
          }
        } else {
          dispatch(showNotification(NotificationType.Warn, t('login.msg.userOrPwdError')))
        }
      })
      .catch((err) => {
        console.log('login err')
        console.log(err)
        dispatch(showNotification(NotificationType.Alarm, err?.error?.message ?? t('login.msg.userOrPwdError')))
      })
      .finally(() => {
        setIsSumbiting(false)
      })
  }
  const sumbitForget = () => {
    setIsSumbiting(true)
    forgetFormRef.current.validate((valid, formData) => {
      setIsSumbiting(false)
      if (valid) {
        forgetSubmit(formData)
      } else {
        dispatch(showNotification(NotificationType.Alarm, t('login.msg.plsInputEmail')))
      }
    })
  }
  const switchLanguage = () => {
    let newlang = i18n.language == 'zh_cn' ? 'en_us' : 'zh_cn'
    changeLanguage(newlang)
  }

  return (
    <>
      <div className='navbar navbar-fixed-top abb-nav-bar'>
        <div className='container-fluid'>
          <div className='navbar-brand'>
            <img src='/ABB-logo.svg' className='abb-navbar-brand-logo' />
          </div>
        </div>
      </div>

      <div className='body-content'>
        <div className='abb-layout-cards'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title'>{showCodePannel?'输入验证码':'验证您的身份'}</h3>

              <div
                onClick={switchLanguage}
                style={{
                  float: 'right',
                  position: 'relative',
                  top: '-40px',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                <WithTooltip>
                  <div> {t(i18n.language)}</div>
                  <Tooltip>
                    <div>{t('login.msg.switchLanguage')}</div>
                  </Tooltip>
                </WithTooltip>
              </div>
            </div>

            <div className='card-body'>
              {!showCodePannel && (
                <CommonList
                  style={{ width: '100%' }}
                  data={tokenProviders}
                  filterText='key'
                  keyName='key'
                  seachable={false}
                  displayName='displayText'
                  itemTemplete={(item) => (
                    <div style={{ padding: 8 }}>
                      <Icon name={`abb/${item.icon}`} />
                      <span style={{ paddingLeft: 8 }}>{item.displayText}</span>
                    </div>
                  )}
                  onItemChange={sendCodeSubmit}
                ></CommonList>
              )}
              {showCodePannel && (
                <>
                {tokenProvider.verifyText}
                <CommonForm ref={forgetFormRef} initData={forgetInitData} rules={forgetRules}>
                  <CommonFormItem type='text' dataType='text' label={'验证码'} fieldName='code' />
                  <CommonFormItem type='button'>
                    <Button text={'验证'} type='primary-blue' sizeClass='small' onClick={sumbitForget} isLoading={isSumbiting} />
                  </CommonFormItem>
                </CommonForm>
                  </>
              )}
            </div>
          </div>
          <div className='card-copyright'>
            &copy; Copyright 2022 ABB |<a href='http://new.abb.com/provider-information'>{t('login.msg.providerInfo')}</a>|
            <a href='http://new.abb.com/privacy-policy'>{t('login.msg.privacyPolicy')}</a>
          </div>
        </div>
      </div>
    </>
  )
}
