import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { createDataDictionaryDetail, updateDataDictionaryDetail } from 'common/endpoints'
import { getClientHeight } from 'slices/appSlice'
import { t } from 'i18next'
import { createRight, updateRight } from 'common/apis/maindata'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const regexp = /\{(\S+?)\}/g;

  //校验规则
  const rules = {
    title: [CommonRule.Require],
    content: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    id: '',
    title: '',
    content: '',
  }
  //表单项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: '权益分组',
      fieldName: 'typeDisplayText',
      visible: !formData || !!formData.typeDisplayText,
      disabled: true,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '标题',
      fieldName: 'title',
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: '权益内容',
      fieldName: 'content',
      innerStyle: { height: '160px' },
      maxLength: 512,
    },
  ]
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      title: inputdata.title,
      content: inputdata.content,
      parameters:[...inputdata.content?.matchAll(regexp)]?.map(r=>[r[1]])?.join()
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      groupId: inputdata.groupId,
      title: inputdata.title,
      content: inputdata.content,
      parameters:[...inputdata.content?.matchAll(regexp)]?.map(r=>[r[1]])?.join()
    }
  }

  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={'权益信息'}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createRight}
      updateData={updateRight}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
