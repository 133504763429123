import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { createExpert, updateExpert } from 'common/apis/remoteSupport'
import { ImageSelector } from 'components/ImageSelector'
import { getUsers } from 'slices/userSlice'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [currImg, setCurrImg] = useState(formData?.avatar)
  const users = useSelector(getUsers)
  console.log(formData)
  //初始化数据
  const initData = {
    name: '',
    title: '',
    avatar: '',
    isRecommend: false,
    desc: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'dropdown',
      label: '关联用户',
      fieldName: 'userId',
      options: users
        ? users.map((c) => {
            return { label: c.name, value: c.id }
          })
        : [],
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '姓名',
      fieldName: 'name',
      maxLength: 50,
    },
    {
      type: 'text',
      dataType: 'text',
      label: '头衔',
      fieldName: 'title',
      maxLength: 50,
    },
    {
      type: 'checkbox',
      dataType: 'check',
      label: '推荐专家',
      fieldName: 'isRecommend',
      maxLength: 50,
    },

    {
      type: 'div',
      label: '头像',
      fieldName: 'avatar',
      maxLength: 256,
      render: (item, callback) => {
        return (
          <div style={{ paddingTop: '12px' }}>
            <ImageSelector
              value={item}
              path={'/_/RemoteSupport/Experts/Avatars'}
              onValueChanged={(d) => {
                setCurrImg(d[0].id)
              }}
            />
          </div>
        )
      },
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: '说明',
      fieldName: 'desc',
      maxLength: 256,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      title: [CommonRule.Require],
      desc: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      title: inputdata.title,
      avatar: currImg ?? inputdata.avatar,
      isRecommend: inputdata.isRecommend,
      desc: inputdata.desc,
      userId: inputdata.userId,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      title: inputdata.title,
      avatar: currImg,
      isRecommend: inputdata.isRecommend,
      desc: inputdata.desc,
      userId: inputdata.userId,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='专家信息'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createExpert}
      updateData={updateExpert}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onChangeBefore={(item, _, name) => {
        if (name === 'userId') {
          item.name = users.find((u) => u.id == item.userId)?.name
        }
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
