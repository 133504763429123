import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Dialog } from "@abb/abb-common-ux-react"
import { getUnAddUsers, getUserPage } from "common/endpoints"
import { CommonIndex } from "components/LoongInCommon"
import { t } from "i18next"
import axios from "axios"
import { getClientHeight } from "slices/appSlice"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"

import "./organiza.scss"
import { addDevice, getStationDevices } from "common/apis/maindata"
import { id } from "date-fns/locale"
import { FileSelector } from "components/FileSelector"

export const UploadReport = forwardRef(({customerId, projectId, onUploaded }, ref) => {
  const refIndex = useRef(null)
  const [path, setPath] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const dispatch = useDispatch()
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //列定义
  
  
  const showDialog = (path) => {
    console.log(path)
    setPath(path)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    // setIsVisible,
    // setSearchData,
    showDialog,
  }))

  const onUpload=(files)=>{
    onUploaded && onUploaded(files)
    setIsVisible(false)
  }
  //返回页面
  return (
    <Dialog
      showCloseButton={true}
      closeOnLostFocus
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title="上传报告"      
      onClose={() => setIsVisible(false)}
    >
      <div style={{ height: "140px",padding:'50px 180px' }}>
      <FileSelector path={path} customerId={customerId} showDownload={false} onValueChanged={onUpload} />
      </div>
    </Dialog>
  )
})
