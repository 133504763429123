import { useSelector } from 'react-redux'

export const getDataDictionary = (dictionary, code) => {
  const dt = dictionary ? dictionary.find((d) => d.code == code) : null
  return dt
}

export const treeMap = (tree, callback) => {
  return tree.map((item, index) => {
    const v = callback(item)
    if (item.children && item.children.length) {
      return { ...v, children: treeMap(item.children, callback) }
    }
    return { ...v }
  })
}

export const findTree = (tree, callback) => {
  for (const index in tree) {
    let item = tree[index]
    if (callback(item)) {
      return item
    }
    if (item.children) {
      const r = findTree(item.children, callback)
      if (r) {
        return r
      }
    }
  }
  return
}
export const hashCode = function (str) {
  var hash = 0
  if (str.length === 0) return hash
  for (var i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return Math.abs(hash)
}
