import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Input, LoadingIndicator, Icon } from '@abb/abb-common-ux-react'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import './commonList.scss'
//通用左侧列表
export const CommonList = forwardRef(
  (
    {
      data,
      isLoading,
      keyName, //主键字段名
      filterText, //过滤字段名
      displayName, //显示字段名
      onItemChange, //选中项变化时
      rightIcon, //首行右侧按钮{name: "abb/plus",color: "blue",sizeClass: "medium",onClick: () => {}},
      contexMenu, //行内右侧菜单
      style,
      seachable = true,
      itemTemplete,
    },
    ref
  ) => {
    const [activeId, setActiveId] = useState(null)
    const [keyWord, setKeyword] = useState('')
    const setCurrentItem = (item) => {
      setActiveId(item[keyName])
      onItemChange && onItemChange(item)
    }
    useImperativeHandle(ref, () => ({
      setCurrentItem, //设置当前选中项
    }))
    return (
      <>
        <div className='admin-main-content content-side-list' style={style}>
          {isLoading && <LoadingIndicator type='bar' sizeClass='small' determinate={false} color='blue' />}
          {data && (
            <>
              {seachable && (
                <div className='content-side-list-header' style={{ diplay: 'flex' }}>
                  <Input
                    style={{ width: rightIcon ? '90%' : '100%' }}
                    value={keyWord}
                    onValueChange={setKeyword}
                    icon='abb/search'
                    type='discreet'
                    showClearIcon
                  />
                  {rightIcon && (
                    <Icon
                      style={{
                        float: 'right',
                        position: 'relative',
                        top: '-25px',
                        color: rightIcon.color || 'gray',
                        cursor: 'pointer',
                        height: '0px',
                      }}
                      onClick={rightIcon.onClick}
                      name={rightIcon.name || 'abb/plus'}
                      sizeClass={rightIcon.sizeClass || 'medium'}
                    />
                  )}
                </div>
              )}
              <div className='content-side-list-body' style={{ height: '100%' }}>
                <ul>
                  {data
                    .filter((m) => (keyWord.trim() === '' ? true : m[filterText].toLowerCase().indexOf(keyWord.trim().toLowerCase()) >= 0))
                    .sort((a, b) => (a[filterText].toUpperCase() > b[filterText].toUpperCase() ? 1 : 0))
                    .map((e, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          setCurrentItem(e)
                        }}
                        className={e[keyName] === activeId ? 'active' : ''}
                      >
                        <div className='resource-dir-header'>
                          <div className='resource-dir-name'>
                            {itemTemplete && itemTemplete(e)}
                            {!itemTemplete && (e[displayName] ?? 'N/A')}
                          </div>
                          <div>
                            {contexMenu && (
                              <div className='resource-dir-menu'>
                                <DropdownButton forcePopupLeft>
                                  {((typeof contexMenu === "function")?contexMenu(e):contexMenu)?.map((m, midx) => (
                                    <DropdownButtonOption
                                      key={'ddbot_' + midx}
                                      onClick={() => {
                                        m.onClick && m.onClick(e)
                                      }}
                                      disabled={m.disabled}
                                    >
                                      <span style={{ color: m.color }}>{m.title}</span>
                                    </DropdownButtonOption>
                                  ))}
                                </DropdownButton>
                              </div>
                            )}
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </>
    )
  }
)
