import React, { useState, useEffect, useRef } from 'react'
// import { WithTooltip, Tooltip, Icon, Button } from "@abb/abb-common-ux-react"
import { Button } from '@abb/abb-common-ux-react'
// import { getUserProfile } from "slices/userProfileSlice"

// import { useSelector } from "react-redux"
import axios from 'axios'
import { CommonConfirm } from 'components/LoongInCommon'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { CommonIndex } from 'components/LoongInCommon'
import { MaintenanceCatalogFormEdit } from './MaintenanceCatalogFormEdit'
import { LeftPanel } from './LeftPanel'

import moment from 'moment'

import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import {
  deleteCheckItem,
  deleteCheckPoint,
  deleteCheckPointOption,
  deleteMaintenanceCatalog,
  getCheckItemPage,
  getCheckPointPage,
} from 'common/apis/ezreport'
import { CheckItemFormEdit } from './CheckItemFormEdit'
import { el } from 'date-fns/locale'
import { CheckPointFormEdit } from './CheckPointFormEdit'
import { CheckPointOptionFormEdit } from './CheckPointOptionFormEdit'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import { LifeCycleModal } from './LifeCycleModal'

export default function CheckItems() {
  // const [isOperating, setIsOperating] = useState(false)
  const [MaintenanceCatalogFormData, setMaintenanceCatalogFormData] = useState(null)
  const [CheckItemFormData, setCheckItemFormData] = useState(null)
  const [CheckPointOptionFormData, setCheckPointOptionFormData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activeItemId, setActiveItemId] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState('')
  const [deleteItem, setDeleteItem] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const formMaintenanceCatalogRef = useRef(null)
  const formCheckItemRef = useRef(null)
  const formCheckPointOptionRef = useRef(null)
  const lifeCycleRef = useRef(null)

  const refIndex = useRef(null)
  const devTypRef = useRef(null)

  const [searchData, setSearchData] = useState(null)
  const options = [
    { label: '短文本', value: '1' },
    { label: '长文本', value: '2' },
    { label: '数值', value: '3' },
    { label: '是否', value: '4' },
    { label: '枚举', value: '5' },
    { label: '日期', value: '6' },
  ]
  //列定义
  const columns = [
    {
      title: '检查点',
      key: 'name',
      isTree: true,
    },
  ]
  const onAddBefore = () => {
    const result = {
      checkItemId: activeItemId,
    }
    setActiveItemId(null)
    return result
  }
  const toolChildren = (
    <>
      <Button
        text='添加维保区域'
        type='normal'
        sizeClass='small'
        icon={'abb/plus'}
        // isLoading={isOperating}
        onClick={() => {
          setMaintenanceCatalogFormData({
            name: '',
          })
          setShowMaintenanceCatalogPanel(true)
        }}
      />
    </>
  )
  const setShowMaintenanceCatalogPanel = (visible) => {
    formMaintenanceCatalogRef.current.setShowSidePanel(visible)
  }
  const setShowCheckItemPanel = (visible) => {
    formCheckItemRef.current.setShowSidePanel(visible)
  }
  const setShowCheckPointOptionPanel = (visible) => {
    formCheckPointOptionRef.current.setShowSidePanel(visible)
  }
  const onSubmit = (d) => {
    if (activDataIndex) {
      setActivDataIndex('')
      setMaintenanceCatalogFormData(null)
      setCheckItemFormData(null)
      devTypRef.current.getCheckItemTree()
    } else {
      devTypRef.current.getCheckItemTree()
    }
  }
  const onOptionSubmit = (d) => {
    setCheckPointOptionFormData(null)
    refIndex.current.query()
  }

  const leftPanel = (
    <LeftPanel
      ref={devTypRef}
      onActive={(item) => {
        if (item.type === 'checkItem') {
          setActiveItem(item)
          setSearchData({ itemId: item.key })
        } else {
          setActiveItem(item)
          setSearchData({ itemId: '00000000-0000-0000-0000-000000000000' })
        }
        // refIndex.current.query()
      }}
      onAdd={(item) => {
        if (item.type === 'catalog') {
          setCheckItemFormData({
            name: '',
            checkType: '',
            checkTools: '',
            description: '',
            catalogId: item.key,
          })
          setActivDataIndex('')
          setShowCheckItemPanel(true)
        } else {
          setActiveItemId(item.key)
        }
      }}
      onConfigLifeCycle={(item) => {
        lifeCycleRef.current.showDialog({
          id: item.key,
          name: item.title,
          checkType: item.checkType,
          checkTools: item.checkTools,
          description: item.description,
          lifeCycleConfig:item.lifeCycleConfig,
          projectType:item.projectType,
        })
      }}
      onEdit={(item) => {
        if (item.type === 'catalog') {
          setMaintenanceCatalogFormData({
            id: item.key,
            name: item.title,
            deviceType:item.deviceType
          })
          setActivDataIndex(item.key)
          setShowMaintenanceCatalogPanel(true)
        } else {
          setCheckItemFormData({
            id: item.key,
            name: item.title,
            checkType: item.checkType,
            checkTools: item.checkTools,
            description: item.description,
          })
          setActivDataIndex(item.key)
          setShowCheckItemPanel(true)
        }
      }}
      onDel={(item) => {
        setDeleteItem(item)
      }}
    ></LeftPanel>
  )

  useEffect(() => {
    if (activeItemId) {
      refIndex.current.add()
    }
  }, [activeItemId])
  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true)
      const url = !deleteItem.type
        ? deleteCheckPointOption()
        : deleteItem.type === 'catalog'
        ? deleteMaintenanceCatalog()
        : deleteCheckItem(deleteItem.key)
      const response = await axios.post(url, {
        id: deleteItem.key || deleteItem.id,
      })
      if (response.status === 200 || response.status === 204) {
        setActivDataIndex('')
        setMaintenanceCatalogFormData(null)
        setCheckItemFormData(null)
        setDeleteItem(null)
        devTypRef.current.getCheckItemTree()
        refIndex.current.query()
        dispatch(showNotification(NotificationType.Success, t('t_hasDelete')))
      } else {
        dispatch(showNotification(NotificationType.Warn, t('t_deleteFail')))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail')))
    } finally {
      setIsDeleting(false)
    }
  }
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => {},
        title: '添加选项',
      },
    ]
  }

  const actionRender = (item, index) => {
    return item.code ? (
      <DropdownButton>
        <DropdownButtonOption
          key='ddb_1'
          onClick={() => {
            setCheckPointOptionFormData({ ...item })
            setShowCheckPointOptionPanel(true)
          }}
        >
          {t('b_edit')}
        </DropdownButtonOption>

        <DropdownButtonOption key='ddb_2' onClick={() => setDeleteItem(item)}>
          <span style={{ color: 'red' }}>{t('b_del')}</span>
        </DropdownButtonOption>
      </DropdownButton>
    ) : (
      <DropdownButton>
        <DropdownButtonOption
          key='ddb_2'
          onClick={() => {
            refIndex.current.edit(item, index)
          }}
        >
          {t('b_edit')}
        </DropdownButtonOption>
        <DropdownButtonOption
          key='ddb_3'
          onClick={() => {
            setCheckPointOptionFormData({
              name: '',
              code: '',
              checkPointName: item.name,
              checkPointId: item.id,
            })
            setShowCheckPointOptionPanel(true)
          }}
        >
          {'添加选项'}
        </DropdownButtonOption>
        <DropdownButtonOption key='ddb_4' onClick={() => refIndex.current.setDeleteItem(item)}>
          <span style={{ color: 'red' }}>{t('b_del')}</span>
        </DropdownButtonOption>
      </DropdownButton>
    )
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        otherMenu={otherMenu}
        getList={getCheckPointPage}
        searchData={searchData}
        deleteData={deleteCheckPoint}
        toolChildren={toolChildren}
        FormEdit={CheckPointFormEdit}
        canEdit
        canDelete
        onAddBefore={onAddBefore}
        refreshAfterDelete={true}
        enableSorting={false}
        leftPanelWidth={400}
        actionRender={actionRender}
        leftPanel={leftPanel}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        searchClassName='searching-form-simple' //查询样式
        toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
      />
      <MaintenanceCatalogFormEdit
        ref={formMaintenanceCatalogRef}
        formData={MaintenanceCatalogFormData}
        onCancel={() => {
          setActivDataIndex('')
          setMaintenanceCatalogFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
      />
      <CheckItemFormEdit
        ref={formCheckItemRef}
        formData={CheckItemFormData}
        onCancel={() => {
          setActivDataIndex('')
          setCheckItemFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
      />
      <CheckPointOptionFormEdit
        ref={formCheckPointOptionRef}
        formData={CheckPointOptionFormData}
        onCancel={() => {
          setCheckPointOptionFormData(null)
        }}
        onSubmit={onOptionSubmit}
        id={CheckPointOptionFormData?.id}
      />

      <CommonConfirm
        title={t('t_titleDeleteData')}
        content={t('t_confirmDeleteData')}
        isVisible={deleteItem != null}
        onConfirm={onDeleteConfirm}
        confirmText={t('b_confirm')}
        onCancel={() => setDeleteItem(null)}
        isLoading={isDeleting}
      />
      <LifeCycleModal ref={lifeCycleRef} onRefresh={() => setTimeout(() => onSubmit(), 500)}></LifeCycleModal>
    </>
  )
}
