import React, { useEffect, useRef, useState, useCallback } from 'react'
import {
  Button, Checkbox, Dialog, CollapsibleContainer, Collapsible,
  LoadingIndicator, ToggleButtonGroup, ToggleButton, Input, ThreeStateValue, WithTooltip, Tooltip
} from '@abb/abb-common-ux-react'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import { showNotification, NotificationType } from "components/Notification/notificationSlice";
import { useSelector, useDispatch } from "react-redux";
import { ResponseResult } from 'common/request'
import { uploadloadDirectoryFile, deleteDirectoryFile, replaceDirectoryFile, moveDirectoryFile, renameDirectoryFile, getDirectoryFiles } from 'common/endpoints';
import axios from 'axios'
import { getEnv } from 'slices/envSlice';
import { useMountedState } from 'common/useMountedState'
import { nanoid } from "nanoid";
import { useDropzone } from 'react-dropzone';
import SidePanel from 'components/SidePanel';
import { renderThumb } from 'components/ResourceSelector/index'

const AccpetFileTypeExt = [
  // 'image/*',
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.bmp',
  '.svg',
  '.pdf',
  '.glb',
  '.gltf',
  // '.obj'
]
export const ResourceDirectoryFiles = ({ directoryId, directory }) => {
  const dispatch = useDispatch();
  const env = useSelector(getEnv);
  const isMounted = useMountedState()
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [displayMode, setDisplayMode] = useState([0])
  const [keyword, setKeyword] = useState('')
  const [selectedItem, setSelectedItem] = useState([])
  const [renameFileId, setRenameFileId] = useState(null)
  const [inputFileName, setInputFileName] = useState('')
  const [errorFileName, setErrorFileName] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isMoving, setIsMoving] = useState(false)
  const [isRenaming, setIsRenaming] = useState(false)
  const [movingFileId, setMovingFileId] = useState([])
  const [movingDestDir, setMovingDestDir] = useState(null)
  const [replaceFileId, setReplaceFileId] = useState(null)
  const source = useRef(null);

  const [deleteConfirmId, setDeleteConfirmId] = useState([])
  const filteredFiles = files.filter(f => f.name?.indexOf(keyword) >= 0);
  const [baseRandomId, setBaseRandomId] = useState(nanoid())

  const getFiles = (directoryId) => {
    setFiles([])
    if (!directoryId) {
      return
    }
    setSelectedItem([])
    if (source.current) {
      source.current.cancel();
    }
    source.current = axios.CancelToken.source();
    setIsLoading(true)
    axios.get(getDirectoryFiles(directoryId), { cancelToken: source.current.token }).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        setFiles(e.data.data)
      }
      else if (e.data.result === ResponseResult.NoRecord) {
        setFiles([])
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
      if (axios.isCancel(e)) {
        return
      }
    }).then(e => {
      if (!isMounted()) {
        return
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (directoryId !== null) {
      setKeyword('')
      getFiles(directoryId)
    }
    return () => {
      if (source.current) {
        source.current.cancel();
      }
    }
  }, [directoryId])

  const toggleSelection = (id, isSelected) => {
    if (isSelected) {
      setSelectedItem([...selectedItem, id])
    } else {
      setSelectedItem(selectedItem.filter(s => s !== id))
    }
  }


  const onKeywordChanged = (e) => {
    setSelectedItem([])
    setKeyword(e)
  }

  const onDelete = (id) => {
    if (id.length === 0) {
      return
    }
    setIsDeleting(true)
    axios.delete(deleteDirectoryFile(id)).then(e => {
      if (e.data.result === ResponseResult.Ok) {
        dispatch(showNotification(NotificationType.Success, '删除成功'))
        setSelectedItem([])
        setDeleteConfirmId([])
        setFiles(files.filter(f => id.indexOf(f.key) === -1))
      }
      else { dispatch(showNotification(NotificationType.Warn, e.data.message)) }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
      dispatch(showNotification(NotificationType.Alarm, '删除失败'))
    }).then(() => {
      if (!isMounted()) {
        return
      }
      setIsDeleting(false)
    })
  }

  const onMove = () => {
    if (movingFileId.length === 0 || !movingDestDir) {
      return
    }
    setIsMoving(true)
    axios.post(moveDirectoryFile(), {
      attachmentIds: movingFileId,
      folderId: movingDestDir
    }).then(e => {
      if (e.data.result === ResponseResult.Ok) {
        dispatch(showNotification(NotificationType.Success, '移动成功'))
        setSelectedItem([])
        setFiles(files => files.filter(f => movingFileId.indexOf(f.key) === -1))
        setMovingFileId([])
      } else { dispatch(showNotification(NotificationType.Warn, '移动失败')) }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
      dispatch(showNotification(NotificationType.Alarm, '移动失败'))
    }).then(() => {
      if (!isMounted()) {
        return
      }
      setIsMoving(false)
    })
  }

  const onRename = () => {
    if (inputFileName.trim() === '') {
      dispatch(showNotification(NotificationType.Warn, '请填写文件名'))
      return
    }
    setIsRenaming(true)
    axios.post(renameDirectoryFile(), {
      fileKey: renameFileId,
      newName: inputFileName
    }).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        setFiles(files => files.map(f => f.key === renameFileId ? { ...f, name: e.data.data.name } : f))
        setRenameFileId(null)
        dispatch(showNotification(NotificationType.Success, '重命名成功'))
      } else { dispatch(showNotification(NotificationType.Warn, '重命名失败')) }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
      dispatch(showNotification(NotificationType.Alarm, '重命名失败'))
    }).then(() => {
      if (!isMounted()) {
        return
      }
      setIsRenaming(false)
    })
  }

  const isLegalAcceptExt = (name) => {
    if (name === '' || name === null || name === undefined) {
      return false
    }
    const index = name.lastIndexOf('.')
    if (index === -1) {
      return false
    }
    const ext = name.substr(name.lastIndexOf('.')).toLowerCase();
    if (AccpetFileTypeExt.indexOf(ext) === -1) {
      return false
    }
    return true;
  }

  const onUploadFiles = useCallback(acceptedFiles => {
    if (isUploading || acceptedFiles.length === 0) {
      return;
    }
    let isFileTooLarge = false
    if (acceptedFiles.some(e => !isLegalAcceptExt(e.name))) {
      dispatch(showNotification(NotificationType.Warn, '请选择图片或PDF文件'))
      return
    }
    const form = new FormData();
    acceptedFiles.forEach(f => {
      if (f.size >= 50 * 1024 * 1024) {
        isFileTooLarge = true
        return;
      }
      form.append('file', f);
    });
    if (isFileTooLarge) {
      dispatch(showNotification(NotificationType.Warn, '上传文件大小超出50MB的限制!'))
      return;
    }

    setIsUploading(true)
    axios.post(uploadloadDirectoryFile(directoryId), form).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        dispatch(showNotification(NotificationType.Success, '上传成功'))
        setFiles(files => [...files, ...e.data.data])
      }
      else {
        dispatch(showNotification(NotificationType.Warn, '上传失败'))
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }

      dispatch(showNotification(NotificationType.Alarm, '上传失败'))
    }).then(() => {
      if (!isMounted()) {
        return
      }
      setIsUploading(false)
    })
  }, [directoryId])

  const uploadHandler = useDropzone({
    onDrop: onUploadFiles,
    // accept: AccpetFileTypeExt,
    multiple: true,
    isDragActive: false,
  })

  const onReplaceFile = useCallback(acceptedFiles => {
    if (isUploading || !acceptedFiles[0]) {
      return;
    } else if (acceptedFiles[0].size >= 50 * 1024 * 1024) {
      setReplaceFileId(null)
      dispatch(showNotification(NotificationType.Warn, '上传文件大小超出50MB的限制!'))
      return
    } else if (!isLegalAcceptExt(acceptedFiles[0].name)) {
      setReplaceFileId(null)
      dispatch(showNotification(NotificationType.Warn, '请选择图片或PDF文件'))
      return
    }
    setIsUploading(true)
    const form = new FormData();
    form.append('file', acceptedFiles[0]);
    axios.post(replaceDirectoryFile(replaceFileId), form).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        dispatch(showNotification(NotificationType.Success, '替换成功'))
        setFiles(files => files.map(f => f.key === replaceFileId ? { ...e.data.data, refreshId: nanoid() } : f))
        setReplaceFileId(null)
      } else {
        dispatch(showNotification(NotificationType.Warn, e.data.message))
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
      dispatch(showNotification(NotificationType.Alarm, '替换失败'))
    }).then(() => {
      if (!isMounted()) {
        return
      }
      setIsUploading(false)
    })
  }, [replaceFileId])

  const replaceHandler = useDropzone({
    onDrop: onReplaceFile,
    // accept: AccpetFileTypeExt,
    multiple: false,
    isDragActive: false,
    maxSize: 50000 * 1000//byte
  })

  const selectAllState =
    filteredFiles.length > 0 ?
      (filteredFiles.length === selectedItem.length ?
        ThreeStateValue.Checked
        : (selectedItem.length > 0 ?
          ThreeStateValue.Indeterminate
          : ThreeStateValue.Unchecked
        )
      )
      : ThreeStateValue.Unchecked

  const renderDir = (id, name, children) => (
    <Collapsible key={id} itemId={id} title={
      <div className="resource-dir-header" title={id === directoryId ? '不能移动到相同目录' : name}>
        <div className="resource-dir-name">{name}</div>
        {id === directoryId &&
          <div className="resource-dir-menu" style={{ width: 'auto' }}>
            <small className="text-gray float-right">文件当前目录</small>
          </div>}
      </div>
    } className={id === movingDestDir ? 'active' : ''}>
      {children ? children.map(c => renderDir(c.id, c.name, c.children)) : null}
    </Collapsible >)

  return (
    <>
      <div className="resource-directory-main" >
        <div className="resource-directory-controls">
          <div style={{ flexGrow: 1 }}>
            <span {...uploadHandler.getRootProps()}>
              <input {...uploadHandler.getInputProps()} />
              <WithTooltip>
                <Button sizeClass="small" style={{ marginRight: 4 }} type="primary-blue" text="上传文件" disabled={isLoading} isLoading={isUploading} />
                <Tooltip><div>单个文件不超过50M</div></Tooltip>
              </WithTooltip>
            </span>
            <Button sizeClass="small" style={{ marginRight: 4 }} text="刷新" onClick={() => {
              setBaseRandomId(nanoid());
              getFiles(directoryId)
              }} isLoading={isLoading} />
            {selectedItem.length > 0 && <Button sizeClass="small" style={{ marginRight: 4 }} text="移动到"
            onClick={() => {
              setMovingFileId(selectedItem);
              setMovingDestDir(null)
              }} />}
            {selectedItem.length > 0 && <Button sizeClass="small" text="删除" onClick={() => setDeleteConfirmId(selectedItem)} isLoading={isDeleting} />}
          </div>
          <div>
            <Input type="discreet" icon="abb/search" value={keyword} onValueChange={onKeywordChanged} placeholder="在当前目录查找" /></div>
          <div style={{ marginLeft: 16 }}>
            <ToggleButtonGroup
              selected={displayMode} onChange={setDisplayMode}
              sizeClass="small" multiselect={false} style={{ margin: 0 }}>
              <ToggleButton icon="abb/thumbnail-view" />
              <ToggleButton icon="abb/list" />
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="resource-directory-details">
          <div style={{ marginBottom: 8 }}>
            <Checkbox
              label={selectAllState === ThreeStateValue.Unchecked ? "全选" : `已选择 ${selectedItem.length} 个文件`}
              disabled={filteredFiles.length === 0}
              value={selectAllState}
              onChange={() => setSelectedItem(selectAllState === ThreeStateValue.Checked ? [] : filteredFiles.map(f => f.key))}
              sizeClass="small" />
          </div>
          <div className={`file-list layout-${displayMode[0] === 0 ? "brick" : "list"}`}>
            {!isLoading && filteredFiles.length === 0 && <p className="central-position text-gray">此目录中没有文件</p>}
            {!isLoading && filteredFiles.length > 0 && filteredFiles.map(file => {
              const isSelected = selectedItem.indexOf(file.key) >= 0;
              const isFocused = renameFileId === file.key
                || replaceFileId === file.key
                || movingFileId.indexOf(file.key) >= 0
                || deleteConfirmId.indexOf(file.key) >= 0
              return (
                <div className={`file-item${isSelected ? ' selected' : ''}${isFocused ? ' focused' : ''}`} key={file.key}>
                  <div className="file-selector">
                    <Checkbox sizeClass="small" value={isSelected} onChange={e => toggleSelection(file.key, !isSelected)} />
                  </div>
                  {displayMode[0] === 0 &&
                    <div className="file-thumb" onClick={e => toggleSelection(file.key, !isSelected)} >
                      {renderThumb(env.fileResource, file.key, file.extensionName, file.refreshId ?? baseRandomId)}
                    </div>}
                  <div className="file-name" title={file.name}>{file.name}</div>
                  <div className="file-size">{file.size}k</div>
                  <div className="file-controls">
                    <DropdownButton >
                      <DropdownButtonOption onClick={() => window.open(`${env.fileResource}${file.key}`)} >打开</DropdownButtonOption>
                      <DropdownButtonOption onClick={() => {
                        setRenameFileId(file.key);
                        setInputFileName(file.name)
                        }} >重命名</DropdownButtonOption>
                      <DropdownButtonOption onClick={() => {
                        setMovingFileId([file.key]);
                        setMovingDestDir(null)
                        }} >移动到...</DropdownButtonOption>
                      <DropdownButtonOption onClick={() => setReplaceFileId(file.key)} >替换</DropdownButtonOption>
                      <DropdownButtonOption onClick={() => setDeleteConfirmId([file.key])} >删除</DropdownButtonOption>
                    </DropdownButton>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {isLoading && <div className="central-position"><LoadingIndicator sizeClass="small" color="blue" type="radial" /></div>}
      </div>

      <Dialog
        showCloseButton={false}
        closeOnLostFocus={true}
        dimBackground={true}
        isOpen={deleteConfirmId.length > 0}
        title="删除文件">
        <div>确认删除所选文件？</div>
        <p>
          <Button text="删除" sizeClass="small" type="primary-blue" onClick={() => onDelete(deleteConfirmId)} isLoading={isDeleting} />
          <Button text="取消" sizeClass="small" style={{ marginLeft: 8 }} onClick={() => setDeleteConfirmId([])} />
        </p>
      </Dialog>

      <Dialog
        showCloseButton={false}
        closeOnLostFocus={true}
        dimBackground={true}
        isOpen={replaceFileId !== null}
        title="替换文件">
        <div>确认替换所选文件？</div>
        <p>
          <span {...replaceHandler.getRootProps()}>
            <input {...replaceHandler.getInputProps()} />
            <Button text="替换" sizeClass="small" type="primary-blue" isLoading={isUploading} />
          </span>
          <Button text="取消" sizeClass="small" style={{ marginLeft: 8 }} onClick={() => setReplaceFileId(null)} />
        </p>
      </Dialog>

      <SidePanel show={renameFileId !== null} onClickBackdrop={() => setRenameFileId(null)}>
        <div className='side-panel-header'>
          <div className='side-panel-title'>重命名</div>
        </div>
        <div className='side-panel-body'>
          <div className='form'>
            <div className='form-group'>
              <Input
                showValidationBarWhenInvalid
                showValidationIconWhenInvalid
                clearOnEscape={false}
                label='文件名称'
                additionalLabel='必填'
                value={inputFileName}
                onValueChange={e => {
                  setInputFileName(e);
                  setErrorFileName(null)
                }}
                validationResult={errorFileName}
                disabled={isRenaming}
              />
            </div>
          </div>
          <div className='form-group-button'>
            <div className="button-group-left">
              <Button text='确定' type='primary-blue' sizeClass='small' onClick={onRename} isLoading={isRenaming} />
              <Button text='取消' type='normal' sizeClass='small' onClick={() => setRenameFileId(null)} style={{ marginLeft: 8 }} />
            </div>
          </div>
        </div>
      </SidePanel>

      <SidePanel show={movingFileId.length > 0} onClickBackdrop={() => setMovingFileId([])} isFlexLayout>
        <div className='side-panel-header'>
          <div className='side-panel-title'>移动到</div>
          <div className='side-panel-tools'>
            <WithTooltip >
              <Button text="确定" sizeClass="small" type="primary-blue" onClick={onMove} disabled={movingDestDir === null} isLoading={isMoving} />
              <Tooltip disabled={movingDestDir !== null} ><div>请选择目录</div></Tooltip>
            </WithTooltip>
            <Button text="取消" sizeClass="small" onClick={() => setMovingFileId([])} />
          </div>
        </div>
        <div className='side-panel-body no-padding'>
          <CollapsibleContainer className="collapsible-directory"
            monochrome
            singleOpenMode={false}
            expandOnTitleClick={false}
            onItemClick={e => e.props.itemId === directoryId ? null : setMovingDestDir(e.props.itemId)}
            style={{ height: 'auto' }}
          >
            {directory?.map(d => renderDir(d.id, d.name, d.children))}
          </CollapsibleContainer>
        </div>
      </SidePanel>
    </>)
}

