import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getProject } from "slices/ezProjectSlice"
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react"
import { nanoid } from "nanoid"
import { t } from "i18next"
import moment from "moment"
import { getCustomers, fetchGetCustomersAsync } from "slices/maindataSlice"
import { getDictionary } from "slices/dataDictionarySlice"
import { getUsers } from "slices/userSlice"
import { getDataDictionaryDetail } from "common/dataDictionary"
import { SlidingCard } from "components/SlidingCard"

export default function ProjectInfo({ onEdit }) {
  const project = useSelector(getProject)
  const dictionary = useSelector(getDictionary)
  const dt = getDataDictionaryDetail(dictionary, "PrjType")
  const [pageIndex, setPageIndex] = useState(0)
  const users = useSelector(getUsers)

  let profiles = []
  profiles = [
    [
      { title: t("project.no"), value: project.no },
      { title: t("project.name"), value: project.name },
      { title: t("project.category"), value: dt.details.find(d=>d.code==project.category)?.displayText },
      { title: t("project.manager"), value: users.find((d) => d.id == project?.manager)?.name},
      { title: t("project.startDate"), value: moment(project.startDate).format("yyyy-MM-DD")  },
      { title: t("project.endDate"), value:  moment(project.endDate).format("yyyy-MM-DD") },
      { title: t("project.description"), value: project?.description },
    ],
  ]

  useEffect(() => {
    window.addEventListener("resize", onWindowResize)
    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [])
  const onWindowResize = () => {
    setPageIndex(0)
  }
  return (
    profiles && (
      <SlidingCard>
        {profiles.map((list) =>
          list.length === 0 ? null : (
            <Table title="项目信息" sizeClass="medium" borderType="discreet" key={nanoid()}>
              <tbody>
                {list.map((item) => (
                  <tr key={nanoid()}>
                    <td title={item.title}>{item.title}</td>
                    <td>
                      {item.value && (
                        <WithTooltip>
                          <span>{item.value}</span>
                          <Tooltip>
                            <div>
                              {item.title}：{item.value}
                            </div>
                          </Tooltip>
                        </WithTooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        )}
      </SlidingCard>
    )
  )
}
