import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { CommonIndex, CommonTable } from 'components/LoongInCommon'
import { Button, Dialog } from '@abb/abb-common-ux-react'
import { FileSelector } from 'components/FileSelector'
import { getMaintenanceRangeTree, setMaintenanceRangeBind } from 'common/apis/maintenanceRange'
import { forwardRef } from 'react'
import { useImperativeHandle } from 'react'
import { t } from 'i18next'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
import { setProjectProperty } from 'common/apis/maindata'

export const SearchTemplate = forwardRef(({ onRefresh }, ref) => {
  const refIndex = useRef(null)
  const [isVisible, setIsVisible] = useState(false)
  const [checkItems, setCheckItems] = useState([])
  const [projectId, setProjectId] = useState(null)
  const dispatch = useDispatch()

  const childrenColumns = [
    {
      title: '模板名称',
      key: 'title',
      width: '300',
    },
  ]

  const list = [{ name: 'Default', title: '默认模板' },{ name: 'AliyunDatacenter', title: '阿里云数据中心模板' }]

  const showDialog = (projectId) => {
    setProjectId(projectId)
    setIsVisible(true)
  }

  const saveBind = () => {
    console.log(checkItems)
    const request = {
      projectId: projectId,
      name: 'ReportTemplate',
      Value: checkItems[0],
    }
    axios.post(setProjectProperty(), request).then((response) => {
      setIsVisible(false)
      onRefresh && onRefresh()
    })
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))

  const onConfirm = () => {
    if (!checkItems || checkItems.length <= 0) {
      dispatch(showNotification(NotificationType.Alarm, '请选择维保项'))
      return
    }
    saveBind()
  }
  const onCancel = () => {
    setIsVisible(false)
  }
  const _onSelectionChange = (items) => {
    console.log(items)
    setCheckItems(items.map((i) => i.name))
  }
  //返回页面
  return (
    <>
      <Dialog
        style={{ width: '960px', height: '800px' }}
        showCloseButton={true}
        closeOnLostFocus
        closeOnEscape={false}
        dimBackground={true}
        isOpen={isVisible}
        title={'选择报告模板'}
        standardButtonsOnBottom={[
          {
            text: t('b_confirm'),
            type: 'primary-blue',
            handler: onConfirm,
          },
          {
            text: t('b_cancel'),
            type: 'normal',
            handler: onCancel,
          },
        ]}
        onClose={() => setIsVisible(false)}
      >
        <div style={{ height: '640px' }}>
          <CommonTable
            zebra={true}
            columns={childrenColumns}
            checkedItems={checkItems}
            canSelection={true}
            multipleChoice={false}
            data={list}
            showPage={false}
            keyField={'name'}
            onSelectionChange={_onSelectionChange}
          />
        </div>
      </Dialog>
    </>
  )
})
