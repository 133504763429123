import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { addCustomerRight, createContact, getAllRights, updateContact, updateCustomerRight } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { findTree, treeMap } from 'common/utils'
import moment from 'moment'
import { da } from 'date-fns/locale'

export const RightFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [rights, setRights] = useState([])
  const [formItem, setFormItem] = useState([])
  const [innerData, setInnerData] = useState({})
  const [data, setData] = useState({})
  const [parameters, setParameters] = useState([])
  useEffect(() => {
    axios.post(getAllRights()).then((r) => {
      setRights(r.data)
    })
  }, [])
  useEffect(() => {
    if (formData?.arguments) {
      const args = JSON.parse(formData.arguments)
      formData = { ...formData, ...args }
    }
    if (formData?.rightId && rights) {
      const right = findTree(rights, (r) => r.key === formData.rightId)
      console.log(right)
      setParameters(right.parameters.split(',').filter((r) => r && r != ''))
    }
    setInnerData(formData)
    setData(formData)
  }, [formData, rights])
  //初始化数据
  const initData = {
    id: '',
    rightId: '',
    arguments: '',
    beginDate: null,
    endDate: null,
  }

  //表单数据项
  const changeFormItem = () => {
    const items = [
      {
        type: 'text',
        dataType: 'text',
        label: '客户名称',
        fieldName: 'customerName',
        maxLength: 256,
        visible: !formData || !!formData.customerName,
        disabled: true,
      },
      {
        type: 'dropdown',
        label: '权益',
        fieldName: 'rightId',
        options: rights
          ? treeMap(rights, (c) => {
              return { disabled: c.children && c.children.length > 0, label: c.title, value: c.key }
            })
          : [],
          disabled: formData && !!formData.rightId,
        },
      {
        type: 'date',
        label: '开始日期',
        fieldName: 'beginDate',
        dateFormat: 'yyyy-MM-dd',
        maxDate: innerData ? innerData.endDate : undefined,
        showClearIcon: true,
      },
      {
        type: 'date',
        label: '结束日期',
        fieldName: 'endDate',
        dateFormat: 'yyyy-MM-dd',
        minDate: innerData ? innerData.beginDate : undefined,
        showClearIcon: true,
      },
    ]
    parameters.forEach((p) => {
      items.push({
        type: 'text',
        dataType: 'text',
        label: p,
        fieldName: p,
      })
    })
    setFormItem(items)
  }

  useEffect(() => {
    if (rights) {
      changeFormItem()
    }
  }, [rights, parameters, innerData])
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      code: [CommonRule.Require],
      externalUrl: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    const args = {}
    parameters.forEach((p) => {
      args[p] = inputdata[p]
    })
    return {
      id: inputdata.id,
      rightId: inputdata.rightId,
      customerId: inputdata.customerId,
      beginDate: inputdata.beginDate,
      endDate: inputdata.endDate,
      arguments: JSON.stringify(args),
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    const args = {}
    parameters.forEach((p) => {
      args[p] = inputdata[p]
    })
    return {
      rightId: inputdata.rightId,
      customerId: inputdata.customerId,
      beginDate: inputdata.beginDate,
      endDate: inputdata.endDate,
      arguments: JSON.stringify(args),
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }

  const onInnerCancel = () => {
    setParameters([])
    onCancel && onCancel()
  }
  const onInnerSubmit = () => {
    setParameters([])
    onSubmit && onSubmit()
  }
  const onChange = (data, preData) => {
    if (
      (data.beginDate && !data.endDate) ||
      (preData.beginDate && moment(preData.beginDate).add(1, 'y').format('yyyy-MM-DD') === preData.endDate && data.endDate === preData.endDate)
    ) {
      data.endDate = moment(data.beginDate).add(1, 'y').format('yyyy-MM-DD')
    }
    console.log(data)
    if (data.rightId != preData.rightId) {
      const right = findTree(rights, (r) => r.key === data.rightId)
      console.log(right)
      setParameters(right.parameters.split(',').filter((r) => r && r != ''))
    }
    setInnerData(data)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='权益数据'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={addCustomerRight}
      updateData={updateCustomerRight}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={data}
      onSubmit={onInnerSubmit}
      onCancel={onInnerCancel}
      height={clientHeight - 280}
      onChange={onChange}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
