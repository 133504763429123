import React, { useState, forwardRef, useImperativeHandle, Children, cloneElement } from "react"
import { t } from "i18next"
import { CommonFormItem } from ".."
import { useEffect } from "react"
import { Row, Col } from "components/Grid"

export const CommonRule = {
  //必填
  Require: {
    required: true,
    message: "t_required",
  },
  //邮箱
  Email: {
    pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{1,4})$/,
    message: "t_ruleEmail", //"邮箱地址格式错误!",
  },
  //手机号
  Mobile: {
    pattern: /^1[356789]\d{9}$/,
    message: "t_ruleMobile", //"手机号格式错误!",
  },
  //身份证
  Id: {
    pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    message: "t_ruleId", //"身份证格式错误!",
  },
  //url
  Url: {
    pattern: /^((https?|ftp|file):\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
    message: "t_ruleUrl", //
  },
  //ip地址
  Ipv4: {
    pattern: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    message: "t_ruleIpv4", //
  },
  //日期
  Date: {
    pattern: /^\d{4}(\-)\d{1,2}\1\d{1,2}$/,
    message: "t_ruleDate", //
  },
  //日期时间
  DateTime: {
    pattern: /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/,
    message: "t_ruleDateTime", //
  },
  //整数
  Int: {
    pattern: /^[-+]?\d*$/,
    message: "t_ruleInt", //
  },
  //小数
  Float: {
    pattern: /^[-\+]?\d+(\.\d+)?$/,
    message: "t_ruleFloat", //
  },
  //邮政编号
  PostalNo: { pattern: /^\d{6}$/, message: "t_rulePostalNo" },
  //QQ号
  QQ: { pattern: /^[1-9][0-9]{4,10}$/, message: "t_ruleQQ" },
  //微信号
  Wechat: {
    pattern: /^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/,
    message: "t_ruleWechat", //
  },
  //车牌号
  CarNo: {
    pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/,
    message: "t_ruleCarNo", //
  },
  //只含字母的字符串
  Letter: { pattern: /^[a-zA-Z]+$/, message: "t_ruleLetter" },
  //包含中文的字符串
  CN: { pattern: /[\u4E00-\u9FA5]/, message: "t_ruleCN" },
  //密码强度校验：密码中必须包含大小写字母、数字、特称字符，至少6个字符，最多30个字符
  Password: {
    pattern: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{6,30}/,
    message: "t_rulePassword", //
  },
}
export const CommonForm = forwardRef(
  (
    {
      children,
      initData, //初始化数据
      newFormData, //新数据，用于更新
      formItem, //表单项
      rules, //校验规则
      onChange, //表单数据改变事件
      onChangeBefore, //表单数据改变前触发事件
      disabled, //是否不可编辑
      render, //渲染
    },
    ref
  ) => {
    const [error, setError] = useState({})
    const [innerData, setInnerData] = useState(initData || {})
    const [commonformItems, setCommonformItems] = useState(null)
    const changeError = (fieldName, err) => {
      // console.log(fieldName, err)
      if (!err) return
      const newerror = { ...error }
      if (err.valid) {
        delete newerror[fieldName]
      } else newerror[fieldName] = err
      // console.log(fieldName, newerror)
      setError(newerror)
    }
    /*
  rules 的格式： {
    email: [
      {
        required: true,
        message: ()=>'请输入邮箱!',
      },
      {
        pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{1,4})$/,//正则表达式
        message: ()=>'邮箱地址格式错误!',
      },
      {
        validator: (value)=>{
          //...校验
          return true;
        },
        message: ()=>'校验错误!',
      }
    ],
    ...
  }
    */
    //验证FormItem
    const onValidateAndChangeError = (fieldName, value) => {
      let err = onValidate(fieldName, value)
      changeError(fieldName, err)
    }
    const onValidate = (fieldName, value) => {
       console.log("onValidate", fieldName, value)
      if (rules && rules[fieldName]) {
        let hasError = false
        let err = {}
        for (let i = 0; i < rules[fieldName].length; i++) {
          const rule = rules[fieldName][i]
          if (rule.required && (!value || value === "" || (value instanceof Array && value.length===0))) {
            err = {
              valid: false,
              text: rule.message.indexOf("t_") >= 0 ? t(rule.message) : rule.message,
            }
            hasError = true
            break
          } else if ((value && rule.pattern && !rule.pattern.test(value)) ) {
            err = {
              valid: false,
              text: rule.message.indexOf("t_") >= 0 ? t(rule.message) : rule.message,
            }
            hasError = true
            break
          }
          else if( (value && rule.match && value!==innerData[rule.match])){
            err = {
              valid: false,
              text: rule.message.indexOf("t_") >= 0 ? t(rule.message) : rule.message,
            }
            hasError = true
            break
          }

          else if( (value && rule.validator && !rule.validator(value))){
            err = {
              valid: false,
              text: rule.message.indexOf("t_") >= 0 ? t(rule.message) : rule.message,
            }
            hasError = true
            break
          }
        }
        if (!hasError) {
          err = {
            valid: true,
            text: "",
          }
        }
        console.log(err);
        return err
        
      }
    }
    useEffect(() => {
      if (newFormData) setInnerData(newFormData)
      else setInnerData(initData || {})
    }, [newFormData])
    const changeData = (fieldName, value, preVal) => {
      // console.log("changeData", fieldName, value, preVal)
      let oldInnerData = innerData
      let newInnerData = { ...innerData }
      newInnerData[fieldName] = value
      onChangeBefore && onChangeBefore(newInnerData, oldInnerData,fieldName)
      setInnerData(newInnerData)
      onChange && onChange(newInnerData, oldInnerData,fieldName)
    }
    const validate = (callback) => {
       console.log(innerData)
      let newerrors = {}
      Object.keys(innerData).forEach((fieldName) => {
        let err = onValidate(fieldName, innerData[fieldName])
        if (err && !err.valid) newerrors[fieldName] = err
      })
      setError(newerrors)
      let valid = Object.keys(newerrors).length == 0
      callback && callback(valid, valid ? innerData : newerrors)
    }
    const resetForm = () => {
      setError({})
      setInnerData(initData)
      return initData
    }

    useEffect(() => {
      if (formItem) changeCommonformItems()
    }, [formItem, innerData, error, rules])
    const renderCommonFormItem = (item, index) => {
      let fieldName = item.fieldName
      let label = item.label
      return (
        <CommonFormItem
          slot={item.slot}
          key={index}
          type={item.type}
          dataType={item.dataType}
          additionalLabel={item.additionalLabel}
          showClearIcon={item.showClearIcon}
          fieldName={item.fieldName}
          searchable={item.searchable}
          disabled={disabled || item.disabled}
          options={item.options}
          style={item.style}
          innerStyle={item.innerStyle}
          placeholder={item.placeholder}
          maxLength={item.maxLength}
          maxDate={item.maxDate}
          minDate={item.minDate}
          dateFormat={item.dateFormat}
          showYearPicker={item.showYearPicker}
          showMonthYearPicker={item.showMonthYearPicker}
          showYearDropdown={item.showYearDropdown}
          showTimeSelect={item.showTimeSelect}
          visible={item.visible}
          multiselect={item.multiselect}
          onKeyUp={item.onKeyUp}
          onEnter={item.onEnter}
          render={item.render}
          value={item.getValue ? item.getValue(innerData) : innerData ? innerData[fieldName] : ""}
          err={error ? error[fieldName] : {}}
          onValidate={onValidateAndChangeError}
          inputAttributes={item.inputAttributes}
          onChange={(fieldName, val, preVal) => {
            changeData(fieldName, val, preVal)
          }}
          label={
            <>
              <span>{label}</span>
              {rules && rules[fieldName] && rules[fieldName].findIndex((c) => c["required"] === true) >= 0 && <span style={{ color: "red" }}>*</span>}
            </>
          }
        >
          {item.children}
        </CommonFormItem>
      )
    }
    const renderFormItem = (item, index) => {
      if (item.type === "row") {
        if (item.children)
          return (
            <Row
              slot={item.slot}
              key={"r" + index}
              align={item.align} //flex 布局下的垂直对齐方式，可选值为 top、middle、bottom	String
              justify={item.justify} //布局下的水平排列方式，可选值为 start、end、center、space-around、space-between
              gutter={item.gutter || 10} //栅格间距，单位 px，左右平分 类型：Number 默认：0
              wrap={item.wrap} //是否自动换行	类型：Boolean	默认：true
              className={item.className} //自定义的class名称
            >
              {item.children.map((itm, idx) => {
                return (
                  <Col
                    key={"c" + index + "_" + idx}
                    flex={itm.flex} // flex 布局属性
                    span={itm.span} // 栅格的占位格数，可选值为0~24的整数，为 0 时，相当于 display:none
                    order={itm.order} // 栅格的顺序，在 flex 布局模式下有效
                    offset={itm.offset} // 栅格左侧的间隔格数，间隔内不可以有栅格
                    push={itm.push} // 栅格向右移动格数
                    pull={itm.pull} // 栅格向左移动格数
                    className={itm.className} // 自定义的class名称
                    xs={itm.xs} //<576px 响应式栅格，可为栅格数或一个包含其他属性的对象
                    sm={itm.sm} // >=576px 响应式栅格，可为栅格数或一个包含其他属性的对象
                    md={itm.md} // >=768px 响应式栅格，可为栅格数或一个包含其他属性的对象
                    lg={itm.lg} // >=992px 响应式栅格，可为栅格数或一个包含其他属性的对象
                    xl={itm.xl} // >=1200px 响应式栅格，可为栅格数或一个包含其他属性的对象
                    xxl={itm.xxl} // >=1600px 响应式栅格，可为栅格数或一个包含其他属性的对象
                  >
                    {renderFormItem(itm.col, idx)}
                  </Col>
                )
              })}
            </Row>
          )
      } else {
        return renderCommonFormItem(item, index)
      }
    }

    const changeCommonformItems = () => {
      let formItemList = formItem.map((item, index) => renderFormItem(item, index))
      // templete
      if (!render) {
        setCommonformItems(formItemList)
      } else {
        let form = {}
        formItemList.forEach((c) => {
          let slotname = c?.props?.slot
          if (!form[slotname]) {
            form[slotname] = []
          }
          form[slotname].push(c)
        })
        setCommonformItems(render(form))
        // return children
      }
    }
    const childrenWithProps = Children.map(children, (child) => {
      let fieldName = child.props["fieldName"]
      let label = child.props["label"]
      return cloneElement(child, {
        value: innerData ? innerData[fieldName] : "",
        err: error ? error[fieldName] : {},
        onValidate: onValidateAndChangeError,
        onChange: (fieldName, val, preVal) => {
          changeData(fieldName, val, preVal)
        },
        label: (
          <>
            <span>{label}</span>
            {rules && rules[fieldName] && rules[fieldName].findIndex((c) => c["required"] === true) >= 0 && <span style={{ color: "red" }}>*</span>}
          </>
        ),
      })
    })
    useImperativeHandle(ref, () => ({
      validate,
      resetForm,
    }))
    return (
      <div className="form">
        {commonformItems}
        {childrenWithProps}
      </div>
    )
  }
)
