import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getMaterial as apiGetMaterial, getMaterialCatalogMetaDatas as apiGetMaterialCatalogMetaDatas,getMaterialDatas as apiGetMaterialDatas,getMaterialCatalog as apiGetMaterialCatalog } from "common/apis/maindata";
import { tr } from "date-fns/locale";

export const fetchMaterialAsync = createAsyncThunk("material/fetchMaterialAsync", async (id) =>
  axios
    .post(apiGetMaterial(id))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
export const fetchMaterialCatalogAsync = createAsyncThunk("material/fetchMaterialCatalogAsync", async (id) =>
  axios
    .post(apiGetMaterialCatalog(id))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
export const fetchMaterialMetaDataAsync = createAsyncThunk("material/fetchMaterialMetaDataAsync", async (catalogId) =>
  axios
    .post(apiGetMaterialCatalogMetaDatas(catalogId))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);

export const fetchMaterialDataAsync = createAsyncThunk("material/fetchMaterialDataAsync", async (materialId) =>
  axios
    .post(apiGetMaterialDatas(materialId))
    .then((response) => {
      return response?.data;
    })
    .catch((e) => {
      console.log(e);
    })
);
const materialSlice = createSlice({
  name: "material",
  initialState: {
    isLoadingMaterial: false,
    isLoadingMaterialCatalog: false,
    isLoadingMetaData: false,
    isLoadingData: false,
    catalog:null,
    material: null,
    metaData: null,
    data: [],
  },
  reducers: {
    setMaterial(state, action) {
      state.material = action.payload;
    },
    setMaterialCatalog(state, action) {
      state.catalog = action.payload;
    },
    setMaterialCatalogMetaDatas(state, action) {
      state.metaData = action.payload;
    },
    setMaterialDatas(state, action) {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [fetchMaterialAsync.pending]: (state, action) => {
      state.isLoadingMaterial=true
      state.material = null;
    },
    [fetchMaterialAsync.fulfilled]: (state, action) => {
      state.material = action.payload;
      state.isLoadingMaterial=false
    },
    [fetchMaterialCatalogAsync.pending]: (state, action) => {
      state.isLoadingMaterialCatalog=true
      state.catalog = null;
    },
    [fetchMaterialCatalogAsync.fulfilled]: (state, action) => {
      state.catalog = action.payload;
      state.isLoadingMaterialCatalog=false
    },
    [fetchMaterialMetaDataAsync.pending]: (state, action) => {
      state.isLoadingMetaData=true
      state.metaData = null;
    },
    [fetchMaterialMetaDataAsync.fulfilled]: (state, action) => {
      state.metaData = action.payload;
      state.isLoadingMetaData=false
    },
    [fetchMaterialDataAsync.pending]: (state, action) => {
      state.isLoadingData=true
      state.data = null;
    },
    [fetchMaterialDataAsync.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isLoadingData=false
    },
  },
});

export const { setMaterial,setMaterialCatalog,setMaterialCatalogMetaDatas,setMaterialDatas } = materialSlice.actions;
export const getMaterial = (state) => state.material.material;
export const getMaterialCatalog = (state) => state.material.catalog;
export const getMaterialCatalogMetaDatas = (state) => state.material.metaData;
export const getMaterialDatas = (state) => state.material.data;
export const getIsLoadingMaterial = (state) => state.material.isLoadingMaterial;
export const getIsLoadingMaterialCatalog = (state) => state.material.isLoadingMaterialCatalog;
export const getIsLoadingMaterialCatalogMetaDatas = (state) => state.material.isLoadingMetaData;
export const getIsLoadingMaterialDatas = (state) => state.material.isLoadingData;
export default materialSlice.reducer;
