import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Checkbox } from "@abb/abb-common-ux-react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { createTestModal, updateTestModal } from "common/apis/ezreport"

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  //初始化数据
  const initData = {
    name: "",
    modal: "",
    description: "",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: "模型名称",
      fieldName: "name",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "textarea",
      label: "备注",
      fieldName: "description",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "textarea",
      label: "模型内容",
      fieldName: "modal",
      maxLength: 50000,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      modal: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      modal: inputdata.modal,
      description: inputdata.description,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      modal: inputdata.modal,
      description: inputdata.description,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title="试验数据模型"
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createTestModal}
      updateData={updateTestModal}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
