export const getAll=()=>`api/EzReport/MaintenanceRange/getAll`

export const getDeviceTypes=()=>`api/EzReport/MaintenanceRange/getDeviceTypes`

export const createRange=()=>`api/EzReport/MaintenanceRange/create`
export const updateRange=()=>`api/EzReport/MaintenanceRange/update`

export const deleteRange=()=>`api/EzReport/MaintenanceRange/delete`

export const getMaintenanceRangeTree = () => `/api/EzReport/MaintenanceRange/getTree`

export const setMaintenanceRangeBind = () => `/api/EzReport/MaintenanceRange/setBind`

export const getMaintenanceRangePreview = () => `/api/EzReport/MaintenanceRange/preview`
