import React, { useState, Children } from "react"
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react"
import { style } from "d3"
import { nanoid } from "nanoid"

export default function SlidingCard({ children }) {
  const [pageIndex, setPageIndex] = useState(0)
  const items = Children.map(children, (child) => {
    return { title: child.props["title"], onActived: child.props["onActived"],otherButton:child.props["otherButton"], content: child }
  })
  const [activeItem, setActiveItem] = useState(items[0])

  const setItem = (index) => {
    setPageIndex(index)
    setActiveItem(items[index])
    activeItem && activeItem.onActived && activeItem.onActived()
  }
  const defaultButtons =
    items.length > 1
      ? [
          <Button key={nanoid()}
            sizeClass="small"
            shape="pill"
            type="discreet-black"
            icon="abb/left"
            disabled={pageIndex <= 0}
            onClick={() => setItem(pageIndex - 1)}
            style={{ opacity: pageIndex <= 0 ? 0.2 : 1, marginRight: 8 }}
          />,
          <Button key={nanoid()}
            sizeClass="small"
            shape="pill"
            type="discreet-black"
            icon="abb/right"
            disabled={pageIndex >= items.length - 1}
            onClick={() => setItem(pageIndex + 1)}
            style={{ opacity: pageIndex >= items.length - 1 ? 0.2 : 1 }}
          />,
        ]
      : []
  const buttons = activeItem.otherButton ? [...activeItem.otherButton, ...defaultButtons] : defaultButtons
  return (
    <div style={{ height: "100%" }}>
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">{activeItem?.title}</div>
          {buttons.length > 0 ? (
            <>
              <div className="widget-tools device-profile-paginator show-md show-lg" style={{ paddingTop: 10 }}>
                {buttons}
               
              </div>
              <div className="widget-tools device-profile-paginator show-xl">
                
                {buttons}
              </div>
            </>
          ) : (
            ``
          )}
        </div>
        <div className="widget-body device-profile-details">
          <div
            className={`layout`}
            style={{ width: `${items.length * 100}%`, transform: `translateX(${pageIndex == 0 ? 0 : (-pageIndex / items.length) * 100 + "%"})` }}
          >
            {items.map((i) => {
              return (
                <div key={nanoid()} style={{ width: `100%` }}>
                  {i.content}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
