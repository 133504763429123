import React from "react"
// import React, { useEffect } from "react"
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { getEnv } from "slices/envSlice"
import { getUserProfile } from "slices/userProfileSlice"
import { AppMainContent } from "@abb/abb-common-ux-react"
import { SideNavMenu, SideNavMenuItem } from "components/SideNavMenu"
import Dashboard from "./Dashboard"
import Factories from "./Factories"
import Projects from "./Projects"
import DeviceTypes from "./DeviceTypes"
import MaterialCatalogs from "./MaterialCatalogs"
import Instruments from "./Instruments"
import Materials from "./Materials"
import Material from "./Material"
import Device from "./Device"
import Clients from './Clients';
import Applications from "./Applications"
import Applicationgroups from "./Applicationgroups"
import Banners from "./Banners"
import Customers from "./Customers"
import decode from "jwt-decode"
import { ErrorBoundary } from "common/ErrorBoundary"
import { useTranslation } from "react-i18next"
import { maindata } from "../menu.js"
import translate from "../../i18n/translate"
import Project from "./Projects/Project"
import Stations from "./Projects/Stations"
import Rights from "./Rights"
import Customer from "./Customers/Customer"
import Documents from "./Documents"

function MainData() {
  // 动态加载
  // const Dashboard = React.lazy(() => import("./Dashboard"))
  // const Users = React.lazy(() => import("./Users"))
  // const Settings = React.lazy(() => import("./Settings"))
  // const IoT = React.lazy(() => import("./IoT"))
  // const Logs = React.lazy(() => import("./Logs"))
  // const Resource = React.lazy(() => import("./Resource"))

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const navItems = translate(maindata, t)

  const env = useSelector(getEnv)
  const userProfile = useSelector(getUserProfile)
  const oauth = useSelector((state) => state.oauth)
  const user = oauth?.accessToken ? decode(oauth?.accessToken) : null

  if (
    // (env.solution === "OnPremise" && user?.role === "admin") ||
    // userProfile?.visibilityCode === "Global"
    userProfile
  ) {
    return (
      <>
        <SideNavMenu>
          {navItems.map((item, index) => (
            <SideNavMenuItem
              key={index}
              itemKey={index}
              icon={item.icon}
              permission={item.permission}
              title={item.title}
              onClick={() => history.push(item.path)}
              active={
                location.pathname === item.path ||
                (item.path !== "/maindata" &&
                  location.pathname.indexOf(item.path) === 0)
              }
            />
          ))}
        </SideNavMenu>
        <ErrorBoundary>
          <AppMainContent style={{ boxSizing: "border-box" }}>
            <Switch>
              <Route exact path={path} component={Dashboard} />
              <Route path={`${path}/factories`} component={Factories} />
              <Route path={`${path}/projects/station/:id`} component={Stations} />
              <Route path={`${path}/projects/:id`} component={Project} />
              <Route path={`${path}/projects`} component={Projects} />
              <Route path={`${path}/deviceTypes`} component={DeviceTypes} />
              <Route path={`${path}/materialCatalogs`} component={MaterialCatalogs} />
              <Route path={`${path}/instruments`} component={Instruments} />
              <Route path={`${path}/materials/detail/:id`} component={Material} />
              <Route path={`${path}/materials`} component={Materials} />
              <Route path={`${path}/clients`} component={Clients} />
              <Route path={`${path}/applications`} component={Applications} />
              <Route path={`${path}/customers/:id`} component={Customer} />
              <Route path={`${path}/customers`} component={Customers} />
              <Route path={`${path}/documents`} component={Documents} />
              <Route path={`${path}/device/:id`} component={Device} />
              <Route path={`${path}/applicationgroups`} component={Applicationgroups} />
              <Route path={`${path}/banners`} component={Banners} />
              <Route path={`${path}/rights`} component={Rights} />
            </Switch>
          </AppMainContent>
        </ErrorBoundary>
      </>
    )
  } else if (!userProfile) {
    return null
  } else {
    return (
      <div className="grid root-grid">
        <p>{t("youHaveNotVisitPurview")}</p>
      </div>
    )
  }
}

export default MainData
