import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Checkbox, Dialog } from '@abb/abb-common-ux-react'
import { getInspenctionReportDetail } from 'common/apis/totalreports'
import '../../../styles/totalReport.scss'

import axios from 'axios'
import moment from 'moment'
import { Image } from 'components/Image'
import envs from './enviroment.json'

import { useEffect } from 'react'
import * as echarts from 'echarts'
import { nanoid } from 'nanoid'
import { chunk, groupBy, sumBy } from 'lodash'
import { optionCSS } from 'react-select/dist/index-f91a2068.cjs.prod'

export const TotalReport = forwardRef(({ report, readonly = false }, ref) => {
  const [detail, setDetail] = useState(null)
  const charRef = useRef(null)

  let index = 0
  const fetchPreviewInfo = (reportId) => {
    axios.post(getInspenctionReportDetail(reportId)).then((response) => {
      const data = response.data
      console.log(data)
      setDetail(data)
    })
  }

  useEffect(() => {
    if (report) {
      fetchPreviewInfo(report.id)
    }
  }, [report])

  const getReport = () => {
    return detail?.report
  }
  useImperativeHandle(ref, () => ({
    getReport,
  }))
  function getRankColorFromNum(rank) {
    try {
      var color = ''
      switch (rank) {
        case 1:
          color = 'red'
          break
        case 2:
          color = 'orange'
          break
        case 3:
          color = 'yellow'
          break
        default:
          color = 'green'
          break
      }
      return color
    } catch (exception) {}
  }

  const renderCheckItem = (item) => {
    const points = item.points
    return points.map((v, i, a) => {
      if (i == 0) {
        return (
          <tr key={nanoid()}>
            <td rowSpan={points.length}>{item.name}</td>
            <td>{v.name}</td>
            {v.options.slice(0, 3).map((opt, i, a) => {
              return (
                <td className='center' colSpan={i > 0 && i + 1 === a.length ? a.length - i + 1 : 1}>
                  <input type='checkbox' checked={v.result.option === opt.id} disabled={true} />
                  {opt.title}
                </td>
              )
            })}
          </tr>
        )
      } else {
        return (
          <tr key={nanoid()}>
            <td>{v.name}</td>
            {v.options.slice(0, 3).map((opt, i, a) => {
              return (
                <td className='center' colSpan={i > 0 && i + 1 === a.length ? a.length - i + 1 : 1}>
                  <input type='checkbox' checked={v.result.option === opt.id} disabled={true} />
                  {opt.title}
                </td>
              )
            })}
          </tr>
        )
      }
    })
  }

  const renderTestItem = (test, item) => {
    console.log(item)
    const modal = JSON.parse(item.modalString.replaceAll('rowspan', 'rowSpan').replaceAll('colspan', 'colSpan'))
    const r = JSON.parse(item.result)
    const result = { ...r?.result, ...item }
    return (
      <table key={nanoid()} className='center '>
        {renderTestModal(modal.content, result)}
      </table>
    )
  }

  const renderTestModal = (modal, result) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderTestModal(m.componments, result)}
            </td>
          )
        }
        if (m.type === 'option') {
          return <input type='checkbox' key={nanoid()} checked={result && result[m.name] == m.key} disabled={true} />
        }
        if (m.type === 'editonly') {
          return ''
        }
        if (m.type === 'input') {
          const data = result[m.name]
          let value = data
          if (m.options) {
            value = m.options.find((o) => o.key == data)?.title
          }
          return <span key={nanoid()}>{result && result[m.name] && (!!m.format ? m.format.replace('{value}', value) : value)}</span>
        }
        if (m.type === 'image') {
          return <Image key={nanoid()} id={result && result[m.name]} style={{ width: '95%' }} />
        }
        return renderTestModal(m.componments, result)
      })
    )
  }

  const renderDevice = (device, name) => {
    if (!device) {
      return null
    }
    return (
      <>
        {device.testItems && device.testItems.length > 0 && (
          <li className='canBeDeleted'>
            <ul data-order='2'>
              {device.testItems.map((test) => (
                <li key={nanoid()} id='expCircuitvoltage' className='li_exp'>
                  {test.modals.map((modal) => renderTestItem(test, modal))}
                </li>
              ))}
            </ul>
          </li>
        )}{' '}
        {device.checkItems && device.checkItems.length > 0 && (
          <table id='switchCheckTable'>
            <thead>
              <tr className='border-top-solid border-right-dotted'>
                <td>检查项目</td>
                <td>检查内容</td>
                <td colSpan='3'>检查结果</td>
              </tr>
            </thead>
            <tbody key={nanoid()}>{device.checkItems.map((i) => renderCheckItem(i))}</tbody>
          </table>
        )}
      </>
    )
  }
  return (
    detail && (
      <div className='total-report'>
        <div className='cover' style={{ textAlign: 'center', marginTop: 60 }}>
          <h2>
            <span data-switchingroom='name'>{report?.station?.name}</span>变电站
          </h2>
          <h2>运行设备</h2>
          <h2>{moment(report.project.startDate).format('yyyy年MM月')}巡检报告</h2>
          <h1 style={{ marginTop: 20 }}>
            <span data-project='customerCompanyName'>{report?.customer.name}</span>
          </h1>
        </div>
        <div className='content'>
          <ul>
            <li>
              <h5>1. 项目信息</h5>
              <ul>
                <li>
                  <h5>1.1 客户信息</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td style={{ width: '16%' }}>客户名称：</td>
                        <td id='maintenanceTeamCompanyName' style={{ width: '84%' }}>
                          {detail?.project?.customer?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>联系人</td>
                        <td id='maintenanceTeamPM'>{detail?.project?.contacts[0]?.name}</td>
                      </tr>
                      <tr>
                        <td>职位</td>
                        <td id='maintenanceTeamFax'>{detail?.project?.contacts[0]?.role}</td>
                      </tr>
                      <tr>
                        <td>手机</td>
                        <td id='maintenanceTeamContactInfo'>{detail?.project?.contacts[0]?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>邮箱</td>
                        <td id='maintenanceTeamEmail'>{detail?.project?.contacts[0]?.email}</td>
                      </tr>

                      <tr>
                        <td>公司地址</td>
                        <td id='maintenanceTeamAddress'>{detail?.project?.customer?.address}</td>
                      </tr>
                      <tr>
                        <td style={{ color: 'red' }}>客户分类</td>
                        <td id='maintenanceTeamSafetySupervisor'>最终用户</td>
                      </tr>
                    </tbody>
                  </table>
                </li>

                <li>
                  <h5>1.2 项目信息</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td>项目名称</td>
                        <td id='projectName' colSpan=' 3'>
                          {detail?.project?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>合同编号</td>
                        <td id='projectContractNo' colSpan='3'>
                          {detail?.project?.no}
                        </td>
                      </tr>
                      {Object.entries(groupBy(detail?.devices, 'type')).map(([k, v]) => {
                        return (
                          <tr>
                            <td style={{ width: '16%' }}>{k}型号(数量)</td>
                            <td>
                              {Object.entries(groupBy(v, 'name')).map(([n, g]) => {
                                return `${n}(${sumBy(g, (i) => i.devices.length)})`
                              })}
                            </td>
                          </tr>
                        )
                      })}
                      <tr className='border-bottom-solid'>
                        <td>备注</td>
                        <td colSpan='5' className='left'></td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>1.3 执行信息</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td style={{ width: '16%' }}>公司名称：</td>
                        <td id='maintenanceTeamCompanyName' style={{ width: '84%' }}>
                          {detail?.team?.company}
                        </td>
                      </tr>
                      <tr>
                        <td>项目经理</td>
                        <td id='maintenanceTeamPM'>{detail?.team?.manager?.name}</td>
                      </tr>
                      <tr>
                        <td>联系电话</td>
                        <td id='maintenanceTeamContactInfo'>{detail?.team?.manager?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>邮箱</td>
                        <td id='maintenanceTeamEmail'>{detail?.team?.manager?.email}</td>
                      </tr>
                      <tr>
                        <td>公司地址</td>
                        <td id='maintenanceTeamAddress'>厦门市翔安区舫山西二路 885 号</td>
                      </tr>
                      <tr>
                        <td>执行团队成员</td>
                        <td id='maintenanceTeamMembers'>
                          {detail?.team?.members
                            ?.filter((t) => t.role !== 'safety')
                            ?.map((t) => t.name)
                            .join(',')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
            <li>
              <h5>2. 变电站运行环境及系统状态</h5>
              <ul>
                <li>
                  <h5>2.1 变电站环境状况</h5>
                  {Object.entries(envs).map(([k, v]) => {
                    return (
                      <table>
                        <thead>
                          <tr>
                            <td className='blue' style={{ width: '20%' }}>
                              {k}
                            </td>
                            <td colSpan='2' style={{ width: '40%' }}></td>
                            <td style={{ width: '40%' }}>备注</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(v).map(([t, a]) => {
                            return (
                              <>
                                <tr>
                                  <td colSpan='4'>{t}</td>
                                </tr>
                                <tr>
                                  <td colSpan='3' data-option='ventilation'>
                                    {a.map((v) => {
                                      return (
                                        <label>
                                          <input type='checkbox' disabled={true} checked={detail?.station?.environments[t] === v} />
                                          {v}
                                        </label>
                                      )
                                    })}
                                  </td>
                                  <td></td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    )
                  })}
                </li>
              </ul>
            </li>
            <li>
              <h5>3. 巡检结果</h5>
              <ul>
                <li className='result'>
                  {detail?.devices[0]?.devices?.map((d) => {
                    const t = detail?.devices[0]
                    console.log('device', t)
                    return (
                      <>
                        <h5>{`开关柜柜号：${d.name}\t\t型号：${t.name}`}</h5>
                        <table id='deviceTitle' className='noborder-bottom'>
                          <tbody>
                            {d?.result?.components &&
                              d.result.components.map((device, index, arr) => {
                                return (
                                  <React.Fragment key={nanoid()}>
                                    <tr key={nanoid()} className={index == 0 ? 'border-top-solid' : ''}>
                                      <td>{device.parameters[0].title}：</td>
                                      <td id='cabinetModel'>{device.parameters[0].value ?? 'NA'}</td>
                                      <td>{device.parameters[1].title}：</td>
                                      <td id='cabinetServISNumber'>{device.parameters[1].value ?? 'NA'}</td>
                                      <td>{device.parameters[2].title}：</td>
                                      <td id='cabinetNumber2'>{device.parameters[2].value ?? 'NA'}</td>
                                    </tr>
                                    {chunk(
                                      device.parameters.filter((d) => !(d.id === 'modal' || d.id === 'sn' || d.id === 'productDate')),
                                      3
                                    ).map((t, i, a) => (
                                      <tr key={nanoid()} className={i >= a.length - 1 ? 'border-bottom-solid' : ''}>
                                        {t.map((d) => {
                                          const kv = d
                                          return (
                                            <React.Fragment key={nanoid()}>
                                              <td key={nanoid()}>{kv.title}</td>
                                              <td key={nanoid()} data-field='cabinetRatedVoltage' id='cabinetRatedVoltage'>
                                                {kv.value ?? 'NA'}
                                              </td>
                                            </React.Fragment>
                                          )
                                        })}
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                )
                              })}
                          </tbody>
                        </table>
                        {d?.result?.components &&
                          d.result.components.map((device) => {
                            if (!device) {
                              return null
                            }
                            return renderDevice(device.result)
                          })}
                        {!d?.result?.components && (
                          <table id='switchCheckTable'>
                            <thead>
                              <tr className='border-top-solid border-right-dotted'>
                                <td>检查项目</td>
                                <td>检查内容</td>
                                <td colSpan='3'>检查结果</td>
                              </tr>
                            </thead>
                            <tbody key={nanoid()}>
                              <tr>
                                <td colSpan={3}>没有提交数据。</td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </>
                    )
                  })}
                </li>
              </ul>
            </li>
            <li>
              <h5>4. 问题汇总</h5>
              <ul>
                <li>
                  <table id='DeviceMaintenanceSitulation'>
                    <thead className='border-bottom-dotted'>
                      <tr>
                        <td style={{ width: '21%' }}>序号</td>
                        <td style={{ width: '21%' }}>发现问题描述</td>
                        <td style={{ width: '10%' }}>附录图片</td>
                        <td style={{ width: '10%' }}>问题等级 </td>
                        <td style={{ width: '38%' }}>处理措施建议</td>
                      </tr>
                    </thead>
                    <tbody>
                      {detail?.devices[0]?.devices &&
                        detail?.devices[0]?.devices
                          ?.flatMap((d) => d?.result?.components ?? [])
                          .flatMap((item) => item.result.checkItems)
                          .flatMap((item) => item.points)
                          .filter((i) => i.result.code === '2')
                          .map((p, i) => {
                            const r = p.result
                            return (
                              <tr key={i}>
                                <td>{i}</td>
                                <td>{r.description}</td>
                                <td style={{ width: '17.5%' }}>
                                  <Image id={r.beforPhoto} style={{ width: '100%' }} />
                                </td>
                                <span className={`circle ${getRankColorFromNum(r.rank)}`}></span>
                                <td>{r.measures}</td>
                              </tr>
                            )
                          })}
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
            <li>
              <h5>5. 结论</h5>
              <ul>
                <li>
                  <textarea
                    id='summaryConclusion'
                    name='summaryConclusion'
                    readOnly={readonly}
                    onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, summaryConclusion: e.target.value } })}
                    value={detail?.report?.summaryConclusion}
                    style={{ width: '100%' }}
                    className='k-textbox'
                    rows='4'
                  ></textarea>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    )
  )
})
