import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { getCustomerPage, deleteCustomer } from 'common/apis/maindata'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'
import { getUsers } from 'slices/userSlice'
import { getDictionary } from 'slices/dataDictionarySlice'
import { getOrganization, fetchGetOrganizationAsync } from 'slices/organizationSlice'
import { getDataDictionaryDetail } from 'common/dataDictionary'
import { findTree } from 'common/utils'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
export default function Customers() {
  const history = useHistory()

  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const dispatch = useDispatch()
  const users = useSelector(getUsers)
  const dictionary = useSelector(getDictionary)
  const organization = useSelector(getOrganization)
  const custCate = getDataDictionaryDetail(dictionary, 'custCate')
  const srvArea = getDataDictionaryDetail(dictionary, 'srvArea')

  const refIndex = useRef(null)
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => showDetail(item),
        title: t('t_detail'),
      },
      {
        onClick: () => showStation(item),
        title: '变电站信息',
      },
    ]
  }
  //打开权限树
  const showStation = (item) => {
    history.push('/maindata/clients/' + item.id)
  }
  const showDetail = (item) => {
    history.push('/maindata/customers/' + item.id)
  }
  useEffect(() => {
    if (!organization) {
      dispatch(fetchGetOrganizationAsync())
    }
  }, [organization])
  //列定义
  const columns = [
    {
      title: 'customer.name',
      key: 'name',
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>客户已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
    {
      title: 'customer.code',
      key: 'code',
    },
    {
      title: 'customer.category',
      key: 'status',
      render: (item, index) => {
        const category = custCate?.details.find((d) => d.code == item.category)
        return category?.displayText
      },
    },
    {
      title: 'customer.manager',
      key: 'manager',
      render: (item, index) => {
        const manager = users.find((d) => d.id == item.manager)
        return manager?.name
      },
    },
    {
      title: 'customer.serviceUnitId',
      key: 'serviceUnitId',
      render: (item, index) => {
        const manager = findTree(organization, (d) => d.key == item.serviceUnitId)
        return manager?.title
      },
    },
    {
      title: 'customer.contact',
      key: 'contact',
    },
    {
      title: 'customer.phone',
      key: 'phone',
    },
    {
      title: 'customer.email',
      key: 'email',
    },
    {
      title: 'customer.area',
      key: 'area',
      render: (item, index) => {
        const category = srvArea?.details.find((d) => d.code == item.area)
        return category?.displayText
      },
    },
    {
      title: 'customer.address',
      key: 'address',
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getCustomerPage}
        deleteData={deleteCustomer}
        FormEdit={FormEdit}
        otherMenu={otherMenu}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        enableSorting={true}
      />
    </>
  )
}
