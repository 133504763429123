import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit, CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { createUser, updateUser, getUserRole } from 'common/endpoints'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { ImageSelector } from 'components/ImageSelector'

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [currAvatarImg, setAvatarCurrImg] = useState(formData?.extraProperties?.Avatar)
  const [currSignImg, setCurrSignImg] = useState(formData?.extraProperties?.SignImage)

  //初始化数据
  const initData = {
    confirmPassword: '',
    email: '',
    name: '',
    password: '',
    phoneNumber: '',
    avatar: '',
    introduction: '',
    roleNames: [],
    userName: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: t('user.userName'),
      fieldName: 'userName',
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('user.name'),
      fieldName: 'name',
      maxLength: 64,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('user.email'),
      fieldName: 'email',
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('user.phoneNumber'),
      fieldName: 'phoneNumber',
      maxLength: 16,
    },

    {
      type: 'div',
      label: '签名',
      fieldName: 'signImage',
      maxLength: 256,
      render: (item, callback) => {
        console.log(item)
        return (
          <div style={{ paddingTop: '12px' }}>
            <ImageSelector
              value={item}
              path={'/_/User/Avatars/'}
              onValueChanged={(d) => {
                setCurrSignImg(d[0].id)
              }}
            />
          </div>
        )
      },
    },
    {
      type: 'div',
      label: '头像',
      fieldName: 'avatar',
      maxLength: 256,
      render: (item, callback) => {
        console.log(item)
        return (
          <div style={{ paddingTop: '12px' }}>
            <ImageSelector
              value={item}
              path={'/_/User/Avatars/'}
              onValueChanged={(d) => {
                setAvatarCurrImg(d[0].id)
              }}
            />
          </div>
        )
      },
    },
    {
      type: 'text',
      dataType: 'textarea',
      label: '简介',
      fieldName: 'introduction',
      maxLength: 500,
    },
    {
      type: 'text',
      dataType: 'password',
      label: t('user.password'),
      fieldName: 'password',
      maxLength: 128,
    },
    {
      type: 'text',
      dataType: 'password',
      label: t('user.confirmPassword'),
      fieldName: 'confirmPassword',
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      email: [CommonRule.Require, CommonRule.Email],
      name: [CommonRule.Require],
      phoneNumber: [CommonRule.Require, CommonRule.Mobile],
      userName: [CommonRule.Require],
    }
    //添加时增加密码必填校验
    if (!id) {
      newrules.password = [CommonRule.Require, CommonRule.Password]
      newrules.confirmPassword = [CommonRule.Require]
    }
    setRules(newrules)
  }, [id])
  //表单的其他校验
  const otherValidate = (formData) => {
    if (formData.password && formData.password != formData.confirmPassword) {
      return t('user.twicePwdNotSame')
    }
    return ''
  }
  //获取用户对应的角色
  const getUserRoleAsync = async (id) => {
    try {
      const response = await axios.post(getUserRole(), { id })
      if (response.status === 200) {
        if (response.data.items) {
          setRoles(response.data.items)
        }
      } else {
        console.log('error to get user roles')
      }
    } catch (e) {
      console.log('exception to get user roles')
      console.log(e)
    } finally {
    }
  }
  //获取所有角色
  useEffect(() => {
    dispatch(fetchGetAllRoleAsync())
  }, [])
  //根据id获取用户对应拥有角色
  useEffect(() => {
    if (id) getUserRoleAsync(id)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      userId: inputdata.id,
      userInfo: {
        userName: inputdata.userName,
        name: inputdata.name,
        surname: inputdata.name,
        email: inputdata.email,
        extraProperties: {
          Avatar: currAvatarImg,
          SignImage: currSignImg,
          Introduction: inputdata.introduction,
        },
        phoneNumber: inputdata.phoneNumber,
        isActive: inputdata.isActive,
        lockoutEnabled: inputdata.lockoutEnabled,
        roleNames: roles.map((c) => c.name),
        password: inputdata.password,
        concurrencyStamp: inputdata.concurrencyStamp,
      },
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      confirmPassword: inputdata.confirmPassword,
      email: inputdata.email,
      name: inputdata.name,
      extraProperties: {
        Avatar: currAvatarImg,
        SignImage: currSignImg,
        Introduction: inputdata.introduction,
      },
      password: inputdata.password,
      phoneNumber: inputdata.phoneNumber,
      roleNames: roles.map((c) => c.name),
      userName: inputdata.userName,
    }
  }
  //修改所属角色
  const changeRoles = (item) => {
    let choice = roles.find((c) => c.name == item.name)
    if (choice == null) {
      let newroles = [...roles, item]
      setRoles(newroles)
    } else {
      let newroles = [...roles]
      let ridx = newroles.findIndex((c) => c.name == item.name)
      newroles.splice(ridx, 1)
      setRoles(newroles)
    }
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('user.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      otherValidate={otherValidate}
      createData={createUser}
      updateData={updateUser}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={{
        ...formData,
        avatar: formData?.extraProperties.Avatar,
        signImage: formData?.extraProperties.SignImage,
        introduction: formData?.extraProperties.Introduction,
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
      <div title={t('user.hasRoles')} style={{ height: clientHeight - 280 + 'px' }}>
        {allRoles &&
          allRoles.map((r, ridx) => {
            let choice = roles.find((c) => c.name == r.name)
            return <Checkbox key={ridx} sizeClass='small' label={r.name} value={choice != null} onChange={(val) => changeRoles(r)} />
          })}
      </div>
    </CommonFormEdit>
  )
})
