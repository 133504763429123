import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { deleteMaterial, getMaterials } from 'common/apis/maindata'
import { CommonIndex } from 'components/LoongInCommon'
import { MaterialFormEdit } from './MaterialFormEdit'
import { LeftPanel } from './LeftPanel'
import { Menu, MenuItem, SubMenu, MenuItemDivider } from '@abb/abb-common-ux-react'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { ImportFormEdit } from './ImportFormEdit'

export default function Materials() {
  const [MaterialFormData, setMaterialFormData] = useState(null)
  const [activeItem, setActiveItem] = useState(null)
  const [activDataIndex, setActivDataIndex] = useState('')
  const [deleteId, setDeleteId] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const dispatch = useDispatch()
  const formDevTypRef = useRef(null)
  const refIndex = useRef(null)
  const devTypRef = useRef(null)
  const history = useHistory()
  const refImport = useRef(null)

  const [searchData, setSearchData] = useState(null)
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => showDetail(item),
        title: t('t_detail'),
      },
    ]
  }
  //打开权限树
  const showDetail = (item) => {
    history.push('/maindata/materials/detail/' + item.id)
  }
  //列定义
  const columns = [
    {
      title: 'material.code',
      key: 'code',
    },
    {
      title: 'material.purchaseCode',
      key: 'purchaseCode',
    },
    {
      title: 'material.name',
      key: 'name',
    },
    {
      title: 'material.modal',
      key: 'modal',
    },
    {
      title: 'material.provider',
      key: 'provider',
    },
    {
      title: 'material.warranty',
      key: 'provider',
      render: (item, index) => {
        return item.warranty + '年'
      },
    },
  ]
  const onAddBefore = () => {
    return {
      materialCatalogId: activeItem.key,
      materialCatalogName: activeItem.title,
    }
  }

  const setShowOrganSidePanel = (visible) => {
    formDevTypRef.current.setShowSidePanel(visible)
  }
  const onSubmit = (d) => {
    if (activDataIndex) {
      setActivDataIndex('')
      setMaterialFormData(null)
      devTypRef.current.getMaterialsTree()
    } else {
      devTypRef.current.getMaterialsTree()
    }
  }
  const leftPanel = (
    <LeftPanel
      ref={devTypRef}
      onActive={(item) => {
        setActiveItem(item)
        setSearchData({ catalogId: item.key })
      }}
    ></LeftPanel>
  )
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getMaterials}
        searchData={searchData}
        deleteData={deleteMaterial}
        FormEdit={MaterialFormEdit}
        canAdd
        canEdit
        canDelete
        canInport
        tools={{ add: { text: t('b_addMaterial'), disabled: !activeItem },inport:{ disabled: !activeItem } }}
        onAddBefore={onAddBefore}
        onImport={() => refImport.current.showPanel()}
        refreshAfterDelete={true}
        enableSorting={false}
        leftPanelWidth={400}
        otherMenu={otherMenu}
        leftPanel={leftPanel}
        refreshWhenOpen={false}
        canSelection={false} //是否开启选择功能
        canBatchDel={false}
        searchClassName='searching-form-simple' //查询样式
        toolClassName='searching-form-simple searching-form-right-tool text-right' //工具栏样式
      />
      <MaterialFormEdit
        ref={formDevTypRef}
        formData={MaterialFormData}
        onCancel={() => {
          setActivDataIndex('')
          setMaterialFormData(null)
        }}
        id={activDataIndex}
        onSubmit={onSubmit}
      />
      <ImportFormEdit ref={refImport} catalog={activeItem} onSubmit={()=>{refIndex.current.query()}} />
    </>
  )
}
