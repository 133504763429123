import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { useSelector } from "react-redux"
import { createApplicationgroup, updateApplicationgroup } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [rules, setRules] = useState({})
  //初始化数据
  const initData = {
    name: "",
    order:0,
    description:''
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("applicationgroup.name"),
      fieldName: "name",
      maxLength: 256,
    },{
      type: "text",
      dataType: "number",
      label: t("applicationgroup.order"),
      fieldName: "order",
      maxLength: 256,
    },{
      type: "text",
      dataType: "textarea",
      label: t("applicationgroup.description"),
      fieldName: "description",
      maxLength: 256,
    }
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      order: [CommonRule.Require,CommonRule.Int],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      order: inputdata.order,
      description: inputdata.name
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      order: inputdata.order,
      description: inputdata.name
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("applicationgroup.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createApplicationgroup}
      updateData={updateApplicationgroup}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
