import React, { useState, useRef } from 'react'
import { Button, WithTooltip, Tooltip } from '@abb/abb-common-ux-react'
import { CommonFormItem } from 'components/LoongInCommon'
import { CommonForm, CommonRule } from 'components/LoongInCommon'
import './bootstrap.css'
import './login.css'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { findTenant, login, sendPasswordResetCode } from 'common/endpoints'

import { changeLanguage } from 'i18n'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { fetchAbpApplicationConfigurationAsync } from 'slices/abpApplicationConfigurationSlice'
import { hashCode } from 'common/utils'
import { CommonPrompt, CommonPromptButtons } from 'components/LoongInCommon/CommonPrompt'
export default function Login() {
  const { i18n, t } = useTranslation()
  const loginFormRef = useRef(null)
  const forgetFormRef = useRef(null)
  const dispatch = useDispatch()
  const location = window.location.toString()
  const [isSumbiting, setIsSumbiting] = useState(false)
  const [isSuccess, settIsSuccess] = useState(false)
  const [showForgetPannel, setShowForgetPannel] = useState(false)
  const currentUser = useCurrentUser()
  const loginInitData = {
    tenant: '',
    name: '',
    password: '',
    // rememberMe: true,
  }
  console.log(window.location)
  const forgetInitData = {
    email: '',
    // rememberMe: true,
  }
  const loginRules = {
    name: [
      {
        ...CommonRule.Require,
        message: t('login.rule.plsInputUserName'),
      },
    ],
    password: [
      {
        ...CommonRule.Require,
        message: t('login.rule.plsInputPwd'),
      },
    ],
  }

  const forgetRules = {
    email: [
      {
        ...CommonRule.Require,
        message: t('login.rule.plsInputEmail'),
      },
      CommonRule.Email,
    ],
  }
  const loginSystem = (payload, config) => {
    axios
      .post(login(), payload, config)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.id) {
            if (response.data.result.succeeded || response.data.result.requiresTwoFactor) {
              currentUser.setUser(response.data)
              dispatch(fetchAbpApplicationConfigurationAsync())
              window.location.reload()
            } else if (response.data.result.isLockedOut) {
              currentUser.setUser(null)
              dispatch(showNotification(NotificationType.Warn, t('login.msg.userLockOut')))
            } else if (response.data.result.isNotAllowed) {
              currentUser.setUser(null)
              dispatch(showNotification(NotificationType.Warn, t('login.msg.usernotAllowed')))
            } else {
              currentUser.setUser(null)
              dispatch(showNotification(NotificationType.Warn, t('login.msg.userOrPwdError')))
            }
          } else {
            currentUser.setUser(null)
            dispatch(showNotification(NotificationType.Warn, t('login.msg.loginFaild')))
          }
        } else {
          dispatch(showNotification(NotificationType.Warn, t('login.msg.loginFaild')))
        }
      })
      .catch((err) => {
        console.log('login err')
        console.log(err)
        dispatch(showNotification(NotificationType.Alarm, t('login.msg.loginFaild')))
      })
      .finally(() => {
        setIsSumbiting(false)
      })
  }
  const sumbitLogin = (payload) => {
    setIsSumbiting(true)
    if (payload.tenant) {
      axios
        .post(findTenant(), { name: payload.tenant })
        .then((resp) => {
          console.log(resp)
          if (resp.status == 200 && resp.data && resp.data.success == true) {
            loginSystem(payload, {
              headers: {
                'abb-tenant': resp.data.tenantId,
              },
            })
          } else {
            return Promise.reject(t('t_noExistsTenant'))
          }
        })
        .catch((err) => {
          console.log(err)
          dispatch(showNotification(NotificationType.Warn, err + ''))
        })
        .finally(() => {
          setIsSumbiting(false)
        })
    } else {
      loginSystem(payload)
    }
  }
  const sumbitForm = () => {
    setIsSumbiting(true)
    loginFormRef.current.validate((valid, formData) => {
      setIsSumbiting(false)
      if (valid) {
        sumbitLogin(formData)
      } else {
        dispatch(showNotification(NotificationType.Alarm, t('login.msg.plsInputUserName&pwd')))
      }
    })
  }
  const forgetSubmit = (payload, config) => {
    axios
      .post(sendPasswordResetCode(), { ...payload, appName: 'MVC', returnUrl: location, returnUrlHash: hashCode(location).toString() }, config)
      .then((response) => {
        settIsSuccess(true)
      })
      .catch((err) => {
        console.log('login err')
        console.log(err)
        dispatch(showNotification(NotificationType.Alarm, err?.error?.message ?? t('login.msg.userOrPwdError')))
      })
      .finally(() => {
        setIsSumbiting(false)
      })
  }
  const sumbitForget = () => {
    setIsSumbiting(true)
    forgetFormRef.current.validate((valid, formData) => {
      setIsSumbiting(false)
      if (valid) {
        forgetSubmit(formData)
      } else {
        dispatch(showNotification(NotificationType.Alarm, t('login.msg.plsInputEmail')))
      }
    })
  }
  const switchLanguage = () => {
    let newlang = i18n.language == 'zh_cn' ? 'en_us' : 'zh_cn'
    changeLanguage(newlang)
  }

  return (
    <>
      <div className='navbar navbar-fixed-top abb-nav-bar'>
        <div className='container-fluid'>
          <div className='navbar-brand'>
            <img src='/ABB-logo.svg' className='abb-navbar-brand-logo' />
          </div>
        </div>
      </div>

      <div className='body-content'>
        <div className='abb-layout-cards'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title'>{showForgetPannel ? t('login.forgetTitle') : t('login.formTitle')}</h3>

              <div
                onClick={switchLanguage}
                style={{
                  float: 'right',
                  position: 'relative',
                  top: '-40px',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                <WithTooltip>
                  <div> {t(i18n.language)}</div>
                  <Tooltip>
                    <div>{t('login.msg.switchLanguage')}</div>
                  </Tooltip>
                </WithTooltip>
              </div>
            </div>

            {!showForgetPannel && (
              <div className='card-body'>
                <CommonForm ref={loginFormRef} initData={loginInitData} rules={loginRules}>
                  <CommonFormItem type='text' dataType='text' label={t('login.account')} fieldName='name' />
                  <CommonFormItem type='text' dataType='password' label={t('login.password')} fieldName='password' />

                  <CommonFormItem type='button'>
                    <Button text={t('b_login')} type='primary-blue' sizeClass='small' onClick={sumbitForm} isLoading={isSumbiting} />
                    <Button
                      text={t('b_forget')}
                      type='normal'
                      sizeClass='small'
                      onClick={() => {
                        setShowForgetPannel(true)
                      }}
                    />
                  </CommonFormItem>
                </CommonForm>
              </div>
            )}
            {showForgetPannel && (
              <div className='card-body'>
                <CommonForm ref={forgetFormRef} initData={forgetInitData} rules={forgetRules}>
                  <CommonFormItem type='div' render={() => '密码重置链接将发送到您的电子邮件以重置密码. 如果您在几分钟内没有收到电子邮件,请重试.'} />
                  <CommonFormItem type='text' dataType='text' label={t('login.email')} fieldName='email' />
                  <CommonFormItem type='button'>
                    <Button text={t('b_send')} type='primary-blue' sizeClass='small' onClick={sumbitForget} isLoading={isSumbiting} />
                    <Button
                      text={t('b_back_to_login')}
                      type='normal'
                      sizeClass='small'
                      onClick={() => {
                        setShowForgetPannel(false)
                      }}
                    />
                  </CommonFormItem>
                </CommonForm>
              </div>
            )}
          </div>
          <div className='card-copyright'>
            &copy; Copyright 2022 ABB |<a href='http://new.abb.com/provider-information'>{t('login.msg.providerInfo')}</a>|
            <a href='http://new.abb.com/privacy-policy'>{t('login.msg.privacyPolicy')}</a>
          </div>
        </div>
      </div>
      <CommonPrompt
        isVisible={isSuccess}
        title={'发送邮件'}
        buttons={CommonPromptButtons.OK}
        onClose={() => {
          settIsSuccess(false)
          setShowForgetPannel(false)
        }}
      >
        <div>
          帐户恢复电子邮件已发送到您的电子邮件地址. 如果您在15分钟内未
          <br />
          在收件箱中看到此电子邮件,请检查垃圾邮件,并标记为非垃圾邮件.
        </div>
      </CommonPrompt>
    </>
  )
}
