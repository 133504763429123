import React from 'react'

export default function BaseSvg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ display: "none" }}>
            <symbol id="sl_lineHorizontal">
                <line x1="0" y1="0" x2="100" y2="0" className="line4px" />
            </symbol>
            <symbol id="sl_lineHorizontal60">
                <line x1="0" y1="0" x2="60" y2="0" className="line4px" />
            </symbol>
            <symbol id="sl_lineHorizontal70">
                <line x1="0" y1="0" x2="70" y2="0" className="line4px" />
            </symbol>
            <symbol id="sl_lineVertical">
                <line x1="10" y1="0" x2="10" y2="102" className="line2px" />
            </symbol>
            <symbol id="sl_lineVertical80">
                <line x1="10" y1="0" x2="10" y2="80" className="line2px" />
            </symbol>
            <symbol id="sl_lineVertical70">
                <line x1="10" y1="0" x2="10" y2="70" className="line2px" />
            </symbol>
            <symbol id="sl_lineVertical50">
                <line x1="10" y1="0" x2="10" y2="50" className="line2px" />
            </symbol>
            <symbol id="sl_lineVertical40">
                <line x1="10" y1="0" x2="10" y2="40" className="line2px" />
            </symbol>
            <symbol id="sl_lineVertical30">
                <line x1="10" y1="0" x2="10" y2="30" className="line2px" />
            </symbol>
            <symbol id="sl_lineVertical20">
                <line x1="10" y1="0" x2="10" y2="20" className="line2px" />
            </symbol>
            <symbol id="sl_lineVertical10">
                <line x1="10" y1="0" x2="10" y2="10" className="line2px" />
            </symbol>
            <symbol id="sl_socketUp">
                <line x1="10" y1="0" x2="10" y2="10" className="line2px" />
                <path d="M 4 16 A 2 2 0 1 1 16 16" className="line2px" />
            </symbol>

            <symbol id="sl_socketDown">
                {/* <path d="M 0 0 A 2 2 -90 1 0 20 0" className="line2px" /> */}
                <circle cx="10" cy="1" r="6" className="line2px" />
                <line x1="10" y1="8" x2="10" y2="20" className="line2px" />
            </symbol>

            <symbol id="sl_plugUp">
                <line x1="10" y1="0" x2="10" y2="10" className="line4px" />
                <line x1="10" y1="0" x2="10" y2="25" className="line2px" />
            </symbol>

            <symbol id="sl_plugDown">
                <line x1="10" y1="0" x2="10" y2="15" className="line2px" />
                <line x1="10" y1="15" x2="10" y2="25" className="line4px" />
            </symbol>

            <symbol id="sl_socketPlugUp">
                <use xlinkHref="#sl_socketUp" x="0" y="0" />
                <use xlinkHref="#sl_plugUp" x="0" y="15" />
            </symbol>

            <symbol id="sl_socketPlugDown">
                <use xlinkHref="#sl_plugDown" x="0" y="0" />
                <use xlinkHref="#sl_socketDown" x="0" y="20" />
            </symbol>


            <symbol id="sl_switch">
                <line x1="10" y1="0" x2="10" y2="25" className="line2px " />

                <line x1="10" y1="50" x2="10" y2="70" className="line2px " />
            </symbol>
            <symbol id="sl_breaker">
                <use xlinkHref="#sl_switch" x="0" y="0" />
                <line x1="5" y1="23" x2="15" y2="30" className="line2px" />
                <line x1="15" y1="23" x2="5" y2="30" className="line2px" />
            </symbol>

            <symbol id="sl_disconnector">
                <use xlinkHref="#sl_switch" x="0" y="0" />
                <line x1="5" y1="25" x2="15" y2="25" className="line2px" />
            </symbol>

            <symbol id="sl_loadSwitch">
                <use xlinkHref="#sl_disconnector" x="0" y="0" />
                <circle cx="10" cy="30" r="3" className="line2px" />
                <use xlinkHref="#sl_fuse" x="0" y="55" />
            </symbol>


            <symbol id="sl_contactor">
                <use xlinkHref="#sl_switch" x="0" y="0" />
                <path d="M10 20 L10 30 L5 25  Z" className="line2px" />
            </symbol>


            <symbol id="sl_fuse">
                <line x1="10" y1="0" x2="10" y2="15" className="line2px" />
                <path d="M6 0 L14 0 L14 15 L6 15 Z" />
            </symbol>

            <symbol id="sl_fuse2">
                <line x1="10" y1="0" x2="10" y2="70" className="line2px" />
                <path d="M3 1 L17 1 L17 30 L3 30 Z" />
            </symbol>

            <symbol id="sl_fuseContactor">
                <use xlinkHref="#sl_fuse" x="0" y="0" />
                <use xlinkHref="#sl_contactor" x="0" y="0" />
            </symbol>


            <symbol id="sl_CT">
                <circle cx="10" cy="10" r="8" className="line1px " />
            </symbol>

            <symbol id="sl_PT">
                <line x1="10" y1="0" x2="10" y2="10" className="line2px" />
                <circle cx="10" cy="19" r="8" className="line2px" />
                <circle cx="10" cy="28" r="8" className="line2px" />
            </symbol>
            <symbol id="sl_socketPT">
                <use xlinkHref="#sl_socketPlugUp" x="0" y="0" />
                <use xlinkHref="#sl_fuse" x="0" y="35" />
                <use xlinkHref="#sl_PT" x="0" y="45" />
            </symbol>
            <symbol id="sl_fusePT">
                <line x1="10" y1="0" x2="10" y2="10" className="line2px" />
                <use xlinkHref="#sl_fuse" x="0" y="10" />
                <use xlinkHref="#sl_PT" x="0" y="20" />
            </symbol>
            <symbol id="sl_upCableOutLine">

                <line x1="10" y1="0" x2="10" y2="70" className="line2px" />
                <path d="M10 0 L5 10 L15 10  Z" className="fill line2px" />
            </symbol>
            <symbol id="sl_downCableOutLine">
                <line x1="10" y1="0" x2="10" y2="100" className="line2px" />
                <path d="M5 90 L15 90 L10 100  Z" className="fill line2px" />
            </symbol>

            <symbol id="sl_earthing">
                <line x1="10" y1="0" x2="10" y2="30" className="line2px" />
                <line x1="0" y1="30" x2="20" y2="30" className="line2px" />
                <line x1="5" y1="34" x2="15" y2="34" className="line2px" />
                <line x1="9" y1="38" x2="11" y2="38" className="line2px" />
            </symbol>

            <symbol id="sl_earthingSwitch">
                <use xlinkHref="#sl_disconnector" x="0" y="0" />
                <use xlinkHref="#sl_earthing" x="0" y="50" />
            </symbol>
            <symbol id="sl_upBusbarIn">
                <line x1="10" y1="10" x2="10" y2="20" className="line2px" />
                <path d="M10 0 L6 6 L10 12 L14 6  Z" className="line2px" />
            </symbol>

            <symbol id="sl_coil1">
                <line x1="0" y1="0" x2="4" y2="4" className="line2px" />
                <line x1="8" y1="0" x2="4" y2="4" className="line2px" />
                <line x1="4" y1="4" x2="4" y2="8" className="line2px" />
            </symbol>
            <symbol id="sl_coil2">
                <path d="M3 0 L0 5 L10 5 L8 0" className="line2px" />
            </symbol>
            <symbol id="sl_upPT2">
                <circle cx="10" cy="10" r="8" className="line1px" />
                <circle cx="22" cy="10" r="8" className="line1px" />
                <circle cx="15" cy="20" r="8" className="line1px" />
                <use xlinkHref="#sl_coil1" x="5" y="5" />
                <use xlinkHref="#sl_coil1" x="10" y="18" />
                <use xlinkHref="#sl_coil2" x="18" y="8" />
                <use xlinkHref="#sl_socketPlugDown" x="5" y="20" />
                <use xlinkHref="#sl_earthingRight" x="12" y="10" />

            </symbol>
            <symbol id="sl_upPT3PS">
                <circle cx="15" cy="10" r="8" className="line1px" />
                <circle cx="15" cy="20" r="8" className="line1px" />
                <use xlinkHref="#sl_coil1" x="10" y="5" />
                <use xlinkHref="#sl_coil1" x="10" y="18" />

                <line x1="15" y1="25" x2="15" y2="35" className="line2px" />
                <use xlinkHref="#sl_socketDown" x="5" y="30" />
                <use xlinkHref="#sl_earthingRight" x="20" y="40" />
                <line x1="15" y1="49" x2="23" y2="58" className="line2px" />
                <line x1="10" y1="60" x2="20" y2="60" className="line2px" />
                <line x1="15" y1="60" x2="15" y2="70" className="line2px" />
            </symbol>
            <symbol id="sl_upArrester_upPT2">
                <use xlinkHref="#sl_upPT2" x="20" y="0" />
                <use xlinkHref="#sl_lineVertical10" x="25" y="60" />
                <use xlinkHref="#sl_upArrester" x="0" y="0" />
                <path d="M9 60 L35 60  " />

            </symbol>
            <symbol id="sl_downPT2">
                <circle cx="10" cy="50" r="8" className="line1px" />
                <circle cx="22" cy="50" r="8" className="line1px" />
                <circle cx="15" cy="40" r="8" className="line1px" />
                <use xlinkHref="#sl_coil1" x="10" y="35" />
                <use xlinkHref="#sl_coil1" x="5" y="48" />
                <use xlinkHref="#sl_coil2" x="18" y="48" />

                <use xlinkHref="#sl_socketPlugUp" x="5" y="0" />
                <use xlinkHref="#sl_earthingRight" x="10" y="28" />

            </symbol>
            <symbol id="sl_downDisconnectorPT2">
                <line x1="15" y1="0" x2="15" y2="10" className="line2px" />
                <line x1="10" y1="5" x2="20" y2="5" className="line2px" />
                <use xlinkHref="#sl_downPT2" x="0" y="10" />
            </symbol>
            <symbol id="sl_upArrester">
                <path d="M5 15 L15 15 L15 35 L5 35 Z" />
                <line x1="10" y1="8" x2="10" y2="15" className="line2px" />
                <line x1="0" y1="8" x2="20" y2="8" className="line2px" />
                <line x1="5" y1="4" x2="15" y2="4" className="line2px" />
                <line x1="9" y1="1" x2="11" y2="1" className="line2px" />
                <path d="M10 23 L14 32 L6 32 Z" className="line1px" />
                <line x1="10" y1="30" x2="10" y2="45" className="line2px" />
                <use xlinkHref="#sl_socketDown" x="0" y="40" />
            </symbol>
            <symbol id="sl_downArrester">
                <use xlinkHref="#sl_socketUp" x="0" y="0" />

                <path d="M5 25 L15 25 L15 45 L5 45 Z" />
                <path d="M6 28 L14 28 L10 38 Z" className="line1px" />
                <line x1="10" y1="15" x2="10" y2="30" className="line2px" />
                <use xlinkHref="#sl_earthingDown" x="0" y="45" />
            </symbol>

            <symbol id="sl_switch3PS">
                <use xlinkHref="#sl_disconnector" x="0" y="0" />

            </symbol>
            <symbol id="sl_switchLoad3PS">
                <use xlinkHref="#sl_disconnector" x="0" y="0" />
                <circle cx="10" cy="30" r="3" className="line2px" />

            </symbol>
            <symbol id="sl_switch2PS">
                <use xlinkHref="#sl_disconnector" x="0" y="0" />
            </symbol>
            <symbol id="sl_switchEarthing2PS">
                <use xlinkHref="#sl_disconnector" x="0" y="0" />
            </symbol>

            <symbol id="sl_earthingLeft">
                <line x1="8" y1="6" x2="20" y2="6" className="line2px" />
                <line x1="2" y1="4" x2="2" y2="7" className="line2px" />
                <line x1="5" y1="2" x2="5" y2="10" className="line2px" />
                <line x1="8" y1="0" x2="8" y2="12" className="line2px" />
            </symbol>

            <symbol id="sl_earthingRight">
                <line x1="5" y1="10" x2="25" y2="10" className="line1px" />
                <line x1="25" y1="5" x2="25" y2="15" className="line2px" />
                <line x1="28" y1="6" x2="28" y2="14" className="line2px" />
                <line x1="31" y1="8" x2="31" y2="12" className="line2px" />
            </symbol>
            <symbol id="sl_earthingDown">
                <line x1="10" y1="0" x2="10" y2="10" className="line2px" />
                <line x1="0" y1="10" x2="20" y2="10" className="line2px" />
                <line x1="5" y1="14" x2="15" y2="14" className="line2px" />
                <line x1="9" y1="18" x2="11" y2="18" className="line2px" />
            </symbol>
            <symbol id="sl_DXN">
                <circle cx="0" cy="9" r="8" className="line2px" />
                <line x1="9" y1="9" x2="15" y2="9" className="line2px" />
                <line x1="15" y1="0" x2="15" y2="18" className="line2px" />
                <line x1="20" y1="0" x2="20" y2="18" className="line2px" />

                <line x1="20" y1="9" x2="30" y2="9" className="line2px" />
            </symbol>
            <symbol id="sl_none">

            </symbol>
            {/* 低压开关 */}
            <symbol id="sl_contactorLV"  >
                <line x1="10" x2="10" y1="0" y2="5" strokeWidth="2" className="line2px" />
                <line x1="10" x2="10" y1="50" y2="60" strokeWidth="2" className="line2px" />
                <rect x="0" y="5" width="20" height="30" strokeWidth="2" fill="#FFFFFF" />
                <path d="M6 13 L13 24 V32" fill="none" strokeWidth="1" className="line2px" />
                <path d="M15 8 V16 A2 2 0 0 1 15 8 z" fill="none" strokeWidth="1" className="line1px" />
            </symbol>

            <symbol id="sl_reactor"  >
                <path d="M10 0 L10 10,A10 10 0 1 0 21 20 L10 20 L10 60" fill="none" strokeWidth="2" />
            </symbol>
            <symbol id="sl_MCU"  >
                <rect x="1" y="1" width="40" height="20" fill="#fff" strokeWidth="2"   ></rect>
                <text x="3" y="15" style={{ fontSize: 16 }}>MCU</text>
            </symbol>
            <symbol id="sl_transducer"  >
                <rect x="1" y="1" width="40" height="35" strokeWidth="2" fill="#FFF" />
                <line x1="39" y1="0" x2="0" y2="34" strokeWidth="1" className="line1px" />
                <line x1="40" y1="5" x2="4" y2="35" strokeWidth="1" className="line1px" />
                <use x="5" y="2" xlinkHref="#sss" />
                <use x="25" y="25" xlinkHref="#sss" />
                <defs>
                    <g id="sss">
                        <path d="M2 5 Q5 0 8 5 T12 5" fill="none" strokeWidth="1" className="line1px" />
                        <line x1="0" y1="5" x2="14" y2="5" strokeWidth="1" className="line1px" />
                    </g>
                </defs>
            </symbol>

            <symbol id="sl_capacitor"  >
                <line y2="7.1666665" fill="none" x1="19.666666" x2="19.666666" y1="0.33333334" strokeWidth="2" className="line2px" />
                <line fill="none" x1="3.4666677" x2="13.366665" y1="15.800005" y2="23.73334" strokeWidth="2" className="line2px"
                />
                <line fill="none" x1="5.266668" x2="15.166665" y1="13.200005" y2="21.133339" strokeWidth="2" className="line2px"
                />
                <line y2="38.5" fill="none" x1="17.400002" x2="17.400002" y1="27.4" strokeWidth="2" className="line2px" />
                <line y2="38.600006" fill="none" x1="21.400002" x2="21.400002" y1="27.499998" strokeWidth="2" className="line2px" />
                <line fill="none" x1="22.766668" x2="31.766665" y1="20.900011" y2="12.753884" strokeWidth="2" className="line2px"
                />
                <line fill="none" x1="25.066668" x2="34.06666" y1="23.50001" y2="15.353885" strokeWidth="2" className="line2px"
                />
                <line y2="17.9" fill="none" x1="19.7" x2="10.9" y1="7.0" strokeWidth="2" className="line2px" />
                <line y2="32.1" fill="none" x1="8.7" x2="0.2" y1="20.8" strokeWidth="2" className="line2px" />
                <line y2="32.6" fill="none" x1="0.3" x2="17.4" y1="32.4" strokeWidth="2" className="line2px" />
                <line y2="32.466667" fill="none" x1="21.599998" x2="39.5" y1="32.466667" strokeWidth="2" className="line2px" />
                <line y2="20.9" fill="none" x1="39.4" x2="30.1" y1="32.4" strokeWidth="2" className="line2px" />
                <line y2="17.7" fill="none" x1="19.6" x2="28.1" y1="7.0" strokeWidth="2" className="line2px" />
            </symbol>
            <symbol id="sl_filter">
                <line x1="10" y1="0" x2="10" y2="5" strokeWidth="2" className="line2px" />
                <rect x="1" y="5" width="18" height="40" strokeWidth="2" className="line2px" fill="#FFFFFF" ></rect>
                <path d="M 7 15 V 10 15 q 3 3 0 5 q 3 3 0 5 q 3 3 0 5 q 3 3 0 5 V 35 40 " strokeWidth="2" className="line2px" fill="none" />
                <line x1="14" y1="15" x2="14" y2="35" strokeWidth="2" className="line2px" />
                <line x1="10" y1="45" x2="10" y2="50" strokeWidth="2" className="line2px" />
            </symbol>
            <symbol id="sl_switchFuse">
                <line x1="10" x2="10" y1="0" y2="8" strokeWidth="2" className="line2px" />
                <line x1="5" x2="15" y1="8" y2="8" strokeWidth="2" className="line2px" />
                <path d="M1 10 L10 33 V40" fill="none" strokeWidth="2" className="line2px" />
                <rect x="-7" y="20" width="8" height="12" transform="rotate(-22)" fill="none" strokeWidth="2" className="line2px" />
            </symbol>

            <symbol id="sl_thermorelay">
                <line x1="20" y1="0" x2="20" y2="10" strokeWidth="2" className="line2px" />
                <rect x="1" y="9" width="40" height="20" fill="#FFF" strokeWidth="2" className="line2px" />
                <rect x="7" y="14" width="28" height="10" fill="none" strokeWidth="2" className="line2px" />
                <line x1="20" y1="12" x2="20" y2="17" strokeWidth="2" className="line2px" />
                <line x1="20" y1="17" x2="26" y2="17" strokeWidth="2" className="line2px" />
                <line x1="26" y1="17" x2="26" y2="21" strokeWidth="2" className="line2px" />
                <line x1="26" y1="21" x2="20" y2="21" strokeWidth="2" className="line2px" />
                <line x1="20" y1="21" x2="20" y2="27" strokeWidth="2" className="line2px" />
                <line x1="20" y1="29" x2="20" y2="70" strokeWidth="2" className="line2px" />

            </symbol>
            <symbol id="sl_softStarter">
                <rect x="0.16666411" y="7.6666636" transform="matrix(1.0 0.0 0.0 1.0 0.16666663 -0.3333333)" fill="none" width="59.000004"
                    height="46.5" strokeWidth="2" className="line2px" />
                <polyline fill="none" points="14.666667,7.3333335 14.666667,20.333334 19.666666,15.0 50.833332,15.0 
        50.833332,29.833334"  strokeWidth="2" className="line2px" />
                <polyline fill="none" points="50.666668,38.833332 50.666668,49.333332 20.666666,49.333332 15.0,44.333332 
        15.0,58.333332" strokeWidth="2" className="line2px" />
                <line y2="27.833334" fill="none" x1="50.666668" x2="42.166668" y1="39.0" strokeWidth="2" className="line2px" />
                <line y2="25.0" fill="none" x1="14.666667" x2="14.666667" y1="20.5" strokeWidth="2" className="line2px" />
                <line y2="39.833332" fill="none" x1="14.999999" x2="14.999999" y1="44.333332" strokeWidth="2" className="line2px" />
                <rect fill="none" x="9.0" width="13.0" height="15.333334" y="24.666666" strokeWidth="2" className="line2px" />
                <line y2="36.833332" fill="none" x1="5.166667" x2="13.166666" y1="36.833332" />
                <polygon fill="none" points="9.166667,37.0 5.1666665,29.333334 12.833333,29.333334" />
                <polygon fill="none" transform="matrix(-0.9995613 -0.029616412 0.029616412 -0.9995613 43.684464 66.3137)" points=
                    "22.495064,36.500072 18.495064,28.833406 26.16173,28.833406" strokeWidth="2" className="line2px" />
                <line y2="29.166672" fill="none" x1="18.0" x2="26.0" y1="29.166672" strokeWidth="2" className="line2px" />
                <line y2="32.999996" fill="none" x1="24.5" x2="33.833332" y1="32.999996" strokeWidth="2" className="line2px" />
                <line y2="32.833332" fill="none" x1="6.6666665" x2="0.8333333" y1="32.833332" strokeWidth="2" className="line2px" />
                <path fill="none" d="M 50.5 29.833332 C 46.333332 28.0 45.333332 23.5 50.166668 20.333334" strokeWidth="2" className="line2px" />
            </symbol>

            <symbol id="sl_breakerOverload_shortCircuit">

                <path fill="none" d="m 0.6499914 17.862495 c 0.0 1.0 0.0 2.0 0.0 3.0 c 0.0 0.0 0.0 1.0 0.0 2.0 l 0.0 1.0"
                    strokeWidth="2" className="line2px" />
                <line fill="none" x1="1.5251853" y1="21.052757" x2="4.4116693" y2="21.052757"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="5.8380504" y1="21.052757" x2="8.724534" y2="21.052757"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="10.059641" y1="21.052757" x2="26.264723" y2="21.052757"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <rect x="12.68537" y="18.05265" fill="none" width="6.5669203" height="6.0"
                    strokeWidth="2" className="line2px" />
                <line fill="none" x1="15.968828" x2="15.968828" y1="15.529724" y2="27.22919"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="30.155762" y1="20.848679" x2="33.876995" y2="20.848679"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="15.968828" y1="28.144375" x2="15.968828" y2="33.199986"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="15.968828" y1="34.107662" x2="15.968828" y2="37.787132"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="16.90696" y1="30.437943" x2="19.721354" y2="30.437943"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="20.424952" y1="30.437943" x2="26.473034" y2="30.437943"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <rect x="26.967945" y="25.162727" fill="none" width="18.997158" height="10.321075"
                    strokeWidth="2" className="line2px" />
                <rect x="26.967945" y="35.254444" fill="none" width="18.997158" height="10.321075"
                    strokeWidth="2" className="line2px" />
                <line fill="none" x1="15.989435" y1="38.46545" x2="15.989435" y2="42.29579"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="16.90696" y1="41.21773" x2="19.721354" y2="41.21773"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="20.424953" y1="41.21773" x2="26.055658" y2="41.21773"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="30.323187" x2="36.375103" y1="11.964256" y2="24.617306"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="37.627224" y1="0.12753105" x2="37.627224" y2="10.558054"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="40.548836" y1="7.8484116" x2="35.21594" y2="13.9605255"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line transform="rotate(90 43.31075286865235,31.154466629028324)"
                    fill="none" x1="25.668362" y1="33.457817" x2="20.453148" y2="39.707855" strokeWidth="2" className="line2px"
                    strokeLinejoin="null" />
                {/* <line  fill="none" x1="36.2753" x2="36.2753" y1="46.375023" y2="57.679375"
                    strokeLinejoin="null"  strokeWidth="2" className="line2px" /> */}
                <line fill="none" x1="36.2753" y1="25.595852" x2="36.2753" y2="28.573257"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="36.2753" y1="31.829601" x2="36.2753" y2="34.302807"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="35.248684" y1="27.765114" x2="42.854942" y2="27.765114"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="35.248684" y1="32.470993" x2="42.854942" y2="32.470993"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="41.774807" y1="28.63633" x2="41.774807" y2="31.613735"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />

                <text x="30" y="43.121323" fontSize="8">I&gt;&gt;</text>
            </symbol>
            <symbol id="sl_breakerOverload">

                <path fill="none" d="m 0.6499914 17.862495 c 0.0 1.0 0.0 2.0 0.0 3.0 c 0.0 0.0 0.0 1.0 0.0 2.0 l 0.0 1.0"
                    strokeWidth="2" className="line2px" />
                <line fill="none" x1="1.5251853" y1="21.052757" x2="4.4116693" y2="21.052757"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="5.8380504" y1="21.052757" x2="8.724534" y2="21.052757"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="10.059641" y1="21.052757" x2="26.264723" y2="21.052757"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <rect x="12.68537" y="18.05265" fill="none" width="6.5669203" height="6.0"
                    strokeWidth="2" className="line2px" />
                <line fill="none" x1="15.968828" x2="15.968828" y1="15.529724" y2="27.22919"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="30.155762" y1="20.848679" x2="33.876995" y2="20.848679"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="15.968828" y1="28.144375" x2="15.968828" y2="33.199986"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />

                <line fill="none" x1="16.90696" y1="30.437943" x2="19.721354" y2="30.437943"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="20.424952" y1="30.437943" x2="26.473034" y2="30.437943"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <rect x="26.967945" y="25.162727" fill="none" width="18.997158" height="10.321075"
                    strokeWidth="2" className="line2px" />

                <line fill="none" x1="30.323187" x2="36.375103" y1="11.964256" y2="24.617306"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="37.627224" y1="0.12753105" x2="37.627224" y2="10.558054"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="40.548836" y1="7.8484116" x2="35.21594" y2="13.9605255"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line transform="rotate(90 43.31075286865235,31.154466629028324)"
                    fill="none" x1="25.668362" y1="33.457817" x2="20.453148" y2="39.707855" strokeWidth="2" className="line2px"
                    strokeLinejoin="null" />

                <line fill="none" x1="36.2753" y1="25.595852" x2="36.2753" y2="28.573257"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="36.2753" y1="31.829601" x2="36.2753" y2="34.302807"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="35.248684" y1="27.765114" x2="42.854942" y2="27.765114"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="35.248684" y1="32.470993" x2="42.854942" y2="32.470993"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />
                <line fill="none" x1="41.774807" y1="28.63633" x2="41.774807" y2="31.613735"
                    strokeLinejoin="null" strokeWidth="2" className="line2px" />


            </symbol>
            <symbol id="sl_busTieIn">
                <line fill="none" x1="6.0" x2="22.875" y1="9.0" y2="48.0" strokeWidth="2" className="line2px" />
                <line fill="none" x1="9.625" x2="23.625" y1="38.0" y2="31.5" strokeWidth="2" className="line2px" />
                <line fill="none" x1="7.625" x2="20.625" y1="31.5" y2="25.625" strokeWidth="2" className="line2px" />
                <line fill="none" x1="5.25" x2="17.875" y1="25.0" y2="19.125" strokeWidth="2" className="line2px" />
                <polygon fill="none" strokeWidth="2" className="line2px" points="16.0,50.885418 28.125,45.0 27.64,59.125" />
                <ellipse fill="none" rx="3.75" cx="29.75" ry="3.75" cy="63.5" strokeWidth="2" className="line2px" />
            </symbol>
            <symbol id="sl_electricalOperation">
                <circle cx="20" cy="20" r="10" fill="#fff" strokeWidth="2" className="line2px" />
                <text x="15" y="24"     fontSize="12px" >M</text>
                <line x1="30" y1="20" x2="35" y2="20" strokeWidth="2" className="line2px"  />
                <line x1="40" y1="20" x2="45" y2="20"strokeWidth="2" className="line2px"  />
            </symbol>

            <symbol id="sl_CT2">
                <line x1="10" y1="0" x2="10" y2="40" strokeWidth="2" className="line2px"  />
                <circle cx="10" cy="20" r="8" className="line1px " /> 
            </symbol>

            <symbol id="sl_VVVF"  >
                <rect x="1" y="1" width="40" height="20" fill="#fff" strokeWidth="2"   ></rect>
                <text x="3" y="15" style={{ fontSize: 14 }}>VVVF</text>
            </symbol>
        </svg>
    )
}