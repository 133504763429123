import React from 'react'
import { Icon, Link } from '@abb/abb-common-ux-react'
import './shortcut.scss'
export default function ShortcutTips({onClose, onAction}) {

    return (
        <div className="shortcut-tips">
            <div className='toolbar'>
                <Icon name='abb/close' sizeClass='large' onClick={onClose} />
            </div>
            <div className="tips-content">
                <div className="tips-header">
                    温馨提示
                </div>
                <div className="tips-body">
                    为了您能快速打开站点, 您可以将此站点添加到桌面。
                </div>
                <div className="tips-action" onClick={onAction} >
                    如何操作？
                </div>
            </div>
            <div className='bottom'></div>
            <div></div>
        </div>
    )
}