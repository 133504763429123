import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getApbApplicationConfiguration } from "common/apis/common"
export const fetchAbpApplicationConfigurationAsync = createAsyncThunk("abpApplicationConfiguration/fetchAbpApplicationConfigurationAsync", () =>
  axios
    .get(getApbApplicationConfiguration())
    .then((response) => {
      return response?.data
    })
    .catch((e) => {
      console.log(e)
    })
)

const abpApplicationConfigurationSlice = createSlice({
  name: "abpApplicationConfiguration",
  initialState: {
    config: null,
  },
  reducers: {
    setConfiguration(state, action) {
      state.config = action.payload
    },
  },
  extraReducers: {
    [fetchAbpApplicationConfigurationAsync.pending]: (state, action) => {
      state.config = null
    },
    [fetchAbpApplicationConfigurationAsync.fulfilled]: (state, action) => {
      state.config = action.payload
    },
  },
})

export const { setConfiguration } = abpApplicationConfigurationSlice.actions
export const getConfiguration = (state) => state.abpApplicationConfiguration.config
export const getPermissions = (state) => state.abpApplicationConfiguration.config?.auth?.grantedPolicies
export const getCurrentTenant = (state) => state.abpApplicationConfiguration.config?.currentTenant

export default abpApplicationConfigurationSlice.reducer
