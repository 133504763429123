import { createSlice } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";

const notificationSlice = createSlice({
  name: "counter",
  initialState: {
    list: [],
  },
  reducers: {
    showNotification: {
      reducer(state, action) {
        state.list.push(action.payload);
      },
      prepare(severity, text) {
        return {
          payload: {
            id: nanoid(),
            type: "banner",
            // Change the Notification type and severity by randomly:
            discreet: false,
            severity: severity, // 'success', 'info', 'warn', 'alarm'
            timeout: 1500,
            text: text,
          },
        };
      },
    },
  },
});
export const NotificationType = { Success: 'success', Info: 'info', Warn: 'warn', Alarm: 'alarm' };
export const { showNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
