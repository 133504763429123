import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { LoadingIndicator } from '@abb/abb-common-ux-react'

import { useSelector, useDispatch } from 'react-redux'
import { t } from 'i18next'
import { ErrorBoundary } from 'common/ErrorBoundary'
import ProjectInfo from './ProjectInfo'
import { MemberList } from './MemberList'
import { SearchUser } from './SearchUser'
import './dashboard.scss'
import { fetchProjectAsync, getProject, getIsLoadingProject, getIsLoadingCustomer, fetchCustomerAsync } from 'slices/ezProjectSlice'
import { fetchGetUsersAsync, getUsers } from 'slices/userSlice'
import CustomerInfo from './CustomerInfo'
import { getOrganization, fetchGetOrganizationAsync } from 'slices/organizationSlice'
import { ContactList } from './ContactList'
export default function Project() {
  const urlParams = useParams()
  const dispatch = useDispatch()
  const project = useSelector(getProject)
  const users = useSelector(getUsers)
  const organization = useSelector(getOrganization)
  const memberlistRef = useRef(null)
  const searchUserRef = useRef(null)
  const isLoadingProject = useSelector(getIsLoadingProject)
  const isLoadingCustomer = useSelector(getIsLoadingCustomer)
  useEffect(() => {
    if (urlParams.id) {
      dispatch(fetchProjectAsync(urlParams.id))
    }
  }, [dispatch, urlParams.id])
  useEffect(() => {
    if (project?.customerId) {
      dispatch(fetchCustomerAsync(project.customerId))
    }
  }, [dispatch, project])
  useEffect(() => {
    if (!organization) {
      dispatch(fetchGetOrganizationAsync())
    }
  }, [dispatch, organization])
  useEffect(() => {
    if (!users) {
      dispatch(fetchGetUsersAsync())
    }
  }, [dispatch, users])
  if (isLoadingProject || isLoadingCustomer) {
    return (
      <div className='central-position'>
        <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
      </div>
    )
  } else if (project) {
    return (
      <ErrorBoundary>
        <div className='grid root-grid' data-cy='module-device-dashboard'>
          <div className='row abb-col-lg-14 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
              <div className='row  abb-col-lg-12 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-8x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms'>
                  <CustomerInfo />
                </div>
              </div>
              <div className='row  abb-col-lg-12 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-8x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms'>
                  <ProjectInfo />
                </div>
              </div>
            </div>
          </div>
          <div className='row  abb-col-lg-10 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
              <div className='row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-4x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'>
                  <MemberList
                    ref={memberlistRef}
                    onEdit={(role, multipleChoice, removeOld, old) => {
                      searchUserRef.current.showDialog(project.id, role, multipleChoice, removeOld, old)
                    }}
                  />
                </div>
              </div>
              <div className='row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-4x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'>
                  <ContactList onEdit={() => {}} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DataFormEdit ref={formDataRef} formData={DataFormData} id={device.id} customerId={customerId} onSubmit={onSubmit} /> */}
        <SearchUser ref={searchUserRef} role='action' onRefresh={() => setTimeout(() => memberlistRef.current.query(), 500)} />
      </ErrorBoundary>
    )
  }
  return (
    <div className='grid root-grid'>
      <p>您没有访问此页面的权限</p>
    </div>
  )
  //返回页面
}
