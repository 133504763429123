import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Checkbox } from "@abb/abb-common-ux-react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import { getCustomerNameList } from "common/apis/common"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { createProject, updateProject } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { getCustomers } from "slices/maindataSlice"
import { getDictionary } from "slices/dataDictionarySlice"
import { getDataDictionaryDetail } from "common/dataDictionary"
import { getUsers } from "slices/userSlice"

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel,event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [formItem, setFormItem] = useState([])
  const [innerData, setInnerData] = useState({})
  const customers = useSelector(getCustomers)
  const dictionary = useSelector(getDictionary)
  const dt = getDataDictionaryDetail(dictionary, "PrjType")
  const users = useSelector(getUsers)

  useEffect(() => {
    setInnerData(formData)
  }, [formData])
  //初始化数据
  const initData = {
    name: "",
    customerId: "",
    category: "",
    manager: "",
    startDate: "",
    endDate: "",
    no: "",
    description: "",
  }
  //表单数据项
  const changeFormItem = () => {
    setFormItem([
      {
        type: "text",
        dataType: "text",
        label: t("project.no"),
        fieldName: "no",
        maxLength: 64,
      },
      {
        type: "text",
        dataType: "text",
        label: t("project.name"),
        fieldName: "name",
        maxLength: 256,
      },
      {
        type: "dropdown",
        label: t("project.customer"),
        fieldName: "customerId",
        searchable:true,
        options: customers.map((c) => {
          return { label: c.name, value: c.id }
        }),
        maxLength: 256,
        disabled: (formData && formData.id),
      },
      {
        type: "dropdown",
        label: t("project.category"),
        fieldName: "category",
        options: dt
          ? dt.details.map((c) => {
              return { label: c.displayText, value: c.code }
            })
          : [],
        maxLength: 256,
        disabled:  (formData && formData.id),
      },
      {
        type: "dropdown",
        label: t("project.manager"),
        fieldName: "manager",
        searchable:true,
        options: users
          ? users.map((c) => {
              return { label: c.name, value: c.id }
            })
          : [],
        maxLength: 256,
      },
      {
        type: "date",
        label: t("project.startDate"),
        fieldName: "startDate",
        dateFormat: "yyyy-MM-dd",
        maxDate: innerData ? innerData.endDate : undefined,
        showClearIcon: true,
      },
      {
        type: "date",
        label: t("project.endDate"),
        fieldName: "endDate",
        dateFormat: "yyyy-MM-dd",
        minDate: innerData ? innerData.startDate : undefined,
        showClearIcon: true,
      },
      {
        type: "text",
        dataType: "textarea",
        label: t("project.description"),
        fieldName: "description",
        maxLength: 1000,
      },
    ])
  }
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      no: [CommonRule.Require],
      name: [CommonRule.Require],
      customerId: [CommonRule.Require],
      category: [CommonRule.Require],
      manager: [CommonRule.Require],
      startDate: [CommonRule.Require],
      endDate: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  useEffect(() => {
    if (dictionary && customers && users) {
      changeFormItem()
    }
  }, [dictionary, customers, users, innerData])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      customerId: inputdata.customerId,
      category: inputdata.category,
      manager: inputdata.manager,
      startDate: inputdata.startDate,
      endDate: inputdata.endDate,
      no: inputdata.no,
      description: inputdata.description,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      customerId: inputdata.customerId,
      category: inputdata.category,
      manager: inputdata.manager,
      startDate: inputdata.startDate,
      endDate: inputdata.endDate,
      no: inputdata.no,
      description: inputdata.description,
    }
  }
  const onChange = (data, preData) => {
    setInnerData(data)
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("project.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createProject}
      updateData={updateProject}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      onChange={onChange}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
