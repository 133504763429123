import React, { useState, useRef, forwardRef } from 'react'
import { getDataDictionaryPageDetail, deleteDataDictionaryDetail } from 'common/endpoints'
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import { ToggleSwitch } from '@abb/abb-common-ux-react'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import { getClientWidth } from 'slices/appSlice'
import { useDispatch } from 'react-redux'
import { updateDataDictionaryStatus } from 'common/endpoints'
import { useEffect } from 'react'
import { fetchGetDataDictionaryAsync } from 'slices/dataDictionarySlice'
export const MainIndex = forwardRef(({ dataDictionaryId, typeDisplayText }, ref) => {
  const refIndex = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const clientWidth = useSelector(getClientWidth)
  const dispatch = useDispatch()
  //设置查询字段dataDictionaryId
  useEffect(() => {
    if (dataDictionaryId)
      setSearchData({
        dataDictionaryId,
      })
    else if (refIndex && refIndex.current) refIndex.current.setList([])
  }, [dataDictionaryId])
  //点击添加按钮时将dataDictionaryId注入到表单中
  const onAddBefore = () => {
    return {
      dataDictionaryId,
      typeDisplayText,
    }
  }
  const getDeleteInput = (item) => {
    return {
      dataDictionaryId: item.dataDictionaryId,
      dataDictionayDetailId: item.id,
    }
  }
  const changeStatus = (item, newStatus) => {
    axios
      .post(updateDataDictionaryStatus(), {
        dataDictionaryId: item.dataDictionaryId,
        dataDictionayDetailId: item.id,
        isEnabled: newStatus,
      })
      .then((response) => {
        console.log(response)
        if (response.status == 200 || response.status == 204) {
          dispatch(showNotification(NotificationType.Success, t('t_successToUpdate')))
          refIndex.current.query()
          dispatch(fetchGetDataDictionaryAsync())
        } else {
          dispatch(showNotification(NotificationType.Success, t('t_failToUpdate')))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(showNotification(NotificationType.Alarm, t('t_failToUpdate')))
      })
      .finally(() => {})
  }
  //列定义
  const columns = [
    {
      title: 'dataDictionary.code',
      key: 'code',
    },
    {
      title: 'dataDictionary.displayText',
      key: 'displayText',
    },
    {
      title: 'dataDictionary.order',
      key: 'order',
    },
    {
      title: 'dataDictionary.isEnabled',
      key: 'isEnabled',
      render: (item, index) => {
        return (
          <ToggleSwitch
            label={item.isEnabled ? '是' : '否'}
            showIcons={true}
            // disabled={false}
            value={item.isEnabled}
            onChange={(newState) => {
              changeStatus(item, newState)
            }}
          />
        )
      },
    },
    {
      title: 'dataDictionary.description',
      key: 'description',
    },
  ]

  //返回页面
  return (
    <CommonIndex
      className='admin-main-content with-padding no-margin'
      ref={refIndex}
      columns={columns}
      searchData={searchData}
      getList={getDataDictionaryPageDetail}
      deleteData={deleteDataDictionaryDetail}
      FormEdit={FormEdit}
      tableWidth={clientWidth - 425}
      canAdd={!!dataDictionaryId}
      canEdit
      canDelete
      enableSorting={false}
      onSubmit={() => {
        dispatch(fetchGetDataDictionaryAsync())
      }}
      onAddBefore={onAddBefore}
      getDeleteInput={getDeleteInput}
    />
  )
})
