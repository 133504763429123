import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CascadeType, CommonFormEdit, CommonRule, CommonTree } from "components/LoongInCommon"
import { createRole, updateRole, treeGrant, updateGrant } from "common/endpoints"
import { getClientHeight } from "slices/appSlice"
import { t } from "i18next"
import { axisTop } from "d3-axis"
import axios from "axios"

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //引用权限树控件
  const treeRef = useRef(null)
  //权限树的数据
  const [tree, setTree] = useState(null)
  //校验规则
  const rules = {
    name: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    name: "",
    isDefault: false,
  }
  //表单项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("role.name"),
      fieldName: "name",
    },
    {
      type: "checkbox",
      label: t("role.isDefault"),
      fieldName: "isDefault",
    },
  ]
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      roleId: inputdata.id,
      roleInfo: {
        name: inputdata.name,
        isDefault: inputdata.isDefault,
        isPublic: inputdata.isPublic,
        concurrencyStamp: inputdata.concurrencyStamp,
      },
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      isDefault: inputdata.isDefault,
      isPublic: false,
    }
  }
  //调用接口获取权限树
  const getTreeGrant = async (roleName) => {
    try {
      const response = await axios.post(treeGrant(), {
        providerKey: roleName,
        providerName: "R",
      })
      if (response.status === 200) {
        if (response.data) {
          setTree(response.data)
        }
      } else {
        console.log("error to get tree grant")
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    if (formData) {
      getTreeGrant(formData.name)
    } else {
      getTreeGrant("!@#$ss")
    }
  }, [formData])
  //复选权限树修改时触发
  const onCheckChange = (newCheckItems) => {
    let newTree = { ...tree, grants: newCheckItems }
    setTree(newTree)
  }
  //提交成功后触发
  const onSubmitThis = async (item) => {
    console.log(item);
    try {
      const response = await axios.post(updateGrant(), {
        providerKey: item.data.name,
        providerName: "R",
        updatePermissionsDto: {
          permissions: tree.allGrants.map((c) => {
            let idx = tree.grants.findIndex((c2) => c2 === c)
            return {
              name: c,
              isGranted: idx >= 0,
            }
          }),
        },
      })
      if (response.status === 200) {
        console.log("update sucess")
      } else {
        console.log("error to get tree grant")
      }
    } catch (e) {
      console.log(e)
    } finally {
    }

    onSubmit && onSubmit(item)
  }
  const onTabChange = (tabIndex) => {
    if (tabIndex == 1) {
      // if (treeRef && treeRef.current) treeRef.current.expandAll()
      // else {
      //   const intev = setInterval(() => {
      //     if (treeRef && treeRef.current) {
      //       clearInterval(intev)
      //       //treeRef.current.expandAll()
      //     }
      //   }, 100)
      // }
    }
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("role.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      // otherValidate={otherValidate}
      createData={createRole}
      updateData={updateRole}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmitThis}
      onCancel={onCancel}
      height={clientHeight - 280}
      onTabChange={onTabChange}
      event={event}
    >
      <div title="授权" style={{ height: clientHeight - 240 + "px" }}>
        {tree && <CommonTree cascade={CascadeType.DownAll | CascadeType.UpCheck} ref={treeRef} items={tree.permissions} checkItems={tree.grants} onCheckChange={onCheckChange} />}
      </div>
    </CommonFormEdit>
  )
})
