import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Checkbox } from "@abb/abb-common-ux-react";
import { CommonFormEdit } from "components/LoongInCommon";
import { CommonRule } from "components/LoongInCommon";
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateDeviceData } from "common/apis/maindata";
import { t } from "i18next";
import { getClientHeight } from "slices/appSlice";
import { getDeviceTypeMetaDatas, getDeviceDatas, getDevice } from "slices/deviceSlice";
export const DataFormEdit = forwardRef(({ id, formData, onSubmit, onCancel,event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null);
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight);
  const [roles, setRoles] = useState([]);
  const allRoles = useSelector(getAllRoles);
  const [rules, setRules] = useState({});
  const [formItem, setFormItem] = useState([]);
  const metaDatas = useSelector(getDeviceTypeMetaDatas);
  const datas = useSelector(getDeviceDatas);
  const device = useSelector(getDevice);

  //初始化数据
  const initData = {};

  useEffect(() => {
    let items = [];
    metaDatas.forEach((m) => {
      items.push({
        type: "text",
        dataType: "text",
        label: m.name,
        fieldName: m.id,
      });
    });
    setFormItem(items)
  }, [device, metaDatas]);
  const getUpdateInput = (inputdata) => {
    console.log(inputdata)
    return {
      ...inputdata
    };
  };

  const resetForm = () => {
    return formEditRef.current.resetForm();
  };
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }));
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible);
  };
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex);
  };
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("deviceData.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      updateData={() => updateDeviceData(id)}
      getUpdateInput={getUpdateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  );
});
