import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { LoadingIndicator } from '@abb/abb-common-ux-react'

import { useSelector, useDispatch } from 'react-redux'
import { t } from 'i18next'
import { ErrorBoundary } from 'common/ErrorBoundary'
import {
  fetchDeviceAsync,
  getDevice,
  getParent,
  fetchDeviceMetaDataAsync,
  fetchStationAsync,
  getDeviceTypeMetaDatas,
  getDeviceDatas,
  getDeviceType,
  fetchDeviceDataAsync,
  fetchDeviceTypeAsync,
  getIsLoadingDevice,
  getIsLoadingDeviceType,
  getIsLoadingDeviceTypeMetaDatas,
  getIsLoadingDeviceDatas,
  fetchParentAsync,
} from 'slices/deviceSlice'
import { fetchFactoriesAsync, getFactories } from 'slices/factorySlice'
import DeviceInfo from './DeviceInfo'
import DeviceData from './DeviceData'
import DeviceDoc from './DeviceDoc'
import { DataFormEdit } from './DataFormEdit'
import './dashboard.scss'
import { LifeCycle } from './LifeCycle'

export default function Device() {
  const urlParams = useParams()
  const [DataFormData, setDataFormData] = useState(null)
  const formDataRef = useRef(null)
  const factories = useSelector(getFactories)
  const dispatch = useDispatch()
  const device = useSelector(getDevice)

  const metaDatas = useSelector(getDeviceTypeMetaDatas)
  const datas = useSelector(getDeviceDatas)
  const type = useSelector(getDeviceType)
  const isLoadingDevice = useSelector(getIsLoadingDevice)
  const isLoadingDeviceType = useSelector(getIsLoadingDeviceType)
  const isLoadingDeviceTypeMetaDatas = useSelector(getIsLoadingDeviceTypeMetaDatas)
  const isLoadingDeviceDatas = useSelector(getIsLoadingDeviceDatas)
  const id = urlParams.id
  useEffect(() => {
    if (urlParams.id) {
      console.log(urlParams.id)
      dispatch(fetchDeviceAsync({ id: urlParams.id }))
    }
  }, [urlParams.id])

  useEffect(() => {
    if (device?.typeId) {
      dispatch(fetchDeviceMetaDataAsync(device.typeId))
      dispatch(fetchDeviceTypeAsync(device.typeId))
    }
    if (device?.parentId) {
      dispatch(fetchParentAsync({ id: device.parentId }))
    }
    if (device?.stationId) {
      dispatch(fetchStationAsync({ id: device.stationId }))
    }
    if (device?.factoryId && !factories?.find((f) => f.id == device?.factoryId)) {
      dispatch(fetchFactoriesAsync())
    }
    if (device?.id) {
      dispatch(fetchDeviceDataAsync({ id: urlParams.id }))
    }
  }, [device])
  const setShowDataSidePanel = (visible) => {
    setDataFormData(null)
    let data = {}
    metaDatas.forEach((m) => (data[m.id] = datas?.find((d) => d.metaDataId == m.id)?.value ?? ''))
    setDataFormData(data)
    formDataRef.current.setShowSidePanel(visible)
  }
  const onSubmit = (d) => {
    dispatch(fetchDeviceAsync({ id }))
  }
  if (isLoadingDevice || isLoadingDeviceType || isLoadingDeviceTypeMetaDatas || isLoadingDeviceDatas) {
    return (
      <div className='central-position'>
        <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
      </div>
    )
  } else if (device && type && metaDatas && datas) {
    return (
      <ErrorBoundary>
        <div className='grid root-grid' data-cy='module-device-dashboard'>
          <div className='row abb-col-lg-16 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
              <div className='row  abb-col-lg-9 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms'>
                  <DeviceInfo />
                </div>
                
              </div>
              <div className='row  abb-col-lg-15 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms'>
                  <LifeCycle onEdit={()=>{}}/>
                </div>
                
              </div>

              <div className='row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24'>
                <div className='tile height-6x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'>
                  <DeviceDoc device={device} />
                </div>
              </div>
            </div>
          </div>
          <div className='row  abb-col-lg-8 abb-col-md-24 abb-col-ms-24'>
            <div className='grid' style={{ margin: 0 }}>
              <div className='tile height-12x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24'>
                <DeviceData onEdit={setShowDataSidePanel} />
              </div>
            </div>
          </div>
        </div>
        <DataFormEdit ref={formDataRef} formData={DataFormData} id={device.id} onSubmit={onSubmit} />
      </ErrorBoundary>
    )
  }
  return (
    <div className='grid root-grid'>
      <p>您没有访问此页面的权限</p>
    </div>
  )
  //返回页面
}
