import React from "react";
import { NotificationContainer } from "@abb/abb-common-ux-react";
import { useSelector } from "react-redux";

export default function () {
  const notifications = useSelector((state) => state.notifications.list);
  return (
    <NotificationContainer notifications={notifications} style={{ position: "absolute", bottom: "20px", right: "0px" }} />
  );
}
