import React, { useEffect, useState } from 'react'
import { useImperativeHandle } from 'react'
import { Dialog } from '@abb/abb-common-ux-react'
import { forwardRef } from 'react'
import '../../../../styles/singleReport.scss'
import axios from 'axios'
import { nanoid } from 'nanoid'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { getSingleReportDetail } from 'common/apis/singlereports'
import { chunk } from 'lodash'
import { Image } from 'components/Image'
import { SingleReport } from '../components/SingleReport'
export const ShowSingleReport = forwardRef(({}, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [report, setReport] = useState(null)
  const showDialog = (report) => {
    setReport(report)
    setIsVisible(true)
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))

  return (
    <Dialog
    style={{ width: '1160px', height: '700px' }}
    showCloseButton={true}
    closeOnLostFocus={false}
    closeOnEscape={false}
    dimBackground={true}
    isOpen={isVisible}
    title={report?.name}
    onClose={() => setIsVisible(false)}
  >
    <SingleReport report={report} readonly={true} />
  </Dialog>
  )
})
