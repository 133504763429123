// import React, { useState, forwardRef, useImperativeHandle } from "react"
import React, { useState, useEffect } from "react"

import { Input, NumericInput, Checkbox, Dropdown, DropdownOption, Icon } from "@abb/abb-common-ux-react"
import DatePicker from "react-datepicker"
import zhCN from "date-fns/locale/zh-CN"
import enUS from "date-fns/locale/en-US"
import { t } from "i18next"
import i18n from "i18next"
import moment from "moment"

export const CommonFormItem = ({
  children,
  type, //类型 text,dropdown,number,checkbox,date,div,button,
  dataType, //type是text时,input的dataType,包括：	"number" | "color" | "date" | "email" | "month" | "password" | "search" | "tel" | "text" | "textarea" | "time" | "url" | "week"
  multiselect, //dropdown是否多选
  style, //样式
  label, //标签
  additionalLabel, //右侧标签
  fieldName, //字段名
  maxLength, //长度,text时有效
  min, //最小值，number时有效
  max, //最大值，number时有效
  step, //步进，number时有效
  decimals, //小数位数，number时有效
  disablePlus, //+失效，number时有效
  disableMinus, //-失效，number时有效
  disableEdit, //编辑失效，number时有效
  maxDate, // 最大日期, date时有效
  minDate, //最小日期, date时有效
  dateFormat, //日期格式，yyyy-MM-DD
  showYearPicker, //显示获取年界面
  showMonthYearPicker, //显示获取年月界面
  showYearDropdown, //显示年下来列表
  showTimeSelect, //是否显示时间
  value, //内容
  onChange, //值修改时触发
  placeholder, //占位字符
  err, //错误信息
  options, //下拉选项
  searchable, //是否可查询
  showClearIcon, //是否显示清除
  onValidate, //验证结果
  disabled, //是否不可用
  visible, //是否可见
  onEnter, //回车键
  onKeyUp, //键触发
  className, //css样式类
  innerStyle, //内部控件样式
  render,
  inputAttributes
}) => {
  let localLang = i18n.language == "zh_cn" ? zhCN : enUS
  const [dateValue, setDateValue] = useState(null)
  const [choice, setChoice] = useState(
    multiselect
      ? value?.map((i) => {
          return { value: i }
        })
      : [{ value }]
  )
  const [shrinkItems, setShrinkItems] = useState([])

  const _onValidate = (useNewVal, newVal) => {
    const val = useNewVal ? newVal : value
    // console.log(useNewVal, newVal, value, val)
    onValidate && onValidate(fieldName, val)
  }
  useEffect(() => {
    if (type === "dropdown") {
      if (value)
        setChoice(
          multiselect
            ? value.map((i) => {
                return { value: i }
              })
            : [{ value }]
        )
      else setChoice([])
    }
    if (type === "date") {
      if (value) setDateValue(new Date(value))
      else setDateValue(null)
    }
  }, [value])
  // useEffect(() => {
  //   if (type == "date" && value) {
  //     setDateValue(moment(value))
  //   }
  // }, [value])
  let err2 = err ? { valid: err.valid, message: err.text } : undefined
  const _onKeyUp = (key) => {
    if (key.code === "Enter") {
      onEnter && onEnter()
    } else {
      onKeyUp && onKeyUp(key)
    }
  }
  const changeShrinkItems = (id) => {
    let newshrinkItems = [...shrinkItems]
    let findIdx = newshrinkItems.findIndex((c) => c == id)
    if (findIdx >= 0) {
      newshrinkItems.splice(findIdx, 1)
    } else {
      newshrinkItems.push(id)
    }
    setShrinkItems(newshrinkItems)
  }
  const isShrinkItems = (id) => {
    let findIdx = shrinkItems.findIndex((c) => c == id)
    return findIdx >= 0
  }
  const getAllItems = (list) => {
    let newList = []
    getItemTree(list, newList, 0)
    return newList
  }
  const getItemTree = (list, newList, level) => {
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      item.level = level
      newList.push(item)
      if (item.children && item.children.length > 0 && !isShrinkItems(item.value)) {
        getItemTree(item.children, newList, level + 1)
      }
    }
  }
  const repeatChar = (c, count) => {
    let rtn = ""
    for (let index = 0; index < count; index++) {
      rtn += c
    }
    return rtn
  }
  const renderDropdownOption = (item, index) => {
    return (
      <DropdownOption
        key={index}
        disabled={item.disabled}
        label={item.label}
        itemTemplate={
          <div style={{ paddingLeft: 24 * item.level + "px" }}>
            {item.children && (
              <Icon
                onClick={(e) => {
                  changeShrinkItems(item.value)
                  e.stopPropagation()
                }}
                style={{ marginRight: "4px", cursor: "pointer" }}
                name={isShrinkItems(item.value) ? "abb/caret-right" : "abb/caret-down"}
                sizeClass="small"
              />
            )}
            {item.label}
          </div>
        }
        selectedItemTemplate={item.label}
        value={item.value}
      />
    )
  }

  return (
    <>
      {(visible === undefined || visible === true) && (
        <div className={className || (type != "button" ? "form-group" : "form-group form-group-button")} style={style}>
          {type == "text" && (
            <Input
              style={innerStyle}
              sizeClass="small"
              dataType={dataType}
              maxLength={maxLength}
              showValidationBarWhenInvalid
              showValidationIconWhenInvalid
              placeholder={disabled ? "" : placeholder || t("t_pleaseInput")}
              disabled={disabled}
              showClearIcon={showClearIcon}
              label={label}
              additionalLabel={additionalLabel}
              inputAttributes={inputAttributes}
              value={value}
              onKeyUp={_onKeyUp}
              onValueChange={(newValue) => {
                const preValue = value
                onChange(fieldName, newValue, preValue)
                _onValidate(true, newValue)
              }}
              onLostFocus={() => {
                _onValidate(false)
              }}
              validationResult={err}
              validator={(value) => {
                return {
                  valid: true,
                  text: "",
                }
              }}
            />
          )}
          {type == "number" && (
            <NumericInput
              // required={required}
              style={innerStyle}
              sizeClass="small"
              label={label}
              placeholder={disabled ? "" : placeholder || t("t_pleaseInput")}
              value={value}
              min={min}
              max={max}
              step={step}
              decimals={decimals}
              onMinusClick={() => {
                const preValue = value
                let newValue = value - 1
                onChange(fieldName, newValue, preValue)
                _onValidate(true, newValue)
              }}
              onPlusClick={() => {
                const preValue = value
                let newValue = value + 1
                onChange(fieldName, newValue, preValue)
                _onValidate(true, newValue)
              }}
              allowClear={showClearIcon}
              disabled={disabled}
              disablePlus={disablePlus}
              disableMinus={disableMinus}
              disableEdit={disableEdit}
              onChange={(newValue) => {
                const preValue = value
                onChange(fieldName, newValue, preValue)
                _onValidate(true, newValue)
              }}
              onLostFocus={() => {
                _onValidate(false)
              }}
              validationMessage={err && !err.valid && err.text}
            />
          )}
          {type == "dropdown" && (
            <Dropdown
              style={innerStyle}
              sizeClass="small"
              searchable={searchable}
              showValidationBarWhenInvalid
              showValidationIconWhenInvalid
              placeholder={disabled ? "" : placeholder || t("t_pleaseSelect")}
              clearable={showClearIcon}
              disabled={disabled}
              label={label}
              multiselect={multiselect}
              value={choice}
              onChange={(e) => {
                const preValue = value
                setChoice(e)
                const newValue = e && e.length > 0 ? (multiselect ? e.map((i) => i.value) : e[0].value) : ""
                onChange(fieldName, newValue === "" ? undefined : newValue, preValue)
                _onValidate(true, newValue)
              }}
              // onChange={(e) => {
              //   const preValue = value
              //   const newValue = multiselect
              //     ? e
              //     : e && e.length > 0
              //     ? e[0].value
              //     : ""
              //   onChange(fieldName, newValue, preValue)
              //   _onValidate(true, newValue)
              // }}
              validationState={err2}
            >
              {getAllItems(options).map((item, index) => renderDropdownOption(item, index))}
            </Dropdown>
          )}
          {type == "checkbox" && (
            <div className="ABB_CommonUX_Input__root">
              <div className="ABB_CommonUX_Input__labelContainer" style={{ display: "flex", marginBottom: 0 }}>
                <label className="form-label" style={{ fontWeight: 400 }}>
                  {/* {label} */}
                </label>
                {additionalLabel && <span className="abb-additionalLabel">{additionalLabel}</span>}
              </div>
              <Checkbox
                style={innerStyle}
                sizeClass="small"
                disabled={disabled}
                data={!value}
                // label={label}
                value={value}
                label={label}
                onChange={(newValue) => {
                  const preValue = value
                  onChange(fieldName, newValue, preValue)
                  _onValidate(true, newValue)
                }}
              />
              {err && !err.valid && (
                <div className="ABB_CommonUX_Input__validationMessageContainer">
                  <span className="ABB_CommonUX_Input__validationIconWrapper">
                    <Icon name="abb/error-circle-1" sizeClass="small" />
                  </span>
                  <span className="ABB_CommonUX_Input__validationMessage">{err.text}</span>
                </div>
              )}
            </div>
          )}
          {type == "date" && (
            <div className="ABB_CommonUX_Input__root">
              <div className="ABB_CommonUX_Input__labelContainer" style={{ display: "flex" }}>
                <label className="form-label" style={{ fontWeight: 400 }}>
                  {label}
                </label>
                <span className="abb-additionalLabel">{additionalLabel}</span>
              </div>
              <DatePicker
                style={innerStyle}
                className={"abb-date-picker" + (disabled ? " disabled" : "")}
                popperClassName="abb-date-picker-popup"
                locale={localLang}
                showYearPicker={showYearPicker}
                showYearDropdown={showYearDropdown}
                showMonthYearPicker={showMonthYearPicker}
                dateFormat={dateFormat || "yyyy-MM-dd"}
                timeFormat="HH:mm"
                showTimeSelect={showTimeSelect}
                placeholderText={disabled ? "" : placeholder || t("t_pleaseSelect")}
                maxDate={maxDate ? moment(maxDate).toDate() : undefined}
                minDate={minDate ? moment(minDate).toDate() : undefined}
                isClearable={showClearIcon}
                onChange={(newValue) => {
                  const preValue = value
                  setDateValue(newValue)
                  let newValueStr = newValue ? moment(newValue).format(dateFormat.toUpperCase()) : ""
                  onChange(fieldName, newValueStr, preValue)
                  _onValidate(true, newValueStr)
                }}
                selected={dateValue}
                disabled={disabled}
              />
              {err && !err.valid && (
                <div className="ABB_CommonUX_Input__validationMessageContainer">
                  <span className="ABB_CommonUX_Input__validationIconWrapper">
                    <Icon name="abb/error-circle-1" sizeClass="small" />
                  </span>
                  <span className="ABB_CommonUX_Input__validationMessage">{err.text}</span>
                </div>
              )}
            </div>
          )}
          {type == "div" && (
            <div className="ABB_CommonUX_Input__root">
              <div className="ABB_CommonUX_Input__labelContainer" style={{ display: "flex" }}>
                <span className="ABB_CommonUX_Input__label">{label}</span>
                <span className="abb-additionalLabel">{additionalLabel}</span>
              </div>
              <div style={innerStyle}>
                {render &&
                  render(value, (newValue) => {
                    const preValue = value
                    onChange(fieldName, newValue, preValue)
                    _onValidate(true, newValue)
                  })}
              </div>
            </div>
          )}
          {!type && "please set type"}
          {type && "text,dropdown,number,checkbox,date,div,button".indexOf(type) < 0 && "No support type:" + type}
          {children}
        </div>
      )}
    </>
  )
}
