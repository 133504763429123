import React, { useEffect, useState } from 'react'
import { forwardRef } from 'react'
import '../../../../styles/singleReport.scss'
import axios from 'axios'
import { getSingleReportDetail } from 'common/apis/singlereports'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import { SingleReport as ReportView } from 'modules/EzReport/components/SingleReport'
export const SingleReport = forwardRef(({ report }, ref) => {
  const [detail, setDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
 
  const fetchPreviewInfo = (reportId) => {
    setIsLoading(true)
    axios.post(getSingleReportDetail(reportId)).then((response) => {
      setDetail(response.data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (report) {
      fetchPreviewInfo(report.id)
    }
  }, [report])


  return (
    <>
      {isLoading && (
          <div style={{ width: '100%', height: '100%', display: 'flex', backgroundColor: '#fff' }}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
                zIndex: 9999,
              }}
            >
              <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
            </div>
          </div>
        )}
        {
          report && <ReportView report={detail}></ReportView>
        }
    </>
  )
})
