import React, { useState, useEffect, useRef } from 'react'
import { Button, WithTooltip, Tooltip } from '@abb/abb-common-ux-react'
import { CommonFormItem } from 'components/LoongInCommon'
import { CommonForm, CommonRule } from 'components/LoongInCommon'
import './bootstrap.css'
import './login.css'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { findTenant, login, resetPassword } from 'common/endpoints'

import { changeLanguage } from 'i18n'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'hooks/useCurrentUser'

import { fetchAbpApplicationConfigurationAsync } from 'slices/abpApplicationConfigurationSlice'
import { useLocation, useHistory } from 'react-router-dom'
import { CommonPrompt, CommonPromptButtons } from 'components/LoongInCommon/CommonPrompt'
import { tr } from 'date-fns/locale'
export default function ResetPassword() {
  const { i18n, t } = useTranslation()
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const [isSumbiting, setIsSumbiting] = useState(false)
  const [isSuccess, settIsSuccess] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const urlQuery = new URLSearchParams(location.search)
  const initData = {
    confirmPassword: '',
    password: '',
  }
  const rules = {
    password: [
      {
        ...CommonRule.Require,
        message: t('resetPassword.rule.plsInputPwd'),
      },
      {
        ...CommonRule.Password,
      },
    ],
    confirmPassword: [
      {
        ...CommonRule.Require,
        message: t('resetPassword.rule.plsInputConfirmPassword'),
      },
      {
        match: 'password',
        message: t('user.twicePwdNotSame'),
      },
    ],
  }
  const loginSystem = (payload, config) => {
    axios
      .post(resetPassword(), payload, config)
      .then((response) => {
        settIsSuccess(true)
      })
      .catch((err) => {
        console.log('reset password err')
        console.log(err)
        dispatch(showNotification(NotificationType.Alarm, t('login.msg.userOrPwdError')))
      })
      .finally(() => {
        setIsSumbiting(false)
      })
  }

  const sumbitForm = () => {
    formRef.current.validate((valid, formData) => {
      if (valid) {
        loginSystem({ password: formData.password, userId: urlQuery.get('userId'), resetToken: urlQuery.get('resetToken') })
      } else {
        dispatch(showNotification(NotificationType.Alarm, t('login.msg.plsInputUserName&pwd')))
      }
    })
  }
  const switchLanguage = () => {
    let newlang = i18n.language == 'zh_cn' ? 'en_us' : 'zh_cn'
    changeLanguage(newlang)
  }
  const resetForm = () => {
    return formRef.current.resetForm()
  }
  const cancel = () => {
    resetForm()
  }

  return (
    <>
      <div className='navbar navbar-fixed-top abb-nav-bar'>
        <div className='container-fluid'>
          <div className='navbar-brand'>
            <img src='/ABB-logo.svg' className='abb-navbar-brand-logo' />
          </div>
        </div>
      </div>

      <div className='body-content'>
        <div className='abb-layout-cards'>
          <div className='card'>
            <div className='card-header'>
              <h3 className='card-title'>{t('resetPassword.formTitle')}</h3>

              <div
                onClick={switchLanguage}
                style={{
                  float: 'right',
                  position: 'relative',
                  top: '-40px',
                  color: 'gray',
                  cursor: 'pointer',
                }}
              >
                <WithTooltip>
                  <div> {t(i18n.language)}</div>
                  <Tooltip>
                    <div>{t('resetPassword.msg.switchLanguage')}</div>
                  </Tooltip>
                </WithTooltip>
              </div>
            </div>

            <div className='card-body'>
              <CommonForm ref={formRef} initData={initData} rules={rules}>
                <CommonFormItem type='text' dataType='password' label={t('resetPassword.password')} fieldName='password' />
                <CommonFormItem type='text' dataType='password' label={t('resetPassword.confirmPassword')} fieldName='confirmPassword' />
                <CommonFormItem type='button'>
                  <Button text={t('b_reset')} type='primary-blue' sizeClass='small' onClick={sumbitForm} isLoading={isSumbiting} />
                </CommonFormItem>
              </CommonForm>
            </div>
          </div>
          <div className='card-copyright'>
            &copy; Copyright 2022 ABB |<a href='http://new.abb.com/provider-information'>{t('login.msg.providerInfo')}</a>|
            <a href='http://new.abb.com/privacy-policy'>{t('login.msg.privacyPolicy')}</a>
          </div>
        </div>
      </div>
      <CommonPrompt
        isVisible={isSuccess}
        title={'重置密码'}
        buttons={CommonPromptButtons.OK}
        onClose={() => {
          window.location= urlQuery.get('returnUrl')
        }}
      >
        <div>
            您已成功重置密码。
          </div>
      </CommonPrompt>
    </>
  )
}
