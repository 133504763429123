import React, { useState, useRef, forwardRef } from 'react'
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import { useSelector } from 'react-redux'
import { getClientWidth } from 'slices/appSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { deleteImportanceWeights,  getImportanceWeightsPage } from 'common/apis/ezreport'

export const MainIndex = forwardRef(({ catalog, title }, ref) => {
  const refIndex = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const clientWidth = useSelector(getClientWidth)
  const dispatch = useDispatch()
  //设置查询字段dataDictionaryId
  useEffect(() => {
    if (catalog)
      setSearchData({
        catalog,
      })
    else if (refIndex && refIndex.current) refIndex.current.setList([])
  }, [catalog])
  //点击添加按钮时将dataDictionaryId注入到表单中
  const onAddBefore = () => {
    return {
      catalog,
      catalogName:title,
    }
  }

  //列定义
  const columns = [
    {
      title: '标题',
      key: 'title',
    },
    {
      title: '编码',
      key: 'key',
    },
    {
      title: '权重值',
      key: 'weight',
    },
  ]

  

  //返回页面
  return (
    <>
      <CommonIndex
        className='admin-main-content with-padding no-margin'
        ref={refIndex}
        columns={columns}
        searchData={searchData}
        getList={getImportanceWeightsPage}
        deleteData={deleteImportanceWeights}
        FormEdit={FormEdit}
        tableWidth={clientWidth - 425}
        canAdd={!!catalog}
        canEdit
        canDelete
        enableSorting={false}
        onAddBefore={onAddBefore}
      />
    </>
  )
})
