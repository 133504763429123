import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, Children } from 'react'
import { Button, TabControl, TabItem, Checkbox, Dialog } from '@abb/abb-common-ux-react'
import { CommonForm } from '..'
import SidePanel from 'components/SidePanel'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import axios from 'axios'
import { useDispatch } from 'react-redux'

import { t } from 'i18next'

export const CommonFormEdit = forwardRef(
  (
    {
      children,
      title, //标题
      panelTitle,
      formItem, //表单项目定义
      initData, //初始化数据
      rules, //校验规则
      otherValidate, //其他校验方法
      createData, //创建数据的接口
      updateData, //更新数据的接口
      getUpdateInput, //获取更新数据的入参
      getCreateInput, //获取创建数据的入参
      id, //Index传递过来的id
      formData, //index传递过来的数据
      onSubmit, //提交成功后
      onCancel, //取消后
      height, //第一个tab高度
      onTabChange, //页签切换、
      type, //类型: sidePanel: 抽屉(默认), dialog: 弹出对话框, div: 普通
      className, //css样式类
      resetAfterSumbit, //提交后是否重置表单
      showSaveButton, //显示保存按钮
      showCancelButton, //显示取消按钮
      onChange, //表单数据改变后触发事件
      onChangeBefore, //表单数据改变前触发事件
      width, //sidePanel的宽度
      event, //
      notificationMessage,
      isPost = true,
      disabled, //是否
      // readonly,//是否只读
      render, //渲染
      otherButtons, //其他按钮
      cancelOnBackdrop,
    },
    ref
  ) => {
    const formRef = useRef(null)
    const dispatch = useDispatch()
    const [showSidePanel, setShowSidePanel] = useState(false)
    const [isSumbiting, setIsSumbiting] = useState(false)
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [activeTab, setActiveTab] = React.useState(0)
    const [newFormData, setNewFormData] = useState(null)

    const createDataAsync = async (payload) => {
      setIsSumbiting(true)
      try {
        const response = await axios.post(createData(), payload)
        dispatch(showNotification(NotificationType.Success, notificationMessage?.add?.success || t('t_successToAdd')))
        if (resetAfterSumbit === undefined || resetAfterSumbit === true) resetForm()
        onSubmit && onSubmit({ action: 'create', item: payload, data: response.data })
        setShowSidePanel(false)
      } catch (e) {
        dispatch(
          showNotification(
            NotificationType.Alarm,
            e && e.error && e.error.message ? e.error.message : notificationMessage?.add?.fail || t('t_failToAdd')
          )
        )
      } finally {
        setIsSumbiting(false)
      }
    }
    const updateDataAsync = async (id,payload) => {
      setIsSumbiting(true)
      let response = undefined
      try {
        response = await (isPost ? axios.post(updateData(), payload,{params:{id:id}}) : axios.put(updateData(), payload,{params:{id:id}}))
        dispatch(showNotification(NotificationType.Success, notificationMessage?.update?.success || t('t_successToUpdate')))
        if (resetAfterSumbit === undefined || resetAfterSumbit === true) resetForm()
        console.log(response);
        onSubmit && onSubmit({ action: 'update', item: payload, data: response.data })
        setShowSidePanel(false)
      } catch (e) {
        console.log(e)
        dispatch(
          showNotification(
            NotificationType.Alarm,
            e && e.error && e.error.message ? e.error.message : notificationMessage?.update?.fail || t('t_failToUpdate')
          )
        )
      } finally {
        setIsSumbiting(false)
      }
    }
    const saveForm = () => {
      setIsSumbiting(true)
      console.log("submit")
      formRef.current.validate((valid, formData) => {
        setIsSumbiting(false)
        if (valid) {
          //做其他校验，返回错误信息则返回
          let errOther = otherValidate && otherValidate(formData)
          if (errOther) {
            dispatch(showNotification(NotificationType.Alarm, errOther))
            return
          }
          if (id) updateDataAsync(id,getUpdateInput(formData))
          else createDataAsync(getCreateInput(formData))
        } else {
          dispatch(showNotification(NotificationType.Alarm, t('t_formError')))
        }
      })
    }
    const resetForm = () => {
      if (formRef && formRef.current) return formRef.current.resetForm()
      else return null
    }
    const cancel = () => {
      if (formRef && formRef.current) {
        formRef.current.resetForm()
        onCancel && onCancel()
      }
      setShowSidePanel(false)
    }
    const showAndSwitchTab = (tabIndex) => {
      setShowSidePanel(true)
      setActiveTab(tabIndex)
      onTabChange && onTabChange(tabIndex)
    }
    useEffect(() => {
      if (event) {
        switch (event.name) {
          case 'setShowSidePanel':
            setShowSidePanel(...event.params)
            event.callback && event.callback()
            break
          case 'showAndSwitchTab':
            showAndSwitchTab(...event.params)
            event.callback && event.callback()
            break
          case 'resetForm':
            console.log('resetForm')
            const d = resetForm(...event.params)
            event.callback && event.callback(d)
            break
          default:
            break
        }
      }
    }, [event])
    const validate = (callback) => {
      formRef.current.validate((valid, formData) => {
        callback(valid, formData)
      })
    }

    useImperativeHandle(ref, () => ({
      setShowSidePanel,
      showAndSwitchTab,
      resetForm,
      validate,
    }))
    useEffect(() => {
      const getDataByIdAsync = async (id,initData, formData) => {
        try {
          setIsLoadingData(true)
          setNewFormData({
           ...initData, ...formData,
          })
        } catch (e) {
          console.log(e)
          dispatch(showNotification(NotificationType.Alarm, t('t_errorToReadData')))
        } finally {
          setIsLoadingData(false)
        }
      }
      if (id || formData) {
        getDataByIdAsync(id,initData, formData)
      }
      if (!formData) setNewFormData(initData || {})
    }, [id, formData])
    const _onTabChange = (oldIndex, newIndex) => {
      setActiveTab(newIndex)
      onTabChange && onTabChange(newIndex)
    }
    //主页签
    const mainTab = (
      <TabItem title={title}>
        <div style={{ height: height ? height + 'px' : undefined }}>
          <CommonForm
            initData={initData}
            newFormData={newFormData}
            formItem={formItem}
            ref={formRef}
            rules={rules}
            onChange={onChange}
            onChangeBefore={onChangeBefore}
            disabled={disabled}
            render={render}
          ></CommonForm>
        </div>
      </TabItem>
    )
    const childrenTabItem = children ? (
      <TabControl type='panel' activeTab={activeTab} onTabChange={_onTabChange}>
        {mainTab}
        {Children.map(children, (child, index) => {
          console.log(child)
          let title = child?.props['title']
          if (!title) return undefined
          return (
            <TabItem key={index} title={title}>
              {child}
            </TabItem>
          )
        })}
      </TabControl>
    ) : (
      <TabControl type='panel' activeTab={activeTab} onTabChange={_onTabChange}>
        {mainTab}
      </TabControl>
    )
    const renderOtherButton = () => {
      // console.log("renderOptionButton")
      const x = [' ']
      let i = 0
      for (const key in otherButtons) {
        i++
        x.push(
          <React.Fragment key={i}>
            <Button
              style={{ marginRight: '5px' }}
              text={otherButtons[key]?.text}
              type={otherButtons[key]?.type || 'normal'}
              sizeClass='small'
              icon={otherButtons[key]?.icon}
              disabled={otherButtons[key]?.disabled}
              onClick={otherButtons[key]?.onClick}
              isLoading={otherButtons[key]?.isLoading}
            />{' '}
          </React.Fragment>
        )
      }
      return x
    }

    const bottomButtons = (
      <div className='form-group-button' style={{ display: 'flex', marginTop: '5px' }}>
        {otherButtons && renderOtherButton()}
        {!disabled && (showSaveButton === undefined || showSaveButton === true) && (
          <Button
            style={{ marginRight: '5px' }}
            icon='abb/save'
            text={t('b_save')}
            type='primary-blue'
            sizeClass='small'
            onClick={saveForm}
            isLoading={isSumbiting}
          />
        )}
        {(showCancelButton === undefined || showCancelButton === true) && (
          <Button icon='abb/log-out' text={t('b_cancel')} type='normal' sizeClass='small' onClick={cancel} />
        )}
      </div>
    )
    const mainContent = (
      <>
        <div>{childrenTabItem}</div>
        {bottomButtons}
      </>
    )
    return (
      <>
        {(type === undefined || type === 'sidePanel') && (
          <SidePanel show={showSidePanel} size={width} onClickBackdrop={() => cancelOnBackdrop && cancel()}>
            <div className='side-panel-header'>
              <div className='side-panel-title'>
                {panelTitle || (disabled ? t('t_titleShowData') : id ? t('t_titleEditData') : t('t_titleAddData'))}
              </div>
            </div>
            <div className='side-panel-body'>{mainContent}</div>
          </SidePanel>
        )}
        {type === 'div' && <div className={className}>{mainContent}</div>}
        {type === 'dialog' && (
          <Dialog
            style={{ width: width + 'px' }}
            showCloseButton={true}
            closeOnLostFocus
            closeOnEscape={false}
            dimBackground={true}
            isOpen={showSidePanel}
            title={panelTitle || (disabled ? t('t_titleShowData') : id ? t('t_titleEditData') : t('t_titleAddData'))}
            standardButtonsOnBottom={() => {
              return bottomButtons
            }}
            onClose={() => cancel()}
          >
            <div>{childrenTabItem}</div>
          </Dialog>
        )}
      </>
    )
  }
)
