import React from 'react'
import './SideNavMenu.scss'
import { Icon } from '@abb/abb-common-ux-react'
import { usePermission } from 'hooks/usePermission'
export default function SideNavMenuItem({ itemKey, active,permission, icon, title, onClick, ...props }) {
  if(!usePermission(permission)){
    return null
  }
  return (
    <div className={active ? "nav-item active" : "nav-item"} key={itemKey} onClick={onClick} {...props} >
      <div className="nav-item-main">
        {icon && icon.indexOf('abb/') === 0 &&
          <Icon name={icon} sizeClass="small" />}
      </div>
      <div className="nav-item-popup">{title}</div>
    </div>
  )
}