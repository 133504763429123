import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getMaterial, getMaterialCatalog } from "slices/materialSlice";
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react";
import { nanoid } from "nanoid";
import { t } from "i18next";

export default function MaterialInfo({onEdit}) {
  const material = useSelector(getMaterial);
  const catalog = useSelector(getMaterialCatalog);
  const [pageIndex, setPageIndex] = useState(0);
console.log(onEdit)
  let profiles = [];

  profiles = [
    [
      { title: t('material.name'), value: material.name },
      { title: t("materialCatalog.name"), value: catalog.name },
      { title: t('material.code'), value: material.code },
      { title: t('material.modal'), value: material.modal },
      { title: t('material.provider'), value: material.provider },
      { title: t('material.warranty'), value: material.warranty + " 年" },
      { title: t('material.description'), value: material.description },
    ],
  ];

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);
  const onWindowResize = () => {
    setPageIndex(0);
  };
  return (
    <div style={{ height: "100%" }}>
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">{t('material.formTitle')}</div>
          <div className="widget-tools device-profile-paginator show-md show-lg" style={{paddingTop:10}}>
            <Button sizeClass="small" shape="pill" type="discreet-black" icon="abb/edit" onClick={()=>onEdit(true)} style={{ opacity: 1, marginRight: 8 }} />
          </div>
          <div className="widget-tools device-profile-paginator show-xl">
            <Button sizeClass="small" shape="pill" type="discreet-black" icon="abb/edit" onClick={()=>onEdit(true)} style={{ opacity: 1, marginRight: 8 }} />
          </div>
        </div>
        <div className="widget-body device-profile-details">
          <div className={`layout column-${profiles.length} offset-${pageIndex}`}>
            {profiles.map((list) =>
              list.length === 0 ? null : (
                <Table sizeClass="medium" borderType="discreet" key={nanoid()}>
                  <tbody>
                    {list.map((item) => (
                      <tr key={nanoid()}>
                        <td title={item.title}>{item.title}</td>
                        <td>
                          {item.value && (
                            <WithTooltip>
                              <span>{item.value}</span>
                              <Tooltip>
                                <div>
                                  {item.title}：{item.value}
                                </div>
                              </Tooltip>
                            </WithTooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
