import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Collapsible, CollapsibleContainer, Icon, Input } from '@abb/abb-common-ux-react'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import './tree.scss'

export const CommonTree = forwardRef(
  ({ children, items, cascade = CascadeType.All, leafOnly = false, rightIcon, menu, getMenu, onActive, checkItems, onCheckChange }, ref) => {
    const [expanded, setExpanded] = useState([])
    const [activeItem, setActiveItem] = useState(null)
    const refCollaps = useRef(null)

    //更新checkItem相关
    const getCheckItemIdx = (newCheckItems, itemId) => {
      return newCheckItems.findIndex((c) => c === itemId)
    }
    const changeCheckItems = (newCheckItems, itemId, checked) => {
      let findIdx = getCheckItemIdx(newCheckItems, itemId)
      if (findIdx >= 0 && !checked) {
        //已经存在的则清除
        newCheckItems.splice(findIdx, 1)
      } else if (findIdx < 0 && checked) {
        //不存在的则添加
        newCheckItems.push(itemId)
      }
    }
    const changChildren = (newCheckItems, _item, checked) => {
      if (
        ((cascade & CascadeType.DownCheck) === CascadeType.DownCheck && checked) ||
        ((cascade & CascadeType.DownUnChecked) === CascadeType.DownUnChecked && !checked)
      ) {
        changeCheckItems(newCheckItems, _item.key, checked)
        if (!_item.children || _item.children.length == 0) return
        for (let i = 0; i < _item.children.length; i++) {
          changChildren(newCheckItems, _item.children[i], checked)
        }
      }
    }
    const checkAndChange = (newCheckItems, _item) => {
      let idx = getCheckItemIdx(newCheckItems, _item.key)
      if (!_item.children || _item.children.length == 0) return idx >= 0
      let existCheck = false
      for (let i = 0; i < _item.children.length; i++) {
        if (checkAndChange(newCheckItems, _item.children[i])) {
          //如果是true，表示找到了
          existCheck = true
        }
      }
      if (existCheck || (cascade & CascadeType.UpUnChecked) == CascadeType.UpUnChecked) {
        changeCheckItems(newCheckItems, _item.key, existCheck)
      }
      return existCheck
    }

    const changeCheck = (itemId) => {
      let newCheckItems = [...checkItems]
      let findItm = findItem(itemId)
      if (!findItm) {
        console.log('No found item!!itemId:' + itemId)
        return
      }
      console.log('checked')
      let checked = newCheckItems.findIndex((c) => c === itemId) >= 0

      changeCheckItems(newCheckItems, itemId, !checked) //反向改变
      //向下更新
      if ((cascade & CascadeType.DownAll) > 0) {
        changChildren(newCheckItems, findItm, !checked)
      }
      // //更新整棵树
      if ((cascade & CascadeType.UpAll) > 0) {
        console.log('check up')
        checkAndChange(newCheckItems, { key: '0', children: items })
      }
      onCheckChange(newCheckItems.filter((n) => n != '0'))
    }
    const renderTitle = (key, title, mnu) => (
      <div className='resource-dir-header'>
        <div className='resource-dir-name'>{title}</div>
        <div>
          {mnu && (
            <div className='resource-dir-menu'>
              <DropdownButton forcePopupLeft>
                {mnu?.map((m, midx) => (
                  <DropdownButtonOption
                    key={'ddbot_' + midx}
                    onClick={() => {
                      let itm = findItem(key)
                      m.onClick && m.onClick(itm)
                    }}
                    disabled={m.disabled || (leafOnly && m.children && m.children.length > 0)}
                  >
                    <span style={{ color: m.color }}>{m.title}</span>
                  </DropdownButtonOption>
                ))}
              </DropdownButton>
            </div>
          )}
        </div>
      </div>
    )
    const renderChild = (item) => {
      const { key, title, children } = item
      return (
        <Collapsible
          key={key}
          itemId={key}
          checked={checkItems && !!checkItems.find((c) => c === key)}
          onClickCheckbox={changeCheck}
          title={renderTitle(key, title, getMenu ? getMenu(item) : menu)}
          className={key === activeItem ? 'active' : ''}
        >
          {children && children.map((c) => renderChild(c))}
        </Collapsible>
      )
    }
    const findItem = (key) => {
      const _findItem = (_key, _item) => {
        if (_item.key === _key) return _item
        if (!_item.children) return null
        for (let i = 0; i < _item.children.length; i++) {
          let itmF = _findItem(_key, _item.children[i])
          if (itmF) {
            return itmF
          }
        }
        return null
      }
      return _findItem(key, { key: '0', children: items })
    }

    const changeActiveItem = (itemId) => {
      // console.log('handle changeActiveItem')
      setActiveItem(itemId)
      let itm = findItem(itemId)
      onActive && onActive(itm)
    }

    const expandRoot = () => {
      let newExpanded = []
      items.forEach((c) => {
        newExpanded.push(c.key)
      })
      setExpanded(Array.from(new Set([...expanded, ...newExpanded])))
    }
    const _setItemId = (item, itemIds) => {
      if (item.children && item.children.length > 0) {
        itemIds.push(item.key)
        item.children.forEach((c) => {
          _setItemId(c, itemIds)
        })
      }
    }

    const expandAll = () => {
      let newExpanded = []
      items.forEach((c) => {
        _setItemId(c, newExpanded)
      })
      setExpanded(Array.from(new Set([...expanded, ...newExpanded])))
      // console.log("expandAll")
    }

    useImperativeHandle(ref, () => ({
      expandAll,
      expandRoot,
    }))
    const onExpanding = (items) => {
      if (expanded !== undefined) {
        setExpanded(Array.from(new Set([...expanded, ...items])))
      }
    }
    const onCollapsing = (item) => {
      if (expanded !== undefined) {
        setExpanded(expanded.filter((i) => i !== item))
      }
    }
    return (
      <>
        {rightIcon && (
          <div className='content-side-list-header' style={{ display: 'flex', height: '48px', flexDirection: 'row-reverse' }}>
            <Icon
              style={{
                width: '20px',
                color: rightIcon.color || 'gray',
                cursor: 'pointer',
                height: '20px',
              }}
              onClick={rightIcon.onClick}
              name={rightIcon.name || 'abb/plus'}
              sizeClass={rightIcon.sizeClass || 'medium'}
            />
          </div>
        )}

        <CollapsibleContainer
          ref={refCollaps}
          monochrome
          filter={''}
          // hideFiltered={false}
          className='collapsible-directory'
          singleOpenMode={false}
          expandOnTitleClick={false}
          expandedCollapsible={expanded}
          onExpanding={onExpanding}
          onCollapsing={onCollapsing}
          onItemClick={(e) => {
            if (!(leafOnly && e.props.children && e.props.children.length > 0)) {
              changeActiveItem(e.props.itemId)
            }
          }}
        >
          {items?.map((item) => renderChild(item))}
        </CollapsibleContainer>
      </>
    )
  }
)

export const CascadeType = { None: 0, UpCheck: 1, DownCheck: 2, UpUnChecked: 4, DownUnChecked: 8, UpAll: 5, DownAll: 10, All: 15 }
