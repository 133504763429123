import React, { useState, useRef, forwardRef } from 'react'
import { CommonIndex, CommonTable } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { getClientWidth } from 'slices/appSlice'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { deleteParameterItems, getParameterItemsPage } from 'common/apis/ezreport'
import { OptionFormEdit } from './OptionFormEdit'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'
import { t } from 'i18next'

export const MainIndex = forwardRef(({ catalogId, catalogName }, ref) => {
  const refIndex = useRef(null)
  const formOptionRef = useRef(null)
  const [searchData, setSearchData] = useState(null)
  const [optionFormData, setOptionFormData] = useState(null)
  const [deleteItem, setDeleteItem] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const clientWidth = useSelector(getClientWidth)
  const dispatch = useDispatch()
  //设置查询字段dataDictionaryId
  useEffect(() => {
    if (catalogId)
      setSearchData({
        catalogId,
      })
    else if (refIndex && refIndex.current) refIndex.current.setList([])
  }, [catalogId])
  //点击添加按钮时将dataDictionaryId注入到表单中
  const onAddBefore = () => {
    return {
      catalogId,
      catalogName,
    }
  }

  const childrenColumns = [
    {
      title: 'maintenanceCatalog.childrenTitle',
      key: 'title',
      width: '300',
    },
  ]
  //列定义
  const columns = [
    {
      title: '检查项',
      key: 'name',
      isTree: true,
      // expand: (item, index) => {
      //   if (!(item.inputType === 'radio' || item.inputType === 'check')) return null
      //   const list = item.options ?? []
      //   if (list.length == 0) return <div style={{ marginLeft: '20px' }}>没有数据</div>
      //   return (
      //     <div style={{ padding: '10px 20px 10px 20px' }}>
      //       <CommonTable zebra={true} columns={childrenColumns} canSelection={true} data={list} showPage={false} keyField='key' />
      //     </div>
      //   )
      // },
    },
  ]

  const setShowOptionPanel = (visible) => {
    formOptionRef.current.setShowSidePanel(visible)
  }
  //其他行菜单
  const otherMenu = (item, index) => {
    return item.inputType === 'radio' || item.inputType === 'check'
      ? [
          {
            onClick: () => {
              setOptionFormData({
                itemName: item.name,
                itemId: item.id,
              })
              setShowOptionPanel(true)
            },
            title: '添加选项',
          },
        ]
      : []
  }

  const actionRender = (item, index) => {
    return !item.inputType ? (
      <DropdownButton>
        <DropdownButtonOption
          key='ddb_1'
          onClick={() => {
            setOptionFormData({ ...item })
            setShowOptionPanel(true)
          }}
        >
          {t('b_edit')}
        </DropdownButtonOption>
        {(item.parentId === null) && (
          <DropdownButtonOption
            key='ddb_3'
            onClick={() => {
              setOptionFormData({
                itemName: item.name,
                parentId:item.id,
                itemId: item.parameterItemId,
              })
              setShowOptionPanel(true)
            }}
          >
            {'添加子选项'}
          </DropdownButtonOption>
        )}
        <DropdownButtonOption key='ddb_2' onClick={() => setDeleteItem(item)}>
          <span style={{ color: 'red' }}>{t('b_del')}</span>
        </DropdownButtonOption>
      </DropdownButton>
    ) : (
      <DropdownButton>
        <DropdownButtonOption
          key='ddb_2'
          onClick={() => {
            refIndex.current.edit(item, index)
          }}
        >
          {t('b_edit')}
        </DropdownButtonOption>
        {(item.inputType === 'radio' || item.inputType === 'check') && (
          <DropdownButtonOption
            key='ddb_3'
            onClick={() => {
              setOptionFormData({
                itemName: item.name,
                itemId: item.id,
              })
              setShowOptionPanel(true)
            }}
          >
            {'添加选项'}
          </DropdownButtonOption>
        )}
        <DropdownButtonOption key='ddb_4' onClick={() => refIndex.current.setDeleteItem(item)}>
          <span style={{ color: 'red' }}>{t('b_del')}</span>
        </DropdownButtonOption>
      </DropdownButton>
    )
  }

  const onOptionSubmit = (d) => {
    setOptionFormData(null)
    refIndex.current.query()
  }
  //返回页面
  return (
    <>
      <CommonIndex
        className='admin-main-content with-padding no-margin'
        ref={refIndex}
        columns={columns}
        actionRender={actionRender}
        searchData={searchData}
        getList={getParameterItemsPage}
        deleteData={deleteParameterItems}
        FormEdit={FormEdit}
        tableWidth={clientWidth - 425}
        canAdd={!!catalogId}
        canEdit
        canDelete
        enableSorting={false}
        onAddBefore={onAddBefore}
      />
      <OptionFormEdit
        ref={formOptionRef}
        formData={optionFormData}
        onCancel={() => {
          setOptionFormData(null)
        }}
        onSubmit={onOptionSubmit}
        id={optionFormData?.id}
      />
    </>
  )
})
