import React, { useState, useEffect,useRef } from "react"
import { Route, useRouteMatch } from "react-router-dom"
import { CommonList } from "components/LoongInCommon"
import { Details } from "./Details"
import { getAllSettings } from "common/endpoints"
import axios from "axios"

export default () => {
  const { path } = useRouteMatch()
  const refList = useRef(null)
  const [list, setList] = useState(null)
  const [activeItem, setActiveItem] = useState([])
  const [isLoadingList, setIsLoadingList] = useState(false)

  const getAllList = () => {
    setIsLoadingList(true)
    axios
      .post(getAllSettings())
      .then((response) => {
        if (response.status == 200 && response.data) {
          setList(response.data)
          if (response.data.length > 0) {
            refList.current.setCurrentItem(response.data[0])
            // setActiveItem(response.data.items[0])
          } else setActiveItem({})
        } else {
          setList([])
        }
      })
      .finally(() => {
        setIsLoadingList(false)
      })
  }

  useEffect(() => {
    getAllList()
  }, [])
  const onItemChange = (item) => {
    setActiveItem(item)
  }
  const onSubmit = () => {
    getAllList()
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        padding: 8,
        boxSizing: "border-box",
      }}
      data-cy="admin-device-model"
    >
      <Route path={[`${path}/:id`, path]}>
        <CommonList
          ref={refList}
          data={list}
          keyName="group"
          filterText="groupDisplayName"
          displayName="groupDisplayName"
          isLoading={isLoadingList}
          onItemChange={onItemChange}
        />
        <Details
          id={activeItem.group}
          items={activeItem.settingItemOutput}
          onSubmit={onSubmit}
        />
      </Route>
    </div>
  )
}
