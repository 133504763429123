import React, { useState, useEffect, useRef } from "react"
import { WithTooltip, Tooltip, Icon } from "@abb/abb-common-ux-react"
import { getUserProfile } from "slices/userProfileSlice"
import { useSelector } from "react-redux"
import axios from "axios"
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import moment from "moment"

import {
  showNotification,
  NotificationType,
} from "components/Notification/notificationSlice"
import { useDispatch } from "react-redux"
// import { ResponseResult } from "common/request"
import { t } from "i18next"
import {  deleteTestModal, getTestModalPage } from "common/apis/ezreport"
import { Preview } from "./preview"

export default function TestModals() {
  const userProfile = useSelector(getUserProfile)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const dispatch = useDispatch()

  const refIndex = useRef(null)
  const refPreview = useRef(null)

  //列定义
  const columns = [
    {
      title: "factory.name",
      key: "name",
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>工厂已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
  ]
  
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => showDetail(item),
        title: '预览',
      },
    ]
  }
  //打开权限树
  const showDetail = (item) => {
    refPreview.current.showDialog(item.modal)
  }
  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        otherMenu={otherMenu}
        getList={getTestModalPage}
        deleteData={deleteTestModal}
        FormEdit={FormEdit}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        enableSorting={true}
      />
      <Preview ref={refPreview}/>
    </>
  )
}
