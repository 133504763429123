export const getSingleReportDetail = (id) => `api/EzReport/SingleReports/detail?id=${id}`

export const approveSingleReport = (id) => `api/EzReport/SingleReports/approve?id=${id}`
export const submitSingleReport = (id) => `api/EzReport/SingleReports/submit?id=${id}`

export const generateSingleReport = (id) => `api/EzReport/SingleReports/generate?id=${id}`

export const importTestData = (id) => `api/EzReport/SingleReports/import`

export const uploadSingleReport = () => `api/EzReport/SingleReports/upload`
