import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonTree } from "components/LoongInCommon"
import axios from "axios"
import { getOrganization } from "common/endpoints"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { useSelector } from "react-redux"

export const LeftPanel = forwardRef(({ children, onActive, onAdd, onEdit, onDel }, ref) => {
  const treeRef = useRef(null)
  //组织架构树的数据
  const [tree, setTree] = useState(null)
  const clientHeight = useSelector(getClientHeight)
  //传递引用方法
  useImperativeHandle(ref, () => ({
    getOrganizationTree,
  }))

  const myMenu = [
    {
      title: t("b_addChild"),
      onClick: (item) => {
        onAdd && onAdd(item)
      },
    },
    {
      title: t("b_edit"),
      color: "blue",
      onClick: (item) => {
        onEdit && onEdit(item)
      },
    },
    {
      title: t("b_del"),
      color: "red",
      onClick: (item) => {
        onDel && onDel(item)
      },
    },
  ]
  const expandAll = () => {
    if (treeRef && treeRef.current) {
      treeRef.current.expandAll()
    } else {
      const intev = setInterval(() => {
        if (treeRef && treeRef.current) {
          clearInterval(intev)
          treeRef.current.expandAll()
        }
      }, 100)
    }
  }
  //调用接口获取权限树
  const getOrganizationTree = async (roleName) => {
    try {
      const response = await axios.post(getOrganization(), {})
      if (response.status === 200) {
        if (response.data) {
          setTree(response.data)
          // expandAll()
        }
      } else {
        console.log("error to get tree grant")
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    getOrganizationTree()
  }, [])
  //返回页面
  return (
    <div style={{ height: clientHeight - 180 + "px" }}>
      <CommonTree ref={treeRef} items={tree} menu={myMenu} onActive={onActive} />
    </div>
  )
})
