import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { LoadingIndicator } from "@abb/abb-common-ux-react";

import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { ErrorBoundary } from "common/ErrorBoundary";
import { SearchMaterial } from "./SearchMaterial";
import {
  fetchMaterialAsync,
  getMaterial,
  fetchMaterialMetaDataAsync,
  getMaterialCatalogMetaDatas,
  getMaterialDatas,
  getMaterialCatalog,
  fetchMaterialDataAsync,
  fetchMaterialCatalogAsync,
  getIsLoadingMaterial,
  getIsLoadingMaterialCatalog,
  getIsLoadingMaterialCatalogMetaDatas,
  getIsLoadingMaterialDatas,
} from "slices/materialSlice";
import MaterialInfo from "./MaterialInfo";
import MaterialData from "./MaterialData";
import MaterialDoc from "./MaterialDoc";
import { MaterialFormEdit } from "../Materials/MaterialFormEdit";
import { DataFormEdit } from "./DataFormEdit";
import { MaterialDeviceType } from "./MaterialDeviceType";
import { MaterialDependency } from "./MaterialDependency";
import { DeviceTypeFormEdit } from "./DeviceTypeFormEdit";
import "./dashboard.scss";

export default function Material() {
  const urlParams = useParams();
  const [MaterialFormData, setMaterialFormData] = useState(null);
  const [DataFormData, setDataFormData] = useState(null);
  const formMaterialRef = useRef(null);
  const formDeviceTypeRef = useRef(null);
  const formDataRef = useRef(null);

  const dispatch = useDispatch();
  const material = useSelector(getMaterial);
  const metaDatas = useSelector(getMaterialCatalogMetaDatas);
  const datas = useSelector(getMaterialDatas);
  const catalog = useSelector(getMaterialCatalog);
  const isLoadingMaterial = useSelector(getIsLoadingMaterial);
  const isLoadingMaterialCatalog = useSelector(getIsLoadingMaterialCatalog);
  const isLoadingMaterialCatalogMetaDatas = useSelector(getIsLoadingMaterialCatalogMetaDatas);
  const isLoadingMaterialDatas = useSelector(getIsLoadingMaterialDatas);
  const searchMaterialRef = useRef(null);
  const materialDependency = useRef(null);

  useEffect(() => {
    if (urlParams.id) {
      dispatch(fetchMaterialAsync(urlParams.id));
    }
  }, [urlParams.id]);

  useEffect(() => {
    if (material?.catalogId) {
      dispatch(fetchMaterialMetaDataAsync(material.catalogId));
      dispatch(fetchMaterialCatalogAsync(material.catalogId));
    }
    if (material?.id) {
      dispatch(fetchMaterialDataAsync(material.id));
    }
  }, [material?.catalogId]);
  const setShowMaterialSidePanel = (visible) => {
    setMaterialFormData({ ...material });
    formMaterialRef.current.setShowSidePanel(visible);
  };const setDeviceTypeSidePanel = (visible) => {
    formDeviceTypeRef.current.setShowSidePanel(visible);
  };
  const setShowDataSidePanel = (visible) => {
    setDataFormData(null);
    let data = {};
    metaDatas.forEach((m) => (data[m.id] = datas?.find((d) => d.metaDataId == m.id)?.value ?? ""));
    setDataFormData(data);
    formDataRef.current.setShowSidePanel(visible);
  };
  const onSubmit = (d) => {
    dispatch(fetchMaterialAsync(urlParams.id));
  };
  if (isLoadingMaterial || isLoadingMaterialCatalog || isLoadingMaterialCatalogMetaDatas || isLoadingMaterialDatas) {
    return (
      <div className="central-position">
        <LoadingIndicator type="radial" sizeClass="medium" determinate={false} color="blue" />
      </div>
    );
  } else if (material && catalog && metaDatas && datas) {
    return (
      <ErrorBoundary>
        <div className="grid root-grid" data-cy="module-device-dashboard">
          <div className="row abb-col-lg-16 abb-col-md-24 abb-col-ms-24">
            <div className="grid" style={{ margin: 0 }}>
              <div className="row  abb-col-lg-12 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms">
                  <MaterialInfo onEdit={setShowMaterialSidePanel} />
                </div>
              </div>
              <div className="row  abb-col-lg-12 abb-col-md-24 abb-col-ms-24">
                <div className="row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24">
                  <div className="tile height-6x abb-col-lg12-24 abb-col-md-24 abb-col-ms-24 over-hidden-ms">
                    <MaterialDependency
                      ref={materialDependency}
                      onEdit={() => {
                        searchMaterialRef.current.showDialog(material.id);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row  abb-col-lg-24 abb-col-md-24 abb-col-ms-24">
                <div className="tile height-6x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24">
                  <MaterialDoc />
                </div>
              </div>
            </div>
          </div>
          <div className="row  abb-col-lg-8 abb-col-md-24 abb-col-ms-24">
            <div className="grid" style={{ margin: 0 }}>
              <div className="tile height-8x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24">
                <MaterialData onEdit={setShowDataSidePanel} />
              </div>
              <div className="tile height-4x  abb-col-lg8-24 abb-col-md8-24 abb-col-ms8-24">
                <MaterialDeviceType onEdit={setDeviceTypeSidePanel} />
              </div>
            </div>
          </div>
        </div>
        <MaterialFormEdit ref={formMaterialRef} formData={MaterialFormData} id={material.id} onSubmit={onSubmit} />
        <DeviceTypeFormEdit ref={formDeviceTypeRef} id={material.id} onSubmit={onSubmit} />
        <DataFormEdit ref={formDataRef} formData={DataFormData} id={material.id} onSubmit={onSubmit} />
        <SearchMaterial ref={searchMaterialRef} onRefresh={() => setTimeout(() => materialDependency.current.refresh(), 500)} />
      </ErrorBoundary>
    );
  }
  return (
    <div className="grid root-grid">
      <p>您没有访问此页面的权限</p>
    </div>
  );
  //返回页面
}
