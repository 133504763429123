import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getDevice, getParent, getDeviceType, getStation } from 'slices/deviceSlice'
import { getFactories } from 'slices/factorySlice'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import moment from 'moment'
import { getCustomer } from 'slices/ezProjectSlice'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { getUsers } from 'slices/userSlice'
import { getOrganization } from 'slices/organizationSlice'
import { findTree } from 'common/utils'

export default function CustomerInfo({ onEdit }) {
  const device = useSelector(getCustomer)
  const parent = useSelector(getParent)
  const custCate = useDataDictionary('custCate')
  const srvArea = useDataDictionary('srvArea')
  const users = useSelector(getUsers)
  const organization = useSelector(getOrganization)

  const station = useSelector(getStation)
  const type = useSelector(getDeviceType)
  const [pageIndex, setPageIndex] = useState(0)
  const factories = useSelector(getFactories)
  console.log('parent', parent)
  let profiles = []
  const factory = factories?.find((f) => f.id == device?.factoryId)
  profiles = [
    [
      { title: t('customer.name'), value: device?.name },
      { title: t('customer.code'), value: device?.code },
      { title: t('customer.category'), value: custCate?.details.find((d) => d.code == device.category)?.displayText },
      { title: t('customer.manager'), value: users.find((d) => d.id == device.manager)?.name },
      { title: t('customer.serviceUnitId'), value: findTree(organization, (d) => d.key == device.serviceUnitId)?.title },
      { title: t('customer.contact'), value: device.contact },
      { title: t('customer.phone'), value: device?.phone },
      { title: t('customer.email'), value: device?.email },
      { title: t('customer.area'), value: srvArea?.details.find((d) => d.code == device.area)?.displayText },
      { title: t('customer.address'), value: device?.address },],
   
  ]

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [])
  const onWindowResize = () => {
    setPageIndex(0)
  }
  return (
    <div style={{ height: '100%' }}>
      <div className='widget'>
        <div className='widget-header'>
          <div className='widget-title'>{t('customer.formTitle')}</div>
          <div className='widget-tools device-profile-paginator show-md show-lg' style={{ paddingTop: 10 }}>
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/left'
              disabled={pageIndex <= 0}
              onClick={() => setPageIndex(pageIndex - 1)}
              style={{ opacity: pageIndex <= 0 ? 0.2 : 1, marginRight: 8 }}
            />
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/right'
              disabled={pageIndex >= profiles.length - 1}
              onClick={() => setPageIndex(pageIndex + 1)}
              style={{ opacity: pageIndex >= profiles.length - 1 ? 0.2 : 1 }}
            />
          </div>
          <div className='widget-tools device-profile-paginator show-xl'>
            <Button sizeClass='small' shape='pill' type='discreet-black' icon='abb/edit' onClick={onEdit} style={{ opacity: 1, marginRight: 8 }} />
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/left'
              disabled={pageIndex <= 0}
              onClick={() => setPageIndex(pageIndex - 1)}
              style={{ opacity: pageIndex <= 0 ? 0.2 : 1, marginRight: 8 }}
            />
            <Button
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/right'
              disabled={pageIndex >= Math.ceil(profiles.length / 2) - 1}
              onClick={() => setPageIndex(pageIndex + 1)}
              style={{ opacity: pageIndex >= Math.ceil(profiles.length / 2) - 1 ? 0.2 : 1 }}
            />
          </div>
        </div>
        <div className='widget-body device-profile-details'>
          <div className={`layout column-${profiles.length} offset-${pageIndex}`}>
            {profiles.map((list) =>
              list.length === 0 ? null : (
                <Table sizeClass='medium' borderType='discreet' key={nanoid()}>
                  <tbody>
                    {list.map((item) => (
                      <tr key={nanoid()}>
                        <td title={item.title}>{item.title}</td>
                        <td>
                          {item.value && (
                            <WithTooltip>
                              <span>{item.value}</span>
                              <Tooltip>
                                <div>
                                  {item.title}：{item.value}
                                </div>
                              </Tooltip>
                            </WithTooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
