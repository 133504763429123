import React, { useState, useEffect } from "react"

const prefixCls = "ivu-col"
const sizes = ["xs", "sm", "md", "lg", "xl", "xxl"]
export const Col = ({
  children,
  flex, // flex 布局属性
  span, // 栅格的占位格数，可选值为0~24的整数，为 0 时，相当于 display:none
  order, // 栅格的顺序，在 flex 布局模式下有效
  offset, // 栅格左侧的间隔格数，间隔内不可以有栅格
  push, // 栅格向右移动格数
  pull, // 栅格向左移动格数
  className, // 自定义的class名称
  xs, //<576px 响应式栅格，可为栅格数或一个包含其他属性的对象
  sm, // >=576px 响应式栅格，可为栅格数或一个包含其他属性的对象
  md, // >=768px 响应式栅格，可为栅格数或一个包含其他属性的对象
  lg, // >=992px 响应式栅格，可为栅格数或一个包含其他属性的对象
  xl, // >=1200px 响应式栅格，可为栅格数或一个包含其他属性的对象
  xxl, // >=1600px 响应式栅格，可为栅格数或一个包含其他属性的对象
  gutter, // 由Row注入
}) => {
  const [classes, setClasses] = useState({})
  const [styles, setStyles] = useState({})
  const sizeProps = { xs, sm, md, lg, xl, xxl }

  const parseFlex = (flex) => {
    if (typeof flex === "number") {
      return `${flex} ${flex} auto`
    }
    if (/^\d+(\.\d+)?(px|em|rem|%)$/.test(flex)) {
      return `0 0 ${flex}`
    }
    return flex
  }
  useEffect(() => {
    let classList = [
      `${prefixCls}`,
      span ? `${prefixCls}-span-${span}` : "",
      order ? `${prefixCls}-order-${order}` : "",
      offset ? `${prefixCls}-offset-${offset}` : "",
      push ? `${prefixCls}-push-${push}` : "",
      pull ? `${prefixCls}-pull-${pull}` : "",
      !!className ? `${className}` : "",
    ]
    sizes.forEach((size) => {
      if (typeof sizeProps[size] === "number") {
        classList.push(`${prefixCls}-span-${size}-${sizeProps[size]}`)
      } else if (typeof sizeProps[size] === "object") {
        let props = sizeProps[size]
        Object.keys(props).forEach((prop) => {
          classList.push(
            prop !== "span"
              ? `${prefixCls}-${size}-${prop}-${props[prop]}`
              : `${prefixCls}-span-${size}-${props[prop]}`
          )
        })
      }
    })
    let newclasses = ""
    classList.forEach((c) => {
      if (c) newclasses += c + " "
    })
    setClasses(newclasses)
  }, [span, order, offset, push, pull, className])

  useEffect(() => {
    let newstyle = {}
    if (gutter !== 0) {
      newstyle = {
        paddingLeft: gutter / 2 + "px",
        paddingRight: gutter / 2 + "px",
      }
    }
    if (flex) {
      newstyle.flex = parseFlex(flex)
    }
    setStyles(newstyle)
  }, [gutter])

  return (
    <div className={classes} style={styles}>
      {children}
    </div>
  )
}
