import React, { useState, useEffect, useRef } from "react"
import { WithTooltip, Tooltip, Icon } from "@abb/abb-common-ux-react"
import { getUserProfile } from "slices/userProfileSlice"
import { useSelector } from "react-redux"
import axios from "axios"
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import moment from "moment"

import {
  showNotification,
  NotificationType,
} from "components/Notification/notificationSlice"
import { useDispatch } from "react-redux"
// import { ResponseResult } from "common/request"
import { t } from "i18next"
import { deleteExpert, getExpertPage } from "common/apis/remoteSupport"

export default function Experts() {
  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isOperating, setIsOperating] = useState(false)
  const dispatch = useDispatch()

  const refIndex = useRef(null)
  // const onRoleUpdated = (id, role) => {
  //   setUpdateUserRole(null)
  //   //两种方式.
  //   //1.直接刷新，一般采用此方法
  //   // refIndex.current.query()
  //   //2.修改列表数据
  //   refIndex.current.changeData({
  //     id,
  //     userRole:
  //       role === "Administrator" ? "Role.Administrator" : "Role.Customer",
  //     visibilityCode: role === "Administrator" ? "Global" : "Customer",
  //   })
  // }

  //列定义
  const columns = [
    // {
    //   width: 32,
    //   align: "center",
    //   style: { paddingLeft: "0", paddingRight: "0" },
    //   render: (item, index) => {
    //     if (item.visibilityCode === "Global")
    //       return (
    //         <WithTooltip>
    //           <Icon
    //             name="abb/user-in-circle"
    //             className="text-primary"
    //             style={{ marginTop: "5px" }}
    //           />
    //           <Tooltip>
    //             <div>超级管理员</div>
    //           </Tooltip>
    //         </WithTooltip>
    //       )
    //     return ""
    //   },
    // },
    {
      title: "姓名",
      key: "name",
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>专家已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
    {
      title: "头衔",
      key: "title",
    },
    {
      title: "是否推荐专家",
      key: "isRecommend",
      render:(item)=>{
        return item.isRecommend?"是":"否"
    },
    },
  ]
  
  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getExpertPage}
        deleteData={deleteExpert}
        FormEdit={FormEdit}
        // searchChildren,//查询的其他参数
        // searchKeys,//查询的其他key
        // searchData,//查询数据
        // toolChildren,//工具栏其他按钮
        canAdd
        canEdit
        canDelete
        // canBatchDel,//是否可以批量删除
        // canOutput,//是否可以输出
        onDeleteData={onDeleteData}
        enableSorting={true}
      />
      {/* 参考，勿删！
       <CommonConfirm
        title="变更权限"
        content="确认删除用户？"
        isVisible={updateUserRole != null}
        onConfirm={onRoleUpdateConfirmed}
        onCancel={() => setUpdateUserRole(null)}
        isLoading={isOperating}
      >
        {updateUserRole && (
          <div>
            <div>
              <span>
                确定将 {updateUserRole.name} ({updateUserRole.userName}
                )的权限设置为
              </span>
              <span>
                {updateUserRole.visibilityCode === "Global"
                  ? "普通用户？"
                  : "超级管理员？"}
              </span>
            </div>
            {updateUserRole.visibilityCode === "Global" && (
              <div>此操作将使该用户将失去后台管理权限！</div>
            )}
            {updateUserRole.visibilityCode !== "Global" && (
              <div>此操作将使该用户将获得最大权限！</div>
            )}
          </div>
        )}
      </CommonConfirm> */}
    </>
  )
}
