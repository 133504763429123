import React, { useState, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit, CommonRule } from "components/LoongInCommon"
import { getConnectionString, updateConnectionString } from "common/endpoints"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { useSelector } from "react-redux"
import axios from "axios"

export const FormEditConStr = forwardRef(({ id, onSubmit, onCancel, event }, ref) => {
  const [formData, setFormData] = useState({})
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //初始化数据
  const initData = {
    connectionString: "",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("tenant.connectionString"),
      fieldName: "connectionString",
      maxLength: 1024,
    },
  ]

  //表单的校验规则
  const rules = {
    connectionString: [CommonRule.Require],
  }

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      connectionString: inputdata.connectionString + "",
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      id: inputdata.id,
      connectionString: inputdata.connectionString + "",
    }
  }
  //获取连接字符串
  const getConnectStr = async (id) => {
    try {
      const response = await axios.post(getConnectionString(), { id })
      if (response.status === 200 || response.status === 204) {
        setFormData({ id, connectionString: response.data || "" })
      } else {
        console.log("error to get tree grant")
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    getConnectStr,
  }))
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("tenant.connectionString.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={updateConnectionString}
      updateData={updateConnectionString}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
