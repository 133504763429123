import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getMaterialCatalogMetaDatas, getMaterialDatas } from "slices/materialSlice";
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react";
import { nanoid } from "nanoid";
import { t } from "i18next";

export default function MaterialData({onEdit}) {
  const metaDatas = useSelector(getMaterialCatalogMetaDatas);
  const datas = useSelector(getMaterialDatas);
  const [pageIndex, setPageIndex] = useState(0);

  let profiles = [];
  console.log(datas);
  profiles = [
    metaDatas.map((m) => {
      return { title: m.name, value: datas?.find((d) => d.metaDataId == m.id)?.value ?? "" };
    }),
  ];

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);
  const onWindowResize = () => {
    setPageIndex(0);
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">{t('materialData.formTitle')}</div>
          <div className="widget-tools device-profile-paginator show-md show-lg"  style={{paddingTop:10}}>
            <Button sizeClass="small" shape="pill" type="discreet-black" icon="abb/edit" onClick={onEdit} style={{ opacity: 1, marginRight: 8 }} />
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/left"
              disabled={pageIndex <= 0}
              onClick={() => setPageIndex(pageIndex - 1)}
              style={{ opacity: pageIndex <= 0 ? 0.2 : 1, marginRight: 8 }}
            />
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/right"
              disabled={pageIndex >= profiles.length - 1}
              onClick={() => setPageIndex(pageIndex + 1)}
              style={{ opacity: pageIndex >= profiles.length - 1 ? 0.2 : 1 }}
            />
          </div>
          <div className="widget-tools device-profile-paginator show-xl">
            <Button sizeClass="small" shape="pill" type="discreet-black" icon="abb/edit" onClick={onEdit} style={{ opacity: 1, marginRight: 8 }} />
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/left"
              disabled={pageIndex <= 0}
              onClick={() => setPageIndex(pageIndex - 1)}
              style={{ opacity: pageIndex <= 0 ? 0.2 : 1, marginRight: 8 }}
            />
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/right"
              disabled={pageIndex >= Math.ceil(profiles.length / 2) - 1}
              onClick={() => setPageIndex(pageIndex + 1)}
              style={{ opacity: pageIndex >= Math.ceil(profiles.length / 2) - 1 ? 0.2 : 1 }}
            />
          </div>
        </div>
        <div className="widget-body device-profile-details">
          <div className={`layout column-${profiles.length} offset-${pageIndex}`}>
            {profiles.map((list) =>
              list.length === 0 ? null : (
                <Table sizeClass="medium" borderType="discreet" key={nanoid()}>
                  <tbody>
                    {list.map((item) => (
                      <tr key={nanoid()}>
                        <td  style={{width:'50%'}} title={item.title}>{item.title}</td>
                        <td>
                          {item.value && (
                            <WithTooltip>
                              <span>{item.value}</span>
                              <Tooltip>
                                <div>
                                  {item.title}：{item.value}
                                </div>
                              </Tooltip>
                            </WithTooltip>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
