import React, { useEffect, useState, useRef } from 'react'
import NavDropdown from './NavDropdown'
import { useDispatch, useSelector } from "react-redux";
import { Icon, Link, LoadingIndicator, WithTooltip, Tooltip, Spinner, Button } from '@abb/abb-common-ux-react'
import { getEnv } from 'slices/envSlice';
 
import { getEventDetailsById } from 'common/endpoints'
import axios from 'axios'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice';
import moment from 'moment'
import { useMountedState } from 'common/useMountedState'
import { ResponseResult } from 'common/request';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next'

export default function NotificationList() {
  const isMounted = useMountedState()
  const history = useHistory();

  const oauth = useSelector(state => state.oauth);
  const token = oauth?.accessToken;
  const env = useSelector(getEnv)
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
 
  const [notifications, setNotifications] = useState([])
  const [historyCount, setHistoryCount] = useState(0)
  const [catchError, setCatchError] = useState(false)
  const [now, setNow] = useState(moment())
  const interval = useRef(null)

  const ws = useRef(null)
  const pageIndex = useRef(-1)
  const maxPageIndex = useRef(0)
  const pageSize = 10
  const source = useRef(null);
  const { t } = useTranslation()

  const onGetNextPage = () => {
    if (!ws.current || pageIndex.current >= maxPageIndex) {
      return
    }
    setIsLoading(true)
    pageIndex.current = pageIndex.current + 1
    ws.current.send(JSON.stringify({
      CommandName: "QUERY_MESSAGE",
      Arguments: { pageIndex: pageIndex.current, pageSize: pageSize },
      ExtensionData: "QUERY_MESSAGE"
    }))
  }

  const connectToWebsocket = () => {
    pageIndex.current = -1
    maxPageIndex.current = 0
    setCatchError(false)
    setIsLoading(true)
    const protocol = env.apiBase.indexOf('https:') === 0 ? 'wss:' : 'ws:'
    const hostname = env.apiBase.split('//')[1]
    ws.current = new WebSocket(`${protocol}//${hostname}/ws/${token}`);
    ws.current.onopen = e => {
      console.log("Connection open ...");
      onGetNextPage()
    };

    ws.current.onmessage = e => {
      const response = JSON.parse(e.data)
      if (response.ExtensionData === 'QUERY_MESSAGE') {
        setInitialized(true)
        setIsLoading(false)
        if (response.Result === 1) {
          setIsLoading(false)
          maxPageIndex.current = response.PageInfo.TotalPage - 1
          setNotifications(n => [...n, ...response.Data])
          setHistoryCount(response.PageInfo.TotalCount)
        }
      } else if (response.ExtensionData === 'DELETE_MESSAGE') {
        console.log('DELETE_MESSAGE')
      } else if (response.ExtensionData === 'CLEAR_PRIVATE_MESSAGE') {
        console.log('CLEAR_PRIVATE_MESSAGE')
      }
      else {
        dispatch(showNotification(NotificationType.Info, t('newMessage')))
        if (response.Data) {
          setNotifications(n => [...response.Data.map(d => ({ ...d, isNew: true })), ...n])
        }
      }

    };

    ws.current.onclose = e => {
      console.log("Connection closed.");
      setCatchError(true)
    }

    ws.current.onerror = e => {
      setIsLoading(false)
      console.log("Connection err.", e);
      setCatchError(true)
    }
  }

  useEffect(() => {
    connectToWebsocket()
    return () => {
      if (ws.current) {
        ws.current.close()
      }
    }
  }, [])

  useEffect(() => {
    interval.current = setInterval(() => {
      setNow(moment())
    }, 10 * 1000)
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [])
  useEffect(() => {
    interval.current = setInterval(() => {
      setNow(moment())
    }, 10 * 1000)
    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [])

  const clearAll = () => {
    if (ws.current) {
      ws.current.send(JSON.stringify({
        CommandName: "CLEAR_PRIVATE_MESSAGE",
        ExtensionData: "CLEAR_PRIVATE_MESSAGE"
      }))
    }
    pageIndex.current = 0
    maxPageIndex.current = 0
    setNotifications([])
    setHistoryCount(0)
  }
  const onClickNotification = (e) => {
    console.log(e)
    
  }

  const count = catchError ? 0 : notifications.filter(n => n.isNew).length + historyCount;
  return (
    <>
      <NavDropdown navButton={
        <div className="notification-button">
          <Icon name="abb/alarm-bell" />
          {(!catchError && count > 0) &&
            <label className="notification-count-label">{count > 99 ? `99+` : count}</label>}
        </div>
      } disabled={!initialized} >
        {catchError &&
          <div>
            <p className="text-center"><Button text={t('refresh')} type="discreet-black" sizeClass="small" onClick={connectToWebsocket} /></p>
          </div>}
        {!catchError &&

          <div style={{ fontSize: 14 }}>
            {isLoading && pageIndex.current === 0 && count === 0 &&
              <div className="nav-dropdown-body" style={{ minHeight: 240 }}>
                <div className="central-position">
                  <LoadingIndicator type="radial" sizeClass="small" color="blue" />
                </div>
              </div>}
            {!isLoading && pageIndex.current === 0 && count === 0 &&
              <div className="nav-dropdown-body" style={{ minHeight: 240 }}>
                <div className="text-gray central-position"><small>{t('noNewMessage')}</small></div>
              </div>}
            {pageIndex.current >= 0 && count > 0 &&
              <>
                <div className="nav-dropdown-body no-padding" style={{ minHeight: 240, overflow: 'auto' }}>
                  <ul className="notification-list">
                    {notifications.map(n => {
                      const eventTime = moment(n.CreationTime)
                      const min = now.diff(eventTime, 'minutes')
                      const hr = now.diff(moment(n.CreationTime), 'hours')
                      let dateDesc = eventTime.format('MM-DD HH:mm')
                      if (min < 1) {
                        dateDesc = t('justNow') //刚刚
                      } else if (min < 60) {
                        dateDesc = `${min+t('minuteBefore')}` //分钟前
                      } else if (hr < 2) {
                        dateDesc = `${hr+t('hourBefore')}` //小时前
                      }
                      return (
                        <li key={n.Id}>
                          <div className="notification-item" onClick={() => onClickNotification(n)}>
                            <div className="notification-header">
                              <div className="notification-title">{n.Subject}</div>
                              <div className="notification-date">
                                <WithTooltip>
                                  <span>{dateDesc}</span>
                                  <Tooltip>{eventTime.format('YYYY-MM-DD HH:mm')}</Tooltip>
                                </WithTooltip>
                              </div>
                            </div>
                            <div className="notification-content">{n.Content}</div>
                          </div>
                        </li>
                      )
                    })}

                    {isLoading && <li><div className="btn-more disabled"><Spinner /></div></li>}
                    {pageIndex.current < maxPageIndex.current && !isLoading &&
                      <li onClick={e => { onGetNextPage(); e.stopPropagation() }}>
                    <div className="btn-more">{t('more')}</div></li>}
                  </ul>
                </div>
                <div className="nav-dropdown-footer no-padding">
                  <div className='text-right'>
                    <Link underlined={false} discreet onClick={clearAll}>{t('allHasRead')}</Link></div>
                </div>
              </>}
          </div>}
      </NavDropdown>
      
    </>
  )
}
