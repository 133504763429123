import React, { useState, useEffect, useRef, useImperativeHandle } from "react"
import { getAssociatedDeviceTypes, removeAssociatedDeviceType, setRecommandMaterial } from "common/apis/maindata"
import axios from "axios"
import { t } from "i18next"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"
import { useDispatch } from "react-redux"

import { CommonIndex } from "components/LoongInCommon"
import { CommonTable } from "components/LoongInCommon"
import { useSelector } from "react-redux"
import { getMaterial } from "slices/materialSlice"
import { Table, Tooltip, WithTooltip, Button, ToggleSwitch } from "@abb/abb-common-ux-react"
import { forwardRef } from "react"
import "./organiza.scss"
import { id } from "date-fns/locale"
export const MaterialDeviceType = forwardRef(({ onEdit }, ref) => {
  const [searchData, setSearchData] = useState(null)
  const refIndex = useRef(null)
  const material = useSelector(getMaterial)
  const dispatch = useDispatch()
  useEffect(() => {
    if (material) {
      setSearchData({ slaverId: material.id })
    }
  }, [material])

  useEffect(() => {
    window.addEventListener("resize", onWindowResize)
    return () => {
      window.removeEventListener("resize", onWindowResize)
    }
  }, [])
  const onWindowResize = () => {}
  const getDeleteInput = (item) => {
    return { masterId: item.id, slaverId: material.id }
  }
  //列定义
  const columns = [
    {
      title: "设备类型",
      key: "value",
    },
    {
      title: "推荐物料",
      key: "recommand",
      render: (item, index) => {
        return (
          <ToggleSwitch
            label={item.recommand ? "是" : "否"}
            showIcons={true}
            // disabled={false}
            value={item.recommand}
            onChange={(newState) => {
              changeStatus(item, newState)
            }}
          />
        )
      },
    },
  ]

  const refresh = () => {
    refIndex.current.query()
  }
  const actionRender = (item, index) => {
    return (
      <Button
        sizeClass="small"
        icon="abb/minus"
        shape="pill"
        onClick={() => {
          refIndex.current.setDeleteItem(item)
        }}
      />
    )
  }
  const changeStatus = (item, newStatus) => {
    axios
      .post(setRecommandMaterial(), {
        deviceTypeId: item.id,
        materialId: material.id,
        recommand: newStatus,
      })
      .then((response) => {
        console.log(response)
        if (response.status == 200 || response.status == 204) {
          dispatch(showNotification(NotificationType.Success, t("t_successToUpdate")))
          refIndex.current.query()
        } else {
          dispatch(showNotification(NotificationType.Success, t("t_failToUpdate")))
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(showNotification(NotificationType.Alarm, t("t_failToUpdate")))
      })
      .finally(() => {})
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    refresh,
  }))
  return (
    <div style={{ height: "100%" }}>
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">适用设备类型</div>
          <div className="widget-tools device-profile-paginator show-md show-lg" style={{ paddingTop: 10 }}>
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/plus"
              onClick={() => onEdit(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>
          <div className="widget-tools device-profile-paginator show-xl">
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/plus"
              onClick={() => onEdit(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>{" "}
        </div>
        <div className="widget-body device-profile-details">
          <CommonIndex
            className={"admin-main-content3 with-padding"}
            ref={refIndex}
            columns={columns}
            getList={getAssociatedDeviceTypes}
            searchData={searchData}
            deleteData={removeAssociatedDeviceType}
            getDeleteInput={getDeleteInput}
            canDelete
            pageSize={3}
            canSearch={false}
            pageInQuery={false}
            refreshAfterDelete={true}
            tableWidth={"100%"}
            tableHeight={"100%"}
            enableSorting={false}
            refreshWhenOpen={false}
            canSelection={false} //是否开启选择功能
            canBatchDel={false}
            actionRender={actionRender}
            searchClassName="searching-form-simple" //查询样式
            toolClassName="searching-form-simple searching-form-right-tool text-right" //工具栏样式
          />
        </div>
      </div>
    </div>
  )
})
