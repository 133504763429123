import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { useSelector } from "react-redux"
import { createApplication, updateApplication } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event, extraData }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [rules, setRules] = useState({})
  const [groups, setGroups] = useState([])
  useEffect(() => {
    if (extraData && extraData.appGroupList) {
      const g = extraData.appGroupList.map(data => {
        return {
          label: data.name,
          value: data.id
        }
      })
      setGroups(g)
    }
  }, [extraData])

  //初始化数据
  const initData = {
    name: "",
    type: 1,
    code: "",
    icon: "",
    externalUrl: "",
    isDefault: false,
    order:0,
    description: "",
    groupId: ""
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("application.name"),
      fieldName: "name",
      maxLength: 256,
    },
    {
      type: "dropdown",
      dataType: "number",
      label: t("application.type"),
      fieldName: "type",
      maxLength: 3,
      options: [
        { label: t("内部应用"), value: 1 },
        { label: t("外部应用"), value: 2 },
        { label: t("Web应用"), value: 3 },
      ],
    },
    {
      type: "text",
      dataType: "text",
      label: t("application.code"),
      fieldName: "code",
      maxLength: 30,
    },
    {
      type: "text",
      dataType: "text",
      label: t("application.icon"),
      fieldName: "icon",
      maxLength: 256,
    },
    {
      type: "dropdown",
      dataType: "text",
      label: t("application.group"),
      fieldName: "groupId",
      options: groups
    },
    {
      type: "text",
      dataType: "text",
      label: t("application.externalUrl"),
      fieldName: "externalUrl",
      maxLength: 256,
    },{
      type: "text",
      dataType: "text",
      label: t("application.order"),
      fieldName: "order",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "textarea",
      label: t("application.description"),
      fieldName: "description",
      maxLength: 256,
    },
    {
      type: "checkbox",
      dataType: "bool",
      label: t("application.isDefault"),
      fieldName: "isDefault"
    }
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      code: [CommonRule.Require],
      type: [CommonRule.Require],
      groupId: [CommonRule.Require],
      order: [CommonRule.Require,CommonRule.Int],
      externalUrl: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      type: inputdata.type,
      icon: inputdata.icon,
      code: inputdata.code,
      externalUrl: inputdata.externalUrl,
      order: inputdata.order,
      description: inputdata.description,
      isDefault: inputdata.isDefault,
      groupId: inputdata.groupId
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      type: inputdata.type,
      code: inputdata.code,
      icon: inputdata.icon,
      externalUrl: inputdata.externalUrl,
      order: inputdata.order,
      description: inputdata.description,
      isDefault: inputdata.isDefault,
      groupId: inputdata.groupId
    }
  }
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("application.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createApplication}
      updateData={updateApplication}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
