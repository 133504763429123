import React, { useState, useRef } from "react"
import { getTenantPage, deleteTenant } from "common/endpoints"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import { FormEditConStr } from "./FormEditConStr"
import { t } from "i18next"

export default () => {
  const refIndex = useRef(null)
  const refConStr = useRef(null)
  const [formEditConStrEvent, setFormEditConStrEvent] = useState(null)
  //列定义
  const columns = [
    {
      title: "tenant.name",
      key: "name",
    },
  ]
  //其他行菜单
  const otherMenu = (item, index) => {
    return [
      {
        onClick: () => connectString(item),
        title: t("t_connectString"),
        color: "blue",
      },
    ]
  }
  //打开权限树
  const connectString = (item) => {
    // refIndex.current.showAndSwitchTab(1,item)
    //连接字符串
    refConStr.current.getConnectStr(item.id)
    setFormEditConStrEvent({
      name: "setShowSidePanel",
      params: [true],
    })
    // refConStr.current.setShowSidePanel(true)
  }

  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getTenantPage}
        deleteData={deleteTenant}
        FormEdit={FormEdit}
        otherMenu={otherMenu}
        canAdd
        canEdit
        canDelete
        enableSorting={false}
      />
      <FormEditConStr ref={refConStr} event={formEditConStrEvent} />
    </>
  )
}
