import React, { lazy, Suspense, useState, useEffect } from 'react'
// import React, { lazy, useRef, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { getUserProfile, getIsLoadingUserProfile } from 'slices/userProfileSlice'
import { AppContainer, AppContent, LoadingIndicator } from '@abb/abb-common-ux-react'
import { Header } from 'components/Header'
// import { Locale } from "components/Locale";
import Auth from 'common/Auth'
// import AuthByAzureAD from "common/AuthByAzureAD"
import './i18n'

import Notification from 'components/Notification'

import { getEnv, setEnv } from 'slices/envSlice'
import { setClientHeight, setClientWidth } from 'slices/appSlice'
import { getTheme } from 'slices/themeSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Theme } from 'common/enums'
import axios from 'axios'
import './mock/index'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import TermsConfirmation from 'components/TermsConfirmation'
import moment from 'moment'
import '@abb/abb-common-ux-react/styles.css'
import 'styles/react-datepicker.scss'
import 'styles/base.scss'
import 'styles/base-dark-theme.scss'
import { ErrorBoundary } from 'common/ErrorBoundary'
import AdminComponent from './modules/Admin'
import MainDataComponent from './modules/MainData'
// import ReportComponent from "./modules/Report"
import EzReportComponent from './modules/EzReport'
import InspectionComponent from './modules/Inspection'
import EvaluationComponent from './modules/Evaluation'

import RemoteSupport from 'modules/RemoteSupport'
import ReportView from 'modules/EzReport/Reports/ReportView'
import { Axios } from 'components/Axios'
import ResetPassword from 'modules/Auth/ResetPassword'
import { createContext } from 'react'
import { UserContext } from 'contexts/UserContext'

const BROWSER_API_KEY = 'k6mBs3JggSu0q48OS7yz'

export default function App() {
  const [isLoadingEnv, setIsLoadingEnv] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [visitorId, setVisitorId] = useState(null)
  const dispatch = useDispatch()
  const theme = useSelector(getTheme)
  const _env = useSelector(getEnv)
  // const AdminComponent = lazy(() => import("./modules/Admin"))
  // const MainDataComponent = lazy(() => import("./modules/MainData"))
  // const ReportComponent = lazy(() => import("./modules/Report"))
  // const EzReportComponent = lazy(() => import("./modules/EzReport"))

  const userProfile = useSelector(getUserProfile)
  const isLoadingUser = useSelector(getIsLoadingUserProfile)
  let mounted = false
  useEffect(() => {
    if (_env.version) {
      console.log(`SEA Version: ${_env.version}`)
    }
    mounted = true
    return () => (mounted = false)
  }, [_env.version])

  useEffect(() => {
    FingerprintJS.load({
      token: BROWSER_API_KEY,
    })
      .then((fp) => fp.get())
      .then((result) => {
        setVisitorId(result.visitorId)
        setIsLoading(false)
      })
  }, [])
  useEffect(() => {
    setIsLoadingEnv(true)
    axios
      .get('/env')
      .then((response) => {
        dispatch(setEnv(response.data))
      })
      .catch((e) => {
        console.log('Unable to fetch env setting,switch to default env')
        dispatch(setEnv({}))
      })
      .then(() => setIsLoadingEnv(false))
  }, [])

  useEffect(() => {
    moment.updateLocale('en', {
      relativeTime: {
        future: '%s后',
        past: '%s前',
        s: '几秒钟',
        ss: '%d 秒',
        m: '1分钟',
        mm: '%d 分钟',
        h: '1小时',
        hh: '%d 小时',
        d: '1天',
        dd: '%d 天',
        w: '1周',
        ww: '%d 周',
        M: '一个月',
        MM: '%d 个月',
        y: '1年',
        yy: '%d 年',
      },
    })
  }, [])

  const LoadingModule = mounted ? (
    <div className='central-position'>
      <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
    </div>
  ) : null

  useEffect(() => {
    window.document.body.style.backgroundColor = theme === Theme.Dark ? '#0f0f0f' : '#ebebeb'
  }, [theme])
  // const AuthWrapper = _env.authType === "AzureAD" ? AuthByAzureAD : AuthByIdentityServer4
  useEffect(() => {
    dispatch(setClientHeight(document.documentElement.clientHeight))
    dispatch(setClientWidth(document.documentElement.clientWidth))
    window.onresize = () => {
      return (() => {
        dispatch(setClientHeight(document.documentElement.clientHeight))
        dispatch(setClientWidth(document.documentElement.clientWidth))
      })()
    }
  }, [])
  if (isLoadingEnv || isLoading) {
    return LoadingModule
  } else {
    return (
      <UserContext.Provider value={{ user: visitorId }}>
        <Axios>
          <Router>
            <Switch>
              <Route path='/Account/ResetPassword' component={ResetPassword} />
              <Route exact>
                <Auth>
                  <AppContainer theme={theme}>
                    <Header />
                    <ErrorBoundary>
                      <AppContent>
                        {isLoadingUser && LoadingModule}
                        {userProfile && (
                          <Switch>
                            <Suspense fallback={LoadingModule}>
                              <Route path='/' exact component={AdminComponent} />
                              <Route path='/maindata' component={MainDataComponent} />
                              <Route path='/admin' component={AdminComponent} />
                              <Route path='/ezreport' component={EzReportComponent} />
                              <Route path='/inspection' component={InspectionComponent} />
                              <Route path='/evaluation' component={EvaluationComponent} />
                              <Route path='/remoteSupport' component={RemoteSupport} />
                            </Suspense>
                          </Switch>
                        )}
                        <div id='popup-portal' />
                        <div id='popup-portal-top' />
                      </AppContent>
                    </ErrorBoundary>
                  </AppContainer>
                </Auth>
              </Route>
            </Switch>
            <Notification />
          </Router>
        </Axios>
      </UserContext.Provider>
    )
  }
}
