import { Dialog } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import '../../../styles/singleReport.scss'
import './style.scss'
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
export const Preview = forwardRef(({}, ref) => {
  const [modal, setModal] = useState(null)
  const [isVisible, setIsVisible] = useState(false)

  const showDialog = (m) => {
    setModal(m)
    setIsVisible(true)
  }

  const renderPreviewTestItem = () => {
    if (!modal) {
      return null
    }
    console.log(modal)
    return (
      <table key={nanoid()} className='center '>
        {renderPreviewTestModal(JSON.parse(modal).content)}
      </table>
    )
  }

  const renderPreviewTestModal = (modal) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderPreviewTestModal(m.componments)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderPreviewTestModal(m.componments)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderPreviewTestModal(m.componments)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderPreviewTestModal(m.componments)}
            </td>
          )
        }
        if (m.type === 'input' || m.type === 'image') {
          return (
            <>
              {m.label} {m.name}
            </>
          )
        }
        if (m.type === 'option') {
          return (
            <>
              <input type='checkbox' disabled={true} />
              {m.name}
            </>
          )
        }
        if (m.type === 'editonly') {
          return ''
        }
        return <>{renderPreviewTestModal(m.componments)}</>
      })
    )
  }

  const renderInputPreviewTestItem = () => {
    if (!modal) {
      return null
    }
    console.log(modal)
    return (
      <table key={nanoid()} className='center '>
        {renderInputPreviewTestModal(JSON.parse(modal).content)}
      </table>
    )
  }
  const renderInputPreviewTestModal = (modal) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderInputPreviewTestModal(m.componments)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderInputPreviewTestModal(m.componments)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderInputPreviewTestModal(m.componments)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderInputPreviewTestModal(m.componments)}
            </td>
          )
        }
        if (m.type === 'input') {
          return <><span>{m.label} </span><input {...m.attrs} type='text' disabled={true} name={m.name} placeholder={m.name} /></>
        }
        if (m.type === 'buttons') {
          return (
            <>
              {m.options.map((o) => {
                return <input type='radio' name={m.name} disabled={true} value={o.key} style={{ '--title': `'${o.title}'` }} />
              })}
              <br />
              {m.name}
            </>
          )
        }
        if (m.type === 'noedit') {
          return ''
        }
        return <>{renderInputPreviewTestModal(m.componments)}</>
      })
    )
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    showDialog,
  }))
  return (
    <Dialog
      style={{ width: '1160px', height: '700px' }}
      showCloseButton={true}
      closeOnLostFocus
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title={'试验模型预览'}
      onClose={() => setIsVisible(false)}
    >
      <div className='single-report'>
        <ul className='content' data-order='0'>
          <li className='canBeDeleted'>
            <ul data-order='1'>
              <li className='canBeDeleted'>
                <ul data-order='2'>
                  <li key={nanoid()} id='expCircuitvoltage' className='li_exp'>
                    <h6>显示预览</h6>
                    {renderPreviewTestItem()}
                  </li>
                  <li key={nanoid()} id='expCircuitvoltage' className='li_exp'>
                    <h6>输入预览</h6>
                    {renderInputPreviewTestItem()}
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Dialog>
  )
})
