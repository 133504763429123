export const ResponseResult = {
  Failed: 0,/// 失败
  Ok: 1,/// 成功
  NoRecord: 4,/// 查询不到数据
  Error: 8, /// 异常
  ValidateFailed: 16, /// 基本参数验证不通过(ModelValidate)注: 只用于接口入参设定的特性标记验证
  Unauthorized: 32, /// 未登录
  Forbidden: 64,/// 没有权限
  Wait: 128,  // 系统中准备数据 提示用户: "系统正在准备数据, 请稍后重试".
  TimeOut: 256,
  UserNotFound: 2048
}
