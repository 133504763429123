import React, { useState, useRef, useEffect } from 'react'
import { useMountedState } from 'common/useMountedState'
import "./singleline.scss"
import Basic from "./Basic"
import BaseSvg from './BaseSvg'
import { DisplayOptionsTemplates, DeviceTemplate, DeviceTypes, GroupTemplate } from "./Const"
import {
    Input, Button, ToggleButtonGroup,
    ToggleButton, WithMessageDialog, MessageDialog,
} from '@abb/abb-common-ux-react'
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useRouteMatch } from "react-router-dom";
import { showNotification, NotificationType } from "components/Notification/notificationSlice";
import { t } from "i18next"
import { updateStationSldConfigs } from "common/apis/maindata"


export default function SingleLine({ substation, configMode, height, onSeved }) {



    const dispatch = useDispatch();
    const isMounted = useMountedState()
    const [singlelineGroups, setSingelineGroups] = useState([])
    const [singleline, setSingeline] = useState(null)
    const [groupIndex, setGroupIndex] = useState([0])

    const [devices, setDevices] = useState([])
    const [equipments, setEquipments] = useState([])
    const [showConfig, setShowConfig] = useState(configMode)
    const sourceCancelList = useRef(null);


    const applySetting = (target, prop, val) => {

        let newdevices = devices.map(device => {
            if (device?.id === target.id)
                return { ...device, displayOptions: { ...device.displayOptions, [prop]: val } }
            else
                return device
        })
        changeDevices(newdevices)
    }
    const changeProperty = (target, prpos) => {
        let newdevices = devices.map(device => {
            if (device?.id === target.id)
                return merge(device, prpos)
            else
                return device
        })
        changeDevices(newdevices)

    }
    const merge = (device, prpos) => {
        prpos.forEach(element => {
            device = { ...device, [element.key]: element.val }
        });
        return device
    }
    const remove = (target) => {
        console.log(target)
        const newdevices = new Array(...devices)
        const targetIndex = newdevices.indexOf(target)
        newdevices.splice(targetIndex, 1)
        changeDevices(newdevices)
    }

    const insertRight = (target, item) => {
        const type = item.value
        const displayOptions = DisplayOptionsTemplates[type] ?? DisplayOptionsTemplates.full
        const sl = { ...DeviceTemplate, displayOptions: displayOptions }
        sl.id = new Date().getTime().toString()
        sl.index = sl.id
        sl.titleBottom = item.label
        sl.type = type
        const newdevices = new Array(...devices)
        const targetIndex = target ? newdevices.indexOf(target) : devices.length

        newdevices.splice(targetIndex + 1, 0, sl)


        changeDevices(newdevices)
    }


    useEffect(() => {
        if (substation) {
            console.log(substation)
            const eqs = []
            for (let i = 0; i < substation?.children?.length; i++) {
                const eq = substation?.children[i];
                if (eq) {
                    eqs.push({ equipmentId: eq.key, name: eq.displayName, id: eq.key });
                    for (let j = 0; j < eq.children?.length; j++) {
                        const child = eq?.children[j];
                        if (child)
                            eqs.push({ equipmentId: child.id, name: child.displayName, id: child.id, child: true ,parentName:eq.displayName});

                    }
                }
            }

            setEquipments(eqs)

            const sldgroup = substation?.sldConfigs ? JSON.parse(substation?.sldConfigs) : []
            setSingelineGroups(sldgroup)
            setSingeline(sldgroup[0] ?? {})
            setDevices(sldgroup[0]?.devices ?? [])

        }
        return () => {
            if (sourceCancelList.current) {
                sourceCancelList.current.cancel();
            }
        }
    }, [substation]);

    const onSave = () => {
        let congfigs = { id: substation.id, sldConfigs: JSON.stringify(singlelineGroups) }
        updateSettings(congfigs)
    }

    const updateSettings = (newSetting) => {
        axios.post(updateStationSldConfigs(), newSetting).then(response => {
            if (response) {
                onSeved && onSeved()
                dispatch(showNotification(NotificationType.Success, t("t_successToUpdate")));
            }
        }).catch(e => dispatch(showNotification(NotificationType.Warn, e.message)))
    }
    const isBinded = (eqid) => {
        for (let i = 0; i < singlelineGroups.length; i++) {
            const ele = singlelineGroups[i];
            if (ele.devices?.find(m => m && m.equipmentId === eqid))
                return true
        }
        return false

    }



    const changeDevices = (devis) => {
        setDevices(devis)
        let grp = singleline
        // if (!grp) 
        //     grp = { ...GroupTemplate, id: new Date().getTime().toString(), name: GroupTemplate.name + ' ' + singlelineGroups.length }
        grp = { ...grp, devices: [...devis] }
        const slds = [...singlelineGroups]
        slds.splice(groupIndex, 1, grp)
        setSingelineGroups(slds)
    }

    const addGroup = () => {
        const grp = { ...GroupTemplate, id: new Date().getTime().toString(), name: GroupTemplate.name + ' ' + singlelineGroups.length }
        setSingelineGroups([...singlelineGroups, grp])
        setGroupIndex([singlelineGroups.length])
        setSingeline(grp)
        setDevices(grp.devices)
    }
    const onGroupNameChange = (val) => {
        const grp = { ...singleline, name: val }
        const slds = [...singlelineGroups]
        slds.splice(groupIndex, 1, grp)
        setSingelineGroups(slds)
        setSingeline(grp)
        setDevices(grp.devices)
    }
    const resetGroupIndex = (idx) => {

        setGroupIndex([idx])
        setSingeline(singlelineGroups[idx] ?? {})
        setDevices(singlelineGroups[idx]?.devices ?? [])
    }
    const removeGroup = () => {

        const slds = [...singlelineGroups]
        slds.splice(groupIndex, 1)
        if (slds.length === 0) slds.push({ ...GroupTemplate, id: new Date().getTime().toString() })
        setSingelineGroups(slds)
        setGroupIndex([0])
        setSingeline(slds[0] ?? {})
        setDevices(slds[0]?.devices ?? [])


    }
    !singleline && addGroup()
    return (
        <div className='single-line single-line-svg' >
            {/* <div className='single-line-groups'>
                {singlelineGroups && (singlelineGroups.length > 1 || configMode) &&
                    <ToggleButtonGroup sizeClass="small" selected={groupIndex}    >
                        {singlelineGroups.map((m, idx) => {
                            if (m)
                                return <ToggleButton key={m.id} text={m.name} onClick={() => resetGroupIndex(idx)} />
                        })
                        }
                    </ToggleButtonGroup>
                }
                {(configMode && showConfig) &&
                    <>
                        <Button icon="abb/plus" text="新增" sizeClass="small" onClick={() => addGroup()}></Button>
                        {singlelineGroups?.length > 0 &&
                            <>
                                <Input placeholder="请输入" value={singleline?.name} onValueChange={(val) => onGroupNameChange(val)} style={{ "marginLeft": "48px" }} maxLength={20} ></Input>
                                <WithMessageDialog>
                                    <Button icon="abb/close" text="删除" sizeClass="small" ></Button>
                                    <MessageDialog buttons="yesno" icon="abb/power-on-off" title="确认删除?" message={singleline?.name}
                                        onYes={() => removeGroup()}
                                    />
                                </WithMessageDialog>
                            </>
                        }

                    </>
                }
            </div> */}
            {(!devices || devices.length === 0) && (!configMode || !showConfig) &&
                <div className='text-gray' style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    无数据, 请配置单线图。
                </div>

            }
            <div className='single-line-main' style={{ height: height ? height + "px" : undefined }} >
                {devices.map((device) => {

                    if (device)
                        return <Basic
                            key={device.id} device={device}
                            configMode={configMode}
                            showConfig={showConfig}
                            applySetting={applySetting}
                            changeProperty={changeProperty}
                            remove={remove}
                            insertRight={insertRight}
                            equipments={equipments}
                            isBinded={isBinded}
                            showScore={true} />
                })
                }
                {(configMode && showConfig) &&
                    <div className='single-line-basic config-mode' >
                        <div className='single-line-empty'>

                            {DeviceTypes.map((item) =>
                                <Button key={item.value} onClick={() => insertRight(null, item)} icon="abb/plus" text={item.label} sizeClass="small" style={{ width: "120px", margin: 10 }}></Button>
                            )}


                        </div>
                    </div>
                }

            </div>

            {configMode &&
                <div   >
                    <Button type="primary-blue" icon="abb/save" text={t("b_save")} sizeClass="small" style={{ width: "120px", margin: 10 }} onClick={() => onSave()}></Button>
                    <Button icon="abb/test" text={showConfig ? t("b_preview") : t("b_back_config")} sizeClass="small" style={{ width: "120px", margin: 10 }} onClick={() => setShowConfig(!showConfig)}></Button>

                </div>
            }

            <BaseSvg></BaseSvg>
        </div>

    )
}
