import React, { useState } from 'react'
import { LoadingIndicator, Dialog, Input } from '@abb/abb-common-ux-react'
import { t } from 'i18next'
import { useEffect } from 'react'
export const CommonPromptButtons = {
  OK: 1,
  Cancel: 2,
  Yes: 4,
  No: 8,
}
export const CommonPrompt = ({
  children, //子内容，如果有则content失效。
  title, //标题
  content, //内容
  isVisible, //是否可见
  isLoading, //是否显示加载中
  onClose, //确认事件
  confirmText, //确认按钮标题
  cancelText, //取消按钮标题
  yesText,
  noText,
  showCloseButton, //显示关闭按钮
  buttons = CommonPromptButtons.OK | CommonPromptButtons.Cancel,
  closeOnEscape, //按Esc关闭
}) => {
  const [value, setValue] = useState('')
  useEffect(() => {
    if (!isVisible) {
      setValue('')
    }
  }, [isVisible])
  const getButtons = () => {
    console.log(buttons & CommonPromptButtons.Yes);
    const bts = []
    if ((buttons & CommonPromptButtons.OK)==CommonPromptButtons.OK) {
      bts.push({
        text: confirmText || t('b_confirm'),
        type: 'primary-blue',
        handler: () => {
          onClose(value, CommonPromptButtons.OK)
        },
      })
    }

    if ((buttons & CommonPromptButtons.Yes)==CommonPromptButtons.Yes) {
      bts.push({
        text: yesText || t('b_yes'),
        type: 'primary-blue',
        handler: () => {
          onClose(value, CommonPromptButtons.Yes)
        },
      })
    }
    if ((buttons & CommonPromptButtons.No)==CommonPromptButtons.No) {
      bts.push({
        text: noText || t('b_no'),
        type: 'normal',
        handler: () => {
          onClose(value, CommonPromptButtons.No)
        },
      })
    }
    if ((buttons & CommonPromptButtons.Cancel)==CommonPromptButtons.Cancel) {
      bts.push({
        text: cancelText || t('b_cancel'),
        type: 'normal',
        handler: () => {
          onClose(value, CommonPromptButtons.Cancel)
        },
      })
    }
    return bts
  }
  return (
    <Dialog
      showCloseButton={showCloseButton || false}
      closeOnLostFocus
      closeOnEscape={closeOnEscape || false}
      dimBackground={true}
      isOpen={isVisible}
      title={title}
      standardButtonsOnBottom={isLoading ? [] : getButtons()}
    >
      {!isLoading &&
        (children || (
          <div>
            {content}
            <Input value={value} onValueChange={setValue} />
          </div>
        ))}
      {isLoading && (
        <div style={{ height: 80, position: 'relative' }}>
          <div className='central-position'>
            <LoadingIndicator sizeClass='small' color='blue' type='radial' />{' '}
          </div>
        </div>
      )}
    </Dialog>
  )
}
