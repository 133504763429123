import React, { useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { useSelector } from "react-redux"
import { createCheckItem, updateCheckItem } from "common/apis/ezreport"

export const CheckItemFormEdit = forwardRef(({ id, formData, onSubmit, onCancel,event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //初始化数据
  const initData = {
    name: "",
    checkType: "",
    checkTools: "",
    description: "",
    lifeCycleConfig:'',
    catalogId: "",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: "检查项名称",
      fieldName: "name",
      maxLength: 128,
    },
    {
      type: "dropdown",
      label: "维保类别",
      fieldName: "checkType",
      options: [
        {
          value: "1",
          label: "维保类别Ⅱ",
        },
        {
          value: "2",
          label: "维保类别Ⅰ",
        },
        {
          value: "3",
          label: "维保类别Ⅲ",
        },
      ],
    },
    {
      type: "text",
      dataType: "textarea",
      label: "检查工具",
      fieldName: "checkTools",
      maxLength: 128,
    },
    {
      type: "text",
      dataType: "textarea",
      label: "备注",
      fieldName: "description",
      maxLength: 200,
    },
  ]

  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
    checkType: [CommonRule.Require],
    checkTools: [CommonRule.Require],
  }

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      checkType: inputdata.checkType,
      checkTools: inputdata.checkTools,
      projectType:'5',
      description: inputdata.description,
      lifeCycleConfig:inputdata.lifeCycleConfig,
      catalogId: inputdata.catalogId,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      checkType: inputdata.checkType,
      checkTools: inputdata.checkTools,
      projectType:'5',
      description: inputdata.description,
      lifeCycleConfig:inputdata.lifeCycleConfig,
      catalogId: inputdata.catalogId,
    }
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title="检查项信息"
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createCheckItem}
      updateData={updateCheckItem}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
