import React, { useState, useEffect, useRef } from 'react'
import SidePanel from 'components/SidePanel'
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux";
import { useMountedState } from 'common/useMountedState'
import { ResponseResult } from 'common/request'
import { getDirectory, getDirectoryFiles } from 'common/endpoints'
import { getEnv } from 'slices/envSlice';
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import { Button, Checkbox, CollapsibleContainer, Collapsible, LoadingIndicator, Icon } from '@abb/abb-common-ux-react'

export default function ResourceSelector({ isShow, isSingleSelect, onSelect, onCancel }) {
  const isMounted = useMountedState()
  const env = useSelector(getEnv);
  const dispatch = useDispatch();
  const [directory, setDirectory] = useState(null)
  const [activeDir, setActiveDir] = useState(null)
  const [expanded, setExpanded] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDir, setIsLoadingDir] = useState(false)
  const source = useRef(null);
  const [files, setFiles] = useState([])
  const [selectedItem, setSelectedItem] = useState([])

  const getData = () => {
    setActiveDir(null)
    setIsLoadingDir(true)
    axios.get(getDirectory()).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        const dir = [e.data.data];
        setDirectory(dir)
        setExpanded(dir[0].id)
      } else {
        dispatch(showNotification(NotificationType.Warn), '文件夹读取失败')
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
    }).then(e => {
      if (!isMounted()) {
        return
      }
      setIsLoadingDir(false)
    })
  }

  const getFiles = (directoryId) => {
    setFiles([])
    if (!directoryId) {
      return
    }
    setSelectedItem([])
    if (source.current) {
      source.current.cancel();
    }
    source.current = axios.CancelToken.source();
    setIsLoading(true)
    axios.get(getDirectoryFiles(directoryId), { cancelToken: source.current.token }).then(e => {
      if (!isMounted()) {
        return
      }
      if (e.data.result === ResponseResult.Ok) {
        setFiles(e.data.data)
      } else if (e.data.result === ResponseResult.NoRecord) {
        setFiles([])
      }
    }).catch(e => {
      if (!isMounted()) {
        return
      }
      if (axios.isCancel(e)) {
        return
      }

    }).then(e => {
      if (!isMounted()) {
        return
      }
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (isShow) {
      if (directory === null) {
        getData()
      } else { setSelectedItem([]) }
    }
  }, [isShow, directory])

  useEffect(() => {
    if (activeDir !== null) {
      // setKeyword('')
      getFiles(activeDir)
    }

  }, [activeDir])

  const onSelectConfirm = () => {
    // onSelect(files.filter(f => selectedItem.indexOf(f.key) !== -1))
    onSelect(selectedItem.map(s => files.find(f => f.key === s)))
    setSelectedItem([])
  }

  const toggleSelection = (selected, key) => {
    if (selected.indexOf(key) === -1) {
      if (isSingleSelect) {
        setSelectedItem([key])
      } else {
        setSelectedItem([...selected, key])
      }
    } else {
      setSelectedItem(selected.filter(s => s !== key))
    }
  }
  const renderDir = (id, name, children) => (
    <Collapsible key={id} itemId={id} title={
      <div className="resource-dir-header" onClick={() => setActiveDir(id)}>
        <div className="resource-dir-name">{name}</div>
      </div>
    }>
      {children ? children.map(c => renderDir(c.id, c.name, c.children)) : null}
    </Collapsible >)

  return (
    <SidePanel show={isShow} onClickBackdrop={onCancel} isFlexLayout>
      <div className='side-panel-header'>
        <div className='side-panel-title'>选择文件</div>
        <div className='side-panel-tools'>
          {!activeDir && <Button text="取消" sizeClass="small" onClick={onCancel} />}
          {!!activeDir &&
            <>
              <Button text='确定' type='primary-blue' sizeClass='small' disabled={selectedItem.length === 0} onClick={onSelectConfirm} />
              <Button text="返回目录" sizeClass="small" onClick={() => setActiveDir(null)} />
            </>}
        </div>
      </div>
      <div className='side-panel-body no-padding'>
        {!activeDir &&
          <CollapsibleContainer className="collapsible-directory"
            monochrome
            singleOpenMode={false}
            expandOnTitleClick={false}
            expandedCollapsible={expanded}
            onExpanding={expanded !== undefined ? (items) => setExpanded(Array.from(new Set([...expanded, ...items]))) : undefined}
            onCollapsing={expanded !== undefined ? (item) => setExpanded(expanded.filter((i) => i !== item)) : undefined}
            style={{ height: 'auto' }}
          >
            {directory?.map(d => renderDir(d.id, d.name, d.children))}
          </CollapsibleContainer>}
        {!!activeDir && !isLoading &&
          <div className="resource-directory-details">
            <div className={`file-list layout-brick`}>
              {files.length === 0 && <p className="central-position text-gray">此目录中没有文件</p>}
              {files.length > 0 && files.map(file => {
                const isSelected = selectedItem.indexOf(file.key) >= 0
                return (
                  <div className={`file-item${isSelected ? ' selected' : ''}`} key={file.key}>
                    <div className="file-selector">
                      <Checkbox sizeClass="small" value={isSelected} onChange={e => toggleSelection(selectedItem, file.key)} />
                    </div>
                    <div className="file-thumb" onClick={() => toggleSelection(selectedItem, file.key)}>
                      {renderThumb(env.fileResource, file.key, file.extensionName)}
                    </div>
                    <div className="file-name" title={file.name}>{file.name}</div>
                    <div className="file-size">{file.size}k</div>
                  </div>
                )
              })}
            </div>
          </div>
        }
        {(isLoadingDir || !!activeDir && isLoading) && <div className="central-position"><LoadingIndicator sizeClass="small" color="blue" type="radial" /></div>}
      </div>

    </SidePanel >
  )
}

export const renderThumb = (prefix, id, ext, refreshId) => {
  switch (ext) {
    case '.png': return <img src={`${prefix}${id}${!!refreshId ? '?t=' + refreshId : ''}`} draggable={false} />
    case '.jpg': return <img src={`${prefix}${id}${!!refreshId ? '?t=' + refreshId : ''}`} draggable={false} />
    case '.svg': return <img src={`${prefix}${id}${!!refreshId ? '?t=' + refreshId : ''}`} draggable={false} />
    case '.bmp': return <img src={`${prefix}${id}${!!refreshId ? '?t=' + refreshId : ''}`} draggable={false} />
    case '.jpeg': return <img src={`${prefix}${id}${!!refreshId ? '?t=' + refreshId : ''}`} draggable={false} />
    case '.pdf': return <Icon name="abb/document-pdf" sizeClass="large" />
    case '.docx': return <Icon name="abb/document-word" sizeClass="large" />
    case '.xlsx': return <Icon name="abb/document-excel" sizeClass="large" />
    default: return <Icon name="abb/document" sizeClass="large" />
  }
}
