export default {
  //系统
  title: "ABB PowerCare 管理平台",
  admin: "系统管理",
  maindata: "主数据",
  report: "报表",
  ezreport: "数字维保",
  inspection: "设备巡检",
  evaluation: "设备评估",
  remoteSupport: "远程支持",

  en_us: "英文",
  zh_cn: "简体中文",
  theme: "主题",
  language: "语言",
  timeZone: "时区",
  about: "关于",
  logout: "退出登录",
  howAddDeskop: "如何添加到桌面",
  version: "版本号",
  tel: "电话",
  feedback: "服务反馈",
  termOfService: "服务条款",
  privacyPolicy: "隐私条款",
  newMessage: "新消息",
  refresh: "刷新",
  noNewMessage: "暂无消息",
  more: "更多",
  allHasRead: "全部已读",
  timeZoneTo: "时区设为",
  youHaveNotVisitPurview: "您没有访问此页面的权限",
  justNow: "刚刚",
  minuteBefore: "分钟前",
  hourBefore: "小时前",
  //菜单
  m_overview: "总览",
  m_admin_iconList: "图标列表",
  m_admin_sync: "同步云端数据",
  m_admin_users: "用户管理",
  m_admin_role: "角色管理",
  m_admin_Organization: "组织管理",
  m_admin_Tenant: "租户管理",
  m_admin_dataDictionary: "字典管理",
  m_admin_auditLogs: "审计日志",

  m_admin_resource: "文件管理",
  m_admin_logs: "日志",
  m_admin_settings: "配置",
  m_admin_iot: "物联网卡信息",
  m_report_smslogs: "短信日志",

  m_maindata_customers: "客户管理",
  m_maindata_factories: "工厂管理",
  m_maindata_projects: "项目管理",
  m_maindata_deviceTypes: "设备类型管理",
  m_maindata_materialCatalogs: "物料目录管理",
  m_maindata_materials: "物料管理",
  m_maindata_applications: "应用管理",
  m_maindata_applicationgroups: "应用分组管理",
  m_maindata_banners:"轮播图管理",
  m_maindata_clients: "变电站管理",
  m_maindata_instruments: "仪器管理",
  m_maindata_rights:"客户权益管理",
  m_ezreport_checkItems: "检查项管理",
  m_ezreport_testItems: "试验项管理",
  m_ezreport_reports: "维保报告",

  m_ezreport_testModals: "试验数据模板管理",
  m_ezreport_deviceTypes: "设备类型管理",

  m_inspection_reports:"巡检报告",
  //button
  b_query: "查找",
  b_add: "添加",
  b_inport:'导入',
  b_del: "删除",
  b_output: "导出",
  b_edit: "编辑",
  b_confirm: "确定",
  b_yes: "是",
  b_no: "否",
  b_cancel: "取消",
  b_send:'发送邮件',
  b_save: "保存",
  b_login: "登录",
  b_forget: "忘记密码",
  b_back_to_login: "返回登陆",
  b_reset: "重置",
  b_enable: "启用",
  b_disable: "禁用",
  b_preview: "预览",
  b_back_config: "返回配置",

  b_setSuperUser: "设为超级管理员",
  b_setUser: "设为用户",
  b_addOrgRoot: "添加根机构",
  b_addDeviceTypeRoot: "添加根类型",
  b_addMaterialCatalogRoot: "添加根目录",
  b_addChild: "添加下级",
  b_addUser: "添加用户",
  b_addMetaData: "添加元数据",
  b_addMaterial: "添加物料",
  //标题
  t_noData: "暂无数据",
  t_showing: "显示",
  t_rowsPerPage: "每页行数",
  t_page: "页",
  t_goToPage: "转到",
  t_hasDelete: "数据已删除",
  t_deleteFail: "删除失败",
  t_successToSet: "设置成功",
  t_confirmDeleteData: "确认删除数据？",
  t_titleDeleteData: "删除数据",
  t_keyword: "关键字",
  t_pleaseInput: "请输入",
  t_pleaseSelect: "请选择",
  t_failToAdd: "添加失败",
  t_successToAdd: "添加成功",
  t_failToUpdate: "更新失败",
  t_successToUpdate: "更新成功",
  t_formError: "表单错误",
  t_errorToReadData: "数据读取失败",
  t_operate: "操作",
  t_search: "查找",
  t_filter: "过滤",
  t_noExistsTenant: "租户不存在",
  t_401Error: "授权超时,将重新跳转到登录页面",
  t_403Error: "没有权限访问此资源",
  t_dateBeg: "开始日期",
  t_dateEnd: "结束日期",
  t_all: "所有",
  t_remind:'警告',

  t_titleAddData: "添加数据",
  t_titleEditData: "编辑数据",
  t_yes: "是",
  t_no: "否",

  t_grant_auth: "授权",

  t_userHasDelete: "用户已删除",
  t_plsSelectUser: "请选择用户",
  t_connectString: "连接字符串",
  t_detail: "详情",

  //表单校验规则
  t_required: "必填",
  t_ruleEmail: "邮箱格式错误",
  t_ruleMobile: "手机号格式错误",
  t_ruleId: "身份证格式错误",
  t_ruleUrl: "url格式出错误",
  t_ruleIpv4: "ip地址格式错误",
  t_ruleDate: "日期格式错误",
  t_ruleDateTime: "日期时间格式错误",
  t_ruleInt: "整数格式错误",
  t_ruleFloat: "小数格式错误",
  t_rulePostalNo: "邮政编号格式错误",
  t_ruleQQ: "QQ号格式错误",
  t_ruleWechat: "微信号格式错误",
  t_ruleCarNo: "车牌号格式错误",
  t_ruleLetter: "字符串中只能包含字母",
  t_ruleCN: "字符串中必须包含中文",
  t_rulePassword: "密码强度校验错误",

  //表单标题
  //用户管理
  "user.formTitle": "用户信息",
  "user.hasRoles": "拥有的角色",
  "user.keyword": "查找用户",
  "user.userName": "用户名",
  "user.name": "姓名",
  "user.email": "邮箱",
  "user.phoneNumber": "手机号",
  "user.isActive": "有效否",
  "user.creationTime": "创建时间",
  "user.password": "密码",
  "user.confirmPassword": "再输一次密码",
  "user.twicePwdNotSame": "两次输入的密码不一致",

  //角色管理
  "role.formTitle": "角色信息",
  "role.name": "角色名称",
  "role.isDefault": "默认",

  //登录
  "login.formTitle": "登录",
  "login.forgetTitle": "忘记密码",
  "login.tenant": "租户",
  "login.account": "账号",
  "login.email": "邮箱地址",
  "login.password": "密码",
  "login.rememberMe": "记住我",
  "login.rule.plsInputUserName": "请输入用户名",
  "login.rule.plsInputEmail": "请输入邮箱地址",
  "login.rule.plsInputPwd": "请输入密码",
  "login.msg.userOrPwdError": "用户名或密码错误",
  "login.msg.loginFaild": "登录失败",
  "login.msg.userLockOut": "您的账号已被锁定",
  "login.msg.usernotAllowed": "您的账号已被禁用",
  "login.msg.plsInputUserName&pwd": "请输入用户名和密码",
  "login.msg.providerInfo": "供应商信息",
  "login.msg.privacyPolicy": "隐私政策",
  "login.msg.switchLanguage": "点击切换语言",

   //重置密码
   "resetPassword.formTitle": "重置密码",
   "resetPassword.password": "密码",
   "resetPassword.confirmPassword": "再输一次密码",
   "resetPassword.rule.plsInputPwd": "请输入密码",
   "resetPassword.rule.plsInputConfirmPassword": "请再次输入密码",
   "resetPassword.rule.twicePwdNotSame": "两次输入的密码不一致",
   "resetPassword.msg.providerInfo": "供应商信息",
   "resetPassword.msg.privacyPolicy": "隐私政策",
   "resetPassword.msg.switchLanguage": "点击切换语言",
  //组织架构
  "organization.formTitle": "组织架构信息",
  "organization.parentDisplayName": "上级机构",
  "organization.displayName": "机构名称",

  //租户
  "tenant.formTitle": "租户信息",
  "tenant.name": "名称",
  "tenant.adminEmailAddress": "管理员邮箱",
  "tenant.adminPassword": "管理员密码",
  "tenant.connectionString.formTitle": "连接字符串信息",
  "tenant.connectionString": "连接字符串",

  //设置
  "settings.formTitle": "配置信息",

  //数据字典
  "dataDictionary.formTitle": "数据字典",
  "dataDictionary.typeDisplayText": "字典类型",
  "dataDictionary.code": "编码",
  "dataDictionary.displayText": "名称",
  "dataDictionary.order": "排序",
  "dataDictionary.description": "描述",
  "dataDictionary.isEnabled": "状态",

  //审计日志
  "auditLogs.url": "url",
  "auditLogs.userName": "用户名",
  "auditLogs.executionTime": "执行时间",
  "auditLogs.executionDuration": "响应时间(毫秒)",
  "auditLogs.exceptions": "异常内容",
  "auditLogs.hasException": "是否异常",

  //工厂管理
  "factory.name": "名称",
  "factory.address": "地址",
  "factory.formTitle": "工厂信息",

  //客户管理
  "customer.name": "客户名称",
  "customer.code": "客户编码",
  "customer.category": "客户类别",
  "customer.manager": "客户经理",
  "customer.serviceUnitId": "服务中心",
  "customer.contact": "客户联系人",
  "customer.phone": "联系电话",
  "customer.email": "联系邮箱",
  "customer.area": "区域",
  "customer.adminEmail": "管理员邮箱",
  "customer.adminPassword": "管理员密码",
  "customer.address": "客户地址",
  "customer.formTitle": "客户信息",

  "station.formTitle": "变电站信息",
  "station.stationNo": "编号",
  "station.name": "名称",
  "station.type": "类型",
  "station.manager": "负责人",
  "station.address": "地址",
  "station.sld": "单线图",
  //应用管理
  "application.name": "应用名称",
  "application.type": "应用类型",
  "application.code": "应用编码",
  "application.icon": "应用图标",
  "application.externalUrl": "应用URL",
  "application.order": "应用排序",
  "application.description": "应用说明",
  "application.isDefault": "是否默认应用",
  "application.formTitle": "应用信息",
  //应用分组管理
  "applicationgroup.name": "应用分组名称",
  "applicationgroup.order": "应用分组排序",
  "applicationgroup.description": "应用分组说明",

  "applicationgroup.formTitle": "应用分组信息",
  "application.group":"分组名称",

  //轮播图
  "banner.title":"标题",
  "banner.application":"所属应用",
  "banner.url":"跳转链接",
  "banner.image":"图片",
  "banner.formTitle": "轮播图信息",
  "banner.view":"轮播图预览",
  //项目管理
  "project.name": "项目名称",
  "project.customer": "客户",
  "project.category": "项目类别",
  "project.manager": "项目经理",
  "project.startDate": "开始日期",
  "project.endDate": "结束日期",
  "project.no": "项目编号",
  "project.description": "项目说明",
  "project.formTitle": "项目信息",

  //设备类型管理
  "deviceType.formTitle": "设备类型信息",
  "deviceType.name": "设备类型名称",
  "deviceType.parentName": "父类型名称",
  "metaData.code": "参数编码",
  "metaData.name": "参数名称",
  "deviceType.type":"设备类别",
  "deviceType.image":"设备图片",
  "metaData.dataType": "数据类型",
  "metaData.attributes": "参数特性",
  "metaData.description": "参数说明",
  "metaData.formTitle": "参数信息",

  //设备管理
  "device.formTitle": "设备信息",

  "device.displayName": "显示名称",
  "device.station": "变电站",
  "device.type": "设备类型",
  "device.parentName": "父设备",
  "device.sn": "序列号",
  "device.orderNumber": "订单号",
  "device.modal": "型号",
  "device.factory": "工厂",
  "device.productDate": "生产日期",
  "device.pannelNo": "回路号",
  "device.pannelName": "回路名称",
  "device.busNo": "母线号",
  "device.description": "设备说明",
  "deviceData.formTitle": "设备参数",

  //物料目录管理
  "materialCatalog.formTitle": "物料目录信息",
  "materialCatalog.name": "目录名称",
  "materialCatalog.parentName": "父目录名称",

  //物料管理
  "material.name": "物料名称",
  "material.enName": "英文名称",
  "material.code": "物料编号",
  "material.purchaseCode": "采购编号",
  "material.level": "物料等级",
  "material.modal": "物料型号",
  "material.provider": "供应商",
  "material.purchaseDate": "采购日期",
  "material.warranty": "有效期",
  "material.lastCheck": "最后检测日期",
  "material.status": "状态",
  "material.manager": "仪器管理员",
  "material.description": "说明",
  "material.formTitle": "物料信息",
  "materialData.formTitle": "物料参数",
  //仪器管理
  "instrument.name": "名称",
  "instrument.assetCode": "计量编号",
  "instrument.modal": "型号",
  "instrument.factory": "生产厂家",
  "instrument.purchaseDate": "采购日期",
  "instrument.validity": "有效期",
  "instrument.lastCheck": "最后检测日期",
  "instrument.status": "状态",
  "instrument.manager": "仪器管理员",
  "instrument.description": "说明",
  "instrument.formTitle": "仪器信息",
  //维保区域
  "maintenanceCatalog.title": "维保区域",
  "maintenanceCatalog.childrenTitle": "检查(试验)项名称",
}
