import { Dialog, Input, NumericInput, Slider } from '@abb/abb-common-ux-react'
import axios from 'axios'
import { updateCheckItem, updateTestItem } from 'common/apis/ezreport'
import { Col, Row } from 'components/Grid'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import { useDataDictionary } from 'hooks/useDataDictionary'
import { t } from 'i18next'
import { valid } from 'mockjs'
import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'

export const LifeCycleModal = forwardRef(({ onRefresh }, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [checkItem, setCheckItem] = useState(null)
  const dispatch = useDispatch()
  const [value, SetValue] = useState({
    stages: [
      { key: 'grinding-in', value: 2 },
      { key: 'stable', value: 2,},
      { key: 'attention', value: 2 },
      { key: 'decommissioning', value: 1 },
    ],
  })
  const dt = useDataDictionary('DeviceStage')
  const onCancel = () => {
    setIsVisible(false)
  }
  const showDialog = (item) => {
    setCheckItem(item)
    if (item.lifeCycleConfig) {
      SetValue(JSON.parse(item.lifeCycleConfig))
    } else {
      SetValue({
        stages: [
          { key: 'grinding-in', value: 2 },
          { key: 'stable', value: 2,},
          { key: 'attention', value: 2 },
          { key: 'decommissioning', value: 1 },
        ],
      })
    }
    setIsVisible(true)
  }

  const setValues = async (callback) => {
    const data = {
      ...checkItem,
      lifeCycleConfig: JSON.stringify(value),
    }
    console.log(data)
    try {
      axios.post(updateTestItem(), data).then((response) => {
        if (response.status === 200 || response.status === 204) {
          dispatch(showNotification(NotificationType.Success, t('t_successToUpdate')))
          if (callback) callback()
        } else {
          dispatch(showNotification(NotificationType.Warn, t('t_failToUpdate')))
        }
      })
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t('t_failToUpdate')))
    } finally {
    }
  }

  const onConfirm = () => {
    setValues(() => {
      setIsVisible(false)
      onRefresh && onRefresh()
    })
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    // setIsVisible,
    // setSearchData,
    showDialog,
  }))
  const changeValue = (key, v) => {
    const data = { ...value }
    console.log(data)
    data.stages.find((f) => f.key == key).value = v
    SetValue(data)
  }
  return (
    <Dialog
      style={{ width: '960px' }}
      showCloseButton={true}
      closeOnLostFocus
      closeOnEscape={false}
      dimBackground={true}
      isOpen={isVisible}
      title={'配置维保执行周期'}
      standardButtonsOnBottom={[
        {
          text: t('b_confirm'),
          type: 'primary-blue',
          handler: onConfirm,
        },
        {
          text: t('b_cancel'),
          type: 'normal',
          handler: onCancel,
        },
      ]}
      onClose={() => setIsVisible(false)}
    >
      <div style={{ padding: 24 }}>
        {dt &&
          dt.details &&
          dt.details.map((item) => {
            const v = value.stages.find((v) => v.key === item.code) ?? { value: 2 }
            return (
              <Row>
                <Slider
                  highlight='low'
                  onChange={(v) => changeValue(item.code, v)}
                  value={v.value}
                  style={{ flex: 6 }}
                  min={0}
                  max={5}
                  label={`${item.displayText}(${v.value} 年/次)`}
                />
              </Row>
            )
          })}
      </div>
    </Dialog>
  )
})
