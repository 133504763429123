export const getProjectPage = () => `/api/EzReport/Projects/page`
export const exportProject = (params) => {
  var query = new URLSearchParams()
  if (!!params?.filter) {
    query.append('filter', params.filter)
  }
  if (!!params?.type) {
    query.append('type', params.type)
  }
  if (!!params?.filter) {
    query.append('startDate', params.startDate)
  }
  if (!!params?.endDate) {
    query.append('endDate', params.endDate)
  }
  if (!!params?.manager) {
    query.append('manager', params.manager)
  }
  if (!!params?.scope) {
    query.append('scope', params.scope)
  }
  console.log(query.size);
  return `api/Maindata/Projects/export${query.size>0?'?'+query.toString():''}`
}
export const getMaintenanceCatalogTree = () => `/api/EzReport/MaintenanceCatalogs/tree`
export const getMaintenanceCatalog = () => `/api/EzReport/MaintenanceCatalogs/get`
export const createMaintenanceCatalog = () => `/api/EzReport/MaintenanceCatalogs/create`
export const updateMaintenanceCatalog = () => `/api/EzReport/MaintenanceCatalogs/update`
export const deleteMaintenanceCatalog = () => `/api/EzReport/MaintenanceCatalogs/delete`
export const getDeviceTypeBindList = () => `/api/EzReport/MaintenanceCatalogs/getBind`
export const setDeviceTypeBind = () => `/api/EzReport/MaintenanceCatalogs/setBind`
export const setDeviceTypeBindSop = () => `/api/EzReport/MaintenanceCatalogs/setSop`

export const getCheckItemTree = () => `/api/EzReport/CheckItems/tree`
export const getCheckItemPage = () => `/api/EzReport/CheckItems/page`
export const getCheckItem = () => `/api/EzReport/CheckItems/get`
export const createCheckItem = () => `/api/EzReport/CheckItems/create`
export const updateCheckItem = () => `/api/EzReport/CheckItems/update`
export const deleteCheckItem = (id) => `/api/EzReport/CheckItems/delete?id=${id}`

export const getTestItemTree = () => `/api/EzReport/TestItems/tree`
export const getTestItemPage = () => `/api/EzReport/TestItems/page`
export const getTestItem = () => `/api/EzReport/TestItems/get`
export const createTestItem = () => `/api/EzReport/TestItems/create`
export const updateTestItem = () => `/api/EzReport/TestItems/update`
export const deleteTestItem = (id) => `/api/EzReport/TestItems/delete?id=${id}`
export const getTestModals = () => `/api/EzReport/TestItems/getTestModals`
export const addTestModal = () => `/api/EzReport/TestItems/addTestModal`
export const removeTestModal = () => `/api/EzReport/TestItems/removeTestModal`
export const getTestInstruments = () => `/api/EzReport/TestItems/getInstruments`
export const addTestInstrument = () => `/api/EzReport/TestItems/addInstrument`
export const removeTestInstrument = () => `/api/EzReport/TestItems/removeInstrument`

export const getCheckPointPage = () => `/api/EzReport/CheckPoints/page`
export const getCheckPoint = () => `/api/EzReport/CheckPoints/get`
export const createCheckPoint = () => `/api/EzReport/CheckPoints/create`
export const updateCheckPoint = () => `/api/EzReport/CheckPoints/update`
export const deleteCheckPoint = () => `/api/EzReport/CheckPoints/delete`

export const getCheckPointOptionPage = () => `/api/EzReport/CheckPointOptions/page`
export const getCheckPointOption = () => `/api/EzReport/CheckPointOptions/get`
export const createCheckPointOption = () => `/api/EzReport/CheckPointOptions/create`
export const updateCheckPointOption = () => `/api/EzReport/CheckPointOptions/update`
export const deleteCheckPointOption = () => `/api/EzReport/CheckPointOptions/delete`

export const getTestModalPage = () => `/api/EzReport/TestModals/page`
export const getTestModal = () => `/api/EzReport/TestModals/get`
export const createTestModal = () => `/api/EzReport/TestModals/create`
export const updateTestModal = () => `/api/EzReport/TestModals/update`
export const deleteTestModal = () => `/api/EzReport/TestModals/delete`

export const getReportView = (id) => `/api/EzReport/view?id=${id}`

export const getStationParameterCatalogPage=()=>`/api/EzReport/StationParameterCatalogs/page`
export const deleteStationParameterCatalog=()=>`/api/EzReport/StationParameterCatalogs/delete`
export const createStationParameterCatalog=()=>`/api/EzReport/StationParameterCatalogs/create`
export const updateStationParameterCatalog=()=>`/api/EzReport/StationParameterCatalogs/update`

export const getParameterItemsPage=()=>`/api/EzReport/ParameterItems/page`
export const deleteParameterItems=()=>`/api/EzReport/ParameterItems/delete`
export const createParameterItems=()=>`/api/EzReport/ParameterItems/create`
export const updateParameterItems=()=>`/api/EzReport/ParameterItems/update`

export const getParameterOptionsPage=()=>`/api/EzReport/ParameterOptions/page`
export const deleteParameterOptions=()=>`/api/EzReport/ParameterOptions/delete`
export const createParameterOptions=()=>`/api/EzReport/ParameterOptions/create`
export const updateParameterOptions=()=>`/api/EzReport/ParameterOptions/update`

export const getImportanceWeightsPage=()=>`/api/EzReport/ImportanceWeights/page`
export const deleteImportanceWeights=()=>`/api/EzReport/ImportanceWeights/delete`
export const createImportanceWeights=()=>`/api/EzReport/ImportanceWeights/create`
export const updateImportanceWeights=()=>`/api/EzReport/ImportanceWeights/update`