import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Checkbox } from "@abb/abb-common-ux-react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { createContact, updateContact } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"

export const ContactFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  //初始化数据
  const initData = {
    id: "",
    projectId: "",
    companyName: "",
    name: "",
    position: "",
    email: "",
    phone: "",
    wechat: "",
  }

  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: "公司名称",
      fieldName: "companyName",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: "姓名",
      fieldName: "name",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: "职位",
      fieldName: "position",
      maxLength: 30,
    },
    {
      type: "text",
      dataType: "text",
      label: "联系电话",
      fieldName: "phone",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: "电子邮箱",
      fieldName: "email",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: "微信号",
      fieldName: "wechat",
      maxLength: 256,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      companyName: [CommonRule.Require],
      phone: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])

  
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      projectId: inputdata.projectId,
      companyName: inputdata.companyName,
      name: inputdata.name,
      position: inputdata.position,
      email: inputdata.email,
      phone: inputdata.phone,
      wechat: inputdata.wechat,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      projectId: inputdata.projectId,
      companyName: inputdata.companyName,
      name: inputdata.name,
      position: inputdata.position,
      email: inputdata.email,
      phone: inputdata.phone,
      wechat: inputdata.wechat,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title="联系人信息"
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createContact}
      updateData={updateContact}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
