import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { getDependentMaterials, removeMasters } from "common/apis/maindata";
import { CommonIndex } from "components/LoongInCommon";
import { CommonTable } from "components/LoongInCommon";
import { useSelector } from "react-redux";
import { getMaterial } from "slices/materialSlice";
import { Table, Tooltip, WithTooltip, Button } from "@abb/abb-common-ux-react";
import { forwardRef } from "react";
import "./organiza.scss";
export const MaterialDependency = forwardRef(({ onEdit }, ref) => {
  const [searchData, setSearchData] = useState(null);
  const refIndex = useRef(null);
  const material = useSelector(getMaterial);

  useEffect(() => {
    if (material) {
      setSearchData({ slaverId: material.id });
    }
  }, [material]);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);
  const onWindowResize = () => {};
  const getDeleteInput = (item) => {
    return { masterId: item.id, slaverId: material.id };
  };
  //列定义
  const columns = [
    {
      title: "material.code",
      key: "code",
    },
    {
      title: "material.name",
      key: "name",
    },
    {
      title: "material.modal",
      key: "modal",
    },
  ];

  const refresh = () => {
    refIndex.current.query();
  };
  const actionRender = (item, index) => {
    return (
      <Button
        sizeClass="small"
        icon="abb/minus"
        shape="pill"
        onClick={() => {
          refIndex.current.setDeleteItem(item);
        }}
      />
    );
  };
  //传递引用方法
  useImperativeHandle(ref, () => ({
    refresh,
  }));
  return (
    <div style={{ height: "100%" }}>
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">依赖的物料</div>
          <div className="widget-tools device-profile-paginator show-md show-lg" style={{ paddingTop: 10 }}>
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/plus"
              onClick={() => onEdit(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>
          <div className="widget-tools device-profile-paginator show-xl">
            <Button
              sizeClass="small"
              shape="pill"
              type="discreet-black"
              icon="abb/plus"
              onClick={() => onEdit(true)}
              style={{ opacity: 1, marginRight: 8 }}
            />
          </div>
        </div>
        <div className="widget-body device-profile-details">
          <CommonIndex
            className={"admin-main-content3 with-padding"}
            ref={refIndex}
            columns={columns}
            getList={getDependentMaterials}
            searchData={searchData}
            deleteData={removeMasters}
            getDeleteInput={getDeleteInput}
            canDelete
            pageSize={4}
            pageInQuery={false}
            canSearch={false}
            refreshAfterDelete={true}
            tableWidth={"100%"}
            tableHeight={"100%"}
            enableSorting={false}
            refreshWhenOpen={false}
            canSelection={false} //是否开启选择功能
            canBatchDel={false}
            actionRender={actionRender}
            searchClassName="searching-form-simple" //查询样式
            toolClassName="searching-form-simple searching-form-right-tool text-right" //工具栏样式
          />
        </div>
      </div>
    </div>
  );
});
