import React, { useState, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { updateSettings } from "common/endpoints"
import { t } from "i18next"
import { getClientHeight, getClientWidth } from "slices/appSlice"
import { useSelector } from "react-redux"
// import axios from "axios"
import { useEffect } from "react"
// import { Row, Col } from "components/Grid"

export const Details = forwardRef(({ id, items, onSubmit, event }, ref) => {
  const [formData, setFormData] = useState({})
  const [formItem, setFormItem] = useState([])
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //获得客户区宽度
  const clientWidth = useSelector(getClientWidth)
  //初始化数据
  const initData = {}
  //表单数据项
  useEffect(() => {
    if (items) {
      //设置表单项目
      setFormItem(
        items.map((itm, idx) => {
          let type = "text"
          let datatype = ""
          if (itm.type == "Text") datatype = "text"
          else if (itm.type == "Number") datatype = "number"
          else if (itm.type == "CheckBox") {
            type = "checkbox"
            datatype = ""
          }
          return {
            type: type,
            dataType: datatype,
            label: itm.displayName,
            additionalLabel: itm.description,
            fieldName: itm.name,
          }
        })
      )
      //设置表单数据
      let newformdata = {}
      for (let i = 0; i < items.length; i++) {
        const itm = items[i]
        newformdata[itm.name] =
          itm.type == "CheckBox"
            ? itm.value === "true" || itm.value === "True"
            : itm.value
      }
      setFormData(newformdata)
    }
  }, [items])
  //表单的校验规则
  const rules = {}

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    let values = {}
    for (const key in inputdata) {
      values["setting_" + key] =
        inputdata[key] === null ? "" : inputdata[key] + ""
    }
    return { values }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    let values = {}
    for (const key in inputdata) {
      values["setting_" + key] =
        inputdata[key] === null ? "" : inputdata[key] + ""
    }
    return { values }
  }
  // 返回页面
    return (
      <div
        className="admin-main-content disable-background no-margin"
        style={{
          width: clientWidth - 420 > 300 ? clientWidth - 420 + "px" : "100%",
        }}
      >
        <CommonFormEdit
          ref={formEditRef}
          title={t("settings.formTitle")}
          formItem={formItem}
          initData={initData}
          rules={rules}
          createData={updateSettings}
          updateData={updateSettings}
          getUpdateInput={getUpdateInput}
          getCreateInput={getCreateInput}
          id={id}
          formData={formData}
          onSubmit={onSubmit}
          height={clientHeight - 200}
          type="div"
          resetAfterSumbit={false}
          showCancelButton={false}
          event={event}
        />
      </div>
    )
})
