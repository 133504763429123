import React from 'react';
import ReactDOM from "react-dom";
import './SidePanel.scss'

export default function SidePanel({ show, isTop, size, onClickBackdrop, isFlexLayout, children, ...props }) {
  const domPortal = document.getElementById(isTop ? 'popup-portal-top' : 'popup-portal')
  const style = size > 400 ? ({
    width: size,
    right: show ? 0 : -size
  }) : null
  return ReactDOM.createPortal((
    <>
       <div className={`backdrop${show ? ' open' : ''}`} onClick={onClickBackdrop} />
      <div
        className={`side-panel${show ? ' open' : ''}${isFlexLayout ? ' layout-flex' : ''}`} style={style}
        {...props}>
        {children}
      </div>
    </>)
    , domPortal
  )
}
