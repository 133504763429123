import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { getCustomerAll,getApplicationAll,getDeviceTypes as apiGetDeviceTypes } from "common/apis/maindata";
export const fetchGetCustomersAsync = createAsyncThunk(
  "maindata/fetchGetCustomersAsync",
  () => axios.post(getCustomerAll())
    .then((response) => {
      // console.log(response?.data)
      return response?.data
    }).catch(e => {
      console.log(e)
    })
);

export const fetchDeviceTypesAsync = createAsyncThunk(
  "maindata/fetchDeviceTypesAsync",
  () => axios.post(apiGetDeviceTypes())
    .then((response) => {
      // console.log(response?.data)
      return response?.data
    }).catch(e => {
      console.log(e)
    })
);
export const fetchGetApplicationsAsync = createAsyncThunk(
  "maindata/fetchGetApplicationsAsync",
  () => axios.post(getApplicationAll())
    .then((response) => {
      // console.log(response?.data)
      return response?.data
    }).catch(e => {
      console.log(e)
    })
);
const maindataSlice = createSlice({
  name: "maindata",
  initialState: {
    customers: null,
    applications:null,
    deviceTypes:null
  },
  reducers: {
    setCustomers(state, action) {
      state.customers = action.payload
    },
    setApplications(state, action) {
      state.applications = action.payload
    },
    setDeviceTypes(state, action) {
      state.deviceTypes = action.payload
    },
  },
  extraReducers: {
    [fetchGetCustomersAsync.pending]: (state, action) => {
      state.customers = null;
    },
    [fetchGetCustomersAsync.fulfilled]: (state, action) => {
      state.customers = action.payload;
    },
    [fetchDeviceTypesAsync.pending]: (state, action) => {
      state.deviceTypes = null;
    },
    [fetchDeviceTypesAsync.fulfilled]: (state, action) => {
      state.deviceTypes = action.payload;
    },
    [fetchGetApplicationsAsync.pending]: (state, action) => {
      state.applications = null;
    },
    [fetchGetApplicationsAsync.fulfilled]: (state, action) => {
      state.applications = action.payload;
    },
  },

  

})

export const {
  setCustomers,
  setApplications,
  setDeviceTypes
} = maindataSlice.actions
export const getCustomers = (state) => state.maindata.customers
export const getDeviceTypes = (state) => state.maindata.deviceTypes
export const getApplications = (state) => state.maindata.applications
export default maindataSlice.reducer
