import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule } from "components/LoongInCommon"
import { useSelector } from "react-redux"
import { createBanner, updateBanner, viewFile } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { FileSelector } from "components/FileSelector"
import { getEnv } from "slices/envSlice"

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event, extraData }, ref) => {
    //引用基础窗体控件
    const formEditRef = useRef(null)
    const env = useSelector(getEnv)
    const [formItem, setFormItem] = useState([])
    const [innerData, setInnerData] = useState(formData)
    //获取客户区高度
    const clientHeight = useSelector(getClientHeight)
    const [rules, setRules] = useState({})
    const [apps, setApps] = useState([])
    useEffect(() => {
        if (extraData && extraData.appList) {
            const g = extraData.appList.map(data => {
                return {
                    label: data.name,
                    value: data.id
                }
            })
            setApps(g)
        }
    }, [extraData])

    //初始化数据
    const initData = {
        title: "",
        application: "",
        url: "",
        image: ""
    }
    useEffect(() => {
        setInnerData(formData)
    }, [formData])
    useEffect(() => {
        changeFormItem()
    }, [apps, innerData])
    //表单数据项
    const changeFormItem = () => {
        let formitem = [
            {
                type: "text",
                dataType: "text",
                label: t("banner.title"),
                fieldName: "title",
                maxLength: 256,
            },
            {
                type: "dropdown",
                dataType: "text",
                label: t("banner.application"),
                fieldName: "application",
                maxLength: 256,
                options: apps,
            },
            {
                type: "text",
                dataType: "text",
                label: t("banner.url"),
                fieldName: "url",
                maxLength: 256,
            },
            {
                type: "div",
                label: t("banner.image"),
                fieldName: "image",
                maxLength: 256,
                render: (item) => {
                    return (
                        <div style={{ paddingTop: "12px" }}>
                            <FileSelector
                                path={"/_/Banner/"}
                                onValueChanged={(d) => {
                                    setInnerData(n => { return { ...n, image: d[0].id } })
                                }}
                            />
                        </div>
                    )
                },
            },
            {
                type: "div",
                label: t("banner.view"),
                fieldName: "view",
                maxLength: 256,
                render: (item) => {
                    if (innerData && innerData.image)
                        return (
                            <div style={{ paddingTop: "12px" }}>
                                <img src={`${env.apiBase}/${viewFile()}?id=${innerData.image}`} alt="图片预览" />
                            </div>
                        )
                },
            }
        ]
        setFormItem(formitem)
    }

    //表单的校验规则
    useEffect(() => {
        const newrules = {
            title: [CommonRule.Require],
            image: [CommonRule.Require],
            application: [CommonRule.Require],
            url: [CommonRule.Require]

        }
        setRules(newrules)
    }, [id])
    //获取更新时的参数
    const getUpdateInput = (inputdata) => {
        return {
            id: inputdata.id,
            title: inputdata.title,
            application: inputdata.application,
            url: inputdata.url,
            image: inputdata.image
        }
    }
    //获取添加时的参数
    const getCreateInput = (inputdata) => {
        console.log(inputdata)
        return {
            title: inputdata.title,
            application: inputdata.application,
            url: inputdata.url,
            image: inputdata.image
        }
    }
    const resetForm = () => {
        return formEditRef.current.resetForm()
    }
    //传递引用方法
    useImperativeHandle(ref, () => ({
        setShowSidePanel,
        showAndSwitchTab,
        resetForm,
    }))
    //显示边栏
    const setShowSidePanel = (visible) => {
        formEditRef.current.setShowSidePanel(visible)
    }
    //显示边栏并切换tab
    const showAndSwitchTab = (tabIndex) => {
        formEditRef.current.showAndSwitchTab(tabIndex)
    }
    //返回页面
    return (
        <CommonFormEdit
            ref={formEditRef}
            title={t("banner.formTitle")}
            formItem={formItem}
            initData={initData}
            rules={rules}
            createData={createBanner}
            updateData={updateBanner}
            getUpdateInput={getUpdateInput}
            getCreateInput={getCreateInput}
            id={id}
            formData={innerData}
            onSubmit={onSubmit}
            onCancel={onCancel}
            height={clientHeight - 280}
            event={event}
            onChange={(val) => {
                console.log("onchange", val)
                setInnerData(val)
            }}
        >
            {/* 其他tab采用div在此添加 */}
        </CommonFormEdit>
    )
})
