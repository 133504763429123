import React from "react"

import { Redirect } from "react-router-dom"
import { Row, Col } from "components/Grid"

export default function Dashboard() {
  return (
    <div className="admin-main-content with-padding">
      
    </div>
  )
}
