import React from 'react'

export default function SideNavMenu({ children }) {
  return (
    <div className="side-nav-menu" data-testid="side-nav-menu">
      <div className="scroll-wrapper">
        {children}
      </div>
    </div>
  )
}
