import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getUserList, getUserPage } from "common/endpoints"

export const fetchGetUsersAsync = createAsyncThunk("Users/page", () =>
  axios
    .post(getUserPage(),{ pageIndex: 1, pageSize: 1000 })
    .then((response) => {
      return response?.data
    })
    .catch((e) => {
      console.log(e)
    })
)

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
  },
  reducers: {
    setUsers(state, action) {
      state.users = action.payload.items
    },
  },
  extraReducers: {
    [fetchGetUsersAsync.pending]: (state, action) => {
      state.users = []
    },
    [fetchGetUsersAsync.fulfilled]: (state, action) => {
      state.users = action.payload?.items
    },
  },
})

export const { setUsers } = userSlice.actions
export const getUsers = (state) => state.user.users
export default userSlice.reducer
