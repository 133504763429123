import React, { useState, useRef, useEffect } from "react"
import { WithTooltip, Tooltip } from "@abb/abb-common-ux-react"
import { getApplicationPage, deleteApplication, getApplicationgroupAll } from "common/apis/maindata"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import axios from "axios"


export default function Applications() {
  const [deletedItems, setDeletedItems] = useState([])
  const refIndex = useRef(null)
  const [appGroupList,setAppGroupList] = useState(null)
  const appType = [
    { label: "内部应用", value: 1 },
    { label: "外部应用", value: 2 },
    { label: "Web应用", value: 3 },
  ]
  useEffect(() => {
    axios.post(getApplicationgroupAll()).then(resp => {
      setAppGroupList(resp.data)
    })
  }, [])
  //列定义
  const columns = [
    {
      title: "application.name",
      key: "name",
      //通过渲染的方式将内容渲染出来
      render: (item, index) => {
        const isDeleted = deletedItems.indexOf(item.id) >= 0
        if (isDeleted)
          return (
            <WithTooltip>
              <del>{item.name}</del>
              <Tooltip>
                <div>应用已被删除</div>
              </Tooltip>
            </WithTooltip>
          )
        else return item.name
      },
    },
    {
      title: "application.code",
      key: "code",
    },
    {
      title: "application.type",
      key: "type",
      render: (item, index) => {
        const category = appType.find((d) => d.value === item.type);
        return category?.label;
      },
    },  {
      title: "application.order",
      key: "order",
    },
    {
      title: "application.externalUrl",
      key: "externalUrl",
    },
  ]

  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        getList={getApplicationPage}
        deleteData={deleteApplication}
        FormEdit={FormEdit}
        canAdd
        canEdit
        canDelete
        onDeleteData={onDeleteData}
        enableSorting={true}
        extraData={{appGroupList}}
      />
    </>
  )
}
