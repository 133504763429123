import React, { useRef, useImperativeHandle, forwardRef } from "react"
import { useSelector } from "react-redux"
import { CommonFormEdit, CommonRule } from "components/LoongInCommon"
import { createDataDictionaryDetail, updateDataDictionaryDetail } from "common/endpoints"
import { getClientHeight } from "slices/appSlice"
import { t } from "i18next"

export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //校验规则
  const rules = {
    code: [CommonRule.Require],
    displayText: [CommonRule.Require],
    order: [CommonRule.Require],
  }
  //初始化数据
  const initData = {
    id: "",
    typeDisplayText: "",
    code: "",
    displayText: "",
    order: "",
    description: "",
  }
  //表单项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("dataDictionary.typeDisplayText"),
      fieldName: "typeDisplayText",
      visible: !formData || !!formData.typeDisplayText,
      disabled: true,
    },
    {
      type: "text",
      dataType: "text",
      label: t("dataDictionary.code"),
      fieldName: "code",
      maxLength: 256,
      disabled: formData && formData.id,
    },
    {
      type: "text",
      dataType: "text",
      label: t("dataDictionary.displayText"),
      fieldName: "displayText",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "number",
      label: t("dataDictionary.order"),
      fieldName: "order",
    },
    {
      type: "text",
      dataType: "text",
      label: t("dataDictionary.description"),
      fieldName: "description",
      maxLength: 512,
    },
  ]
  //获取更新的输入参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      dataDictionaryId: inputdata.dataDictionaryId,
      displayText: inputdata.displayText,
      description: inputdata.description,
      code: inputdata.code,
      order: inputdata.order,
    }
  }
  //获取添加的输入参数
  const getCreateInput = (inputdata) => {
    return {
      id: inputdata.dataDictionaryId,
      typeDisplayText: inputdata.typeDisplayText,
      code: inputdata.code,
      displayText: inputdata.displayText,
      order: inputdata.order,
      description: inputdata.description,
    }
  }

  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("dataDictionary.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createDataDictionaryDetail}
      updateData={updateDataDictionaryDetail}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
