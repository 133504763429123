import { Dialog } from '@abb/abb-common-ux-react'
import axios from 'axios'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { t } from 'i18next'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEnv } from 'slices/envSlice'
const KEY_Lang = 'system.lang'

export const Axios = ({ children }) => {
  const env = useSelector(getEnv)
  const [initialized, setInitialized] = useState(false)
  const dispatch = useDispatch()
  const [remindMessage, setRemindMessage] = useState(null)
  const currentUser = useCurrentUser()
  const [showRemind, setShowRemind] = useState(false)

  const user = currentUser.getUser()

  const handleConfirm = () => {
    setShowRemind(false)
    if (remindMessage.type === 1) {
      window.location.reload()
    }
  }
  if (!initialized) {
    const token = user?.token
    axios.defaults.baseURL = env.apiBase
    axios.interceptors.request.use(
      (config) => {
        if (config.url === '/env' || config.url?.indexOf('/api/test') === 0) {
          return config
        }
        const lang = localStorage.getItem(KEY_Lang) || 'zh_cn' //
        if (token) {
          config.headers.common['Authorization'] = 'Bearer ' + token
        }
        config.headers.common['accept-language'] = lang == 'zh_cn' ? 'zh-Hans' : 'en'
        config.headers.common['accept'] = 'text/plain'
        config.headers.common['Content-Type'] = 'application/json'
        config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response) {
          if (error.response.status === 401 && !user.result.requiresTwoFactor) {
            //未经授权
            currentUser.clear()
            setRemindMessage({ type: 1, message: '授权超时,将重新跳转到登录页面' })
            setShowRemind(true)
          }
        } else {
          setRemindMessage({ type: 3, message: '服务端响应超时，请检查网络或联系管理员处理' })
          setShowRemind(true)
        }
        const realData = error && error.response ? error.response.data : undefined

        return Promise.reject(realData || error)
      }
    )
    setInitialized(true)
  }
  return (
    <>
      {children}
      <Dialog
        title={t('t_remind')}
        dimBackground
        isOpen={showRemind}
        standardButtonsOnBottom={[
          {
            text: t('b_confirm'),
            type: 'primary-blue',
            handler: () => handleConfirm(),
          },
        ]}
      >
        <div style={{ padding: '10px 15px' }}>{remindMessage ? remindMessage?.message : '系统错误'}</div>
      </Dialog>
    </>
  )
}
