import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonList, CommonTree } from 'components/LoongInCommon'
// import { Checkbox } from "@abb/abb-common-ux-react"
// import { CommonRule } from "components/LoongInCommon"
// import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from 'axios'
// import { useDispatch, useSelector } from "react-redux"
import { t } from 'i18next'
import { getTestItemTree as apiGetTestItemTree } from 'common/apis/ezreport'
import { Col, Row } from 'components/Grid'
// import {  getClientHeight,} from "slices/appSlice"

export const LeftPanel = forwardRef(({ children, onActive, onAdd, onConfigLifeCycle, onEdit, onDel }, ref) => {
  const treeRef = useRef(null)
  //组织架构树的数据
  const [tree, setTree] = useState(null)
  //传递引用方法
  useImperativeHandle(ref, () => ({
    getTestItemTree,
  }))

  const myMenu = (item) => {
    return [
      {
        title: '添加试验项',
        onClick: (item) => {
          onAdd && onAdd(item)
        },
      },
      {
        title: t('b_edit'),
        color: 'blue',
        onClick: (item) => {
          onEdit && onEdit(item)
        },
      },
      {
        title: t('b_del'),
        color: 'red',
        onClick: (item) => {
          onDel && onDel(item)
        },
      },
    ]
  }
  const expandAll = () => {
    if (treeRef && treeRef.current) {
      treeRef.current.expandAll()
    } else {
      const intev = setInterval(() => {
        if (treeRef && treeRef.current) {
          clearInterval(intev)
          treeRef.current.expandAll()
        }
      }, 100)
    }
  }
  //调用接口获取权限树
  const getTestItemTree = async (roleName) => {
    try {
      const response = await axios.post(apiGetTestItemTree(), { projectType: '5' })
      if (response.status === 200) {
        if (response.data) {
          const items = response.data
          setTree(items)
          // expandAll()
        }
      } else {
        console.log('error to get tree grant')
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    getTestItemTree()
  }, [])

  //添加按钮
  const rightIcon = {
    name: 'abb/plus',
    color: 'blue',
    sizeClass: 'medium',
    onClick: () => {
      onAdd && onAdd()
      // typeFormRef.current.setShowSidePanel(true)
    },
  }
  //返回页面
  return (
    <div className={'admin-main-content with-padding'}>
      <CommonList
        keyName='key'
        filterText='title'
        displayName='title'
        rightIcon={rightIcon}
        leafOnly={true}
        ref={treeRef}
        data={tree}
        contexMenu={myMenu}
        onActive={onActive}
      />
    </div>
  )
})
