import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Checkbox, Dialog } from '@abb/abb-common-ux-react'
import { getInspenctionReportDetail } from 'common/apis/totalreports'
import '../../../styles/totalReport.scss'

import axios from 'axios'
import moment from 'moment'
import { Image } from 'components/Image'
import envs from './enviroment.json'

import { useEffect } from 'react'
import * as echarts from 'echarts'
import { nanoid } from 'nanoid'
import { chunk, groupBy, sumBy } from 'lodash'
import { optionCSS } from 'react-select/dist/index-f91a2068.cjs.prod'

export const TotalReport = forwardRef(({ report, readonly = false }, ref) => {
  const [detail, setDetail] = useState(null)
  const charRef = useRef(null)

  let index = 0
  const fetchPreviewInfo = (reportId) => {
    axios.post(getInspenctionReportDetail(reportId)).then((response) => {
      const data = response.data
      console.log(data)
      setDetail(data)
    })
  }

  useEffect(() => {
    if (report) {
      fetchPreviewInfo(report.id)
    }
  }, [report])

  const getReport = () => {
    return detail?.report
  }
  useImperativeHandle(ref, () => ({
    getReport,
  }))
  function getRankColorFromNum(rank) {
    try {
      var color = ''
      switch (rank) {
        case 1:
          color = 'red'
          break
        case 2:
          color = 'orange'
          break
        case 3:
          color = 'yellow'
          break
        default:
          color = 'green'
          break
      }
      return color
    } catch (exception) {}
  }

  const renderCheckItem = (item) => {
    const points = item.points
    return points.map((v, i, a) => {
      if (i == 0) {
        return (
          <tr key={nanoid()}>
            <td rowSpan={points.length}>{item.name}</td>
            <td>{v.name}</td>
            {v.options.slice(0, 3).map((opt, i, a) => {
              return (
                <td className='center' colSpan={i > 0 && i + 1 === a.length ? a.length - i + 1 : 1}>
                  <input type='checkbox' checked={v.result.code === opt.code} disabled={true} />
                  {opt.title}
                </td>
              )
            })}
          </tr>
        )
      } else {
        return (
          <tr key={nanoid()}>
            <td>{v.name}</td>
            {v.options.slice(0, 3).map((opt, i, a) => {
              return (
                <td className='center' colSpan={i > 0 && i + 1 === a.length ? a.length - i + 1 : 1}>
                  <input type='checkbox' checked={v.result.code === opt.code} disabled={true} />
                  {opt.title}
                </td>
              )
            })}
          </tr>
        )
      }
    })
  }

  const renderTestItem = (test, item) => {
    console.log(item);
    const modal = JSON.parse(item.modalString.replaceAll('rowspan', 'rowSpan').replaceAll('colspan', 'colSpan'))
    const r = JSON.parse(item.result)
    const result = { ...r?.result, ...item }
    return (
      <table key={nanoid()} className='center '>
        {renderTestModal(modal.content, result)}
      </table>
    )
  }

  const renderTestModal = (modal, result) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderTestModal(m.componments, result)}
            </td>
          )
        }
        if (m.type === 'option') {
          return <input type='checkbox' key={nanoid()} checked={result && result[m.name] == m.key} disabled={true} />
        }
        if (m.type === 'editonly') {
          return ''
        }
        if (m.type === 'input') {
          const data = result[m.name]
          let value = data
          if (m.options) {
            value = m.options.find((o) => o.key == data)?.title
          }
          return <span key={nanoid()}>{result && result[m.name] && (!!m.format ? m.format.replace('{value}', value) : value)}</span>
        }
        if (m.type === 'image') {
          return <Image key={nanoid()} id={result && result[m.name]} style={{ width: '95%' }} />
        }
        return renderTestModal(m.componments, result)
      })
    )
  }

  const renderDevice = (device, name) => {
    if (!device) {
      return null
    }
    return (
      <>
            {device.testItems && device.testItems.length > 0 && (
            <li className='canBeDeleted'>
              <ul data-order='2'>
                {device.testItems.map((test) => (
                  <li key={nanoid()} id='expCircuitvoltage' className='li_exp'>
                    {test.modals.map((modal) => renderTestItem(test,modal))}
                  </li>
                ))}
              </ul>
            </li>
          )}    {device.checkItems && device.checkItems.length > 0 && (
          <table id='switchCheckTable'>
            <thead>
              <tr className='border-top-solid border-right-dotted'>
                <td>检查项目</td>
                <td>检查内容</td>
                <td colSpan='3'>检查结果</td>
              </tr>
            </thead>
            <tbody key={nanoid()}>{device.checkItems.map((i) => renderCheckItem(i))}</tbody>
          </table>
        )}

      </>
    )
  }
  return (
    detail && (
      <div className='total-report'>
        <div className='cover' style={{ textAlign: 'center', marginTop: 60 }}>
          <h2>
            <span data-switchingroom='name'>{report?.station?.name}</span>变电站
          </h2>
          <h2>运行设备</h2>
          <h2>{moment(report.project.startDate).format('yyyy年MM月')}巡检报告</h2>
          <h1 style={{ marginTop: 20 }}>
            <span data-project='customerCompanyName'>{report?.customer.name}</span>
          </h1>
        </div>
        <div className='content'>
          <ul>
            <li>
              <h5>1. 项目信息</h5>
              <ul>
                <li>
                  <h5>1.1 客户信息</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td style={{ width: '16%' }}>客户名称：</td>
                        <td id='maintenanceTeamCompanyName' style={{ width: '84%' }}>
                          {detail?.project?.customer?.name}
                        </td>
                      </tr>
                      {console.log('project', detail?.project)}
                      <tr>
                        <td>联系人</td>
                        <td id='maintenanceTeamPM'>{detail?.project?.contacts[0]?.name}</td>
                      </tr>
                      <tr>
                        <td>职位</td>
                        <td id='maintenanceTeamFax'>{detail?.project?.contacts[0]?.role}</td>
                      </tr>
                      <tr>
                        <td>手机</td>
                        <td id='maintenanceTeamContactInfo'>{detail?.project?.contacts[0]?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>邮箱</td>
                        <td id='maintenanceTeamEmail'>{detail?.project?.contacts[0]?.email}</td>
                      </tr>

                      <tr>
                        <td>公司地址</td>
                        <td id='maintenanceTeamAddress'>{detail?.project?.customer?.address}</td>
                      </tr>
                      <tr>
                        <td style={{ color: 'red' }}>客户分类</td>
                        <td id='maintenanceTeamSafetySupervisor'>最终用户</td>
                      </tr>
                    </tbody>
                  </table>
                </li>

                <li>
                  <h5>1.2 项目信息</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td>项目名称</td>
                        <td id='projectName' colSpan=' 3'>
                          {detail?.project?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>合同编号</td>
                        <td id='projectContractNo' colSpan='3'>
                          {detail?.project?.no}
                        </td>
                      </tr>
                      {Object.entries(groupBy(detail?.devices, 'type')).map(([k, v]) => {
                        return (
                          <tr>
                            <td style={{ width: '16%' }}>{k}型号(数量)</td>
                            <td>
                              {Object.entries(groupBy(v, 'name')).map(([n, g]) => {
                                return `${n}(${sumBy(g, (i) => i.devices.length)})`
                              })}
                            </td>
                          </tr>
                        )
                      })}
                      <tr className='border-bottom-solid'>
                        <td>备注</td>
                        <td colSpan='5' className='left'></td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>1.3 执行信息</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td style={{ width: '16%' }}>公司名称：</td>
                        <td id='maintenanceTeamCompanyName' style={{ width: '84%' }}>
                          {detail?.team?.company}
                        </td>
                      </tr>
                      <tr>
                        <td>项目经理</td>
                        <td id='maintenanceTeamPM'>{detail?.team?.manager?.name}</td>
                      </tr>
                      <tr>
                        <td>联系电话</td>
                        <td id='maintenanceTeamContactInfo'>{detail?.team?.manager?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>邮箱</td>
                        <td id='maintenanceTeamEmail'>{detail?.team?.manager?.email}</td>
                      </tr>
                      <tr>
                        <td>公司地址</td>
                        <td id='maintenanceTeamAddress'>厦门市翔安区舫山西二路 885 号</td>
                      </tr>
                      <tr>
                        <td>执行团队成员</td>
                        <td id='maintenanceTeamMembers'>
                          {detail?.team?.members
                            ?.filter((t) => t.role !== 'safety')
                            ?.map((t) => t.name)
                            .join(',')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
            <li>
              <h5>2. 变电站运行环境及系统状态</h5>
              <ul>
                <li>
                  <h5>2.1 变电站环境状况</h5>
                  {Object.entries(envs).map(([k, v]) => {
                    return (
                      <table>
                        <thead>
                          <tr>
                            <td className='blue' style={{ width: '20%' }}>
                              {k}
                            </td>
                            <td colSpan='2' style={{ width: '40%' }}></td>
                            <td style={{ width: '40%' }}>备注</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(v).map(([t, a]) => {
                            return (
                              <>
                                <tr>
                                  <td colSpan='4'>{t}</td>
                                </tr>
                                <tr>
                                  <td colSpan='3' data-option='ventilation'>
                                    {a.map((v) => {
                                      return (
                                        <label>
                                          <input type='checkbox' disabled={true} checked={detail?.station?.environments[t] === v} />
                                          {v}
                                        </label>
                                      )
                                    })}
                                  </td>
                                  <td></td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    )
                  })}
                </li>
                {/* <li>
                  <h5>2.2 变电站系统状态一览</h5>
                  <table>
                    <thead>
                      <tr>
                        <td style={{ width: '20%' }} className='blue'>
                          站内温湿度
                        </td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td>备注</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          温度: <span data-switchingroom='stationTemperature'>{detail?.station?.deviceStatus['温度']}</span> ℃
                        </td>
                        <td>
                          湿度: <span data-switchingroom='stationHumidity'>{detail?.station?.deviceStatus['湿度']}</span> %
                        </td>
                        <td></td>
                        <td>
                          <span data-switchingroom='stationTemHumRemark'></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue' style={{ width: '20%' }}>
                          消谐方式
                        </td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    {console.log(detail)}
                    <tbody>
                      <tr>
                        <td colspan='3' data-option='connectionOfPT' data-optionkey='ConnectionOfPT' className='col_2_w'>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.split(',')?.includes('二次微机消谐')}
                            />
                            二次微机消谐
                          </label>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.split(',')?.includes('二次电阻消谐')}
                            />
                            二次电阻消谐
                          </label>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.split(',')?.includes('一次消谐电阻')}
                            />
                            一次消谐电阻
                          </label>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.split(',')?.includes('四PT消谐方案')}
                            />
                            四PT消谐方案
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='connectionOfPTRemark'></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue'>系统最大负荷</td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ⅰ段母线负荷电流</td>
                        <td>
                          <span data-switchingroom='busLoadCurrentOne'>{detail?.station?.deviceStatus['Ⅰ段母线负荷电流']}</span>
                        </td>
                        <td>A</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ⅱ段母线负荷电流</td>

                        <td>
                          <span data-switchingroom='busLoadCurrentTwo'>{detail?.station?.deviceStatus['Ⅱ段母线负荷电流']}</span>
                        </td>
                        <td>A</td>

                        <td>
                          <span data-switchingroom='busLoadCurrentReamrk'></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue'>辅助电源</td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>控制电源电压</td>
                        <td colspan='2' data-option='controlVoltageStyle' data-optionkey='ControlSupplyVoltage'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['控制电源电压'] === 'AC'} />
                            AC
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['控制电源电压'] === 'DC'} />
                            DC
                          </label>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ⅰ段控母电压</td>
                        <td>
                          <span data-switchingroom='controlVoltageOne'>{detail?.station?.deviceStatus['Ⅰ段控母电压']}</span>
                        </td>
                        <td>V</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ⅱ段控母电压</td>

                        <td>
                          <span data-switchingroom='controlVoltageTwo'>{detail?.station?.deviceStatus['Ⅱ段控母电压']}</span>
                        </td>
                        <td>V</td>

                        <td>
                          <span data-switchingroom='controlVoltageRemark'></span>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: '20%' }}>储能电源电压</td>
                        <td colSpan='2' data-option='storageVoltageStyle' data-optionkey='EnergySupplyVoltage'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['储能电源电压'] === 'AC'} />
                            AC
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['储能电源电压'] === 'DC'} />
                            DC
                          </label>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ⅰ段合母电压</td>

                        <td>
                          <span data-switchingroom='parentingVoltageOne'>{detail?.station?.deviceStatus['Ⅰ段合母电压']}</span>
                        </td>
                        <td>V</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ⅱ段合母电压</td>

                        <td>
                          <span data-switchingroom='parentingVoltageTwo'>{detail?.station?.deviceStatus['Ⅱ段合母电压']}</span>
                        </td>
                        <td>V</td>

                        <td>
                          <span data-switchingroom='storageVoltageRemark'></span>
                        </td>
                      </tr>
                      <tr id=''>
                        <td>加热器电源电压</td>
                        <td colSpan='2' data-option='heaterVoltageStyle' data-optionkey='HeaterSupplyVoltage'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['加热器电源电压'] === 'AC'} />
                            AC
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['加热器电源电压'] === 'DC'} />
                            DC
                          </label>
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ⅰ段加热器电压</td>

                        <td>
                          <span data-switchingroom='heaterVoltageOne'>{detail?.station?.deviceStatus['Ⅰ段加热器电压']}</span>
                        </td>
                        <td>V</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Ⅱ段加热器电压</td>

                        <td>
                          <span data-switchingroom='heaterVoltageTwo'>{detail?.station?.deviceStatus['Ⅱ段加热器电压']}</span>
                        </td>
                        <td>V</td>

                        <td>
                          <span data-switchingroom='heaterVoltageRemark'></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue'>接地情况</td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan='4'>开关柜接地情况</td>
                      </tr>
                      <tr>
                        <td colspan='3' data-option='cabinetEarthingMode' data-optionkey='CabinetEarthingMode'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['开关柜接地情况'] === '良好'} />
                            良好
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['开关柜接地情况'] === '需要改进'} />
                            需要改进
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['开关柜接地情况'] === '没有接地'} />
                            没有接地
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='cabinetEarthingModeRemark'></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue' style={{ width: '20%' }}>
                          其它检查情况
                        </td>
                        <td colSpan='2' style={{ width: '40%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan='4'>运行操作工具</td>
                      </tr>
                      <tr>
                        <td colSpan='3' data-option='operationTools' data-optionkey='OperationTools'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['运行操作工具'] === '齐全，状态良好'} />
                            齐全，状态良好
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['运行操作工具'] === '部分缺损'} />
                            部分缺损
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['运行操作工具'] === '无'} />无
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='operationToolsRemark'></span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan='4'>维修专用工具</td>
                      </tr>
                      <tr>
                        <td colSpan='3' data-option='maintainTools' data-optionkey='MaintainTools'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['维修专用工具'] === '齐全，状态良好'} />
                            齐全，状态良好
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['维修专用工具'] === '部分缺损'} />
                            部分缺损
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['维修专用工具'] === '无'} />无
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='maintainToolsRemark'></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li> */}
              </ul>
            </li>
            <li>
              <h5>3. 巡检结果</h5>
              <ul>
                <li className='result'>
                  {detail?.devices[0]?.devices?.map((d) => {
                    const t = detail?.devices[0]
                    return (
                      <>
                        <h5>{`开关柜柜号：${d.name}\t\t型号：${t.name}`}</h5>
                        <table id='deviceTitle' className='noborder-bottom'>
                          <tbody>
                            {d?.result?.deviceInfo &&
                              Object.values(d.result.deviceInfo).map((device, index, arr) => {
                                console.log('device', d.result.deviceInfo)
                                return (
                                  <React.Fragment key={nanoid()}>
                                    <tr key={nanoid()} className={index == 0 ? 'border-top-solid' : ''}>
                                      <td>{device.modal.title}：</td>
                                      <td id='cabinetModel'>{device.modal.value ?? 'NA'}</td>
                                      <td>{device.sn.title}：</td>
                                      <td id='cabinetServISNumber'>{device.sn.value ?? 'NA'}</td>
                                      <td>{device.productDate.title}：</td>
                                      <td id='cabinetNumber2'>{device.productDate.value ?? 'NA'}</td>
                                    </tr>
                                    {chunk(
                                      Object.entries(device).filter((d) => !(d[0] === 'modal' || d[0] === 'sn' || d[0] === 'productDate')),
                                      3
                                    ).map((t, i, a) => (
                                      <tr key={nanoid()} className={i >= a.length - 1 ? 'border-bottom-solid' : ''}>
                                        {t.map((d) => {
                                          const kv = d[1]
                                          return (
                                            <React.Fragment key={nanoid()}>
                                              <td key={nanoid()}>{kv.title}</td>
                                              <td key={nanoid()} data-field='cabinetRatedVoltage' id='cabinetRatedVoltage'>
                                                {kv.value ?? 'NA'}
                                              </td>
                                            </React.Fragment>
                                          )
                                        })}
                                      </tr>
                                    ))}
                                  </React.Fragment>
                                )
                              })}
                          </tbody>
                        </table>
                        {d?.result?.maintenanceInfo && Object.values(d.result.maintenanceInfo).map((device) => {
                          if (!device) {
                            return null
                          }
                          return renderDevice(device)
                        })}
                        {
                          !(d?.result?.maintenanceInfo) && (
                            <table id='switchCheckTable'>
                            <thead>
                              <tr className='border-top-solid border-right-dotted'>
                                <td>检查项目</td>
                                <td>检查内容</td>
                                <td colSpan='3'>检查结果</td>
                              </tr>
                            </thead>
                            <tbody key={nanoid()}>
                              <tr>
                                <td colSpan={3}>没有提交数据。</td>
                              </tr>
                            </tbody>
                          </table>
                          )
                        }
                      </>
                    )
                  })}
                </li>
              </ul>
            </li>
            <li>
              <h5>4. 问题汇总</h5>
              <ul>
                <li>
                  <table id='DeviceMaintenanceSitulation'>
                    <thead className='border-bottom-dotted'>
                      <tr>
                        <td style={{ width: '21%' }}>序号</td>
                        <td style={{ width: '21%' }}>发现问题描述</td>
                        <td style={{ width: '10%' }}>附录图片</td>
                        <td style={{ width: '10%' }}>问题等级 </td>
                        <td style={{ width: '38%' }}>处理措施建议</td>
                      </tr>
                    </thead>
                    <tbody>
                      {detail?.devices[0]?.devices &&
                        detail?.devices[0]?.devices
                          ?.flatMap((d) => Object.values(d?.result?.maintenanceInfo??{})??[])
                          .flatMap((item) => item.checkItems)
                          .flatMap((item) => item.points)
                          .filter((i) => i.result.code === '2')
                          .map((p, i) => {
                            const r = p.result
                            return (
                              <tr key={i}>
                                <td>{i}</td>
                                <td>{r.description}</td>
                                <td style={{ width: '17.5%' }}>
                                  <Image id={r.beforPhoto} style={{ width: '100%' }} />
                                </td>
                                <span className={`circle ${getRankColorFromNum(r.rank)}`}></span>
                                <td>{r.measures}</td>
                              </tr>
                            )
                          })}
                      
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
            <li>
              <h5>5. 结论</h5>
              <ul>
                <li>
                  <textarea
                    id='summaryConclusion'
                    name='summaryConclusion'
                    readOnly={readonly}
                    onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, summaryConclusion: e.target.value } })}
                    value={detail?.report?.summaryConclusion}
                    style={{ width: '100%' }}
                    className='k-textbox'
                    rows='4'
                  ></textarea>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    )
  )
})
