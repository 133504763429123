const TIMEZONE = [
  "-11:00",
  "-10:00",
  "-09:30",
  "-09:00",
  "-08:00",
  "-07:00",
  "-06:00",
  "-05:00",
  "-04:30",
  "-03:00",
  "-04:00",
  "-03:30",
  "-02:00",
  "-01:00",
  "+00:00",
  "+01:00",
  "+02:00",
  "+03:00",
  "+03:30",
  "+04:00",
  "+04:30",
  "+05:00",
  "+05:30",
  "+05:45",
  "+06:00",
  "+06:30",
  "+07:00",
  "+08:00",
  "+08:30",
  "+09:00",
  "+10:30",
  "+09:30",
  "+10:00",
  "+11:00",
  "+12:00",
  "+13:00",
  "+14:00"
]
const sortTimezoneAsc = (a, b) => {
  if (a[0] === b[0]) {
    if (a[0] === '+') {
      return a > b ? 1 : -1
    } else {
      return a < b ? 1 : -1
    }
  }
  else { return a[0] < b[0] ? 1 : -1 }
}
export const listedUtc = TIMEZONE.sort(sortTimezoneAsc)
