import i18n from "i18next"
import zhCn from "./zh_cn.js"
import enUs from "./en_us.js"
import { initReactI18next } from "react-i18next"
// import axios from "axios"
//存储在localStorage中的key
const KEY_Lang = 'system.lang'
let lang = localStorage.getItem(KEY_Lang)// "zh_cn"
const lng = lang || "zh_cn"
//切换语言
export function changeLanguage(newlang) {
  i18n.changeLanguage(newlang)
  localStorage.setItem(KEY_Lang, newlang)
  // s
}
//i18n初始化
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en_us: {
        translation: {
          ...enUs,
        },
      },
      zh_cn: {
        translation: {
          ...zhCn,
        },
      },
    },
    lng: lng,
    fallbackLng: lng,
    interpolation: {
      escapeValue: false,
    },
  })
export default i18n
