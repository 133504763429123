import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { getFactoryAll } from "common/apis/maindata";
export const fetchFactoriesAsync = createAsyncThunk(
  "factory/fetchFactoriesAsync",
  () => axios.post(getFactoryAll())
    .then((response) => {
      // console.log(response?.data)
      return response?.data
    }).catch(e => {
      console.log(e)
    })
);

const factorySlice = createSlice({
  name: "factory",
  initialState: {
    factories: null,
  },
  reducers: {
    setFactories(state, action) {
      state.factories = action.payload
    },
  },
  extraReducers: {
    [fetchFactoriesAsync.pending]: (state, action) => {
      state.factories = null;
    },
    [fetchFactoriesAsync.fulfilled]: (state, action) => {
      state.factories = action.payload;
    },
  },
})

export const {
  setFactories,
} = factorySlice.actions
export const getFactories = (state) => state.factory.factories
export default factorySlice.reducer


