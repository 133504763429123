import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { getProject as apiGetProject, getCustomer as apiGetCustomer } from "common/apis/maindata"
import { tr } from "date-fns/locale"

export const fetchProjectAsync = createAsyncThunk("ezProject/fetchProjectAsync", async (id) =>
  axios
    .post(apiGetProject(id))
    .then((response) => {
      return response?.data
    })
    .catch((e) => {
      console.log(e)
    })
)
export const fetchCustomerAsync = createAsyncThunk("ezProject/fetchCustomerAsync", async (id) =>
  axios
    .post(apiGetCustomer(id))
    .then((response) => {
      return response?.data
    })
    .catch((e) => {
      console.log(e)
    })
)
const ezProjectSlice = createSlice({
  name: "ezProject",
  initialState: {
    isLoadingProject: false,
    project: null,
    isLoadingCustomer: false,
    customer: null,
  },
  reducers: {
    // setProject(state, action) {
    //   state.project = action.payload
    // },
    // setCustomer(state, action) {
    //   state.project = action.payload
    // },
  },
  extraReducers: {
    [fetchProjectAsync.pending]: (state, action) => {
      state.isLoadingProject = true
      state.project = null
    },
    [fetchProjectAsync.fulfilled]: (state, action) => {
      state.project = action.payload
      state.isLoadingProject = false
    },
    [fetchCustomerAsync.pending]: (state, action) => {
      state.isLoadingCustomer = true
      state.customer = null
    },
    [fetchCustomerAsync.fulfilled]: (state, action) => {
      state.customer = action.payload
      state.isLoadingCustomer = false
    },
  },
})

export const {  } = ezProjectSlice.actions
export const getProject = (state) => state.ezProject.project
export const getIsLoadingProject = (state) => state.ezProject.isLoadingProject
export const getCustomer = (state) => state.ezProject.customer
export const getIsLoadingCustomer = (state) => state.ezProject.isLoadingCustomer
export default ezProjectSlice.reducer
