import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
// import { getUserInfo, moduleSettings } from "common/endpoints";
// import { getUserByUserId, moduleSettings } from "common/endpoints";

// export const fetchUserProfileAsync = createAsyncThunk(
//   "userProfile/fetchUserProfile",
//   (userId) => axios.get(getUserByUserId(userId))
//     .then((response) => {
//       // console.log(response?.data)
//       return response?.data
//     }).catch(e => {
//       console.log(e)
//     })
// );
// export const fetchUserSettingAsync = createAsyncThunk(
//   "userProfile/fetchUserSetting",
//   (userId) => axios.get(moduleSettings('user', userId)).then((response) => {
//     const strSettings = response?.data?.data?.value
//     let _settings = {};
//     try {
//       _settings = strSettings ? JSON.parse(strSettings) : {}
//       console.log(_settings)
//     }
//     catch (e) { console.error('Invalid setting') }
//     return _settings
//   })
// );

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    isLoadingUserProfile: false,
    currentUser: null,
    userSetting: null,
    isAuthenticated: null,
  },
  reducers: {
    setUserTimezone(state, action) {
      state.currentUser = action.payload
    },
    updateUserProfile(state, action) {
      // console.log('updateUserProfile')
      // console.log(action.payload)
      let userprofile = action.payload
      if (userprofile) {
        userprofile.timeZone = "+08:00"
        state.currentUser = userprofile
        state.isAuthenticated = true
      } else {
        state.isAuthenticated = false
      }
    },
    updateUserSetting(state, action) {
      state.userSetting = action.payload ?? {}
    },
    userAcceptTerms(state, action) {
      state.currentUser = action.payload
    },
  },
  extraReducers: {
    // [fetchUserProfileAsync.pending]: (state, action) => {
    //   state.isLoadingUserProfile = true;
    //   state.currentUser = null;
    //   state.userSetting = null;
    // },
    // [fetchUserProfileAsync.fulfilled]: (state, action) => {
    //   state.isLoadingUserProfile = false;
    //   const data = action.payload?.data
    //   if (!data || action.payload?.result === 32) {
    //     state.isAuthenticated = false;
    //   } else {
    //     state.currentUser = data;
    //     state.isAuthenticated = true;
    //   }
    // },
    // [fetchUserSettingAsync.pending]: (state, action) => {
    //   state.userSetting = null;
    // },
    // [fetchUserSettingAsync.fulfilled]: (state, action) => {
    //   state.userSetting = action.payload;
    // },
  },
})

export const {
  setUserTimezone,
  updateUserProfile,
  updateUserSetting,
  userAcceptTerms,
} = userProfileSlice.actions
export const getIsLoadingUserProfile = (state) =>
  state.userProfile.isLoadingUserProfile
export const getUserProfile = (state) => state.userProfile.currentUser
export const getUserSetting = (state) => state.userProfile.userSetting
export const getIsAuthenticated = (state) => state.userProfile.isAuthenticated

export default userProfileSlice.reducer
