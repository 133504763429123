import { useParam } from 'hooks/useParam'
import React, { useEffect } from 'react'
import { TotalReport } from './components/TotalReport'
import { SingleReport } from './components/SingleReport'
import { getReportView } from 'common/apis/ezreport'
import axios from 'axios'
import { useState } from 'react'
import { Button } from '@abb/abb-common-ux-react'
import { useSelector } from 'react-redux'
import { getUserProfile } from 'slices/userProfileSlice'
import { getPermissions } from 'slices/abpApplicationConfigurationSlice'
import { approveSingleReport, generateSingleReport, submitSingleReport } from 'common/apis/singlereports'
import { CommonPrompt, CommonPromptButtons } from 'components/LoongInCommon/CommonPrompt'
import { CommonConfirm } from 'components/LoongInCommon'
import { t } from 'i18next'
import { getEnv } from 'slices/envSlice'
import { downloadFile } from 'common/apis/maindata'
export default function SingleReportView({ detail, onRefresh }) {
  const id = useParam('id')
  const env = useSelector(getEnv)

  const userProfile = useSelector(getUserProfile)
  const permissions = useSelector(getPermissions)
  const [singleReportApprovl, setSingleReportApprovl] = useState(null)
  const [singleReportGenerate, setSingleReportGenerate] = useState(null)
  const [singleReportSubmit, setSingleReportSubmit] = useState(null)
  const [singleReportBack, setSingleReportBack] = useState(null)
  const [isSingleReportLoading, setIsSingleReportLoading] = useState(false)
  const [isSingleGenerating, setIsSingleGenerating] = useState(false)
  const [isSingleSubmiting, setIsSingleSubmiting] = useState(false)
  const [isSingleBacking, setIsSingleBacking] = useState(false)
  const onSingleReportApprovl = (value, result) => {
    if (result === CommonPromptButtons.Yes || result === CommonPromptButtons.No) {
      setIsSingleReportLoading(true)
      axios.post(approveSingleReport(singleReportApprovl.id), { status: result === CommonPromptButtons.Yes ? 2 : -1, suggestion: value }).then(() => {
        setIsSingleReportLoading(false)
        setSingleReportApprovl(null)
        setTimeout(() => onRefresh && onRefresh(), 500)
      })
    } else {
      setSingleReportApprovl(null)
    }
  }

  const onSingleReportSubmit = () => {
    setIsSingleSubmiting(true)
    axios.post(submitSingleReport(singleReportSubmit.id), { status: 1, suggestion: '' }).then(() => {
      setIsSingleSubmiting(false)
      setSingleReportSubmit(null)
      setTimeout(() => onRefresh && onRefresh(), 500)
    })
  }

  const onSingleReportBack = () => {
    setIsSingleBacking(true)
    axios.post(submitSingleReport(singleReportBack.id), { status: -2, suggestion: '' }).then(() => {
      setIsSingleBacking(false)
      setSingleReportBack(null)
      setTimeout(() => onRefresh && onRefresh(), 500)
    })
  }

  const onSingleReportGenerate = () => {
    setIsSingleGenerating(true)
    axios.post(generateSingleReport(singleReportGenerate.id), {}).then(() => {
      setIsSingleGenerating(false)
      setSingleReportGenerate(null)
      setTimeout(() => onRefresh && onRefresh(), 500)
    })
  }
  const hasPermission = (permission) => {
    return !permissions || !permission || (permission in permissions && permissions[permission])
  }
  return (
    <div style={{ width: '100%', overflow: 'visible', display: 'flex', flexDirection: 'column', backgroundColor: '#fff' }}>
      {detail && detail.type === 'SingleReport' && (
        <>
          <SingleReport report={detail} readonly={true} />
          <div style={{ display: 'flex', flexDirection: 'row-reverse', padding: 8 }}>
            {detail.status <= 0 && detail.manager === userProfile.id && (
              <Button text='提交' onClick={() => {
                setSingleReportSubmit(detail)
              }} sizeClass='small' type='primary-blue' style={{ margin: 8 }} />
            )}
            {detail.status >= 0 && detail.manager === userProfile.id && (
              <Button text='退回' onClick={() => {
                setSingleReportBack(detail)
              }} sizeClass='small' type='primary-red' style={{ margin: 8 }} />
            )}
            {detail.status ===1 && detail.inScope && hasPermission('EzReport.SingleReport.Approve') && (
              <Button text='审核' onClick={() => {
                setSingleReportApprovl(detail)
              }} sizeClass='small' type='primary-blue' style={{ margin: 8 }} />
            )}
            {detail.status ===2 && detail.manager === userProfile.id && hasPermission('EzReport.SingleReport.Generate') && (
              <Button text='生成' onClick={() => {
                setSingleReportGenerate(detail)
              }} sizeClass='small' type='primary-blue' style={{ margin: 8 }} />
            )}
            {detail.status ===4 && detail.file && (
              <Button text='下载' onClick={() => {
                window.open(`${env.apiBase + (env.apiBase.endsWith('/') ? '' : '/')}${downloadFile()}?id=${detail.file}`, '_blank')
              }} sizeClass='small' type='primary-black' style={{ margin: 8 }} />
            )}
          </div>
        </>
      )}
      <CommonPrompt
        title={'审核单体报告'}
        content={'请输入您的审核意见'}
        isVisible={singleReportApprovl != null}
        yesText={'批准'}
        noText={'拒绝'}
        buttons={CommonPromptButtons.Yes | CommonPromptButtons.No | CommonPromptButtons.Cancel}
        onClose={onSingleReportApprovl}
        onCancel={()=>setSingleReportApprovl(null)}
        isLoading={isSingleReportLoading}
      />
      <CommonConfirm
        title={'生成报告'}
        content={'是否确定要生成单体报告？'}
        isVisible={singleReportGenerate != null}
        onConfirm={onSingleReportGenerate}
        confirmText={t('b_confirm')}
        onCancel={() => setSingleReportGenerate(null)}
        isLoading={isSingleGenerating}
      />
      <CommonConfirm
        title={'提交报告'}
        content={'是否确定要提交单体报告？'}
        isVisible={singleReportSubmit != null}
        onConfirm={onSingleReportSubmit}
        confirmText={t('b_confirm')}
        onCancel={() => setSingleReportSubmit(null)}
        isLoading={isSingleSubmiting}
      />
      <CommonConfirm
        title={'退回报告'}
        content={'是否确定要退回单体报告？'}
        isVisible={singleReportBack != null}
        onConfirm={onSingleReportBack}
        confirmText={t('b_confirm')}
        onCancel={() => setSingleReportBack(null)}
        isLoading={isSingleBacking}
      />
    </div>
  )
}
