import React, { useState, useRef, useEffect } from "react"
import { WithTooltip, Tooltip } from "@abb/abb-common-ux-react"
import { getBannersPage, deleteBanner, getApplicationAll } from "common/apis/maindata"
import { CommonIndex } from "components/LoongInCommon"
import { FormEdit } from "./FormEdit"
import axios from "axios"


export default function Banners() {
    const [deletedItems, setDeletedItems] = useState([])
    const refIndex = useRef(null)
    const [appList, setAppList] = useState(null)
    useEffect(() => {
        axios.post(getApplicationAll()).then(resp => {
            setAppList(resp.data)
        })
    }, [])
    //列定义
    const columns = [
        {
            title: "banner.title",
            key: "title",
            //通过渲染的方式将内容渲染出来
            render: (item, index) => {
                const isDeleted = deletedItems.indexOf(item.id) >= 0
                if (isDeleted)
                    return (
                        <WithTooltip>
                            <del>{item.title}</del>
                            <Tooltip>
                                <div>轮播图已被删除</div>
                            </Tooltip>
                        </WithTooltip>
                    )
                else return item.title
            },
        },
        {
            title: "banner.application",
            key: "application",
            render: (item) => {
                if (appList) {
                    return appList.find(o => o.id == item?.application)?.name
                }
            }
        },
        {
            title: "banner.url",
            key: "url"
        }
    ]

    //删除时放入删除项
    const onDeleteData = (deleteId) => {
        setDeletedItems([...deletedItems, deleteId])
    }
    //返回页面
    return (
        <>
            <CommonIndex
                ref={refIndex}
                columns={columns}
                getList={getBannersPage}
                deleteData={deleteBanner}
                FormEdit={FormEdit}
                canAdd
                canEdit
                canDelete
                onDeleteData={onDeleteData}
                enableSorting={true}
                extraData={{ appList }}
            />
        </>
    )
}
