import React, { useState, useEffect } from "react";


export default function MaterialDoc() {
  

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);
  const onWindowResize = () => {
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="widget">
        <div className="widget-header">
          <div className="widget-title">物料相关文档</div>

        </div>
        <div className="widget-body device-profile-details">
          
        </div>
      </div>
    </div>
  );
}
