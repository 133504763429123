import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getDevice, getParent, getDeviceType, getStation } from 'slices/deviceSlice'
import { getFactories } from 'slices/factorySlice'
import { Table, Tooltip, WithTooltip, Button } from '@abb/abb-common-ux-react'
import { nanoid } from 'nanoid'
import { t } from 'i18next'
import moment from 'moment'
import { SlidingCard } from 'components/SlidingCard'
import axios from 'axios'
import { getTestModals } from 'common/apis/ezreport'
import '../../../styles/singleReport.scss'
import './style.scss'
import { SearchModal } from './SearchModal'
import { useRef } from 'react'
export default function TestModal({ item, onEdit }) {
  const [modal, setModal] = useState(null)
  const devSearchRef = useRef(null)

  useEffect(() => {
    if (item && item.type === 'testItem') {
      fetchTestModal()
    }
  }, [item])

  const fetchTestModal = () => {
    console.log(item)
    axios.post(getTestModals(), { pageIndex: 1, pageSize: 1, testItemId: item.key }).then((r) => {
      const data = r?.data?.items?.length > 0 ? r.data.items[0] : null
      setModal(data?.modal)
      console.log(data)
    })
  }

  const renderPreviewTestItem = () => {
    if (!modal) {
      return null
    }
    console.log(modal)
    return (
      <table key={nanoid()} className='center '>
        {renderPreviewTestModal(JSON.parse(modal).content)}
      </table>
    )
  }

  const renderPreviewTestModal = (modal) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderPreviewTestModal(m.componments)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderPreviewTestModal(m.componments)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderPreviewTestModal(m.componments)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderPreviewTestModal(m.componments)}
            </td>
          )
        }
        if (m.type === 'input' || m.type === 'image') {
          return (
            <>
              {m.label} {m.name}
            </>
          )
        }
        if (m.type === 'option') {
          return (
            <>
              <input type='checkbox' disabled={true} />
              {m.name}
            </>
          )
        }
        if (m.type === 'editonly') {
          return ''
        }
        return <>{renderPreviewTestModal(m.componments)}</>
      })
    )
  }

  const renderInputPreviewTestItem = () => {
    if (!modal) {
      return null
    }
    console.log(modal)
    return (
      <table key={nanoid()} className='center '>
        {renderInputPreviewTestModal(JSON.parse(modal).content)}
      </table>
    )
  }
  const renderInputPreviewTestModal = (modal) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderInputPreviewTestModal(m.componments)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderInputPreviewTestModal(m.componments)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderInputPreviewTestModal(m.componments)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderInputPreviewTestModal(m.componments)}
            </td>
          )
        }
        if (m.type === 'input') {
          return (
            <>
              <span>{m.label} </span>
              <input {...m.attrs} type='text' disabled={true} name={m.name} placeholder={m.name} />
            </>
          )
        }
        if (m.type === 'buttons') {
          return (
            <>
              {m.options.map((o) => {
                return <input type='radio' name={m.name} disabled={true} value={o.key} style={{ '--title': `'${o.title}'` }} />
              })}
              <br />
              {m.name}
            </>
          )
        }
        if (m.type === 'noedit') {
          return ''
        }
        return <>{renderInputPreviewTestModal(m.componments)}</>
      })
    )
  }
  return (
    <>
      <SlidingCard>
        <div
          className='single-report'
          style={{ overflow: 'scroll', height: 420 }}
          otherButton={[
            <Button
              key={nanoid()}
              sizeClass='small'
              shape='pill'
              type='discreet-black'
              icon='abb/plus'
              onClick={() => devSearchRef.current.showDialog()}
              style={{ opacity: 1, marginRight: 8 }}
            />,
          ]}
          title='模型预览'
        >
          <ul className='content' data-order='0'>
            <li key={nanoid()} id='expCircuitvoltage' className='li_exp'>
              {modal && renderPreviewTestItem()}
            </li>
          </ul>
        </div>
      </SlidingCard>
      <SearchModal itemId={item.key} ref={devSearchRef} onRefresh={() => {fetchTestModal()}} />
    </>
  )
}
