import React, { useState, useEffect } from "react"
import { Route, useRouteMatch, useHistory } from "react-router-dom"
import { Details } from "./Details"
import { getCustomerAll } from "common/apis/maindata"
import { ResponseResult } from "common/request"
import { useDispatch } from "react-redux"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"
import { Dropdown, DropdownOption, Input, Dialog, ToggleSwitch, Button } from "@abb/abb-common-ux-react"
import ClientSelector from "./ClientSelector"
import "./Clients.scss"
import axios from "axios"

import { useMountedState } from "common/useMountedState"
import { useCurrentTenant } from "hooks/useCurrentTenant"

export default function Clients() {
  const isMounted = useMountedState()
  const dispatch = useDispatch()
  const history = useHistory()
  const { path } = useRouteMatch()
  const [clients, setClients] = useState(null)
  const [isLoadingClients, setIsLoadingClients] = useState(false)
  const currentTenant = useCurrentTenant()
  useEffect(async () => {
    if (!currentTenant.isTenant) {
      setIsLoadingClients(true)
      const resp = await axios
        .post(getCustomerAll())
        .then((resp) => {
          setClients(resp.data)
        })
        .catch((e) => {})

      setIsLoadingClients(false)
    }
  }, [])

  return (
    <>
      <div className="admin-main-content" data-cy="admin-client-management">
        <Route path={[`${path}/:id`, path]}>
          {!currentTenant.isTenant && (<ClientSelector clients={clients ?? []} isLoading={isLoadingClients} />)}
          <Details />
        </Route>
      </div>
    </>
  )
}
