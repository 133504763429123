import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonTree } from 'components/LoongInCommon'
// import { Checkbox } from "@abb/abb-common-ux-react"
// import { CommonRule } from "components/LoongInCommon"
// import { fetchGetAllRoleAsync, getAllRoles } from "slices/roleSlice"
import axios from 'axios'
// import { useDispatch, useSelector } from "react-redux"
import { getDeviceTypes } from 'common/apis/maindata'
import { t } from 'i18next'
// import {  getClientHeight,} from "slices/appSlice"

export const LeftPanel = forwardRef(({ children, onActive }, ref) => {
  const treeRef = useRef(null)
  //组织架构树的数据
  const [tree, setTree] = useState(null)
  //传递引用方法
  useImperativeHandle(ref, () => ({
    getDeviceTypesTree,
  }))

  // const myMenu = [
  //   {
  //     title: t("b_addChild"),
  //     onClick: (item) => {
  //       onAdd && onAdd(item)
  //     },
  //   },
  //   {
  //     title: t("b_edit"),
  //     color: "blue",
  //     onClick: (item) => {
  //       onEdit && onEdit(item)
  //     },
  //   },
  //   {
  //     title: t("b_del"),
  //     color: "red",
  //     onClick: (item) => {
  //       onDel && onDel(item)
  //     },
  //   },
  // ]
  const expandAll = () => {
    // if (treeRef && treeRef.current) {
    //   treeRef.current.expandAll()
    // } else {
    //   const intev = setInterval(() => {
    //     if (treeRef && treeRef.current) {
    //       clearInterval(intev)
    //       treeRef.current.expandAll()
    //     }
    //   }, 100)
    // }
  }
  //调用接口获取权限树
  const getDeviceTypesTree = async (roleName) => {
    try {
      const response = await axios.post(getDeviceTypes(), {})
      if (response.status === 200) {
        if (response.data) {
          setTree(response.data)
          // expandAll()
        }
      } else {
        console.log('error to get tree grant')
      }
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    getDeviceTypesTree()
  }, [])
  //返回页面
  return (
    <>
      <CommonTree
        ref={treeRef}
        items={tree}
        leafOnly={true}
        // menu={myMenu}
        onActive={onActive}
      />
    </>
  )
})
