import React, { useState, useEffect } from "react";
import {
    Button, Dropdown,
    DropdownOption
} from "@abb/abb-common-ux-react";
import SidePanel from "components/SidePanel";
import "./shortcut.scss"
import device from '../../common/device';
const phoneModels = [
    { key: 'iphone', label: 'iPhone', value: "iphone" },
    { key: 'huawei', label: '华为', value: "huawei" },
    { key: 'xiaomi', label: '小米', value: "xiaomi" },
    { key: 'oppo', label: 'OPPO', value: "oppo" },
    { key: 'vivo', label: 'VIVO', value: "vivo" },
    { key: 'samsung', label: '三星', value: "samsung" },
]
const defaultImageUrl = '/'
export default function ShortcutHelpPanel({ isShow, onClose }) {

    const [model, setModel] = useState([])
    useEffect(() => {
        if (isShow) {
            let pm = device.getPhoneModel()
            if (pm) {
                let phone = phoneModels.find(m => m.key === pm)
                phone && setModel([phone])
            }
        }
    }, [isShow])
    useEffect(() => {
        model[0] && scrollToAnchor(model[0].value)
    }, [model])

    const scrollToAnchor = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementsByClassName(anchorName);
            if (anchorElement && anchorElement[0]) {
                anchorElement[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
            }
        }
    }
    if (isShow)
        return (
            <SidePanel show={isShow} onClickBackdrop={onClose}>
                <div className="shortcut-help">
                    <div className="header">
                        <Dropdown
                            value={model}
                            onChange={(val) => setModel(val)}
                            style={{ width: '100%' }}>
                            {phoneModels.map(m => (
                                <DropdownOption key={m.key} value={m.value} label={m.label} />
                            ))}
                        </Dropdown>
                        <Button icon="abb/close" sizeClass="small" type="discreet-black" onClick={onClose} />
                    </div>
                    <div className="body">
                        <div className="widget iphone">
                            <div className="title">
                                <div className="title-text"> iPhone 手机如何添加网页书签到桌面:</div>
                                <Button icon="abb/close" sizeClass="small" type="discreet-black" onClick={onClose} />
                            </div>
                            <div className="content">
                                <p>需使用浏览器打开站点。</p>
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/iphone.png`} />
                                </div>
                            </div>

                        </div>

                        <div className="widget huawei">
                            <div className="title">
                                <div className="title-text"> 华为 手机如何添加网页书签到桌面:</div>
                                <Button icon="abb/close" sizeClass="small" type="discreet-black" onClick={onClose} />
                            </div>
                            <div className="content">
                                <p>需使用浏览器打开站点。</p>
                                1. 点击右下角图标。
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/huawei_1.jpg`} />
                                </div>
                                2. 点击添加至图标。
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/huawei_2.jpg`} />
                                </div>
                                3. 选择桌面后确认即可
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/huawei_3.jpg`} />
                                </div>

                            </div>

                        </div>
                        <div className="widget xiaomi">
                            <div className="title">
                                <div className="title-text">小米 手机如何添加网页书签到桌面: </div>
                                <Button icon="abb/close" sizeClass="small" type="discreet-black" onClick={onClose} />
                            </div>
                            <div className="content">
                                <p>需使用浏览器打开站点。</p>
                                1. 点击右下角图标菜单
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/xiaomi_1.jpg`} />
                                </div>
                                2. 上划显示更多菜单
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/xiaomi_2.jpg`} />
                                </div>
                                3. 点击添加到桌面
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/xiaomi_3.jpg`} />
                                </div>
                                4. 点击确认即可
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/xiaomi_4.jpg`} />
                                </div>

                            </div>

                        </div>


                        <div className="widget oppo">
                            <div className="title">
                                <div className="title-text">OPPO 手机如何添加网页书签到桌面: </div>
                                <Button icon="abb/close" sizeClass="small" type="discreet-black" onClick={onClose} />
                            </div>
                            <div className="content">
                                <p>需使用浏览器打开站点。</p>
                                1. 点击下方工具栏图标。
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/oppo_1.jpg`} />
                                </div>
                                2. 点击添加书签。
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/oppo_2.jpg`} />
                                </div>
                                3. 选择桌面书签后点击完成即可
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/oppo_3.jpg`} />
                                </div>

                            </div>

                        </div>
                        <div className="widget vivo">
                            <div className="title">
                                <div className="title-text"> VIVO 手机如何添加网页书签到桌面: </div>
                                <Button icon="abb/close" sizeClass="small" type="discreet-black" onClick={onClose} />
                            </div>
                            <div className="content">
                                <p>需使用浏览器打开站点。</p>
                                1. 点击下方工具栏图标。
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/vivo_1.jpg`} />
                                </div>
                                2. 点击添加收藏。
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/vivo_2.jpg`} />
                                </div>
                                3. 进入收藏/历史页面,长按对应站点并点击发送至手机桌面即可
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/vivo_3.jpg`} />
                                </div>

                            </div>

                        </div>
                        <div className="widget samsung">
                            <div className="title">
                                <div className="title-text">三星 手机如何添加网页书签到桌面: </div>
                                <Button icon="abb/close" sizeClass="small" type="discreet-black" onClick={onClose} />
                            </div>
                            <div className="content">
                                <p>需使用浏览器打开站点。</p>
                                1. 点击右下角图标菜单
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/samsung_1.jpg`} />
                                </div>
                                2. 点击添加页面至
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/samsung_2.jpg`} />
                                </div>
                                3. 点击选择主屏幕
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/samsung_3.jpg`} />
                                </div>
                                4. 点击添加即可
                                <div className="image">
                                    <img src={`${defaultImageUrl}shortcut/samsung_4.jpg`} />
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </SidePanel>
        )
    else
        return (<></>)
}
