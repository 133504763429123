import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'
import { useSelector } from 'react-redux'
import { createMaintenanceCatalog, updateMaintenanceCatalog } from 'common/apis/ezreport'
import { useDataDictionary } from 'hooks/useDataDictionary'

export const MaintenanceCatalogFormEdit = forwardRef(({ id, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  //初始化数据
  const dt = useDataDictionary('DeviceType')
  const initData = {
    name: '',
    deviceType: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: '区域名称',
      fieldName: 'name',
      maxLength: 128,
    },
    {
      type: 'dropdown',
      dataType: 'text',
      label: '设备类别',
      fieldName: 'deviceType',
      options: dt
        ? dt.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
    },
  ]

  //表单的校验规则
  const rules = {
    name: [CommonRule.Require],
    deviceType: [CommonRule.Require],
  }

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      projectType: '5',
      deviceType: inputdata.deviceType,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      name: inputdata.name,
      projectType: '5',
      deviceType: inputdata.deviceType,
    }
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title='维保区域信息'
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createMaintenanceCatalog}
      updateData={updateMaintenanceCatalog}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    />
  )
})
