import React from "react"

// import { Redirect } from "react-router-dom"
import { Icon } from "@abb/abb-common-ux-react"
import { Row, Col } from "components/Grid"

export default () => {
  const icons = {
    "abb/add-user": {
      content: "e900",
    },
    "abb/alarm-bell": {
      content: "e901",
    },
    "abb/align-bottom": {
      content: "e902",
    },
    "abb/align-horizontal-center": {
      content: "e903",
    },
    "abb/align-left": {
      content: "e904",
    },
    "abb/align-right": {
      content: "e905",
    },
    "abb/align-top": {
      content: "e906",
    },
    "abb/align-vertical-center": {
      content: "e907",
    },
    "abb/analyze-document": {
      content: "e908",
    },
    "abb/anchor": {
      content: "e909",
    },
    "abb/audio-off": {
      content: "e90a",
    },
    "abb/audio-on": {
      content: "e90b",
    },
    "abb/background": {
      content: "e90c",
    },
    "abb/bar-chart": {
      content: "e90d",
    },
    "abb/battery-charging": {
      content: "e90e",
    },
    "abb/battery-empty": {
      content: "e90f",
    },
    "abb/battery-full": {
      content: "e910",
    },
    "abb/battery-half": {
      content: "e911",
    },
    "abb/battery-high": {
      content: "e912",
    },
    "abb/battery-low": {
      content: "e913",
    },
    "abb/binary-data": {
      content: "e914",
    },
    "abb/bluetooth": {
      content: "e915",
    },
    "abb/boolean": {
      content: "e916",
    },
    "abb/briefcase": {
      content: "e917",
    },
    "abb/bring-to-front": {
      content: "e918",
    },
    "abb/broadcast": {
      content: "e919",
    },
    "abb/calculator": {
      content: "e91a",
    },
    "abb/calendar": {
      content: "e91b",
    },
    "abb/call": {
      content: "e91c",
    },
    "abb/camera": {
      content: "e91d",
    },
    "abb/cart": {
      content: "e91e",
    },
    "abb/certificate-document-1": {
      content: "e91f",
    },
    "abb/certificate-document-2": {
      content: "e920",
    },
    "abb/chat": {
      content: "e921",
    },
    "abb/check-mark": {
      content: "e922",
    },
    "abb/check-mark-circle-1": {
      content: "e923",
    },
    "abb/check-mark-circle-2": {
      content: "e924",
    },
    "abb/close": {
      content: "e925",
    },
    "abb/comment": {
      content: "e926",
    },
    "abb/computer": {
      content: "e927",
    },
    "abb/connect": {
      content: "e928",
    },
    "abb/controller-cabinet": {
      content: "e929",
    },
    "abb/controls-and-settings": {
      content: "e92a",
    },
    "abb/copy": {
      content: "e92b",
    },
    "abb/cut": {
      content: "e92c",
    },
    "abb/dashboard": {
      content: "e92d",
    },
    "abb/database": {
      content: "e92e",
    },
    "abb/database-new": {
      content: "e92f",
    },
    "abb/decimal": {
      content: "e930",
    },
    "abb/devices": {
      content: "e931",
    },
    "abb/disconnect": {
      content: "e932",
    },
    "abb/distribution": {
      content: "e933",
    },
    "abb/document": {
      content: "e934",
    },
    "abb/dollar": {
      content: "e935",
    },
    "abb/double-down": {
      content: "e936",
    },
    "abb/double-left": {
      content: "e937",
    },
    "abb/double-right": {
      content: "e938",
    },
    "abb/double-up": {
      content: "e939",
    },
    "abb/down": {
      content: "e93a",
    },
    "abb/down-arrow": {
      content: "e93b",
    },
    "abb/download": {
      content: "e93c",
    },
    "abb/drive": {
      content: "e93d",
    },
    "abb/edit": {
      content: "e93e",
    },
    "abb/end-call": {
      content: "e93f",
    },
    "abb/envelope": {
      content: "e940",
    },
    "abb/error-circle-1": {
      content: "e941",
    },
    "abb/error-circle-2": {
      content: "e942",
    },
    "abb/euro": {
      content: "e943",
    },
    "abb/event": {
      content: "e944",
    },
    "abb/export": {
      content: "e945",
    },
    "abb/factory": {
      content: "e946",
    },
    "abb/filter": {
      content: "e947",
    },
    "abb/folder": {
      content: "e948",
    },
    "abb/folder-new": {
      content: "e949",
    },
    "abb/folder-open": {
      content: "e94a",
    },
    "abb/forward": {
      content: "e94b",
    },
    "abb/fullscreen": {
      content: "e94c",
    },
    "abb/gauge": {
      content: "e94d",
    },
    "abb/gbp": {
      content: "e94e",
    },
    "abb/globe": {
      content: "e94f",
    },
    "abb/group": {
      content: "e950",
    },
    "abb/help-circle-1": {
      content: "e951",
    },
    "abb/help-circle-2": {
      content: "e952",
    },
    "abb/hide": {
      content: "e953",
    },
    "abb/history": {
      content: "e954",
    },
    "abb/home": {
      content: "e955",
    },
    "abb/hour-glass": {
      content: "e956",
    },
    "abb/image": {
      content: "e957",
    },
    "abb/import": {
      content: "e958",
    },
    "abb/information-circle-1": {
      content: "e959",
    },
    "abb/information-circle-2": {
      content: "e95a",
    },
    "abb/key": {
      content: "e95b",
    },
    "abb/label": {
      content: "e95c",
    },
    "abb/left": {
      content: "e95d",
    },
    "abb/left-arrow": {
      content: "e95e",
    },
    "abb/level": {
      content: "e95f",
    },
    "abb/light-bulb": {
      content: "e960",
    },
    "abb/link": {
      content: "e961",
    },
    "abb/list": {
      content: "e962",
    },
    "abb/location": {
      content: "e963",
    },
    "abb/lock-closed": {
      content: "e964",
    },
    "abb/lock-open": {
      content: "e965",
    },
    "abb/log-in": {
      content: "e966",
    },
    "abb/log-out": {
      content: "e967",
    },
    "abb/magnet": {
      content: "e968",
    },
    "abb/make-same-height": {
      content: "e969",
    },
    "abb/make-same-size": {
      content: "e96a",
    },
    "abb/make-same-width": {
      content: "e96b",
    },
    "abb/map": {
      content: "e96c",
    },
    "abb/matrix": {
      content: "e96d",
    },
    "abb/maximize": {
      content: "e96e",
    },
    "abb/menu": {
      content: "e96f",
    },
    "abb/menu-narrow": {
      content: "e970",
    },
    "abb/microphone": {
      content: "e971",
    },
    "abb/microphone-off": {
      content: "e972",
    },
    "abb/minimize": {
      content: "e973",
    },
    "abb/minus": {
      content: "e974",
    },
    "abb/minus-in-circle": {
      content: "e975",
    },
    "abb/missed-call": {
      content: "e976",
    },
    "abb/moon": {
      content: "e977",
    },
    "abb/more": {
      content: "e978",
    },
    "abb/motor": {
      content: "e979",
    },
    "abb/network": {
      content: "e97a",
    },
    "abb/new-document": {
      content: "e97b",
    },
    "abb/numericals": {
      content: "e97c",
    },
    "abb/object": {
      content: "e97d",
    },
    "abb/object-container-1": {
      content: "e97e",
    },
    "abb/object-container-2": {
      content: "e97f",
    },
    "abb/open-in-new-window": {
      content: "e980",
    },
    "abb/panel": {
      content: "e981",
    },
    "abb/paper-clip": {
      content: "e982",
    },
    "abb/paste": {
      content: "e983",
    },
    "abb/pause": {
      content: "e984",
    },
    "abb/pie-chart": {
      content: "e985",
    },
    "abb/pin": {
      content: "e986",
    },
    "abb/play": {
      content: "e987",
    },
    "abb/plugin": {
      content: "e988",
    },
    "abb/plus": {
      content: "e989",
    },
    "abb/plus-in-circle": {
      content: "e98a",
    },
    "abb/pop-out-window": {
      content: "e98b",
    },
    "abb/power-on-off": {
      content: "e98c",
    },
    "abb/pressure": {
      content: "e98d",
    },
    "abb/print": {
      content: "e98e",
    },
    "abb/pump": {
      content: "e98f",
    },
    "abb/qr-code": {
      content: "e990",
    },
    "abb/rearrange": {
      content: "e991",
    },
    "abb/redo": {
      content: "e992",
    },
    "abb/refresh": {
      content: "e993",
    },
    "abb/reports": {
      content: "e994",
    },
    "abb/resistor": {
      content: "e995",
    },
    "abb/rewind": {
      content: "e996",
    },
    "abb/right": {
      content: "e997",
    },
    "abb/right-arrow": {
      content: "e998",
    },
    "abb/robot": {
      content: "e999",
    },
    "abb/robot-cabinet": {
      content: "e99a",
    },
    "abb/robot-tool": {
      content: "e99b",
    },
    "abb/rotate-left-90": {
      content: "e99c",
    },
    "abb/rotate-left-180": {
      content: "e99d",
    },
    "abb/rotate-right-270": {
      content: "e99f",
    },
    "abb/rotate-left-270": {
      content: "e99e",
    },
    "abb/rotate-right-90": {
      content: "e9a0",
    },
    "abb/rotate-right-180": {
      content: "e9a1",
    },
    "abb/save": {
      content: "e9a2",
    },
    "abb/save-as": {
      content: "e9a3",
    },
    "abb/screen": {
      content: "e9a4",
    },
    "abb/search": {
      content: "e9a5",
    },
    "abb/send-to-back": {
      content: "e9a6",
    },
    "abb/server": {
      content: "e9a7",
    },
    "abb/settings": {
      content: "e9a8",
    },
    "abb/share": {
      content: "e9a9",
    },
    "abb/shortcut": {
      content: "e9aa",
    },
    "abb/shuffle": {
      content: "e9ab",
    },
    "abb/skip-to-end": {
      content: "e9ac",
    },
    "abb/skip-to-start": {
      content: "e9ad",
    },
    "abb/sld-1": {
      content: "e9ae",
    },
    "abb/sld-2": {
      content: "e9af",
    },
    "abb/smartphone": {
      content: "e9b0",
    },
    "abb/sort-alphabet-down": {
      content: "e9b1",
    },
    "abb/sort-alphabet-up": {
      content: "e9b2",
    },
    "abb/sort-date-down-1": {
      content: "e9b3",
    },
    "abb/sort-date-down-2": {
      content: "e9b4",
    },
    "abb/sort-date-up-1": {
      content: "e9b5",
    },
    "abb/sort-date-up-2": {
      content: "e9b6",
    },
    "abb/specification": {
      content: "e9b7",
    },
    "abb/stairs": {
      content: "e9b8",
    },
    "abb/star": {
      content: "e9b9",
    },
    "abb/stop": {
      content: "e9ba",
    },
    "abb/sun": {
      content: "e9bb",
    },
    "abb/sun-2": {
      content: "e9bc",
    },
    "abb/synchronize": {
      content: "e9bd",
    },
    "abb/system": {
      content: "e9be",
    },
    "abb/table": {
      content: "e9bf",
    },
    "abb/temperature": {
      content: "e9c0",
    },
    "abb/temperature-celsius-1": {
      content: "e9c1",
    },
    "abb/temperature-celsius-2": {
      content: "e9c2",
    },
    "abb/temperature-fahrenheit-1": {
      content: "e9c3",
    },
    "abb/temperature-fahrenheit-2": {
      content: "e9c4",
    },
    "abb/template": {
      content: "e9c5",
    },
    "abb/template-new": {
      content: "e9c6",
    },
    "abb/test": {
      content: "e9c7",
    },
    "abb/text": {
      content: "e9c8",
    },
    "abb/time": {
      content: "e9c9",
    },
    "abb/time-and-date": {
      content: "e9ca",
    },
    "abb/track-1": {
      content: "e9cb",
    },
    "abb/track-2": {
      content: "e9cc",
    },
    "abb/transformer": {
      content: "e9cd",
    },
    "abb/trash": {
      content: "e9ce",
    },
    "abb/tree-view": {
      content: "e9cf",
    },
    "abb/trend-1": {
      content: "e9d0",
    },
    "abb/trend-2": {
      content: "e9d1",
    },
    "abb/turbocharger": {
      content: "e9d2",
    },
    "abb/undo": {
      content: "e9d3",
    },
    "abb/unpin": {
      content: "e9d4",
    },
    "abb/up": {
      content: "e9d5",
    },
    "abb/up-arrow": {
      content: "e9d6",
    },
    "abb/upload": {
      content: "e9d7",
    },
    "abb/usb": {
      content: "e9d8",
    },
    "abb/user": {
      content: "e9d9",
    },
    "abb/user-in-circle": {
      content: "e9da",
    },
    "abb/video-camera-off": {
      content: "e9db",
    },
    "abb/video-camera-on": {
      content: "e9dc",
    },
    "abb/video-chat": {
      content: "e9dd",
    },
    "abb/view": {
      content: "e9de",
    },
    "abb/voltage": {
      content: "e9df",
    },
    "abb/warning-circle-1": {
      content: "e9e0",
    },
    "abb/warning-circle-2": {
      content: "e9e1",
    },
    "abb/warning-triangle": {
      content: "e9e2",
    },
    "abb/wrench": {
      content: "e9e3",
    },
    "abb/zoom-in": {
      content: "e9e4",
    },
    "abb/zoom-out": {
      content: "e9e5",
    },
    "abb/trend-3": {
      content: "e9e6",
    },
    "abb/trend-scatter": {
      content: "e9e7",
    },
    "abb/sort-ascending": {
      content: "e9e8",
    },
    "abb/sort-descending": {
      content: "e9e9",
    },
    "abb/advanced-settings": {
      content: "e9ea",
    },
    "abb/align": {
      content: "e9eb",
    },
    "abb/robot-axis": {
      content: "e9ec",
    },
    "abb/calibration": {
      content: "e9ed",
    },
    "abb/connection": {
      content: "e9ee",
    },
    "abb/network-status": {
      content: "e9ef",
    },
    "abb/create-backup": {
      content: "e9f0",
    },
    "abb/data-editor": {
      content: "e9f1",
    },
    "abb/execution": {
      content: "e9f2",
    },
    "abb/hardware-devices": {
      content: "e9f3",
    },
    "abb/io-devices": {
      content: "e9f4",
    },
    "abb/joystick": {
      content: "e9f5",
    },
    "abb/lead-through": {
      content: "e9f6",
    },
    "abb/jog-linear": {
      content: "e9f7",
    },
    "abb/document-excel": {
      content: "e9f8",
    },
    "abb/document-pdf": {
      content: "e9f9",
    },
    "abb/document-word": {
      content: "e9fa",
    },
    "abb/modify-rapid-position": {
      content: "e9fb",
    },
    "abb/software-module": {
      content: "e9fc",
    },
    "abb/network-status-1": {
      content: "e9fe",
    },
    "abb/network-status-2": {
      content: "e9ff",
    },
    "abb/payload": {
      content: "ea00",
    },
    "abb/jog-planar": {
      content: "ea01",
    },
    "abb/software-program-and-modules": {
      content: "ea02",
    },
    "abb/software-program-editor": {
      content: "ea03",
    },
    "abb/software-program": {
      content: "ea04",
    },
    "abb/program-pointer": {
      content: "ea05",
    },
    "abb/rapid-tasks": {
      content: "ea06",
    },
    "abb/jog-reorient": {
      content: "ea07",
    },
    "abb/reset-user-data": {
      content: "ea09",
    },
    "abb/restore-backup": {
      content: "ea0a",
    },
    "abb/select": {
      content: "ea0b",
    },
    "abb/software-routins": {
      content: "ea0c",
    },
    "abb/network-signal-strength-level-1": {
      content: "ea0d",
    },
    "abb/network-signal-strength-level-2": {
      content: "ea0e",
    },
    "abb/network-signal-strength-level-3": {
      content: "ea0f",
    },
    "abb/io-signals": {
      content: "ea10",
    },
    "abb/software-resources": {
      content: "ea11",
    },
    "abb/start-installer": {
      content: "ea12",
    },
    "abb/step-in": {
      content: "ea13",
    },
    "abb/step-out": {
      content: "ea14",
    },
    "abb/step-over": {
      content: "ea15",
    },
    "abb/touch": {
      content: "ea16",
    },
    "abb/video": {
      content: "ea17",
    },
    "abb/turn-clockwise": {
      content: "ea18",
    },
    "abb/turn-clockwise-off-target-speed": {
      content: "ea19",
    },
    "abb/turn-counter-clockwise-off-target-speed": {
      content: "ea1a",
    },
    "abb/add-drives": {
      content: "ea1b",
    },
    "abb/bearing": {
      content: "ea1d",
    },
    "abb/cloud": {
      content: "ea1e",
    },
    "abb/delete-drives": {
      content: "ea1f",
    },
    "abb/download-from-cloud": {
      content: "ea20",
    },
    "abb/drive-connected": {
      content: "ea21",
    },
    "abb/drives-error": {
      content: "ea22",
    },
    "abb/fieldbus": {
      content: "ea23",
    },
    "abb/generator": {
      content: "ea24",
    },
    "abb/maximum-value": {
      content: "ea26",
    },
    "abb/minimum-value": {
      content: "ea27",
    },
    "abb/not-allowed": {
      content: "ea28",
    },
    "abb/powertrain": {
      content: "ea29",
    },
    "abb/prevent-turning-fault": {
      content: "ea2a",
    },
    "abb/target-value-range": {
      content: "ea2b",
    },
    "abb/thumbnail-view": {
      content: "ea2c",
    },
    "abb/train": {
      content: "ea2d",
    },
    "abb/turn-counter-clockwise": {
      content: "ea2e",
    },
    "abb/wheel": {
      content: "ea2f",
    },
    "abb/wifi-network-disconnected": {
      content: "ea31",
    },
    "abb/write-to-default": {
      content: "ea32",
    },
    "abb/write-to-default-disabled": {
      content: "ea33",
    },
    "abb/caret-down": {
      content: "ea08",
    },
    "abb/caret-left": {
      content: "ea1c",
    },
    "abb/caret-right": {
      content: "ea30",
    },
    "abb/caret-up": {
      content: "ea34",
    },
    "abb/triangle-down": {
      content: "ea36",
    },
    "abb/triangle-left": {
      content: "ea37",
    },
    "abb/triangle-right": {
      content: "ea38",
    },
    "abb/triangle-up": {
      content: "ea39",
    },
    "abb/check-mark-light": {
      content: "ea3a",
    },
    "abb/numbered-list": {
      content: "ea3b",
    },
    "abb/text-bold": {
      content: "ea3c",
    },
    "abb/text-italic": {
      content: "ea3d",
    },
    "abb/text-underline": {
      content: "ea3e",
    },
    "abb/call-outline": {
      content: "e9fd",
    },
    "abb/location-outline": {
      content: "ea25",
    },
    "abb/user-outline": {
      content: "ea3f",
    },
    "abb/close-light": {
      content: "ea35",
    },
    "abb/smart-gripper": {
      content: "ea43",
    },
    "abb/bookmark": {
      content: "f101",
    },
    "abb/tiles": {
      content: "f102",
    },
    "abb/edge": {
      content: "f103",
    },
    "abb/hierarchy": {
      content: "f104",
    },
    "abb/list-2": {
      content: "f105",
    },
  }

  const icons2 = [
    "abb/add-user",
    "abb/alarm-bell",
    "abb/align-bottom",
    "abb/align-horizontal-center",
    "abb/align-left",
    "abb/align-right",
    "abb/align-top",
    "abb/align-vertical-center",
    "abb/analyze-document",
    "abb/anchor",
    "abb/audio-off",
    "abb/audio-on",
    "abb/background",
    "abb/bar-chart",
    "abb/battery-charging",
    "abb/battery-empty",
    "abb/battery-full",
    "abb/battery-half",
    "abb/battery-high",
    "abb/battery-low",
    "abb/binary-data",
    "abb/bluetooth",
    "abb/boolean",
    "abb/briefcase",
    "abb/bring-to-front",
    "abb/broadcast",
    "abb/calculator",
    "abb/calendar",
    "abb/call",
    "abb/camera",
    "abb/cart",
    "abb/certificate-document-1",
    "abb/certificate-document-2",
    "abb/chat",
    "abb/check-mark",
    "abb/check-mark-circle-1",
    "abb/check-mark-circle-2",
    "abb/close",
    "abb/comment",
    "abb/computer",
    "abb/connect",
    "abb/controller-cabinet",
    "abb/controls-and-settings",
    "abb/copy",
    "abb/cut",
    "abb/dashboard",
    "abb/database",
    "abb/database-new",
    "abb/decimal",
    "abb/devices",
    "abb/disconnect",
    "abb/distribution",
    "abb/document",
    "abb/dollar",
    "abb/double-down",
    "abb/double-left",
    "abb/double-right",
    "abb/double-up",
    "abb/down",
    "abb/down-arrow",
    "abb/download",
    "abb/drive",
    "abb/edit",
    "abb/end-call",
    "abb/envelope",
    "abb/error-circle-1",
    "abb/error-circle-2",
    "abb/euro",
    "abb/event",
    "abb/export",
    "abb/factory",
    "abb/filter",
    "abb/folder",
    "abb/folder-new",
    "abb/folder-open",
    "abb/forward",
    "abb/fullscreen",
    "abb/gauge",
    "abb/gbp",
    "abb/globe",
    "abb/group",
    "abb/help-circle-1",
    "abb/help-circle-2",
    "abb/hide",
    "abb/history",
    "abb/home",
    "abb/hour-glass",
    "abb/image",
    "abb/import",
    "abb/information-circle-1",
    "abb/information-circle-2",
    "abb/key",
    "abb/label",
    "abb/left",
    "abb/left-arrow",
    "abb/level",
    "abb/light-bulb",
    "abb/link",
    "abb/list",
    "abb/location",
    "abb/lock-closed",
    "abb/lock-open",
    "abb/log-in",
    "abb/log-out",
    "abb/magnet",
    "abb/make-same-height",
    "abb/make-same-size",
    "abb/make-same-width",
    "abb/map",
    "abb/matrix",
    "abb/maximize",
    "abb/menu",
    "abb/menu-narrow",
    "abb/microphone",
    "abb/microphone-off",
    "abb/minimize",
    "abb/minus",
    "abb/minus-in-circle",
    "abb/missed-call",
    "abb/moon",
    "abb/more",
    "abb/motor",
    "abb/network",
    "abb/new-document",
    "abb/numericals",
    "abb/object",
    "abb/object-container-1",
    "abb/object-container-2",
    "abb/open-in-new-window",
    "abb/panel",
    "abb/paper-clip",
    "abb/paste",
    "abb/pause",
    "abb/pie-chart",
    "abb/pin",
    "abb/play",
    "abb/plugin",
    "abb/plus",
    "abb/plus-in-circle",
    "abb/pop-out-window",
    "abb/power-on-off",
    "abb/pressure",
    "abb/print",
    "abb/pump",
    "abb/qr-code",
    "abb/rearrange",
    "abb/redo",
    "abb/refresh",
    "abb/reports",
    "abb/resistor",
    "abb/rewind",
    "abb/right",
    "abb/right-arrow",
    "abb/robot",
    "abb/robot-cabinet",
    "abb/robot-tool",
    "abb/rotate-left-90",
    "abb/rotate-left-180",
    "abb/rotate-right-270",
    "abb/rotate-left-270",
    "abb/rotate-right-90",
    "abb/rotate-right-180",
    "abb/save",
    "abb/save-as",
    "abb/screen",
    "abb/search",
    "abb/send-to-back",
    "abb/server",
    "abb/settings",
    "abb/share",
    "abb/shortcut",
    "abb/shuffle",
    "abb/skip-to-end",
    "abb/skip-to-start",
    "abb/sld-1",
    "abb/sld-2",
    "abb/smartphone",
    "abb/sort-alphabet-down",
    "abb/sort-alphabet-up",
    "abb/sort-date-down-1",
    "abb/sort-date-down-2",
    "abb/sort-date-up-1",
    "abb/sort-date-up-2",
    "abb/specification",
    "abb/stairs",
    "abb/star",
    "abb/stop",
    "abb/sun",
    "abb/sun-2",
    "abb/synchronize",
    "abb/system",
    "abb/table",
    "abb/temperature",
    "abb/temperature-celsius-1",
    "abb/temperature-celsius-2",
    "abb/temperature-fahrenheit-1",
    "abb/temperature-fahrenheit-2",
    "abb/template",
    "abb/template-new",
    "abb/test",
    "abb/text",
    "abb/time",
    "abb/time-and-date",
    "abb/track-1",
    "abb/track-2",
    "abb/transformer",
    "abb/trash",
    "abb/tree-view",
    "abb/trend-1",
    "abb/trend-2",
    "abb/turbocharger",
    "abb/undo",
    "abb/unpin",
    "abb/up",
    "abb/up-arrow",
    "abb/upload",
    "abb/usb",
    "abb/user",
    "abb/user-in-circle",
    "abb/video-camera-off",
    "abb/video-camera-on",
    "abb/video-chat",
    "abb/view",
    "abb/voltage",
    "abb/warning-circle-1",
    "abb/warning-circle-2",
    "abb/warning-triangle",
    "abb/wrench",
    "abb/zoom-in",
    "abb/zoom-out",
    "abb/trend-3",
    "abb/trend-scatter",
    "abb/sort-ascending",
    "abb/sort-descending",
    "abb/advanced-settings",
    "abb/align",
    "abb/robot-axis",
    "abb/calibration",
    "abb/connection",
    "abb/network-status",
    "abb/create-backup",
    "abb/data-editor",
    "abb/execution",
    "abb/hardware-devices",
    "abb/io-devices",
    "abb/joystick",
    "abb/lead-through",
    "abb/jog-linear",
    "abb/document-excel",
    "abb/document-pdf",
    "abb/document-word",
    "abb/modify-rapid-position",
    "abb/software-module",
    "abb/network-status-1",
    "abb/network-status-2",
    "abb/payload",
    "abb/jog-planar",
    "abb/software-program-and-modules",
    "abb/software-program-editor",
    "abb/software-program",
    "abb/program-pointer",
    "abb/rapid-tasks",
    "abb/jog-reorient",
    "abb/reset-user-data",
    "abb/restore-backup",
    "abb/select",
    "abb/software-routins",
    "abb/network-signal-strength-level-1",
    "abb/network-signal-strength-level-2",
    "abb/network-signal-strength-level-3",
    "abb/io-signals",
    "abb/software-resources",
    "abb/start-installer",
    "abb/step-in",
    "abb/step-out",
    "abb/step-over",
    "abb/touch",
    "abb/video",
    "abb/turn-clockwise",
    "abb/turn-clockwise-off-target-speed",
    "abb/turn-counter-clockwise-off-target-speed",
    "abb/add-drives",
    "abb/bearing",
    "abb/cloud",
    "abb/delete-drives",
    "abb/download-from-cloud",
    "abb/drive-connected",
    "abb/drives-error",
    "abb/fieldbus",
    "abb/generator",
    "abb/maximum-value",
    "abb/minimum-value",
    "abb/not-allowed",
    "abb/powertrain",
    "abb/prevent-turning-fault",
    "abb/target-value-range",
    "abb/thumbnail-view",
    "abb/train",
    "abb/turn-counter-clockwise",
    "abb/wheel",
    "abb/wifi-network-disconnected",
    "abb/write-to-default",
    "abb/write-to-default-disabled",
    "abb/caret-down",
    "abb/caret-left",
    "abb/caret-right",
    "abb/caret-up",
    "abb/triangle-down",
    "abb/triangle-left",
    "abb/triangle-right",
    "abb/triangle-up",
    "abb/check-mark-light",
    "abb/numbered-list",
    "abb/text-bold",
    "abb/text-italic",
    "abb/text-underline",
    "abb/call-outline",
    "abb/location-outline",
    "abb/user-outline",
    "abb/close-light",
    "abb/smart-gripper",
    "abb/bookmark",
    "abb/tiles",
    "abb/edge",
    "abb/hierarchy",
    "abb/list-2",
  ]
  const getItems = (items, rowSize) => {
    let rows = []
    let row = []
    let index = 0
    for (const key in items) {
      const content = items[key].content
      row.push({ key, content })
      if (index % rowSize == rowSize - 1 || index == items.length - 1) {
        rows.push(row)
        row = []
      }
      index++
    }
    // for (let index = 0; index < items.length; index++) {
    //   const item = items[index]
    //   row.push(item)
    //   if (index % rowSize == rowSize - 1 || index == items.length - 1) {
    //     rows.push(row)
    //     row = []
    //   }
    // }
    // console.log(rows)
    return rows
  }
  // getItems(icons,12)
  return (
    <div className="admin-main-content with-padding">
      {icons &&
        getItems(icons, 12).map((row, rowIdx) => {
          return (
            <div key={"d_" + rowIdx} style={{ margin: "20px" }}>
              <Row>
                {row.map((item, itemIdx) => {
                  return (
                    <Col key={"c_" + rowIdx + "_" + itemIdx} span={2}>
                      <div style={{ textAlign: "center" }}>
                        <Icon name={item.key} sizeClass={"medium"} />
                        <div>{item.key}</div>
                        <div>{item.content}</div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          )
        })}
    </div>
  )
}
