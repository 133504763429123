// import { useSelector } from "react-redux"

export const getDataDictionaryDetail = (dictionary, code) => {
  let dt = dictionary ? dictionary.find((d) => d.code == code) : null
  if (!dt || !dt.details) dt = { details: [] }
  return dt
}
export const getDataDictionaryText = (dt, value, isEnabled) => {
  const category = dt?.details
    ?.filter((c) => c.isEnabled === undefined || c.isEnabled === null || isEnabled === undefined || c.isEnabled === isEnabled)
    ?.find((d) => d.code == value)
  return category?.displayText
}
export const getDataDictionaryItem = (dt, value, isEnabled) => {
  const category = dt?.details
    ?.filter((c) => c.isEnabled === undefined || c.isEnabled === null || isEnabled === undefined || c.isEnabled === isEnabled)
    ?.find((d) => d.code == value)
  return category
}
export const getDataDictionaryOption = (dt, isEnabled) => {
  const options = dt?.details
    ?.filter((c) => c.isEnabled === undefined || c.isEnabled === null || isEnabled === undefined || c.isEnabled === isEnabled)
    .sort((a, b) => {
      return a.order - b.order
    })
    .map((c) => {
      return { label: c.displayText, value: c.code }
    })
  return options ?? []
}
