import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { getAllRole } from "common/endpoints";


export const fetchGetAllRoleAsync = createAsyncThunk(
  "role/fetchGetAllRoleAsync",
  () => axios.post(getAllRole())
    .then((response) => {
      // console.log(response?.data)
      return response?.data.items
    }).catch(e => {
      console.log(e)
    })
);
const roleSlice = createSlice({
  name: "role",
  initialState: {
    allRoles: null,
  },
  reducers: {
    updateAllRoles(state, action) {
      let roles = action.payload
      if (roles) {
        state.allRoles = roles
      }
    },
  },
  extraReducers: {
    [fetchGetAllRoleAsync.pending]: (state, action) => {
      state.allRoles = null;
    },
    [fetchGetAllRoleAsync.fulfilled]: (state, action) => {
      state.allRoles = action.payload;
    },
  },
});

export const { updateAllRoles } = roleSlice.actions;
export const getAllRoles = (state) => state.role.allRoles;

export default roleSlice.reducer;
