import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react"
import { Checkbox } from "@abb/abb-common-ux-react"
import { CommonFormEdit } from "components/LoongInCommon"
import { CommonRule, CommonForm } from "components/LoongInCommon"
import { fetchGetApplicationsAsync, getApplications } from "slices/maindataSlice"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { createCustomer, updateCustomer, getCustomerApplications } from "common/apis/maindata"
import { t } from "i18next"
import { getClientHeight } from "slices/appSlice"
import { getDictionary } from "slices/dataDictionarySlice"
import { getDataDictionaryDetail } from "common/dataDictionary"
import { getUsers } from "slices/userSlice"
import { getOrganization, fetchGetOrganizationAsync } from "slices/organizationSlice"
import { treeMap } from "common/utils"
import { showNotification, NotificationType } from "components/Notification/notificationSlice"
export const FormEdit = forwardRef(({ id, formData, onSubmit, onCancel,event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  const adminFormEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const dispatch = useDispatch()
  const [rules, setRules] = useState({})
  const [adminRules, setAdminRules] = useState({})
  const dictionary = useSelector(getDictionary)
  const custCate = getDataDictionaryDetail(dictionary, "custCate")
  const srvArea = getDataDictionaryDetail(dictionary, "srvArea")
  const users = useSelector(getUsers)
  const organization = useSelector(getOrganization)

  useEffect(() => {
    if (!organization) {
      dispatch(fetchGetOrganizationAsync())
    }
  }, [organization])
  
  //初始化数据
  const initData = {
    name: "",
    category: "",
    manager: "",
    contact: "",
    phone: "",
    serviceUnitId: "",
    code: "",
    email: "",
    adminEmail: "",
    adminPassword: "",
    area: "",
    address: "",
  }
  //表单数据项
  const formItem = [
    {
      type: "text",
      dataType: "text",
      label: t("customer.name"),
      fieldName: "name",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: t("customer.code"),
      fieldName: "code",
      maxLength: 30,
    },
    {
      type: "dropdown",
      label: t("customer.category"),
      fieldName: "category",
      searchable:true,
      options: custCate
        ? custCate.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
    },
    {
      type: "dropdown",
      label: t("customer.manager"),
      fieldName: "manager",
      searchable:true,
      options: users
        ? users.map((c) => {
            return { label: c.name, value: c.id }
          })
        : [],
    },
    {
      type: "dropdown",
      label: t("customer.serviceUnitId"),
      fieldName: "serviceUnitId",
      searchable:true,
      options: organization
        ? treeMap(organization, (c) => {
            return { label: c.title, value: c.key }
          })
        : [],
    },
    {
      type: "dropdown",
      label: t("customer.area"),
      fieldName: "area",
      options: srvArea
        ? srvArea.details.map((c) => {
            return { label: c.displayText, value: c.code }
          })
        : [],
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: t("customer.contact"),
      fieldName: "contact",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: t("customer.phone"),
      fieldName: "phone",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: t("customer.email"),
      fieldName: "email",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: t("customer.address"),
      fieldName: "address",
      maxLength: 256,
    },
  ]
  const formAdminItem = [
    {
      type: "text",
      dataType: "text",
      label: t("customer.adminEmail"),
      fieldName: "adminEmail",
      maxLength: 256,
    },
    {
      type: "text",
      dataType: "text",
      label: t("customer.adminPassword"),
      fieldName: "adminPassword",
      maxLength: 30,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      category: [CommonRule.Require],
      manager: [CommonRule.Require],
      contact: [CommonRule.Require],
      phone: [CommonRule.Require],
      serviceUnitId: [CommonRule.Require],
      code: [CommonRule.Require],
    }
    setRules(newrules)
    const newAdminrules = {
      adminEmail: [CommonRule.Require],
      adminPassword: [CommonRule.Require],
    }
    setAdminRules(newAdminrules)
  }, [id])

  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    return {
      id: inputdata.id,
      name: inputdata.name,
      category: inputdata.category,
      manager: inputdata.manager,
      contact: inputdata.contact,
      phone: inputdata.phone,
      serviceUnitId: inputdata.serviceUnitId,
      code: inputdata.code,
      email: inputdata.email,
      adminEmail: inputdata.adminEmail,
      adminPassword: inputdata.adminPassword,
      area: inputdata.area,
      address: inputdata.address,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    let adminInput = {}
    adminFormEditRef.current.validate((valid, formData) => {
      if (valid) {
        //做其他校验，返回错误信息则返回
        adminInput = formData
      } else {
        dispatch(showNotification(NotificationType.Alarm, t("t_formError")))
        return
      }
    })
    return {
      name: inputdata.name,
      category: inputdata.category,
      manager: inputdata.manager,
      contact: inputdata.contact,
      phone: inputdata.phone,
      serviceUnitId: inputdata.serviceUnitId,
      code: inputdata.code,
      email: inputdata.email,
      adminEmail: adminInput.adminEmail,
      adminPassword: adminInput.adminPassword,
      area: inputdata.area,
      address: inputdata.address,
    }
  }

  
  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t("customer.formTitle")}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createCustomer}
      updateData={updateCustomer}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {!id && (
        <div title="管理员" style={{ height: clientHeight - 280 + "px" }}>
          <CommonForm initData={initData} ref={adminFormEditRef} rules={adminRules} formItem={formAdminItem}></CommonForm>
        </div>
      )}
    </CommonFormEdit>
  )
})
