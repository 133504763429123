import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Login from 'modules/Auth/Login'
import { fetchGetDataDictionaryAsync } from 'slices/dataDictionarySlice'
import { fetchGetUsersAsync } from 'slices/userSlice'
import { fetchAbpApplicationConfigurationAsync } from 'slices/abpApplicationConfigurationSlice'
import { useCurrentUser } from 'hooks/useCurrentUser'
import MfaLogin from 'modules/Auth/MfaLogin'

export default function Auth({ children }) {
  const [initialized,setInitialized] = useState(false)
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()

  const user = currentUser.getUser()
  if (user ) {
    if (!initialized && user.result.succeeded) {
      dispatch(fetchGetDataDictionaryAsync())
      dispatch(fetchGetUsersAsync())
      dispatch(fetchAbpApplicationConfigurationAsync())
      setInitialized(true)
    }
    if(user.result.requiresTwoFactor){
      return <MfaLogin/>
    }
  } else {
    return <Login />
  }

  return children
}
