import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Checkbox, Dialog, LoadingIndicator } from '@abb/abb-common-ux-react'
import { getTotalReportDetail } from 'common/apis/totalreports'
import '../../../../styles/totalReport.scss'

import axios from 'axios'
import moment from 'moment'
import { Image } from 'components/Image'
import envs from './enviroment.json'
import { useEffect } from 'react'
import * as echarts from 'echarts'
import { groupBy, sumBy } from 'lodash'
import { tr } from 'date-fns/locale'

export const TotalReport = forwardRef(({ report, readonly = false }, ref) => {
  const [detail, setDetail] = useState(null)
  const [problems, setProblems] = useState([])
  const charRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const totalStatus = [
    { key: -2, title: '已退回' },
    { key: -1, title: '已驳回' },
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  const totalStatusText = [
    { key: -2, title: '退回意见' },
    { key: -1, title: '驳回意见' },
  ]
  let index = 0
  const fetchPreviewInfo = (reportId) => {
    setIsLoading(true)
    axios.post(getTotalReportDetail(reportId)).then((response) => {
      const data = response.data
      data.report.summaryBackground =
        data.report.summaryBackground ??
        `${data.project?.customer.name}公司${
          data?.station?.name
        }变电站中压开关设备使用厦门ABB开关有限公司（以下简称ABB）20xx、20xx年设计制造的ZS1空气绝缘开关柜XX台和VD4型真空断路器XX台。
    按照${data.project?.customer.name}公司和ABB的合同约定，ABB在${moment(data?.project?.startDate).format('yyyy年MM月DD日')}-${moment(
          data?.project?.endDate
        ).format('yyyy年MM月DD日')}，对${
          data?.station?.name
        }变电站内0台 型开关柜/真空断路器进行维护保养作业，本次作业按照ABB ZS1开关柜/VD4型真空断路器维护作业指导执行。
    `
      data.report.summaryDiscovery =
        data.report.summaryDiscovery ??
        `系统环境：在本次维护保养过程中我们发现高压变电站的卫生环境良好，通风措施完善，开关柜整体运行良好，防尘密封良好，电缆沟干燥、无污水，地面平整。\r\n设备安全：变电站所有的开关设备五防装置结构可靠，动作正确，无闭锁失效状况；内部故障电弧压力释放板安装正确，压力释放通道在故障情况下将正确动作；开关设备门、盖板密封状况良好、可靠，电力电缆及控制电缆通道孔使用防火材料有效密封，可防止小动物进入设备引发故障；加热器按照设备使用要求，置于常投状态，可有效防凝露。\r\n设备可靠性：变电站辅助电源（控制电源、储能电源、加热器电源）电压幅值与额定电压一致，极性正确。有利于设备的可靠运行。开关柜外观完好，无破损，无锈蚀；除XX外，单体试验项目及系统试验项目结果未见异常。断路器机外观完好，机构经过润滑后动作灵活，无破坏型磨损；除XX外，机械特性试验参数合格，极柱真空度测试合格，整体良好；站内有（没有）应急备件，辅件（操作钥匙，服务手车，操作工具）完整；保护装置定值校验，动作可靠。\r\n设备问题及处理：检查及试验中发现了XX处重大安全隐患，XX处设备运行缺陷；发现问题中，除了XXX，其他的均已修复，确保其功能正确。`
      data.report.summarySecurity =
        data.report.summarySecurity ??
        `本次现场维护保养作业完全遵守${data.project?.customer.name}公司和ABB相关的安全作业要求，严格执行相关安全作业规程，整个作业过程中未发生任何未遂及以上的安全事故。`
      data.report.summaryConclusion =
        data.report.summaryConclusion ??
        `结论：本次首次有我司对${data?.station?.name}变电站的开关设备进行维护保养，设备运行已超过XX年。设备处于生命周期的XX期（XX期的说明描述），此次维保消除了XX处重大安全隐患及XX项设备运行缺陷，使设备恢复到较高的安全水平，具备良好的运行工况。在完成未处理事项及实施我司的建议措施后，我们完全相信设备可安全运行到下一个检修周期。`
      data.report.deviceMaintenanceState =
        data.report.deviceMaintenanceState ??
        `${data.project?.customer.name}公司${
          data?.station?.name
        }变电站使用的是ABB生产的ZS1开关成套设备，共XXX台。XX变电站备于XX年投入运行，XXX变电站备于XX年投入运行，XXX变电站备于XX年投入运行。设备为首次/第X次由ABB中压服务提供维护保养服务。
    ABB于${moment(data?.project?.startDate).format('yyyy年MM月DD日')}到${moment(data?.project?.endDate).format(
          'yyyy年MM月DD日'
        )}对站内0台开关设备进行全面的检修维护保养工作，现场工作内容包括：常规检查及试验，设备修复(现场有备件且可执行)和设备保养。`
      data.report.deviceStateScan =
        data.report.deviceStateScan ??
        `${data?.station?.name}变电站，开关柜整体运行环境良好，运行及安全制度完善；设备各项联锁功能动作可靠，无联锁失效现象；设备辅助回路电压正常，电动及手动操作设备未见异常；备品备件数量及类型能满足一般故障的维修需求，开关柜运行操作工具配置完善；运行人员经过专业培训，具备开关柜运行经验，运行制度完善。`
      data.report.cabinetWorkOtherAdvice =
        data.report.cabinetWorkOtherAdvice ??
        `1.${data?.station?.name}变电站开关设备运行超过XX年，设备处于生命周期的XX期（XX期的说明描述），按ABB全生命周期管理建议进行相关维护保养工作，提升设备运行的可靠性。\r\n2.${report?.station?.name}站，编号为XXX的断路器丝杆螺栓尽快进行更换维修。\r\n3.${report?.station?.name}变电站XX柜母线室压力释放板补充。\r\n4. 其他方面问题建议`
      data.report.undealedProblem =
        data.report.undealedProblem ??
        `1. ${data?.station?.name}站，编号为XXX的断路器丝杆螺栓断裂，无维修物料未处理。\r\n2.${data?.station?.name}变电站XX柜母线室压力释放板丢失需补充。\r\n为使开关设备保持良好运行状态，建议尽快完成未处理问题的修复。`
      data.report.switchDeviceStateConclusion =
        data.report.switchDeviceStateConclusion ??
        `此设备为首次/第X次由ABB中压服务提供维护保养服务，本次维护保养完成了XXX台开关柜（XXX断路器XX台，XXX断路器XX台）的维护保养工作，发现X处属于严重安全隐患，XX处设备运行缺陷。X处严重安全隐患及XX处设备运行缺陷已经得到处理；经过此次维护保养，设备已恢复良好的运行状态。`
      setDetail(data)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (report) {
      fetchPreviewInfo(report.id)
    }
  }, [report])

  const getReport = () => {
    return detail?.report
  }
  useImperativeHandle(ref, () => ({
    getReport,
  }))
  function getRankColorFromNum(rank) {
    try {
      var color = ''
      switch (rank) {
        case 1:
          color = 'red'
          break
        case 2:
          color = 'orange'
          break
        case 3:
          color = 'yellow'
          break
        default:
          color = 'green'
          break
      }
      return color
    } catch (exception) {}
  }

  useEffect(() => {
    const myChart = echarts.init(charRef.current)

    let option = {
      title: {
        text: '各类型缺陷台次',
        x: 'center',
      },
      toolbox: {
        //显示策略，可选为：true（显示） | false（隐藏），默认值为false
        show: true,
        //启用功能，目前支持feature，工具箱自定义功能回调处理
        feature: {
          dataView: { show: true, readOnly: true },

          //saveAsImage，保存图片（IE8-不支持）,图片类型默认为'png'
          saveAsImage: { show: true },
        },
      },
      tooltip: {
        //触发类型，默认（'item'）数据触发，可选为：'item' | 'axis'
        trigger: 'axis',
      },
      legend: {
        data: ['台次'],
        x: 'center',
        y: 'bottom',
      },
      xAxis: {
        data: ['紧急设备缺陷', '重大设备缺陷', '一般设备缺陷'],
      },
      yAxis: {
        ype: 'value',
        name: '设备故障(台次)',
      },
      series: [
        {
          name: '台次',
          type: 'bar',
          data: [detail?.problemCounts[1] ?? 0, detail?.problemCounts[2] ?? 0, detail?.problemCounts[3] ?? 0],
          itemStyle: {
            normal: {
              //这里是重点
              color: function (params) {
                //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                var colorList = ['#EE5C42', '#FFA54F', '#7CCD7C']
                return colorList[params.dataIndex]
              },
            },
          },
        },
      ],
    }

    myChart.setOption(option)

    if (detail) {
      const ps = (detail?.problems ?? []).concat(detail?.testProblems ?? [])
      setProblems(ps)
    }
  }, [detail])

  return (
    <>
      {isLoading && (
        <div style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
              zIndex: 9999,
            }}
          >
            <LoadingIndicator type='radial' sizeClass='medium' determinate={false} color='blue' />
          </div>
        </div>
      )}
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }} className='total-report'>
        <div className='cover'>
          <h1>
            <span data-project='customerCompanyName'>{detail?.project?.customer.name}</span>
          </h1>
          <h2>
            <span data-switchingroom='name'>{detail?.station?.name}</span>变电站
          </h2>
          <h2>
            <span data-device='name'>{detail?.deviceTitle}</span>检修维护保养报告
          </h2>
          <div style={{ textAlign: 'left', flexDirection: 'row', display: 'flex' }}>
            <dl style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: 1 }}>
              <dt>报告状态：</dt>
              <dd>{totalStatus.find((s) => s.key === detail?.report?.status)?.title}</dd>
              {detail?.report?.status < 0 && (
                <>
                  <dt style={{ marginTop: 16 }}>{totalStatusText.find((s) => s.key === detail?.report?.status)?.title}</dt>
                  <dd>{detail?.report?.suggestion}</dd>
                </>
              )}
            </dl>
            <img src='/templates/TotalReportCover.png' alt='cover' />
          </div>
          <p>厦门ABB开关有限公司</p>
        </div>
        <div className='summary'>
          <h2>报告摘要</h2>
          <ul>
            <li>
              <h4>1、背景</h4>
              <textarea
                id='summaryBackground'
                value={detail?.report?.summaryBackground}
                readOnly={readonly}
                onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, summaryBackground: e.target.value } })}
                name='summaryBackground'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
            <li>
              <h4>2、安全</h4>

              <textarea
                id='summarySecurity'
                value={detail?.report?.summarySecurity}
                onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, summarySecurity: e.target.value } })}
                readOnly={readonly}
                name='summarySecurity'
                className='k-textbox'
                rows='1'
              ></textarea>
            </li>
            <li>
              <h4>3、发现</h4>
              <div hidden='hidden' id='summaryDiscoveryTemplate'></div>
              <textarea
                id='summaryDiscovery'
                name='summaryDiscovery'
                value={detail?.report?.summaryDiscovery}
                onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, summaryDiscovery: e.target.value } })}
                readOnly={readonly}
                className='k-textbox'
                rows='7'
              ></textarea>
              <div id='chart' ref={charRef} style={{ width: '100%', height: '350px' }}></div>
              <div id='jinjitable'>
                <h5>紧急设备缺陷</h5>
                <table className='center row1-blue col1-blue col1-bold'>
                  <thead>
                    <tr className='border-top-solid  border-right-dotted'>
                      <th style={{ width: '10%' }}>序号</th>
                      <th style={{ width: '55%' }}>设备缺陷描述</th>
                      <th style={{ width: '15%' }}>涉及设备</th>
                      <th style={{ width: '20%' }}>备注</th>
                    </tr>
                  </thead>
                  <tbody>
                    {problems
                      .filter((p) => p.rank === 1)
                      .map((p, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{p.description}</td>
                            <td>{p.devices}</td>
                            <td></td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div id='zhongdatable'>
                <h5>重大设备缺陷</h5>
                <table>
                  <thead>
                    <tr className='border-top-solid  border-right-dotted'>
                      <th style={{ width: '10%' }}>序号</th>
                      <th style={{ width: '55%' }}>设备缺陷描述</th>
                      <th style={{ width: '15%' }}>涉及设备</th>
                      <th style={{ width: '20%' }}>备注</th>
                    </tr>
                  </thead>
                  <tbody>
                    {problems
                      .filter((p) => p.rank === 2)
                      .map((p, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{p.description}</td>
                            <td>{p.devices}</td>
                            <td></td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <h4>4、结论</h4>
              <div hidden='hidden' id='summaryConclusionTemplate'>
                <p></p>
              </div>
              <textarea
                id='summaryConclusion'
                value={detail?.report?.summaryConclusion}
                onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, summaryConclusion: e.target.value } })}
                readOnly={readonly}
                name='summaryConclusion'
                className='k-textbox'
                rows='4'
              ></textarea>
            </li>
          </ul>
        </div>
        <div className='content'>
          <ul>
            <li>
              <h5>1. 项目信息</h5>
              <ul>
                <li>
                  <h5>1.1 维护保养服务团队</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td style={{ width: '16%' }}>公司名称：</td>
                        <td id='maintenanceTeamCompanyName' style={{ width: '84%' }}>
                          {detail?.team?.company}
                        </td>
                      </tr>
                      <tr>
                        <td>项目经理</td>
                        <td id='maintenanceTeamPM'>{detail?.team?.manager?.name}</td>
                      </tr>
                      <tr>
                        <td>联系电话</td>
                        <td id='maintenanceTeamContactInfo'>{detail?.team?.manager?.phoneNumber}</td>
                      </tr>
                      <tr>
                        <td>邮箱</td>
                        <td id='maintenanceTeamEmail'>{detail?.team?.manager?.email}</td>
                      </tr>
                      <tr>
                        <td>公司地址</td>
                        <td id='maintenanceTeamAddress'>厦门市翔安区舫山西二路 885 号</td>
                      </tr>
                      <tr>
                        <td style={{ color: 'red' }}>安全监督员</td>
                        <td id='maintenanceTeamSafetySupervisor'>{detail?.team?.members?.find((t) => t.role === 'safety')?.name}</td>
                      </tr>
                      <tr>
                        <td>执行团队成员</td>
                        <td id='maintenanceTeamMembers'>
                          {detail?.team?.members
                            ?.filter((t) => t.role !== 'safety')
                            ?.map((t) => t.name)
                            .join(',')}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>1.2 项目信息</h5>
                  <table className='center row1-blue col1-blue col1-bold'>
                    <tbody>
                      <tr>
                        <td>项目名称</td>
                        <td id='projectName' colSpan=' 3'>
                          {detail?.project?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>合同编号</td>
                        <td id='projectContractNo' colSpan='3'>
                          {detail?.project?.no}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '16%' }}>执行时间</td>
                        <td id='projectStartTime' style={{ width: '36%' }}>
                          {detail?.project?.startDate && moment(detail?.project.startDate).format('yyyy-MM-DD')}
                        </td>
                        <td style={{ width: '12%' }} className='blue border-solid'>
                          至
                        </td>
                        <td id='projectEndTime' style={{ width: '36%' }}>
                          {detail?.project?.endDate && moment(detail?.project.endDate).format('yyyy-MM-DD')}
                        </td>
                      </tr>
                      <tr>
                        <td>客户联系人</td>
                        <td id='customerOwner' colSpan='3'>
                          {detail?.project?.contacts?.map((t) => t.name).join(',')}
                        </td>
                      </tr>
                      <tr>
                        <td>联系电话</td>
                        <td id='customerContactInfo' colSpan='3'>
                          {detail?.project?.contacts?.map((t) => t.phoneNumber).join(',')}
                        </td>
                      </tr>
                      <tr>
                        <td>传真/邮箱</td>
                        <td id='customerFaxcustomerEmail' colSpan='3'>
                          {detail?.project?.contacts?.map((t) => t.email).join(',')}
                        </td>
                      </tr>
                      <tr>
                        <td>客户公司名称</td>
                        <td id='customerCompanyName' colSpan='3'>
                          {detail?.project?.customer?.name}
                        </td>
                      </tr>
                      <tr>
                        <td>地址</td>
                        <td id='customerAddress' colSpan='3'>
                          {detail?.project?.customer?.address}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>1.3 设备信息</h5>
                  <table className='center col1-blue col1-bold'>
                    <tbody>
                      {Object.entries(groupBy(detail?.devices, 'type')).map(([k, v]) => {
                        return (
                          <tr>
                            <td style={{ width: '16%' }}>{k}型号(数量)</td>
                            <td className='left' data-dokey='CabinetModel' data-doid='cabinetModelIDs' data-donum='cabinetModelQuantitys'>
                              {Object.entries(groupBy(v, 'name')).map(([n, g]) => {
                                return `${n}(${sumBy(g, (i) => i.devices.length)})`
                              })}
                            </td>
                          </tr>
                        )
                      })}
                      <tr className='border-bottom-solid'>
                        <td>备注</td>
                        <td colSpan='5' className='left'></td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>1.4 站状况图览</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td id='beforeMaintenanceImages'>
                          <p>站初始状况</p>
                          <Image id={detail?.station?.pictures['1']} style={{ maxWidth: 230, maxHeight: 230 }} />
                        </td>
                      </tr>
                      <tr>
                        <td id='MaintenanceImages'>
                          <p>站维护保养中</p>
                          <Image id={detail?.station?.pictures['2']} style={{ maxWidth: 230, maxHeight: 230 }} />
                        </td>
                      </tr>
                      <tr>
                        <td id='afterMaintenanceImages'>
                          <p>站维护保养结束</p>
                          <Image id={detail?.station?.pictures['3']} style={{ maxWidth: 230, maxHeight: 230 }} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
            <li>
              <h5>2. 变电站运行环境及系统状态</h5>
              <ul>
                <li>
                  <h5>2.1 变电站环境状况</h5>
                  {Object.entries(envs).map(([k, v]) => {
                    return (
                      <table>
                        <thead>
                          <tr>
                            <td className='blue' style={{ width: '20%' }}>
                              {k}
                            </td>
                            <td colSpan='2' style={{ width: '40%' }}></td>
                            <td style={{ width: '40%' }}>备注</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(v).map(([t, a]) => {
                            return (
                              <>
                                <tr>
                                  <td colSpan='4'>{t}</td>
                                </tr>
                                <tr>
                                  <td colSpan='3' data-option='ventilation'>
                                    {a.map((v) => {
                                      return (
                                        <label>
                                          <input type='checkbox' disabled={true} checked={detail?.station?.environments[t]?.value === v} />
                                          {v}
                                        </label>
                                      )
                                    })}
                                  </td>
                                  <td>{detail?.station?.environments[t]?.description}</td>
                                </tr>
                              </>
                            )
                          })}
                        </tbody>
                      </table>
                    )
                  })}
                </li>
                <li>
                  <h5>2.2 变电站系统状态一览</h5>
                  <table>
                    <thead>
                      <tr>
                        <td style={{ width: '20%' }} className='blue'>
                          站内温湿度
                        </td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td>备注</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          温度: <span data-switchingroom='stationTemperature'>{detail?.station?.deviceStatus['温度']?.value}</span> ℃
                        </td>
                        <td>
                          湿度: <span data-switchingroom='stationHumidity'>{detail?.station?.deviceStatus['湿度']?.value}</span> %
                        </td>
                        <td>{detail?.station?.deviceStatus['温度']?.description}</td>
                        <td>
                          <span data-switchingroom='stationTemHumRemark'>{detail?.station?.deviceStatus['湿度']?.description}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue' style={{ width: '20%' }}>
                          消谐方式
                        </td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    {console.log(detail?.station)}
                    <tbody>
                      <tr>
                        <td colspan='3' data-option='connectionOfPT' data-optionkey='ConnectionOfPT' className='col_2_w'>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.value?.split(',')?.includes('二次微机消谐')}
                            />
                            二次微机消谐
                          </label>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.value?.split(',')?.includes('二次电阻消谐')}
                            />
                            二次电阻消谐
                          </label>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.value?.split(',')?.includes('一次消谐电阻')}
                            />
                            一次消谐电阻
                          </label>
                          <label>
                            <input
                              type='checkbox'
                              disabled='disabled'
                              checked={detail?.station?.deviceStatus['消谐方式']?.value?.split(',')?.includes('四PT消谐方案')}
                            />
                            四PT消谐方案
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='connectionOfPTRemark'>{detail?.station?.deviceStatus['消谐方式']?.description}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue'>系统最大负荷</td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Ⅰ段母线负荷电流</td>
                        <td>
                          <span data-switchingroom='busLoadCurrentOne'>{detail?.station?.deviceStatus['Ⅰ段母线负荷电流']?.value}</span>
                        </td>
                        <td>A</td>
                        <td>{detail?.station?.deviceStatus['Ⅰ段母线负荷电流']?.description}</td>
                      </tr>
                      <tr>
                        <td>Ⅱ段母线负荷电流</td>

                        <td>
                          <span data-switchingroom='busLoadCurrentTwo'>{detail?.station?.deviceStatus['Ⅱ段母线负荷电流']?.value}</span>
                        </td>
                        <td>A</td>

                        <td>
                          <span data-switchingroom='busLoadCurrentReamrk'>{detail?.station?.deviceStatus['Ⅱ段母线负荷电流']?.description}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue'>辅助电源</td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>控制电源电压</td>
                        <td colspan='2' data-option='controlVoltageStyle' data-optionkey='ControlSupplyVoltage'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['控制电源电压']?.value === 'AC'} />
                            AC
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['控制电源电压']?.value === 'DC'} />
                            DC
                          </label>
                        </td>
                        <td>{detail?.station?.deviceStatus['控制电源电压']?.description }</td>
                      </tr>
                      <tr>
                        <td>Ⅰ段控母电压</td>
                        <td>
                          <span data-switchingroom='controlVoltageOne'>{detail?.station?.deviceStatus['Ⅰ段控母电压']?.value}</span>
                        </td>
                        <td>V</td>
                        <td>{detail?.station?.deviceStatus['Ⅰ段控母电压']?.description}</td>
                      </tr>
                      <tr>
                        <td>Ⅱ段控母电压</td>

                        <td>
                          <span data-switchingroom='controlVoltageTwo'>{detail?.station?.deviceStatus['Ⅱ段控母电压']?.value}</span>
                        </td>
                        <td>V</td>

                        <td>
                          <span data-switchingroom='controlVoltageRemark'>{detail?.station?.deviceStatus['Ⅱ段控母电压']?.description}</span>
                        </td>
                      </tr>

                      <tr>
                        <td style={{ width: '20%' }}>储能电源电压</td>
                        <td colSpan='2' data-option='storageVoltageStyle' data-optionkey='EnergySupplyVoltage'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['储能电源电压']?.value === 'AC'} />
                            AC
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['储能电源电压']?.value === 'DC'} />
                            DC
                          </label>
                        </td>
                        <td>{detail?.station?.deviceStatus['储能电源电压']?.description}</td>
                      </tr>
                      <tr>
                        <td>Ⅰ段合母电压</td>

                        <td>
                          <span data-switchingroom='parentingVoltageOne'>{detail?.station?.deviceStatus['Ⅰ段合母电压']?.value}</span>
                        </td>
                        <td>V</td>
                        <td>{detail?.station?.deviceStatus['Ⅰ段合母电压']?.description}</td>
                      </tr>
                      <tr>
                        <td>Ⅱ段合母电压</td>

                        <td>
                          <span data-switchingroom='parentingVoltageTwo'>{detail?.station?.deviceStatus['Ⅱ段合母电压']?.value}</span>
                        </td>
                        <td>V</td>

                        <td>
                          <span data-switchingroom='storageVoltageRemark'>{detail?.station?.deviceStatus['Ⅱ段合母电压']?.description}</span>
                        </td>
                      </tr>
                      <tr id=''>
                        <td>加热器电源电压</td>
                        <td colSpan='2' data-option='heaterVoltageStyle' data-optionkey='HeaterSupplyVoltage'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['加热器电源电压']?.value === 'AC'} />
                            AC
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['加热器电源电压']?.value === 'DC'} />
                            DC
                          </label>
                        </td>
                        <td>{detail?.station?.deviceStatus['加热器电源电压']?.description}</td>
                      </tr>
                      <tr>
                        <td>Ⅰ段加热器电压</td>

                        <td>
                          <span data-switchingroom='heaterVoltageOne'>{detail?.station?.deviceStatus['Ⅰ段加热器电压']?.value}</span>
                        </td>
                        <td>V</td>
                        <td>{detail?.station?.deviceStatus['Ⅰ段加热器电压']?.description}</td>
                      </tr>
                      <tr>
                        <td>Ⅱ段加热器电压</td>

                        <td>
                          <span data-switchingroom='heaterVoltageTwo'>{detail?.station?.deviceStatus['Ⅱ段加热器电压']?.value}</span>
                        </td>
                        <td>V</td>

                        <td>
                          <span data-switchingroom='heaterVoltageRemark'>{detail?.station?.deviceStatus['Ⅱ段加热器电压']?.description}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue'>接地情况</td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '20%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan='4'>开关柜接地情况</td>
                      </tr>
                      <tr>
                        <td colspan='3' data-option='cabinetEarthingMode' data-optionkey='CabinetEarthingMode'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['开关柜接地情况']?.value === '良好'} />
                            良好
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['开关柜接地情况']?.value === '需要改进'} />
                            需要改进
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['开关柜接地情况']?.value === '没有接地'} />
                            没有接地
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='cabinetEarthingModeRemark'>{detail?.station?.deviceStatus['开关柜接地情况']?.description}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table>
                    <thead>
                      <tr>
                        <td className='blue' style={{ width: '20%' }}>
                          其它检查情况
                        </td>
                        <td colSpan='2' style={{ width: '40%' }}></td>
                        <td style={{ width: '40%' }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan='4'>运行操作工具</td>
                      </tr>
                      <tr>
                        <td colSpan='3' data-option='operationTools' data-optionkey='OperationTools'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['运行操作工具']?.value === '齐全,状态良好'} />
                            齐全，状态良好
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['运行操作工具']?.value === '部分缺损'} />
                            部分缺损
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['运行操作工具']?.value === '无'} />无
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='operationToolsRemark'>{detail?.station?.deviceStatus['运行操作工具']?.description}</span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan='4'>维修专用工具</td>
                      </tr>
                      <tr>
                        <td colSpan='3' data-option='maintainTools' data-optionkey='MaintainTools'>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['维修专用工具']?.value === '齐全,状态良好'} />
                            齐全，状态良好
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['维修专用工具']?.value === '部分缺损'} />
                            部分缺损
                          </label>
                          <label>
                            <input type='checkbox' disabled='disabled' checked={detail?.station?.deviceStatus['维修专用工具']?.value === '无'} />无
                          </label>
                        </td>
                        <td>
                          <span data-switchingroom='maintainToolsRemark'>{detail?.station?.deviceStatus['维修专用工具']?.description}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ul>
            </li>
            <li>
              <h5>3. 设备维护保养状态及总结</h5>
              <ul>
                <li>
                  <h5>3.1 设备维护保养状态</h5>
                  <div hidden='hidden' id='deviceMaintenanceStateTemplate'></div>
                  <textarea
                    id='deviceMaintenanceState'
                    name='deviceMaintenanceState'
                    className='k-textbox'
                    rows='3'
                    readOnly={readonly}
                    onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, deviceMaintenanceState: e.target.value } })}
                    value={detail?.report?.deviceMaintenanceState}
                    style={{ width: '100%', height: '75px' }}
                  ></textarea>
                  <h6>状态标示说明</h6>
                  <table className='col-border'>
                    <tbody>
                      <tr>
                        <td rowspan='4' style={{ width: '50%' }} className='border-bottom-solid'>
                          设备总览表及问题汇总采用红、黄绿表示维保前后评估结果，各图标含义如下：
                        </td>
                        <td>
                          <span className='circle red'></span>
                          严重安全问题，存在威胁设备正常运行的安全缺陷
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className='circle orange'></span>
                          影响安全问题
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className='circle yellow'></span>
                          一般性安全问题，开关设备存在运行缺陷
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className='circle green'></span>
                          开关设备运行状态良好
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h6>设备维护保养情况</h6>
                  <table id='DeviceMaintenanceSitulation'>
                    <thead className='border-bottom-dotted'>
                      <tr>
                        <td style={{ width: '21%' }}>开关柜/设备编号</td>
                        <td style={{ width: '21%' }}>用户设备号</td>
                        <td style={{ width: '10%' }}>检查结果</td>
                        <td style={{ width: '10%' }}>维护保养后</td>
                        <td style={{ width: '38%' }}>备注</td>
                      </tr>
                    </thead>
                    <tbody>
                      {detail?.results &&
                        detail.results.map((r) => {
                          return (
                            <tr>
                              <td>{r.sn}</td>
                              <td>{r.name}</td>
                              <td>
                                <span className={`circle ${getRankColorFromNum(r.rank)}`}></span>
                              </td>
                              <td>
                                <span className={`circle ${getRankColorFromNum(r.afterRank)}`}></span>
                              </td>
                              <td>{r.measures}</td>
                            </tr>
                          )
                        })}
                      <tr>
                        <td colSpan='5'>
                          <span style={{ fontWeight: 'bold' }}>其它问题备注：</span>
                          <textarea
                            id='deviceMaintenanceRemark'
                            name='deviceMaintenanceRemark'
                            rows='3'
                            readOnly={readonly}
                            style={{ width: '100%' }}
                            onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, deviceMaintenanceRemark: e.target.value } })}
                            value={detail?.report?.deviceMaintenanceRemark}
                            className='k-textbox'
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>3.2 设备问题汇总</h5>
                  <table className='col-border' id='problemlist'>
                    <thead>
                      <tr>
                        <td className='blue' style={{ width: '30%' }}>
                          图片（处理前）
                        </td>
                        <td className='blue' style={{ width: '30%' }}>
                          图片（处理后）
                        </td>
                        <td className='blue' colSpan='4'>
                          问题及处理描述
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {detail &&
                        detail.problems &&
                        detail.problems.map((p) => {
                          return (
                            <>
                              <tr>
                                <td rowspan='4'>
                                  <Image id={p.beforPhoto} style={{ width: '100%' }} />
                                </td>
                                <td rowspan='4'>
                                  <Image id={p.afterPhtot} style={{ width: '100%' }} />
                                </td>
                                <td className='heading'>问题描述</td>
                                <td>{p.description}</td>
                                <td className='heading'>问题等级</td>
                                <td style={{ width: '40px' }}>
                                  <span className={`circle ${getRankColorFromNum(p.rank)}`}></span>
                                </td>
                              </tr>
                              <tr>
                                <td className='heading'>处理结果</td>
                                <td>{p.result === 1 ? '已处理' : '未处理'}</td>
                                <td className='heading'>最终状况</td>
                                <td>
                                  <span className={`circle ${getRankColorFromNum(p.afterRank)}`}></span>
                                </td>
                              </tr>
                              <tr>
                                <td className='heading'>处理措施</td>
                                <td colspan='3'>{p.measures}</td>
                              </tr>
                              <tr>
                                <td className='heading'>涉及设备</td>
                                <td colspan='3'>{p.devices}</td>
                              </tr>
                            </>
                          )
                        })}
                      <tr>
                        <td colSpan='6'>
                          <span className='bold'>备注：</span>
                          <textarea
                            id='deviceProblemSummaryRemark'
                            value={detail?.report?.deviceProblemSummaryRemark}
                            onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, deviceProblemSummaryRemark: e.target.value } })}
                            readOnly={readonly}
                            rows='3'
                            className='k-textbox'
                            style={{ width: '100%' }}
                          ></textarea>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>3.3 试验项问题总汇</h5>
                  <table className='col-border' id='explist'>
                    <thead>
                      <tr className='border-top-solid  border-right-dotted'>
                        <td className='blue' style={{ width: '20%' }}>
                          试验项目
                        </td>
                        <td className='blue' style={{ width: '10%' }}>
                          缺陷等级
                        </td>
                        <td className='blue' style={{ width: '10%' }}>
                          处理结果
                        </td>
                        <td className='blue' style={{ width: '20%' }}>
                          缺陷描述
                        </td>
                        <td className='blue' style={{ width: '20%' }}>
                          处理措施
                        </td>
                        <td className='blue' style={{ width: '20%' }}>
                          涉及设备
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {detail &&
                        detail.testProblems &&
                        detail.testProblems.map((p) => {
                          return (
                            <>
                              <tr>
                                <td style={{ width: '20%' }}>{p.name}</td>
                                <td style={{ width: '10%' }}>
                                  <span className={`circle ${getRankColorFromNum(p.rank)}`}></span>
                                </td>
                                <td style={{ width: '10%' }}>{p.result === 1 ? '已处理' : '未处理'}</td>
                                <td style={{ width: '20%' }}>{p.description}</td>
                                <td style={{ width: '20%' }}>{p.measures}</td>
                                <td style={{ width: '20%' }}>{p.devices}</td>
                              </tr>
                            </>
                          )
                        })}
                    </tbody>
                  </table>
                </li>
                <li>
                  <h5>3.4 维护保养作业总结</h5>

                  <div id='workSummaryImageUrl'></div>
                  <ul>
                    <li>
                      <h6>系统状态一览</h6>
                      <div hidden='hidden' id='deviceStateScanTemplate'>
                        <p>
                          <span data-switchingroom='name'></span>
                        </p>
                      </div>
                      <textarea
                        id='deviceStateScan'
                        value={detail?.report?.deviceStateScan}
                        onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, deviceStateScan: e.target.value } })}
                        readOnly={readonly}
                        name='deviceStateScan'
                        style={{ width: '100%' }}
                        className='k-textbox'
                        rows='2'
                      ></textarea>
                    </li>
                    <li>
                      <h6>开关设备状态结论</h6>

                      <textarea
                        id='switchDeviceStateConclusion'
                        name='switchDeviceStateConclusion'
                        value={detail?.report?.switchDeviceStateConclusion}
                        readOnly={readonly}
                        style={{ width: '100%' }}
                        onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, switchDeviceStateConclusion: e.target.value } })}
                        className='k-textbox'
                        rows='2'
                      ></textarea>
                    </li>
                    <li>
                      <h6>未处理问题</h6>
                      <textarea
                        id='undealedProblem'
                        name='undealedProblem'
                        value={detail?.report?.undealedProblem}
                        readOnly={readonly}
                        onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, undealedProblem: e.target.value } })}
                        style={{ width: '100%' }}
                        className='k-textbox'
                        rows='3'
                      ></textarea>
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>3.5 开关柜运行管理建议</h5>
                  <textarea
                    id='cabinetWorkOtherAdvice'
                    name='cabinetWorkOtherAdvice'
                    readOnly={readonly}
                    onChange={(e) => setDetail({ ...detail, report: { ...detail?.report, cabinetWorkOtherAdvice: e.target.value } })}
                    value={detail?.report?.cabinetWorkOtherAdvice}
                    style={{ width: '100%' }}
                    className='k-textbox'
                    rows='4'
                  ></textarea>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
})
