import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { getDataDictionaryAll } from "common/endpoints";

export const fetchGetDataDictionaryAsync = createAsyncThunk(
  "dataDictionary/fetchGetDataDictionaryAsync",
  () => axios.post(getDataDictionaryAll())
    .then((response) => {
      // console.log(response?.data)
      return response?.data
    }).catch(e => {
      console.log(e)
    })
);

const dataDictionarySlice = createSlice({
  name: "dataDictionary",
  initialState: {
    dictionary: [],
  },
  reducers: {
    setDictionary(state, action) {
      state.dictionary = action.payload
    },
  },
  extraReducers: {
    [fetchGetDataDictionaryAsync.pending]: (state, action) => {
      state.dictionary = [];
    },
    [fetchGetDataDictionaryAsync.fulfilled]: (state, action) => {
      state.dictionary = action.payload;
    },
  },
})

export const {
  setDictionary,
} = dataDictionarySlice.actions
export const getDictionary = (state) => state.dataDictionary.dictionary
export default dataDictionarySlice.reducer


