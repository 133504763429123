import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { getOrganization as apiGetOrganization } from "common/endpoints";

export const fetchGetOrganizationAsync = createAsyncThunk(
  "organization/fetchGetOrganizationAsync",
  () => axios.post(apiGetOrganization())
    .then((response) => {
      // console.log(response?.data)
      return response?.data
    }).catch(e => {
      console.log(e)
    })
);

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organization: null,
  },
  reducers: {
    setOrganization(state, action) {
      state.organization = action.payload
    },
  },
  extraReducers: {
    [fetchGetOrganizationAsync.pending]: (state, action) => {
      state.organization = null;
    },
    [fetchGetOrganizationAsync.fulfilled]: (state, action) => {
      state.organization = action.payload;
    },
  },
})

export const {
  setOrganization,
} = organizationSlice.actions
export const getOrganization = (state) => state.organization.organization
export default organizationSlice.reducer


